import { SortingState } from '@applift/datagrid'

export const sortingString = (sorting: SortingState) => {
  return {
    sortBy: sorting.length
      ? sorting
          .map(
            (sort: { desc: boolean; id: string }) =>
              `${sort.desc ? '-' : '+'}${sort.id}`
          )
          .join(',')
      : undefined,
  }
}
