import { useAppContext } from '@applift/platform'
import {
  createFileRoute,
  redirect,
  useRouteContext,
} from '@tanstack/react-router'
import * as React from 'react'
import { CreateWrapper } from '../../modules/CreateWrapper'
import { useStore } from '../../store'

export const Route = createFileRoute('/create/')({
  beforeLoad: ({ context }) => {
    // We will not let user create report if he has not created any campaigns
    if (context._root.hasCampaigns === false) {
      throw redirect({ to: '/' })
    }
  },
  component: () => <CreatePage />,

  errorComponent: () => <h3>error loading data</h3>,
})

export const CreatePage = () => {
  const contextData = useRouteContext({ from: '__root__' })
  const dimensionAndMetricsListData =
    contextData._root.dimensionAndMetricsListData
  const [setReportMode] = useStore(state => [state.report.setReportMode])

  const ctx = useAppContext()
  const orgType = ctx.appMeta.organizationType
  const [
    initCreateDimsAndMetrics,
    isCreatingFromDraft,
    setSelectedCustomersData,
    setIsCustomerEdit,
  ] = useStore(state => [
    state.selectedDimensionsAndMetricsSlice.initCreateDimsAndMetrics,
    state.selectedDimensionsAndMetricsSlice.isCreatingFromDraft,
    state.customerFilter.initSelectedCustomersData,
    state.customerFilter.setIsCustomerEdit,
  ])
  const toggleCustomerDialog = useStore(
    state => state.customerFilter.toggleCustomerDialog
  )

  React.useEffect(() => {
    if (dimensionAndMetricsListData && !isCreatingFromDraft) {
      initCreateDimsAndMetrics(
        [],
        dimensionAndMetricsListData.defaultMetrics,
        false,
        false
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensionAndMetricsListData, initCreateDimsAndMetrics])

  React.useEffect(() => {
    setReportMode('Create')
    setSelectedCustomersData(contextData._root.customerList)
  }, [contextData._root.customerList, setReportMode, setSelectedCustomersData])

  React.useEffect(() => {
    if (orgType !== 'ADVERTISER' && !isCreatingFromDraft) {
      toggleCustomerDialog(true)
      setIsCustomerEdit(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleCustomerDialog])

  return <CreateWrapper />
}
