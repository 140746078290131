import {
  DateRangePicker,
  DateRangeType,
  MinMaxParamsType,
} from '@applift/app-utils'
import { Box, enqueueSnackbar } from '@applift/factor'
import { now } from '@internationalized/date'
import * as React from 'react'
import { DateRange } from '../../../models/DateTime'
import { useStore } from '../../../store'
import { daysDifference } from '../../../utils/dateDifference'
import styles from './index.module.scss'

interface IFilterDateRange {
  dateRange?: DateRange
  setDateRange: (prop: any) => void
  variant: 'Campaign Start Date' | 'Campaign End Date'
}

export const FilterDateRangePicker = (props: IFilterDateRange) => {
  const { dateRange, setDateRange, variant } = props

  const [timezone] = useStore(state => [state.report.timezone])

  const [prevDateRange, setPrevDateRange] = React.useState<DateRange | null>()

  const maxValueParams: MinMaxParamsType = {
    param: 'months',
    subtractor: variant === 'Campaign End Date' ? -1 : 0,
  }
  const minValueParams: MinMaxParamsType = {
    param: 'months',
    subtractor: 36,
  }

  const handleDateChange = (newDateRange: DateRangeType) => {
    setPrevDateRange(dateRange)
    setDateRange(newDateRange)
  }

  const defaultStartDate = now(timezone?.value ?? 'UTC')
    ?.subtract({
      months: 3,
    })
    .add({ days: 1 })
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .toDate()
    .valueOf()

  const defaultEndDate = now(timezone?.value ?? 'UTC')
    .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
    .toDate()
    .valueOf()

  const defaultDateRange: DateRangeType = {
    startDate: defaultStartDate,
    endDate: defaultEndDate,
  }

  React.useEffect(() => {
    if (
      dateRange &&
      prevDateRange &&
      daysDifference(dateRange.startDate, dateRange.endDate) > 365
    ) {
      enqueueSnackbar({
        variant: 'error',
        message: 'Date range cannot exceeds more than one year',
      })
      setDateRange(prevDateRange)
    }
  }, [dateRange, prevDateRange, setDateRange])

  return (
    <>
      <Box className={styles.daterangePicker} sx={{ ml: 0 }}>
        <DateRangePicker
          dateRange={dateRange || defaultDateRange}
          onChange={handleDateChange}
          maxValueParams={maxValueParams}
          minValueParams={minValueParams}
          timezoneName={timezone?.value ?? 'UTC'}
          hideDateSegment={false}
          disabled={false}
          label="Date Range"
          variant="outlinedDash"
        />
      </Box>
    </>
  )
}
