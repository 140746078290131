import { now } from '@internationalized/date'
import { StateCreator } from 'zustand'
import { DEFAULT_TIMEZONE } from '../../constants'
import { ReportMode, ReportType } from '../../models/Report'
import { ReportSlice, StoreFilter } from '../../models/Store'

const endDate = now(DEFAULT_TIMEZONE?.value)
  .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
  .toDate()
  .valueOf()

const startDate = now(DEFAULT_TIMEZONE?.value)
  .subtract({ months: 3 })
  .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  .toDate()
  .valueOf()

const initialState = {
  name: '',
  timezone: DEFAULT_TIMEZONE,
  dateRange: {
    startDate,
    endDate: endDate,
  },
  view: 'Aggregated' as ReportType,
  mode: 'Create' as ReportMode,
  dirty: false,
  dirtyView: false,
  dirtyName: false,
  globalDirty: false,
}

export const createReportStore: StateCreator<
  StoreFilter,
  [['zustand/devtools', never]],
  [],
  ReportSlice
> = set => ({
  report: {
    ...initialState,
    initReport: ({ name, dateRange, view, timezone }) => {
      set(
        prev => ({
          ...prev,
          report: {
            ...prev.report,
            name,
            timezone,
            dateRange,
            view,
          },
        }),
        true,
        'initReport'
      )
    },
    setReportName: value => {
      set(
        prev => ({
          ...prev,
          report: {
            ...prev.report,
            name: value,
            dirtyName: true,
          },
        }),
        true,
        'setReportName'
      )
    },
    setTimezone: value => {
      set(
        prev => ({
          ...prev,
          report: {
            ...prev.report,
            timezone: value,
            dirty: true,
          },
        }),
        true,
        'setTimezone'
      )
    },
    setDateRange: value => {
      set(
        prev => ({
          ...prev,
          report: {
            ...prev.report,
            dateRange: value,
            dirty: true,
          },
        }),
        false,
        'setDateRange'
      )
    },
    setReportView: value => {
      if (value) {
        set(
          prev => ({
            ...prev,
            report: {
              ...prev.report,
              view: value,
              dirtyView: true,
            },
          }),
          false,
          'setReportView'
        )
      }
    },
    setReportMode: value => {
      set(
        prev => ({
          ...prev,
          report: {
            ...prev.report,
            mode: value,
          },
        }),
        false,
        'setReportMode'
      )
    },
    commit: () => {
      set(
        prev => ({
          ...prev,
          report: {
            ...prev.report,
            dirty: false,
          },
        }),
        false,
        'commitReport'
      )
    },
    commitView: () => {
      set(
        prev => ({
          ...prev,
          report: {
            ...prev.report,
            dirtyView: false,
          },
        }),
        false,
        'commitReportView'
      )
    },
    commitName: () => {
      set(
        prev => ({
          ...prev,
          report: {
            ...prev.report,
            dirtyName: false,
          },
        }),
        false,
        'commitReportName'
      )
    },
    toggleGlobalDirty: (value: boolean) => {
      set(
        prev => ({
          ...prev,
          report: {
            ...prev.report,
            globalDirty: value,
          },
        }),
        false,
        'toggleGlobalDirty'
      )
    },
    reset: () => {
      set(
        prev => ({
          ...prev,
          report: {
            ...prev.report,
            ...initialState,
          },
        }),
        false,
        'resetReport'
      )
    },
  },
})
