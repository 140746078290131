import * as React from 'react'
import { DNDRefType } from '../../models/events'
import { DNDContextProvider } from '../../context/DNDContext'
import { Create } from './Create'
import { useClearCache } from '../../hooks/useClearCache'

export const CreateWrapper = () => {
  const dndRef = React.useRef({}) as DNDRefType

  const { resetStore } = useClearCache()

  React.useEffect(() => {
    return () => {
      resetStore()
    }
  }, [resetStore])

  return (
    <DNDContextProvider value={dndRef}>
      <Create />
    </DNDContextProvider>
  )
}
