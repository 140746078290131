import { TypoTooltip, SxProps, TruncateMiddle } from '@applift/factor'

interface TextCellType {
  label: number | string
  sx?: SxProps
  truncation?: 'middle' | 'end'
}

export const TextCellWithTooltip = ({
  label,
  sx,
  truncation = 'end',
}: TextCellType) => {
  return (
    <>
      {truncation === 'end' ? (
        <TypoTooltip
          arrow
          placement="top"
          TypgraphyProps={{
            gutterBottom: false,
            sx: sx,
          }}
        >
          {label}
        </TypoTooltip>
      ) : (
        <TruncateMiddle arrow placement="top">{`${label}`}</TruncateMiddle>
      )}
    </>
  )
}
