import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ButtonProps,
  Typography,
} from '@applift/factor'

interface DiscardUnsavedChangesProps {
  onCancel: ButtonProps['onClick']
  onDiscard: ButtonProps['onClick']
}

export const DiscardUnsavedChanges = (props: DiscardUnsavedChangesProps) => {
  const { onCancel, onDiscard } = props
  return (
    <>
      <DialogTitle>Discard Unsaved Changes</DialogTitle>
      <DialogContent>
        <Typography>
          You have unsaved changes. All the parameters will be cleared and
          current report data will be lost.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="primary" onClick={onDiscard}>
          Discard
        </Button>
      </DialogActions>
    </>
  )
}
