import { ColumnDef, ReactTableCellContext } from '@applift/datagrid'
import { IconTextCell } from '../cellTypes'
import { ChannelIconMapper } from '../../../models/Filters'

export const channelFilterColDef: ColumnDef<any>[] = [
  {
    accessorKey: 'name',
    header: 'Channel',
    enableSorting: false,
    size: 300,
    cell: (props: ReactTableCellContext) => {
      return (
        <IconTextCell
          iconName={
            ChannelIconMapper[
              props.renderValue() as keyof typeof ChannelIconMapper
            ] || 'error'
          }
          iconSize={24}
          label={props.renderValue() as string}
        />
      )
    },
  },
]
