import * as React from 'react'
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from '@tanstack/react-query'
import { SortingState } from '@applift/datagrid'
import { enqueueSnackbar } from '@applift/factor'
import * as ListAPI from '../api/list'
import { ListType } from '../models/List'
import { ListError } from '../models/Response'
import { WithRecordResponse } from '../models/Response'
import { ITimezone } from '../models/DateTime'
import { sortingString } from '../utils/sorting'
import { AxiosError } from 'axios'
import { useNavigate } from '@tanstack/react-router'

type List = WithRecordResponse<ListType[]>['data']

export const useReportList = (
  search = '',
  customerIds: string[] = [],
  pageSize = 10,
  sorting: SortingState = [],
  options?: {
    onSuccess?: UseInfiniteQueryOptions<List>['onSuccess']
    onSettled?: UseInfiniteQueryOptions<List>['onSettled']
    enabled?: UseInfiniteQueryOptions['enabled']
  },
  meta?: { timezoneList?: ITimezone[] }
): UseInfiniteQueryResult<
  WithRecordResponse<ListType[]>['data'],
  ListError
> => {
  const customerIdString = React.useMemo(
    () => customerIds.join(','),
    [customerIds]
  )

  const navigate = useNavigate()

  const queryData = useInfiniteQuery(
    ListAPI.getReportQueryKey.keys(
      'ReportList',
      search,
      customerIdString,
      pageSize,
      sortingString(sorting).sortBy
    ),
    ListAPI.getReportList,
    {
      getNextPageParam: (lastPage, pages) => {
        const totalRecordsFetched = pages.reduce(
          (prev, one) => prev + one.data.length,
          0
        )
        if (totalRecordsFetched < lastPage?.filteredRecords) {
          return pages.length + 1
        }
        return
      },
      onSuccess: options?.onSuccess,
      onError: (e: AxiosError<any>['response']) => {
        enqueueSnackbar(
          e?.data?.errorObjects
            ? (e.data?.errorObjects[0]?.error as string)
            : 'Something went wrong!',
          {
            variant: 'error',
          }
        )
        if (e?.status === 422) {
          navigate({ to: '/' })
        }
        return e
      },
      meta: meta,
      onSettled: options?.onSettled,
      enabled: options?.enabled ?? true,
    }
  )
  return queryData as UseInfiniteQueryResult<
    WithRecordResponse<ListType[]>['data'],
    ListError
  >
}
