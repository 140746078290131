import { ColumnDef } from '@applift/datagrid'
import { LocationFilterType } from '../../../models/Filters'
import { TextCell } from '../cellTypes'

export const congressionalDistrictFilterColDef: ColumnDef<LocationFilterType>[] =
  [
    {
      accessorKey: 'name',
      header: 'Congressional District',
      enableSorting: false,
      size: 300,
      cell: props => {
        return <TextCell label={props.renderValue() as string} />
      },
    },
  ]
