import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@applift/factor'

import { SpecialRuleType } from '../../../models/DimensionMetrics'
import { useRouteContext } from '@tanstack/react-router'

export interface RemoveDependentMetricDialogProps {
  isAggregationDependent?: SpecialRuleType['shouldToggleAggregation']
  dimensionMetricInfo?: SpecialRuleType['info']
  onClose?: (params?: any) => any
  onReset: (params?: any) => any
}

export const RemoveDependentMetricDialog = (
  props: RemoveDependentMetricDialogProps
) => {
  const { onClose, onReset, dimensionMetricInfo, isAggregationDependent } =
    props

  const contextData = useRouteContext({ from: '__root__' })
  const dimensionMetric = contextData._root.dimensionAndMetricsListData

  const dimensionName =
    dimensionMetric?.keyWiseDimensions[
      dimensionMetricInfo?.dimensions?.dimensionKey || ''
    ]

  const metricsName = dimensionMetricInfo?.metrics?.metricKey
    ?.split(', ')
    .map((val: string | number) => dimensionMetric?.keyWiseMetrics[val]?.label)
    .join(', ')

  return (
    <>
      <DialogTitle>Remove Dimension?</DialogTitle>
      <DialogContent>
        <Typography>
          Removing <strong>{dimensionName?.label}</strong> will also remove{' '}
          <strong>{metricsName}</strong>{' '}
          {isAggregationDependent ? 'as well as the report aggregation' : ''}.
          Are you sure you want to remove the dimension?
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="error" onClick={onReset}>
          Remove
        </Button>
      </DialogActions>
    </>
  )
}
