import { ColumnDef, ReactTableCellContext } from '@applift/datagrid'
import { CampaignGroupFilterType } from '../../../models/Filters'
import { TextCell } from '../cellTypes'

export const creativeGroupFilterColDef: ColumnDef<CampaignGroupFilterType>[] = [
  {
    accessorKey: 'creativeGroupName',
    header: 'Creative Group',
    enableSorting: false,
    size: 500,
    cell: (props: ReactTableCellContext) => {
      return <TextCell label={props.renderValue()} />
    },
  },
]
