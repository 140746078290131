export interface AgeGroupFilterType {
  id: number
  name: string
}

export interface CreativeFilterType {
  id: number
  name: string
  creativeTypeId: number
  creativeType: string
  status: string
  createdAt: number
  modifiedAt: number
  owId: number
  organizationName: string
  organizationLogo: string
  previewFlag: number
  creativeScreenshotSource: string
  creativeCardSource: string
  creativeThumbnailSource: string
}

export interface LocationFilterType {
  id: number | string
  name: string
  stateId: number
  parentId: number
}

export interface DeviceFilterType {
  id: number
  name: string
}

export interface CreativeTypeFilterType {
  id: number
  name: string
}

export interface CampaignGroupFilterType {
  groupId: number
  groupName: string
  createdDate: number
  owId: number
}

export interface CampaignFilterType {
  campaignId?: number
  id?: number
  name: string
  creativeTypeId: number
  status: string
  startTime: number
  endTime: number
  createdAt: number
  modifiedAt: number
  owId: number
  organizationName: string
  organizationLogo: string
}

export interface IoFilterType {
  ioId: number
  ioName: string
  ioStartTime: number
  ioEndTime: number
  ioTimeZoneId: number
  ioTotalBudget: number
  ioBudgetDistributionMethodId: number
  ioBudgetTypeId: number
  ioStatusId: number
  ioTotalImpressions: number
  isBudgetAutoSum: boolean
}

export interface IDValueFilterType {
  id: number
  name: string
}
export interface CreativeListFilterType {
  data: {
    filteredList: {
      approvalRequiredPostUrlUpdate: boolean
      createdAt: number
      creativeCardSource: string
      creativeHeight: number
      creativeId: number
      creativeName: string
      creativePreviewFlag: number
      creativeStatusId: number
      creativeThumbnailSource: string
      creativeWidth: number
      externalCreativeId: string
      modifiedAt: string
      organizationDetails: {
        owId: number
      }
      platformCreativeTypeId: number
      userDetails: {
        uowId: number
      }
    }[]
    filteredRecords: number
    totalRecords: number
  }
}
export interface DealListFilterType {
  data: {
    dealList: {
      id: number
      dealId: string
      dealName: string
      dealStatus: string
      exchange: string
      dealType: string
    }[]
    filteredRecords: number
    totalRecords: number
  }
}
export interface DealTypeFilterType {
  data: {
    dealTypeList: {
      displayName: string
      name: string
      id: string
      order: string
    }[]
    filteredRecords: number
    totalRecords: number
  }
}

export interface CampaignTypeFilterType {
  data: {
    campaignTypeDetails: {
      label: string
      name: string
      id: string
      order: string
    }[]
    filteredRecords: number
    totalRecords: number
  }
}

export interface siteAndAppFilterType {
  id: number
  name: string
  trafficType: string
}

export interface CustomerFilterType {
  organizationLogo: string
  organizationName: string
  owId: number
  owStatus: string
  owStatusId: number
  ownerUserEmail: string
  ownerUserName: string
  runningCampaigns: number
  wsId: number
}

export const ChannelIconMapper = {
  Web: 'Web',
  App: 'App',
  'OTT App': 'OTT',
  OTT: 'OTT',
  CTV: 'CTV',
}

export interface SelectedCampaignRow {
  startTime: number
  endTime: number
}
export interface SelectedIORow {
  ioStartTime: number
  ioEndTime: number
}
