import { ThemeProvider } from '@applift/factor'
import { useAppContext, useAppRootContext } from '@applift/platform'
import {
  createHashHistory,
  createRouter,
  RouterProvider,
} from '@tanstack/react-router'
import * as React from 'react'
import { queryClient } from '../cache'
import { routeTree } from '../routeTree.gen'
import { GlobalLoader } from '../components/GlobalLoader'

const TanStackRouterDevTools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then(res => ({
          default: res.TanStackRouterDevtools,
        }))
      )

const hashHistory = createHashHistory()

const router = createRouter({
  routeTree,
  context: undefined!,
  history: hashHistory,
  defaultGcTime: 0,
  defaultPendingComponent: () => <GlobalLoader />,
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

const ThemeMapper = {
  SUPER: 'blue',
  WORKSPACE: 'green',
  ADVERTISER: 'green',
}

export const AppRoute = () => {
  const appCtx = useAppContext()
  const appRootCtx = useAppRootContext()
  const orgType = appCtx.appMeta.organizationType

  return (
    <ThemeProvider
      variant={ThemeMapper[orgType] as any}
      style={{ height: '100%' }}
    >
      <RouterProvider
        router={router}
        context={{
          appContext: appCtx,
          appRootContext: appRootCtx,
          queryClient,
          _root: {
            hasCampaigns: false,
            hasReports: false,
            emptyView: 'report',
            dimensionAndMetricsListData: undefined,
            timezoneList: undefined,
            customerList: undefined,
          },
        }}
      />
      <React.Suspense>
        <TanStackRouterDevTools router={router} />
      </React.Suspense>
    </ThemeProvider>
  )
}
