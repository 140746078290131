import { AxiosError, AxiosResponse } from 'axios'
import { QueryFunctionContext } from '@tanstack/react-query'
import { WithResponse } from '../models/Response'
import { Option } from '../models/Option'
import {
  ReportSchedulingEventDetailsType,
  ScheduleResponseData,
} from '../models/Schedule'
import { getInstance } from './instance'

export const getMiscQueryKey = {
  keys: (scope: string) => [{ scope } as const],
}

export const staticDeliverySchedule: Option<number>[] = [
  {
    name: 'One Time Now',
    value: 1,
    label: 'One time now',
  },
  {
    name: 'DAY',
    value: 2,
    label: 'Daily until',
  },
  {
    name: 'WEEK',
    value: 3,
    label: 'Weekly until',
  },
  {
    name: 'MONTH',
    value: 4,
    label: 'Monthly until',
  },
]

export const getDeliverySchedule = async (
  _: QueryFunctionContext<ReturnType<(typeof getMiscQueryKey)['keys']>>
) => {
  try {
    return staticDeliverySchedule
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const staticWeeks: Option<string>[] = [
  { value: 'Sunday', label: 'Sunday', name: 'Sunday' },
  { value: 'Monday', label: 'Monday', name: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday', name: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday', name: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday', name: 'Thursday' },
  { value: 'Friday', label: 'Friday', name: 'Friday' },
  { value: 'Saturday', label: 'Saturday', name: 'Saturday' },
]

export const getWeekDay = async (
  _: QueryFunctionContext<ReturnType<(typeof getMiscQueryKey)['keys']>>
) => {
  try {
    return staticWeeks
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const staticDays: Option<string>[] = [
  { value: 'First', label: 'First Day of Month', name: 'First' },
  { value: 'Last', label: 'Last Day of Month', name: 'Last' },
  { value: '2', label: '2', name: '2' },
  { value: '3', label: '3', name: '3' },
  { value: '4', label: '4', name: '4' },
  { value: '5', label: '5', name: '5' },
  { value: '6', label: '6', name: '6' },
  { value: '7', label: '7', name: '7' },
  { value: '8', label: '8', name: '8' },
  { value: '9', label: '9', name: '9' },
  { value: '10', label: '10', name: '10' },
  { value: '11', label: '11', name: '11' },
  { value: '12', label: '12', name: '12' },
  { value: '13', label: '13', name: '13' },
  { value: '14', label: '14', name: '14' },
  { value: '15', label: '15', name: '15' },
  { value: '16', label: '16', name: '16' },
  { value: '17', label: '17', name: '17' },
  { value: '18', label: '18', name: '18' },
  { value: '19', label: '19', name: '19' },
  { value: '20', label: '20', name: '20' },
  { value: '21', label: '21', name: '21' },
  { value: '22', label: '22', name: '22' },
  { value: '23', label: '23', name: '23' },
  { value: '24', label: '24', name: '24' },
  { value: '25', label: '25', name: '25' },
  { value: '26', label: '26', name: '26' },
  { value: '27', label: '27', name: '27' },
  { value: '28', label: '28', name: '28' },
]

export const getMonthDay = async (
  _: QueryFunctionContext<ReturnType<(typeof getMiscQueryKey)['keys']>>
) => {
  try {
    return staticDays
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const addEmailSchedule = async (
  data: ReportSchedulingEventDetailsType
) => {
  try {
    const response: AxiosResponse<WithResponse<ScheduleResponseData>> =
      await getInstance().post(`/v3/ra/report/email/schedule`, data)
    return response.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const stopScheduling = async (id: number) => {
  try {
    const response: AxiosResponse<WithResponse<string>> =
      await getInstance().delete(`/v3/ra/report/email/schedule/${id}`)
    return response.data
  } catch (e) {
    return Promise.reject(e)
  }
}

export const updateEmailSchedule = async (
  updateProp: ReportSchedulingEventDetailsType
) => {
  try {
    const { reportId, ...other } = updateProp
    const response: AxiosResponse<WithResponse<ScheduleResponseData>> =
      await getInstance().patch(
        `/v3/ra/report/email/schedule/${reportId}`,
        other
      )
    return response.data
  } catch (e) {
    return Promise.reject(e)
  }
}
