import { LayoutContainer } from '@applift/factor'
import {
  AppBar,
  InfoBar,
  useAppContext,
  useAppRootContext,
} from '@applift/platform'
import { NormalizedCustomerList } from '@applift/platform'
import { QueryClient } from '@tanstack/react-query'
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'
import { getCheckCampaignExist, getTimezones } from '../api/createEdit'
import { getCustomerList } from '../api/customerList'
import { getDimensionAndMertics } from '../api/dimensionMetrics'
import * as listAPI from '../api/list'
import {
  getCustomerListKey,
  getDimensionAndMetricsKey,
  getFilterQueryKey,
} from '../api/queryKey'
import { GlobalLoader } from '../components/GlobalLoader'
import { customerStatus, defaultListSorting, ListPageSize } from '../constants'
import { ITimezone } from '../models/DateTime'
import { TransformedDimensionAndMetricsType } from '../models/DimensionMetrics'
import { FilterTypes } from '../models/filterMetaData'
import { sortingString } from '../utils'

type RootContext = {
  appRootContext: ReturnType<typeof useAppRootContext>
  appContext: ReturnType<typeof useAppContext>
  queryClient: QueryClient
  _root: {
    hasReports: boolean
    hasCampaigns: boolean
    emptyView: 'campaign' | 'report'
    timezoneList?: ITimezone[]
    dimensionAndMetricsListData?: TransformedDimensionAndMetricsType
    customerList?: Record<string, NormalizedCustomerList>
  }
}

const rootBeforeLoadFunction = async ({
  context,
}: {
  context: RootContext
}) => {
  const organizationType = context.appContext.appMeta.organizationType
  const campaignDataPromise = context?.queryClient?.fetchQuery(
    getFilterQueryKey.keys({
      scope: FilterTypes['CampaignFilter'],
    }),
    getCheckCampaignExist
  )

  const dimensionAndMetricsListDataPromise = context.queryClient.fetchQuery(
    getDimensionAndMetricsKey.keys({ scope: 'getDimensionAndMertics' }),
    getDimensionAndMertics
  )

  const timezoneListPromise = context.queryClient.fetchQuery(
    getDimensionAndMetricsKey.keys({ scope: 'getTimezones' }),
    getTimezones
  )

  // note returning empty promise if organizationType is ADVERTISER so that we don't call CustomerList  api
  const customerlistPromise =
    organizationType !== 'ADVERTISER'
      ? context?.queryClient?.fetchQuery(
          getCustomerListKey.keys({
            scope: 'getCustomerList',
            status: [customerStatus.ACTIVE, customerStatus.SUSPENDED].join(','),
            owId: context?.appContext?.appMeta?.loggedInOwId,
            organizationType: context?.appContext?.appMeta?.organizationType,
          }),
          getCustomerList
        )
      : Promise.resolve({})

  const [
    campaignData,
    timezoneList,
    dimensionAndMetricsListData,
    customerList,
  ] = await Promise.all([
    campaignDataPromise,
    timezoneListPromise,
    dimensionAndMetricsListDataPromise,
    customerlistPromise,
  ])

  const reportData = await context.queryClient.fetchInfiniteQuery(
    listAPI.getReportQueryKey.keys(
      'ReportList',
      '',
      '',
      ListPageSize,
      sortingString(defaultListSorting).sortBy
    ),
    listAPI.getReportList,
    {
      meta: { timezoneList: timezoneList },
    }
  )

  if ((campaignData?.totalRecords ?? 0) > 0) {
    context._root.hasCampaigns = true
  }
  if (context._root.hasCampaigns === false) {
    context._root.emptyView = 'campaign'
  }

  if ((reportData?.pages?.[0]?.data?.length ?? 0) > 0) {
    context._root.hasReports = true
  }
  context._root.dimensionAndMetricsListData = dimensionAndMetricsListData
  context._root.timezoneList = timezoneList
  context._root.customerList = customerList
}

export const Route = createRootRouteWithContext<RootContext>()({
  beforeLoad: rootBeforeLoadFunction,
  component: RootRoute,
  pendingComponent: () => <GlobalLoader />,
  errorComponent: () => <h3>error loading data</h3>,
})

export function RootRoute() {
  return (
    <>
      <LayoutContainer
        header={
          <>
            <InfoBar />
            <AppBar />
          </>
        }
      >
        <Outlet />
      </LayoutContainer>
    </>
  )
}
