import * as React from 'react'
import { Paper } from '@applift/factor'
import { Filter } from './filter'
import { ListGrid } from './listGrid'
import { colDef } from './colDef'
import {
  SortingState,
  ColumnPinningState,
  GridActionCellParams,
} from '@applift/datagrid'
import { ActionBar } from './actionBar'
import { ActionNameType } from './action'
import { ListType } from '../../models/List'
import { useReportList } from '../../hooks/useReportList'
import { ListPageSize, defaultListSorting } from '../../constants'
import { useRouteContext } from '@tanstack/react-router'

export const ListGridWrapper = () => {
  const [search, setSearch] = React.useState('')
  const [sorting, setSorting] = React.useState<SortingState>(defaultListSorting)
  const [customerIds, setCustomerIds] = React.useState<string[]>([])
  const [action, setAction] = React.useState<GridActionCellParams<
    ActionNameType,
    ListType
  > | null>(null)
  const [columnPinning] = React.useState<ColumnPinningState>({
    left: ['id'],
    right: ['Actions'],
  })
  const contextData = useRouteContext({ from: '__root__' })
  const timezoneList = contextData._root.timezoneList
  const closeAction = () => setAction(null)

  const { data, isLoading, fetchNextPage } = useReportList(
    search,
    customerIds,
    ListPageSize,
    sorting,
    {
      enabled: Array.isArray(timezoneList) && timezoneList.length > 0,
    },
    { timezoneList }
  )

  const filteredData = React.useMemo(
    () => data?.pages.map(val => val.data).flat(1) ?? [],
    [data?.pages]
  )

  return (
    <>
      <Paper
        elevation={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 100,
          height: 100,
        }}
      >
        <Filter
          search={search}
          setSearch={setSearch}
          customerIds={customerIds}
          setCustomerIds={setCustomerIds}
        />
        <ListGrid
          data={filteredData}
          columnDef={colDef}
          totalRecords={data?.pages[0]?.filteredRecords}
          loading={isLoading}
          onFetchRows={() => {
            // he we delay for RQ to set state and we've new page to load and not stale pages otherwise we will have infinite loading issue
            setTimeout(fetchNextPage, 100)
          }}
          sorting={sorting}
          onSortingChange={setSorting}
          rowIdKey="id"
          pageSize={ListPageSize}
          columnPinning={columnPinning}
          onAction={setAction}
          search={search}
        />
        <ActionBar
          onClose={closeAction}
          // @ts-ignore
          action={action?.actionName}
          rowData={action?.metaData}
        />
      </Paper>
    </>
  )
}
