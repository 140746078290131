import { TypoTooltip } from '@applift/factor'
import { GridActionCellParams } from '@applift/datagrid'
import { ListType } from '../../../models/List'

interface ReportNameCellType {
  action?: (args: GridActionCellParams) => any
  cellData: ListType['reportName']
}

export const ReportNameCell = ({ cellData }: ReportNameCellType) => {
  return (
    <TypoTooltip placement="top" arrow TypgraphyProps={{ gutterBottom: false }}>
      {cellData}
    </TypoTooltip>
  )
}
