import { useMutation } from '@tanstack/react-query'
import { enqueueSnackbar } from '@applift/factor'

import { DeleteReportDialog } from './DeleteReportDialog'
import { deleteReport } from '../../api/list'
import { queryClient } from '../../cache'
import { WithRecordResponse } from '../../models/Response'
import { ListType } from '../../models/List'

interface DeleteReportProps {
  onClose: (params?: any) => void
  data: ListType
}

export const ListDeleteReportDialogWrapper = (props: DeleteReportProps) => {
  const { onClose, data: reportData } = props

  const deletePostMutation = useMutation(deleteReport, {
    mutationKey: ['DeleteReport'],
    onSuccess: (response, id) => {
      queryClient.setQueriesData<WithRecordResponse<ListType[]>>(
        {
          // @ts-ignore
          predicate: query => query.queryKey[0].scope === 'ReportList',
        },
        (pages: any) => {
          const newPages = pages?.pages?.map((page: any) => {
            const newData = page.data?.filter((one: any) => one.id !== id)
            return {
              ...page,
              data: newData,
              totalRecords:
                page.totalRecords - 1 > 0 ? page.totalRecords - 1 : 0,
              filteredRecords:
                page.filteredRecords - 1 > 0 ? page.filteredRecords - 1 : 0,
            }
          })
          return { ...pages, pages: newPages }
        }
      )
      enqueueSnackbar('Report deleted successfully', { variant: 'success' })
    },
    onError: (_: any) => {
      enqueueSnackbar('failed', { variant: 'error' })
    },
    onSettled: () => {
      onClose()
    },
  })
  const deleteAction = () => {
    deletePostMutation.mutate(reportData.id)
  }
  return (
    <DeleteReportDialog
      onClose={onClose}
      data={reportData}
      deleteAction={deleteAction}
      isLoading={deletePostMutation.isLoading}
    />
  )
}
