import { Box, Button, Typography } from '@applift/factor'
import { useStore } from '../../../../store'

export interface ExecuteReportType {
  isExecuteReportFetching: boolean
}

export const ExecuteReport = (props: ExecuteReportType) => {
  const { isExecuteReportFetching } = props

  const [setExecutePayload, incrementExecutionCount] = useStore(state => [
    state.executeTable.setExecutePayload,
    state.executeTable.incrementExecutionCount,
  ])

  return (
    <Box
      sx={{
        bgColor: 'neutral-75',
        px: 16,
        py: 8,
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'between',
      }}
    >
      <Typography noWrap gutterBottom={false}>
        Execute your report to view most up to date data in the table below.
      </Typography>
      <Button
        onClick={() => {
          incrementExecutionCount()
          setExecutePayload()
        }}
        variant="outlined"
        disabled={isExecuteReportFetching}
        sx={{ flexShrink: 0 }}
      >
        Execute Report
      </Button>
    </Box>
  )
}
