import { ColumnDef } from '@applift/datagrid'
import { LocationFilterType } from '../../../models/Filters'
import { TextCell } from '../cellTypes'

export const countryFilterColDef: ColumnDef<LocationFilterType>[] = [
  {
    accessorKey: 'name',
    header: 'Country',
    enableSorting: false,
    size: 300,
    cell: props => {
      return <TextCell label={props.renderValue() as string} />
    },
  },
]
