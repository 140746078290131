import { REQUEST_TYPE } from '../constants'
import { CreateReportType, ReportExecutionPayloadType } from '../models/Report'
import { FilterMapping } from '../models/filterMetaData'

export const createExecutionPayload = (
  args: ReportExecutionPayloadType | undefined,
  isExecuteReportOnly: boolean
): CreateReportType => {
  if (!args) {
    return {} as CreateReportType
  }

  if (args.counter === 0) {
    return {} as CreateReportType
  }

  const { customerIds, dimsAndMetrics, filters, report, counter } = args

  const dimensions = dimsAndMetrics?.dimensions?.reduce((acc, val) => {
    const filterName = val.key
    let result
    // @ts-ignore
    const originalfilter = filters[FilterMapping[val.key]]
    if ((filterName === 'dealId' || filterName === 'deal') && originalfilter) {
      // @ts-ignore
      const transfromedFilter: { PG: string[]; PMP: string[] } = {
        PG: [],
        PMP: [],
      }

      const keys = Object.keys(originalfilter)

      keys.forEach(key => {
        if (key.startsWith('PG_')) {
          transfromedFilter?.PG?.push(key.substring(3))
        } else if (key.startsWith('PMP_')) {
          transfromedFilter?.PMP?.push(key.substring(4))
        }
      })
      result = [transfromedFilter]
    } else {
      // @ts-ignore
      result = filters[FilterMapping[val.key]]
    }
    // @ts-ignore
    acc = [
      // @ts-ignore
      ...acc,
      // @ts-ignore
      filterName === 'dealId' || filterName === 'deal'
        ? { filter: filterName, value: result }
        : { filter: filterName, value: result && Object.keys(result) },
    ]
    return acc
  }, [])

  const allColumns = dimsAndMetrics
    ? [...dimsAndMetrics.metrics].map(one => one.key)
    : []

  const output: CreateReportType = {
    reportName: isExecuteReportOnly ? undefined : report!.name,
    customerIds: customerIds!,
    startDate: report?.dateRange?.startDate ?? 0,
    endDate: report?.dateRange?.endDate ?? 0,
    dimensions: JSON.stringify(dimensions),
    columns: allColumns,
    requestType:
      report?.view === 'Aggregated'
        ? REQUEST_TYPE.aggregated
        : REQUEST_TYPE.daily,
    reportAggregated: dimsAndMetrics?.hasAggregation ? '1' : '0',
    timezoneName: report?.timezone?.value ?? 'UTC',
    timezoneId: report?.timezone?.id ?? 11, // 11 for UTC
    counter: isExecuteReportOnly ? counter : undefined,
  }
  return output
}
