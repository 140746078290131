import { QueryFunctionContext } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { DimensionAndMetricsApiType } from '../models/DimensionMetrics'
import { WithResponse } from '../models/Response'
import { transformDimensionAndMetrics } from '../transformers/dimensionAndMetrics'
import { getInstance } from './instance'
import { getDimensionAndMetricsKey } from './queryKey'

export const getDimensionAndMertics = async ({
  signal,
}: QueryFunctionContext<
  ReturnType<(typeof getDimensionAndMetricsKey)['keys']>
>) => {
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled')
  })
  try {
    const response: AxiosResponse<WithResponse<DimensionAndMetricsApiType>> =
      await getInstance().get(`/v3/ra/report/dimension-metrics/detail`, {
        cancelToken: source.token,
      })
    return transformDimensionAndMetrics(response.data.data!)
  } catch (e) {
    //@ts-ignore
    return Promise.reject((e as AxiosError).response?.data?.errorObjects?.error)
  }
}
