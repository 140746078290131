import * as React from 'react'

import { Filters } from '../models/filterMetaData'
import { LocalStorageReportDraftType } from '../models/Report'
import { localStorageService } from '../services/localStorage'
import { createFiltersFromDraft } from '../transformers/create-from-draft'

interface UseLocalStorageDataParam {
  onSuccess: (data: LocalStorageReportDraftType) => void
  onError: (err?: Error) => void
  enabled: boolean
}

const parseIntAndThrow = (id: any) => {
  const parsed = Number.parseInt(id)
  if (!Number.isNaN(parsed)) return parsed
  else throw new TypeError('Invalid draft report')
}

export const useLocalStorageReportDraft = ({
  onSuccess,
  onError,
  enabled,
}: UseLocalStorageDataParam) => {
  const [reportDraft, setReportDraft] =
    React.useState<LocalStorageReportDraftType | null>(null)

  React.useEffect(() => {
    if (enabled) {
      try {
        const draft: any = localStorageService.getLocal('reportDraft').data
        const timezone = Number.parseInt(draft?.timezone)
        const startDate = Number.parseInt(draft?.startDate)
        const endDate = Number.parseInt(draft?.endDate)
        const campaignIds: string[] = draft?.campaignIds
          ?.split(',')
          ?.map(parseIntAndThrow)
        const ioIds: string[] = draft?.ioIds?.split(',')?.map(parseIntAndThrow)
        const owIds = draft?.owIds?.map(parseIntAndThrow)

        if (
          !draft ||
          Number.isNaN(timezone) ||
          Number.isNaN(startDate) ||
          Number.isNaN(endDate) ||
          (!campaignIds && !ioIds)
        ) {
          throw new TypeError('Invalid draft report format')
        }

        const filters: Filters = createFiltersFromDraft(campaignIds, ioIds)

        onSuccess({
          ...draft,
          timezone,
          startDate,
          endDate,
          campaignIds,
          ioIds,
          filters,
          owIds,
        } as LocalStorageReportDraftType)
        setReportDraft(draft as LocalStorageReportDraftType)
      } catch (err) {
        onError(err as Error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled])

  return reportDraft
}
