import { ColumnDef } from '@applift/datagrid'
import { ExecuteReportColumnType } from '../../../../models/Report'
import { CampaignCell } from './cellTypes/CampaignCell'
import { PercentCell } from './cellTypes/PercentCell'
import { CurrencyCell } from './cellTypes/CurrencyCell'
import { RawDataCell } from './cellTypes/RawDataCell'
import { DAY } from '../../../../constants/filterResponse'
import { FormattedNumberCell } from './cellTypes/FormattedNumberCell'
import { HourCell } from './cellTypes/HourCell'
import { IoNameCell } from './cellTypes/IoNameCell'
import { IconTextCell } from '../../../Filters/cellTypes'
import { CAMPAIGN_TYPE_BY_NAME } from '../../../../constants'

export type ExecuteReportColumnMapType = {
  [key in ExecuteReportColumnType]: Partial<ColumnDef<any>>
}

export const executeReportColumnMap: ExecuteReportColumnMapType = {
  ioName: {
    accessorKey: 'ioName',
    header: 'IO Name',
    enableSorting: true,
    size: 450,
    cell: props => <IoNameCell rowData={props.row.original} />,
  },
  campaignType: {
    accessorKey: 'campaignType',
    header: 'Campaign Type',
    enableSorting: true,
    size: 135,
    cell: props => (
      <IconTextCell
        iconName={
          props.renderValue() === CAMPAIGN_TYPE_BY_NAME.pg ? 'PG' : 'Advanced'
        }
        iconSize={24}
        label={
          props.renderValue() === CAMPAIGN_TYPE_BY_NAME.pg ? 'PG' : 'Advanced'
        }
      />
    ),
  },
  deal: {
    accessorKey: 'deal',
    header: 'Deal',
    enableSorting: true,
    size: 112,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
  },
  dealId: {
    accessorKey: 'dealId',
    header: 'Deal ID',
    enableSorting: true,
    size: 112,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
  },
  dealType: {
    accessorKey: 'dealType',
    header: 'Deal Type',
    enableSorting: true,
    size: 112,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
  },
  clickUrl: {
    accessorKey: 'clickUrl',
    header: 'Click URL',
    enableSorting: true,
    size: 450,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
  },
  ioId: {
    accessorKey: 'ioId',
    header: 'Insertion Order ID',
    enableSorting: true,
    size: 160,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
  },
  ioStartDate: {
    accessorKey: 'ioStartDate',
    header: 'Insertion Order Start Date',
    enableSorting: true,
    size: 200,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
  },
  ioEndDate: {
    accessorKey: 'ioEndDate',
    header: 'Insertion Order End Date',
    enableSorting: true,
    size: 200,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
  },
  campaign: {
    accessorKey: 'campaign',
    header: 'Campaign',
    enableSorting: true,
    size: 450,
    cell: props => <CampaignCell campaign={props.renderValue() as string} />,
  },
  campaignId: {
    accessorKey: 'campaignId',
    header: 'Campaign ID',
    enableSorting: true,
    size: 120,
    meta: { align: 'right' },
    cell: props => <RawDataCell value={props.renderValue() as string} />,
  },
  campaignStartDate: {
    accessorKey: 'campaignStartDate',
    header: 'Campaign Start Date',
    enableSorting: true,
    size: 180,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
  },
  campaignEndDate: {
    accessorKey: 'campaignEndDate',
    header: 'Campaign End Date',
    enableSorting: true,
    size: 180,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
  },
  campaignGroup: {
    accessorKey: 'campaignGroup',
    header: 'Campaign Group',
    enableSorting: true,
    size: 200,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
  },
  campaignGroupId: {
    accessorKey: 'campaignGroupId',
    header: 'Campaign Group ID',
    enableSorting: true,
    size: 100,
    meta: { align: 'right' },
    cell: props => <RawDataCell value={props.renderValue() as string} />,
  },
  hour: {
    accessorKey: 'hour',
    header: 'Hour',
    enableSorting: true,
    size: 100,
    meta: { align: 'right' },
    cell: props => <RawDataCell value={props.renderValue() as string} />,
  },
  day: {
    accessorKey: 'day',
    header: 'Day',
    enableSorting: true,
    size: 200,
    cell: props => {
      const renderValue = DAY.filter(
        day => day.id === +(props.renderValue() as string)
      )
      return <RawDataCell value={renderValue[0]?.name as string} />
    },
  },
  creative: {
    accessorKey: 'creative',
    header: 'Creative',
    enableSorting: true,
    size: 200,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
  },
  creativeId: {
    accessorKey: 'creativeId',
    header: 'Creative ID',
    enableSorting: true,
    size: 100,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    meta: { align: 'right' },
  },
  creativeType: {
    accessorKey: 'creativeType',
    header: 'Creative Type',
    enableSorting: true,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 200,
  },
  creativeGroup: {
    accessorKey: 'creativeGroup',
    header: 'Creative Group',
    enableSorting: true,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 200,
  },
  playbackMethod: {
    accessorKey: 'playbackMethod',
    header: 'Playback Method',
    enableSorting: true,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 200,
  },
  playerSize: {
    accessorKey: 'playerSize',
    header: 'Player Size',
    enableSorting: true,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 200,
  },
  placementType: {
    accessorKey: 'placementType',
    header: 'Placement Type',
    enableSorting: true,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 200,
  },
  rollPosition: {
    accessorKey: 'rollPosition',
    header: 'Roll Position',
    enableSorting: true,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 200,
  },
  skippability: {
    accessorKey: 'skippability',
    header: 'Skippability',
    enableSorting: true,
    size: 200,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
  },
  ageGroup: {
    accessorKey: 'ageGroup',
    header: 'Age Group',
    enableSorting: true,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 200,
  },
  gender: {
    accessorKey: 'gender',
    header: 'Gender',
    enableSorting: true,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 200,
  },
  ethnicity: {
    accessorKey: 'ethnicity',
    header: 'Ethnicity',
    enableSorting: true,
    size: 200,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
  },
  language: {
    accessorKey: 'language',
    header: 'Language',
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    enableSorting: true,
    size: 200,
  },
  incomeRange: {
    accessorKey: 'incomeRange',
    header: 'Income Range',
    enableSorting: true,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 200,
  },
  deviceType: {
    accessorKey: 'deviceType',
    header: 'Device Type',
    enableSorting: true,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 200,
  },
  manufacturer: {
    accessorKey: 'manufacturer',
    header: 'Manufacturer',
    enableSorting: true,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 200,
  },
  carrier: {
    accessorKey: 'carrier',
    header: 'Carrier',
    enableSorting: true,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 200,
  },
  connectionType: {
    accessorKey: 'connectionType',
    header: 'Connection Type',
    enableSorting: true,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 200,
  },
  os: {
    accessorKey: 'os',
    header: 'OS',
    enableSorting: true,
    size: 200,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
  },
  channel: {
    accessorKey: 'channel',
    header: 'Channel',
    enableSorting: true,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 200,
  },
  siteAndApp: {
    accessorKey: 'siteAndApp',
    header: 'Site & App',
    enableSorting: true,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 200,
  },
  siteAndAppId: {
    accessorKey: 'siteAndAppId',
    header: 'Site & App ID',
    enableSorting: true,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 200,
  },
  exchange: {
    accessorKey: 'exchange',
    header: 'Exchange',
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    enableSorting: true,
    size: 200,
  },
  privateDeal: {
    accessorKey: 'privateDeal',
    header: 'Private Deal',
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    enableSorting: true,
    size: 200,
  },
  publisherCategory: {
    accessorKey: 'publisherCategory',
    header: 'Publisher Category',
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    enableSorting: true,
    size: 200,
  },
  country: {
    accessorKey: 'country',
    header: 'Country',
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    enableSorting: true,
    size: 200,
  },
  state: {
    accessorKey: 'state',
    header: 'State',
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    enableSorting: true,
    size: 200,
  },
  city: {
    accessorKey: 'city',
    header: 'City',
    enableSorting: true,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 200,
  },
  county: {
    accessorKey: 'county',
    header: 'County',
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    enableSorting: true,
    size: 200,
  },
  zipCode: {
    accessorKey: 'zipCode',
    header: 'Zip Code',
    enableSorting: true,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 200,
  },
  congressionalDistrict: {
    accessorKey: 'congressionalDistrict',
    header: 'Congressional District',
    enableSorting: true,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 200,
  },
  stateSenate: {
    accessorKey: 'stateSenate',
    header: 'State Senate',
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    enableSorting: true,
    size: 200,
  },
  stateHouse: {
    accessorKey: 'stateHouse',
    header: 'State House',
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    enableSorting: true,
    size: 200,
  },
  dma: {
    accessorKey: 'dma',
    header: 'DMA',
    enableSorting: true,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 200,
  },
  customer: {
    accessorKey: 'customer',
    header: 'Customers',
    enableSorting: true,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    size: 200,
  },
  customerId: {
    accessorKey: 'customerId',
    header: 'Customer ID',
    cell: props => <RawDataCell value={props.renderValue() as string} />,
    enableSorting: true,
    size: 200,
  },
  // metrics
  dailyBudget: {
    accessorKey: 'dailyBudget',
    header: 'Daily Budget',
    enableSorting: true,
    size: 120,
    meta: {
      align: 'right',
      description:
        'The amount set as daily spending limit including media cost, data cost and pre-bid cost',
    },
    cell: props => {
      return (
        <CurrencyCell
          value={props.renderValue() as number}
          budgetType={
            props.row.original.ioBudgetTypeId || props.row.original.budgetTypeId
          }
        />
      )
    },
    footer: props => (
      <CurrencyCell
        isFooterCell
        // @ts-ignore
        value={props.renderValue() as number}
        budgetType={
          props.row?.original.ioBudgetTypeId || props.row?.original.budgetTypeId
        }
      />
    ),
  },
  ioBudget: {
    accessorKey: 'ioBudget',
    header: 'IO Budget',
    enableSorting: true,
    size: 120,
    meta: {
      align: 'right',
      description:
        'IO budget sets the total spending limit for all campaigns, including media cost, data cost and pre-bid cost',
    },
    cell: props => {
      return (
        <CurrencyCell
          value={props.renderValue() as number}
          budgetType={
            props.row.original.ioBudgetTypeId || props.row.original.budgetTypeId
          }
        />
      )
    },
    footer: props => (
      <CurrencyCell
        isFooterCell
        // @ts-ignore
        value={props.renderValue() as number}
        budgetType={
          props.row?.original.ioBudgetTypeId || props.row?.original.budgetTypeId
        }
      />
    ),
  },
  totalBudget: {
    accessorKey: 'totalBudget',
    header: 'Total Budget',
    enableSorting: true,
    size: 120,
    meta: {
      align: 'right',
      description:
        'The amount set as total spending limit including media cost, data cost and pre-bid cost',
    },
    cell: props => {
      return (
        <CurrencyCell
          value={props.renderValue() as number}
          budgetType={
            props.row.original.ioBudgetTypeId || props.row.original.budgetTypeId
          }
        />
      )
    },
    footer: props => (
      <CurrencyCell
        isFooterCell
        // @ts-ignore
        value={props.renderValue() as number}
        budgetType={
          props.row?.original.ioBudgetTypeId || props.row?.original.budgetTypeId
        }
      />
    ),
  },
  maxBid: {
    accessorKey: 'maxBid',
    header: 'Max Bid Price',
    enableSorting: true,
    size: 120,
    meta: {
      align: 'right',
      description:
        'The maximum bidding price limit acts as the upper limit for any adjusted bid price, if applicable.',
    },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  baseBid: {
    accessorKey: 'baseBid',
    header: 'Base Bid Price',
    enableSorting: true,
    size: 120,
    meta: {
      align: 'right',
      description: 'A base bidding amount up to which you allow us to bid.',
    },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  budgetCompletion: {
    accessorKey: 'budgetCompletion',
    header: 'Daily Budget Completion',
    enableSorting: true,
    size: 200,
    meta: { align: 'right' },
    cell: props => <PercentCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <PercentCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  impressions: {
    accessorKey: 'impressions',
    header: 'Impressions',
    enableSorting: true,
    size: 130,
    cell: props => (
      <FormattedNumberCell value={props.renderValue() as number} />
    ),
    footer: props => (
      // @ts-ignore
      <FormattedNumberCell isFooterCell value={props.renderValue() as number} />
    ),
    meta: { align: 'right' },
  },
  clicks: {
    accessorKey: 'clicks',
    header: 'Clicks',
    cell: props => (
      <FormattedNumberCell value={props.renderValue() as number} />
    ),
    footer: props => (
      // @ts-ignore
      <FormattedNumberCell isFooterCell value={props.renderValue() as number} />
    ),
    enableSorting: true,
    size: 100,
    meta: { align: 'right' },
  },
  organizationBidShadingSavings: {
    accessorKey: 'organizationBidShadingSavings',
    header: 'Bid Shading Savings ($)',
    enableSorting: true,
    size: 200,
    meta: {
      align: 'right',
      description:
        'Amount saved in programmatic ad buying auction after bidding with the optimal bid price.',
    },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  workspaceBidShadingEarnings: {
    accessorKey: 'workspaceBidShadingEarnings',
    header: 'Workspace Bid Shading Earnings',
    enableSorting: true,
    size: 220,
    meta: { align: 'right' },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  workspaceTotalEarnings: {
    accessorKey: 'workspaceTotalEarnings',
    header: 'Total Workspace Earnings',
    enableSorting: true,
    size: 200,
    meta: { align: 'right' },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  platformBidShadingEarnings: {
    accessorKey: 'platformBidShadingEarnings',
    header: 'Platform Bid Shading Earnings',
    enableSorting: true,
    size: 240,
    meta: { align: 'right' },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  platformTotalEarnings: {
    accessorKey: 'platformTotalEarnings',
    header: 'Total Platform Earnings',
    enableSorting: true,
    size: 200,
    meta: { align: 'right' },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  reach: {
    accessorKey: 'reach',
    header: 'Reach',
    enableSorting: true,
    cell: props => (
      <FormattedNumberCell value={props.renderValue() as number} />
    ),
    footer: props => (
      // @ts-ignore
      <FormattedNumberCell isFooterCell value={props.renderValue() as number} />
    ),
    size: 100,
    meta: { align: 'right' },
  },
  frequency: {
    accessorKey: 'frequency',
    header: 'Frequency',
    enableSorting: true,
    cell: props => {
      const value = props.renderValue()
        ? Number(props.renderValue() as number)?.toFixed(2)
        : null
      return <FormattedNumberCell value={value as unknown as number} />
    },
    footer: props => {
      // @ts-ignore
      const value = props.renderValue()
        ? // @ts-ignore
          Number(props.renderValue() as number)?.toFixed(2)
        : null
      return (
        <FormattedNumberCell isFooterCell value={value as unknown as number} />
      )
    },
    size: 100,
    meta: { align: 'right' },
  },
  CTR: {
    accessorKey: 'CTR',
    header: 'CTR',
    enableSorting: true,
    size: 80,
    meta: {
      align: 'right',
    },
    cell: props => <PercentCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <PercentCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  winRate: {
    accessorKey: 'winRate',
    header: 'WinRate',
    enableSorting: true,
    size: 100,
    meta: { align: 'right' },
    cell: props => <PercentCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <PercentCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  VCR: {
    accessorKey: 'VCR',
    header: 'VCR',
    enableSorting: true,
    size: 80,
    meta: { align: 'right' },
    cell: props => {
      return <PercentCell value={props.renderValue() as number} />
    },
    footer: props => (
      // @ts-ignore
      <PercentCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  viewability: {
    accessorKey: 'viewability',
    header: 'Viewability',
    enableSorting: true,
    size: 120,
    meta: { align: 'right' },
    cell: props => <PercentCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <PercentCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  spent: {
    accessorKey: 'spent',
    header: 'Total Spent',
    enableSorting: true,
    size: 100,
    meta: {
      align: 'right',
      description:
        'Total spent is the amount spent by the campaign(s) for the selected timeframe, that includes the media cost, data cost and pre-bid cost',
    },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  dataCost: {
    accessorKey: 'dataCost',
    header: 'Data Cost',
    enableSorting: true,
    size: 100,
    meta: { align: 'right' },
    cell: props => {
      return <CurrencyCell value={+(props.renderValue() as number)} />
    },
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  mediaSpent: {
    accessorKey: 'mediaSpent',
    header: 'Media Cost',
    enableSorting: true,
    size: 120,
    meta: { align: 'right' },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  eCPM: {
    accessorKey: 'eCPM',
    header: 'eCPM',
    enableSorting: true,
    size: 80,
    meta: { align: 'right' },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  eCPC: {
    accessorKey: 'eCPC',
    header: 'eCPC',
    enableSorting: true,
    size: 80,
    meta: { align: 'right' },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  eCPCV: {
    accessorKey: 'eCPCV',
    header: 'CPCV',
    enableSorting: true,
    size: 80,
    meta: { align: 'right' },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  startCount: {
    accessorKey: 'startCount',
    header: 'Video Start',
    enableSorting: true,
    size: 100,
    meta: { align: 'right' },
    cell: props => (
      <FormattedNumberCell value={props.renderValue() as number} />
    ),
    footer: props => (
      // @ts-ignore
      <FormattedNumberCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  firstCount: {
    accessorKey: 'firstCount',
    header: 'Video 25%',
    enableSorting: true,
    size: 100,
    meta: { align: 'right' },
    cell: props => (
      <FormattedNumberCell value={props.renderValue() as number} />
    ),
    footer: props => (
      // @ts-ignore
      <FormattedNumberCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  midCount: {
    accessorKey: 'midCount',
    header: 'Video 50%',
    enableSorting: true,
    cell: props => (
      <FormattedNumberCell value={props.renderValue() as number} />
    ),
    footer: props => (
      // @ts-ignore
      <FormattedNumberCell isFooterCell value={props.renderValue() as number} />
    ),
    size: 100,
    meta: { align: 'right' },
  },
  thirdCount: {
    accessorKey: 'thirdCount',
    header: 'Video 75%',
    cell: props => (
      <FormattedNumberCell value={props.renderValue() as number} />
    ),
    footer: props => (
      // @ts-ignore
      <FormattedNumberCell isFooterCell value={props.renderValue() as number} />
    ),
    enableSorting: true,
    size: 100,
    meta: { align: 'right' },
  },
  completeCount: {
    accessorKey: 'completeCount',
    header: 'Video 100%',
    cell: props => (
      <FormattedNumberCell value={props.renderValue() as number} />
    ),
    footer: props => (
      // @ts-ignore
      <FormattedNumberCell isFooterCell value={props.renderValue() as number} />
    ),
    enableSorting: true,
    size: 140,
    meta: { align: 'right' },
  },
  hoursViewed: {
    accessorKey: 'hoursViewed',
    header: 'Hours Viewed',
    enableSorting: true,
    cell: props => <HourCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <HourCell isFooterCell value={props.renderValue() as number} />
    ),
    size: 140,
    meta: { align: 'right' },
  },
  conversionCount: {
    accessorKey: 'conversionCount',
    header: 'Attributed Conversions',
    enableSorting: true,
    cell: props => (
      <FormattedNumberCell value={props.renderValue() as number} />
    ),
    footer: props => (
      // @ts-ignore
      <FormattedNumberCell value={props.renderValue() as number} />
    ),
    size: 140,
    meta: { align: 'right' },
  },
  attributedViewThroughConversions: {
    accessorKey: 'attributedViewThroughConversions',
    header: 'Attributed View Through Conversions',
    enableSorting: true,
    cell: props => (
      <FormattedNumberCell value={props.renderValue() as number} />
    ),
    footer: props => (
      // @ts-ignore
      <FormattedNumberCell value={props.renderValue() as number} />
    ),
    size: 280,
    meta: { align: 'right' },
  },
  attributedClickThroughConversions: {
    accessorKey: 'attributedClickThroughConversions',
    header: 'Attributed Click Through Conversions',
    enableSorting: true,
    cell: props => (
      <FormattedNumberCell value={props.renderValue() as number} />
    ),
    footer: props => (
      // @ts-ignore
      <FormattedNumberCell value={props.renderValue() as number} />
    ),
    size: 280,
    meta: { align: 'right' },
  },
  costPerAttributedConversions: {
    accessorKey: 'costPerAttributedConversions',
    header: 'Cost Per Attributed Conversion',
    enableSorting: true,
    cell: props => (
      <FormattedNumberCell value={props.renderValue() as number} />
    ),
    footer: props => (
      // @ts-ignore
      <FormattedNumberCell value={props.renderValue() as number} />
    ),
    size: 240,
    meta: { align: 'right' },
  },
  attributedConversionsRate: {
    accessorKey: 'attributedConversionsRate',
    header: 'Attributed Conversion Rate(%)',
    enableSorting: true,
    cell: props => (
      <FormattedNumberCell value={props.renderValue() as number} />
    ),
    footer: props => (
      // @ts-ignore
      <FormattedNumberCell value={props.renderValue() as number} />
    ),
    size: 240,
    meta: { align: 'right' },
  },
  CPA: {
    accessorKey: 'CPA',
    header: 'CPA',
    enableSorting: true,
    size: 80,
    meta: { align: 'right' },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  conversionValue: {
    accessorKey: 'conversionValue',
    header: 'Attributed Conversion Value',
    enableSorting: true,
    size: 240,
    meta: { align: 'right' },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  conversionRate: {
    accessorKey: 'conversionRate',
    header: 'Conversion Rate',
    enableSorting: true,
    size: 140,
    meta: { align: 'right' },
    cell: props => <PercentCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <PercentCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  returnOnAdSpend: {
    accessorKey: 'returnOnAdSpend',
    header: 'ROAS(%)',
    enableSorting: true,
    size: 100,
    meta: { align: 'right' },
    cell: props => <PercentCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <PercentCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  workspaceSpent: {
    accessorKey: 'workspaceSpent',
    header: 'Workspace Spent',
    enableSorting: true,
    size: 140,
    meta: { align: 'right' },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  preBidCost: {
    accessorKey: 'preBidCost',
    header: 'Pre-bid Cost',
    enableSorting: true,
    size: 140,
    meta: { align: 'right' },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  workspaceEarnings: {
    accessorKey: 'workspaceEarnings',
    header: 'Workspace Earnings',
    enableSorting: true,
    size: 180,
    meta: { align: 'right' },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  platformEarnings: {
    accessorKey: 'platformEarnings',
    header: 'Platform Earnings',
    enableSorting: true,
    size: 180,
    meta: { align: 'right' },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  platformSpent: {
    accessorKey: 'platformSpent',
    header: 'Platform Spent',
    enableSorting: true,
    size: 140,
    meta: { align: 'right' },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  vldInsightCost: {
    accessorKey: 'vldInsightCost',
    header: 'VLD Insights Cost',
    enableSorting: true,
    size: 140,
    meta: { align: 'right' },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  workspaceVLDInsightsEarnings: {
    accessorKey: 'workspaceVLDInsightsEarnings',
    header: 'Workspace VLD Insights Earnings',
    enableSorting: true,
    size: 280,
    meta: { align: 'right' },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  platformVLDInsightsEarnings: {
    accessorKey: 'platformVLDInsightsEarnings',
    header: 'Platform VLD Insights Earnings',
    enableSorting: true,
    size: 280,
    meta: { align: 'right' },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  pldInsightCost: {
    accessorKey: 'pldInsightCost',
    header: 'PLD Insights Cost',
    enableSorting: true,
    size: 140,
    meta: { align: 'right' },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  workspacePLDInsightsEarnings: {
    accessorKey: 'workspacePLDInsightsEarnings',
    header: 'Workspace PLD Insights Earnings',
    enableSorting: true,
    size: 280,
    meta: { align: 'right' },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  platformPLDInsightsEarnings: {
    accessorKey: 'platformPLDInsightsEarnings',
    header: 'Platform PLD Insights Earnings',
    enableSorting: true,
    size: 280,
    meta: { align: 'right' },
    cell: props => <CurrencyCell value={props.renderValue() as number} />,
    footer: props => (
      // @ts-ignore
      <CurrencyCell isFooterCell value={props.renderValue() as number} />
    ),
  },
  externalCreativeId: {
    accessorKey: 'externalCreativeId',
    header: '3rd Party ID',
    enableSorting: true,
    cell: props => (
      <FormattedNumberCell value={props.renderValue() as number} />
    ),
    size: 240,
  },
}
