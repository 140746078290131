import { QueryFunctionContext } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { customerStatus } from '../constants'
import { DAY, HOURS } from '../constants/filterResponse'
import { FilterKeys } from '../models/filterMetaData'
import {
  AgeGroupFilterType,
  CampaignFilterType,
  CampaignGroupFilterType,
  CampaignTypeFilterType,
  CreativeFilterType,
  CreativeListFilterType,
  CreativeTypeFilterType,
  CustomerFilterType,
  DealListFilterType,
  DealTypeFilterType,
  DeviceFilterType,
  IDValueFilterType,
  IoFilterType,
  LocationFilterType,
} from '../models/Filters'
import { Response, WithRecordResponse, WithResponse } from '../models/Response'
import { sortingString } from '../utils/sorting'
import { getInstance } from './instance'
import { getCustomersDetailsFilterKey, getFilterQueryKey } from './queryKey'

type responseType = {
  AgeGroupFilter: AgeGroupFilterType
  IoNameFilter: IoFilterType
  IoIdFilter: IoFilterType
  IoStartDateFilter: string[]
  IoEndDateFilter: string[]
  CampaignFilter: CampaignFilterType
  CampaignIdFilter: CampaignFilterType
  CampaignGroupFilter: CreativeFilterType
  CampaignGroupIdFilter: CreativeFilterType
  CampaignStartDateFilter: string[]
  CampaignEndDateFilter: string[]
  CreativeGroupFilter: IDValueFilterType
  CarrierFilter: IDValueFilterType
  CityFilter: LocationFilterType
  CountryFilter: LocationFilterType
  CustomerFilter: CustomerFilterType
  CustomerIdFilter: CustomerFilterType
  CongressionalDistrictFilter: LocationFilterType
  ConnectionTypeFilter: IDValueFilterType
  CountiesFilter: LocationFilterType
  CreativeFilter: CreativeFilterType
  CreativeIdFilter: CreativeFilterType
  CreativeTypeFilter: CreativeTypeFilterType
  ChannelFilter: IDValueFilterType
  DayFilter: IDValueFilterType
  HoursFilter: IDValueFilterType
  DeviceTypesFilter: DeviceFilterType
  DmaFilter: LocationFilterType
  EthnicityFilter: IDValueFilterType
  ExchangeFilter: IDValueFilterType
  GenderFilter: IDValueFilterType
  IncomeRangeFilter: IDValueFilterType
  LanguageFilter: IDValueFilterType
  ManufacturerFilter: IDValueFilterType
  OsFilter: IDValueFilterType
  PlaybackMethodFilter: IDValueFilterType
  PlayerSizeFilter: IDValueFilterType
  PlacementTypeFilter: IDValueFilterType
  RollPositionFilter: IDValueFilterType
  SkippabilityFilter: IDValueFilterType
  SiteAndAppFilter: IDValueFilterType
  SiteAndAppIdFilter: IDValueFilterType
  StatesHouseFilter: LocationFilterType
  StatesSenateFilter: LocationFilterType
  StatesFilter: LocationFilterType
  ZipFilter: IDValueFilterType
  CampaignTypeFilter: IDValueFilterType
  DealFilter: IDValueFilterType
  DealIdFilter: IDValueFilterType
  DealTypeFilter: IDValueFilterType
}

export const getV2FilterFn =
  (apiEndPoint: string) =>
  async ({
    pageParam = -1,
    queryKey,
    signal,
  }: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
    const { searchField, pageSize, segmentIds, ids } = queryKey[0]!
    const { CancelToken } = axios
    const source = CancelToken.source()
    signal?.addEventListener('abort', () => {
      source.cancel('Query was cancelled')
    })

    try {
      const response: AxiosResponse<Response<responseType[FilterKeys][]>> =
        await getInstance().get(apiEndPoint, {
          params: {
            noOfEntries: pageSize,
            pageNo: pageParam,
            searchField,
            ...(segmentIds ? { segmentIds: segmentIds.toString() } : {}),
            ...(ids ? { ids: ids.toString() } : {}),
          },
          cancelToken: source.token,
        })
      return response.data.responseObject
    } catch (e) {
      return Promise.reject((e as AxiosError).response?.data)
    }
  }

export const getV3FilterFn =
  (apiEndPoint: string) =>
  async ({
    pageParam = -1,
    queryKey,
    signal,
  }: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
    const { searchField, pageSize, order, sortBy, segmentIds, ids } =
      queryKey[0]!
    const { CancelToken } = axios
    const source = CancelToken.source()
    signal?.addEventListener('abort', () => {
      source.cancel('Query was cancelled')
    })
    try {
      const response: AxiosResponse<
        WithRecordResponse<responseType[FilterKeys][]>
      > = await getInstance().post(
        apiEndPoint as string,
        {
          noOfEntries: pageSize,
          pageNo: pageParam,
          searchField,
          sortBy: sortingString([{ id: sortBy ?? '', desc: order === 'desc' }])
            .sortBy,
          ...(segmentIds ? { segmentIds: segmentIds } : {}),
          ...(ids ? { ids: ids.toString() } : {}),
        },
        { cancelToken: source.token }
      )
      return response.data.data
    } catch (e) {
      return Promise.reject((e as AxiosError).response?.data)
    }
  }

export const getCountyFilter = async ({
  pageParam = -1,
  queryKey,
  signal,
}: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
  const { searchField, pageSize, order, sortBy, segmentIds, ids } = queryKey[0]!
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled')
  })
  try {
    const response: AxiosResponse<
      WithRecordResponse<responseType[FilterKeys][]>
    > = await getInstance().post(
      '/v3/master/segment/country',
      {
        noOfEntries: pageSize,
        pageNo: pageParam,
        searchField,
        sortBy: sortingString([{ id: sortBy ?? '', desc: order === 'desc' }])
          .sortBy,
        // to get the india and United States countries on the top RB-981
        ids: [30100001, 30100002],
        ...(segmentIds ? { segmentIds: segmentIds } : {}),
        ...(ids ? { ids: ids.toString() } : {}),
      },
      { cancelToken: source.token }
    )
    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const getV3FilterGetFn =
  (apiEndPoint: string) =>
  async ({
    pageParam = 1,
    queryKey,
    signal,
  }: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
    const { searchField, pageSize, segmentIds, ids } = queryKey[0]!
    const { CancelToken } = axios
    const source = CancelToken.source()
    signal?.addEventListener('abort', () => {
      source.cancel('Query was cancelled')
    })

    try {
      const response: AxiosResponse<
        WithRecordResponse<responseType[FilterKeys][]>
      > = await getInstance().get(apiEndPoint, {
        params: {
          noOfEntries: pageSize,
          pageNo: pageParam,
          searchField,
          ...(segmentIds ? { segmentIds: segmentIds.toString() } : {}),
          ...(ids ? { ids: ids.toString() } : {}),
        },
        cancelToken: source.token,
      })
      return response.data.data
    } catch (e) {
      return Promise.reject((e as AxiosError).response?.data)
    }
  }

export const getIoFilters =
  () =>
  async ({
    pageParam = -1,
    queryKey,
    signal,
  }: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
    const {
      searchField,
      pageSize,
      sortBy,
      order,
      segmentIds,
      isAllOwIds,
      owIds,
      status,
    } = queryKey[0]!
    const { CancelToken } = axios
    const source = CancelToken.source()
    signal?.addEventListener('abort', () => {
      source.cancel('Query was cancelled')
    })
    try {
      const response: AxiosResponse<IoFilterType> = await getInstance().post(
        `/v3/cmp/io/basic/list`,
        {
          noOfEntries: pageSize,
          pageNo: pageParam,
          searchField,
          sortBy: `${order === 'desc' ? '-' : '+'}${sortBy}`,
          ...(isAllOwIds ? { isAllOwIds } : { owIdList: owIds }),
          status,
          ...(segmentIds ? { ioIdList: segmentIds.map(Number) } : {}),
        },
        { cancelToken: source.token }
      )
      return response.data
    } catch (e) {
      return Promise.reject((e as AxiosError).response?.data)
    }
  }

export const getCampaignFilters =
  (columnToCall: string[]) =>
  async ({
    pageParam = -1,
    queryKey,
    signal,
  }: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
    const {
      searchField,
      pageSize,
      sortBy,
      order,
      segmentIds,
      isAllOwIds,
      owIds,
      status,
    } = queryKey[0]!
    const { CancelToken } = axios
    const source = CancelToken.source()
    signal?.addEventListener('abort', () => {
      source.cancel('Query was cancelled')
    })
    try {
      const response: AxiosResponse<WithRecordResponse<CampaignFilterType[]>> =
        await getInstance().post(
          `/v3/cmp/basic/list`,
          {
            noOfEntries: pageSize,
            pageNo: pageParam,
            searchField,
            sortBy,
            order,
            responseFields: columnToCall,
            ...(isAllOwIds ? { isAllOwIds } : { owIds }),
            status,
            ...(segmentIds ? { campaignIds: segmentIds } : {}),
          },
          { cancelToken: source.token }
        )
      return response.data.data
    } catch (e) {
      return Promise.reject((e as AxiosError).response?.data)
    }
  }

export const getCampaignGroupFilters = async ({
  pageParam = -1,
  queryKey,
  signal,
}: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
  const {
    searchField,
    pageSize,
    sortBy,
    order,
    segmentIds,
    owIds,
    isAllOwIds,
  } = queryKey[0]!
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled')
  })
  try {
    const response: AxiosResponse<WithResponse<CampaignGroupFilterType[]>> =
      await getInstance().post(
        `/v3/cmp/campaigngroup/list`,
        {
          noOfEntries: pageSize,
          pageNo: pageParam,
          searchField,
          order: order,
          sortBy,
          ...(isAllOwIds ? { isAllOwIds } : { owIds }),
          ...(segmentIds ? { campaignGroupIds: segmentIds } : {}),
        },
        { cancelToken: source.token }
      )
    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const getCreativeTypeFilter = async ({
  queryKey,
  signal,
}: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
  const { order, sortBy, segmentIds, searchField } = queryKey[0]!
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled')
  })
  try {
    const response: AxiosResponse<WithResponse<IDValueFilterType[]>> =
      await getInstance().get(`/v3/crt/master/static/creative-types`, {
        params: {
          sortBy,
          order,
          searchField,
          ...(segmentIds ? { creativeTypeIds: segmentIds?.toString() } : {}),
        },
        cancelToken: source.token,
      })
    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const getChannelFilter = async ({
  queryKey,
  signal,
}: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
  const { order, sortBy, segmentIds, searchField } = queryKey[0]!
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled')
  })
  try {
    const response: AxiosResponse<IDValueFilterType[]> =
      await getInstance().get(`/source_traffic_types`, {
        params: {
          sortBy,
          order,
          searchField,
          ...(segmentIds ? { creativeTypeIds: segmentIds?.toString() } : {}),
        },
        cancelToken: source.token,
      })

    if (segmentIds) {
      const filteredData = response?.data?.filter(data =>
        segmentIds.includes(`${data.id}`)
      )
      return {
        data: filteredData,
        filteredRecords: filteredData?.length || 0,
        totalRecords: response.data?.length || 0,
      }
    }

    if (searchField) {
      const filteredData = response?.data?.filter(data =>
        data.name.toLowerCase().includes(searchField.toLowerCase())
      )
      return {
        data: filteredData,
        filteredRecords: filteredData?.length || 0,
        totalRecords: response.data?.length || 0,
      }
    }

    return {
      data: response.data,
      totalRecords: response.data?.length || 0,
      filteredRecords: response.data?.length || 0,
    }
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const getExchangeFilter = async ({
  pageParam = -1,
  queryKey,
  signal,
}: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
  const { searchField, pageSize, segmentIds, sortBy, order } = queryKey[0]!
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled')
  })
  try {
    const response: AxiosResponse<WithRecordResponse<IDValueFilterType[]>> =
      await getInstance().get(`/v3/master/exchanges`, {
        params: {
          noOfEntries: pageSize,
          pageNo: pageParam,
          searchField,
          sortBy,
          order,
          ...(segmentIds ? { exchangeIds: segmentIds?.toString() } : {}),
        },
        cancelToken: source.token,
      })
    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const getSiteAndAppFilter = async ({
  pageParam = 1,
  queryKey,
  signal,
}: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
  const { searchField, pageSize, order, sortBy, segmentIds, ids } = queryKey[0]!
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled')
  })

  try {
    const response: AxiosResponse<
      WithRecordResponse<responseType[FilterKeys][]>
    > = await getInstance().post(
      '/v3/master/inventory',
      {
        noOfEntries: pageSize,
        pageNo: pageParam,
        searchField,
        order: order,
        sortBy,
        ...(segmentIds ? { inventoryIds: segmentIds } : {}),
        ...(ids ? { ids: ids.toString() } : {}),
      },
      { cancelToken: source.token }
    )
    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const getCustomerFilterDetails = async ({
  signal,
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof getCustomersDetailsFilterKey)['keys']>
>) => {
  const {
    noOfEntries = 20,
    owIds,
    owStatusIds = [customerStatus.ACTIVE, customerStatus.SUSPENDED],
    pageNo = -1,
    provideRunningCampaigns = true,
    searchField,
  } = queryKey[0]!
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled')
  })
  try {
    const response: AxiosResponse<WithRecordResponse<CustomerFilterType[]>> =
      await getInstance().post(
        `/v3/ua/customer/basic/list`,
        {
          noOfEntries,
          owIds,
          owStatusIds,
          pageNo,
          provideRunningCampaigns,
          searchField,
        },
        {
          cancelToken: source.token,
        }
      )
    return response.data.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const getCustomerFilters = async ({
  pageParam = -1,
  queryKey,
  signal,
}: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
  const {
    searchField,
    pageSize,
    sortBy,
    order,
    segmentIds,
    isAllOwIds,
    owIds,
    status,
  } = queryKey[0]!
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled')
  })
  try {
    const response: AxiosResponse<WithRecordResponse<CampaignFilterType[]>> =
      await getInstance().post(
        `/v3/ua/customer/basic/list`,
        {
          noOfEntries: pageSize,
          pageNo: pageParam,
          searchField,
          sortBy: `${order === 'desc' ? '-' : '+'}${sortBy}`,
          ...(isAllOwIds ? { isAllOwIds } : { owIds }),
          status,
          ...(segmentIds ? { campaignIds: segmentIds } : {}),
        },
        { cancelToken: source.token }
      )
    if (segmentIds && segmentIds.length) {
      const filteredResponse = response.data.data.data.filter(res =>
        segmentIds.includes(`${res.owId}`)
      )
      return {
        data: filteredResponse,
        filteredRecords: filteredResponse.length,
        totalRecords: response.data.data.totalRecords,
      }
    }
    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const getDayFilter = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
  const { searchField, segmentIds } = queryKey[0]!

  if (searchField && searchField.trim()) {
    const searchedResponse = DAY.filter(day =>
      day.name.toLowerCase().includes(searchField.toLowerCase())
    )
    return {
      data: searchedResponse,
      filteredRecords: searchedResponse.length,
      totalRecords: DAY.length,
    }
  }

  if (segmentIds && segmentIds.length) {
    const filteredResponse = DAY.filter(day => segmentIds.includes(`${day.id}`))
    return {
      data: filteredResponse,
      filteredRecords: filteredResponse.length,
      totalRecords: DAY.length,
    }
  }

  return { data: DAY, filteredRecords: DAY.length, totalRecords: DAY.length }
}

export const getHoursFilter = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
  const { searchField, segmentIds } = queryKey[0]!

  if (searchField && searchField.trim()) {
    const searchedResponse = HOURS.filter(day =>
      day.id.toLowerCase().includes(searchField.toLowerCase())
    )
    return {
      data: searchedResponse,
      filteredRecords: searchedResponse.length,
      totalRecords: HOURS.length,
    }
  }

  if (segmentIds && segmentIds.length) {
    const filteredResponse = HOURS.filter(hour =>
      segmentIds.includes(`${hour.id}`)
    )
    return {
      data: filteredResponse,
      filteredRecords: filteredResponse.length,
      totalRecords: HOURS.length,
    }
  }

  return {
    data: HOURS,
    filteredRecords: HOURS.length,
    totalRecords: HOURS.length,
  }
}

function epochToDateString(epochMs: number) {
  const date = new Date(epochMs)
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const year = date.getFullYear().toString()
  return `${month}/${day}/${year}`
}

export const getCampaignDateRangeFilters =
  (apiEndPoint: string) =>
  async ({
    pageParam = -1,
    queryKey,
    signal,
  }: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
    const {
      searchField,
      pageSize,
      sortBy,
      order,
      dateRange,
      segmentIds,
      isAllOwIds,
      owIds,
    } = queryKey[0]!
    const { CancelToken } = axios
    const source = CancelToken.source()
    signal?.addEventListener('abort', () => {
      source.cancel('Query was cancelled')
    })

    try {
      const response: AxiosResponse<WithRecordResponse<CampaignFilterType[]>> =
        await getInstance().post(
          apiEndPoint,
          {
            noOfEntries: pageSize,
            pageNo: pageParam,
            searchField,
            sortBy: `${order === 'desc' ? '-' : '+'}${sortBy}`,
            ...(dateRange && dateRange.startDate
              ? { startDate: epochToDateString(dateRange.startDate) }
              : {}),
            ...(dateRange && dateRange.endDate
              ? { endDate: epochToDateString(dateRange.endDate) }
              : {}),
            ...(isAllOwIds ? { isAllOwIds } : { owIds }),
            ...(segmentIds ? { campaignIds: segmentIds } : {}),
          },
          { cancelToken: source.token }
        )
      if (response) {
        const modifiedResponse = response.data.data.data.map(
          (startDate, index) => ({ campaignDate: startDate, id: index })
        )
        if (segmentIds) {
          const filteredData = modifiedResponse.filter(data =>
            segmentIds.includes(data.campaignDate as unknown as string)
          )
          return {
            data: filteredData,
            filteredRecords: filteredData.length,
            totalRecords: filteredData.length,
          }
        }
        return { ...response.data.data, data: modifiedResponse }
      }
    } catch (e) {
      return Promise.reject((e as AxiosError).response?.data)
    }
  }

export const getIODateRangeFilters =
  (apiEndPoint: string) =>
  async ({
    pageParam = -1,
    queryKey,
    signal,
  }: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
    const {
      searchField,
      pageSize,
      sortBy,
      order,
      dateRange,
      segmentIds,
      isAllOwIds,
      owIds,
    } = queryKey[0]!
    const { CancelToken } = axios
    const source = CancelToken.source()
    signal?.addEventListener('abort', () => {
      source.cancel('Query was cancelled')
    })

    try {
      const response: AxiosResponse<{ data: { ioStartDateList: string[] } }> =
        await getInstance().post(
          apiEndPoint,
          {
            noOfEntries: pageSize,
            pageNo: pageParam,
            searchField,
            sortBy: `${order === 'desc' ? '-' : '+'}${sortBy}`,
            ...(dateRange && dateRange.startDate
              ? { startDate: epochToDateString(dateRange.startDate) }
              : {}),
            ...(dateRange && dateRange.endDate
              ? { endDate: epochToDateString(dateRange.endDate) }
              : {}),
            ...(isAllOwIds ? { isAllOwIds } : { owIdList: owIds }),
          },
          { cancelToken: source.token }
        )
      if (response) {
        const reponseAccessKey = apiEndPoint.includes('endDate')
          ? 'ioEndDateList'
          : 'ioStartDateList'

        // @ts-ignore
        const modifiedResponse = response.data.data[reponseAccessKey].map(
          // @ts-ignore
          (startDate, index) => ({ ioDate: startDate, id: index })
        )
        if (segmentIds) {
          // @ts-ignore
          const filteredData = modifiedResponse.filter(data =>
            segmentIds.includes(data.ioDate as unknown as string)
          )
          return {
            data: filteredData,
            filteredRecords: filteredData.length,
            totalRecords: filteredData.length,
          }
        }
        return { ...response.data.data, data: modifiedResponse }
      }
    } catch (e) {
      return Promise.reject((e as AxiosError).response?.data)
    }
  }

export const getCreativeGroupFilters = async ({
  pageParam = 1,
  queryKey,
  signal,
}: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
  const { searchField, segmentIds, owIds, isAllOwIds, pageSize } = queryKey[0]!
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled')
  })
  try {
    const response: AxiosResponse<WithResponse<CampaignGroupFilterType[]>> =
      await getInstance().post(
        `/v3/crt/creatives/groups/list`,
        {
          pageNo: pageParam,
          searchField,
          noOfEntries: pageSize,
          platformCreativeTypeIds: [13, 15, 11, 14, 17],
          ...(isAllOwIds ? { allOwIds: isAllOwIds } : { owIds: owIds }),
          ...(segmentIds ? { creativeGroupIds: segmentIds } : {}),
        },
        {
          cancelToken: source.token,
        }
      )
    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}
export const getDealTypeFilter = async ({
  pageParam = -1,
  queryKey,
  signal,
}: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
  const { searchField, pageSize, segmentIds, sortBy, order } = queryKey[0]!
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled')
  })
  try {
    const response: AxiosResponse<DealTypeFilterType> = await getInstance().get(
      `/v3/inv/static/deal-types`,
      {
        params: {
          noOfEntries: pageSize,
          pageNo: pageParam,
          searchField,
          sortBy,
          order,
        },
        cancelToken: source.token,
      }
    )

    const modifiedData = response.data.data.dealTypeList
      .map(item => {
        return {
          ...item,
          id: item.name === 'PMP' ? 'PMP' : 'PG',
        }
      })
      .filter(item => {
        return segmentIds ? segmentIds?.includes(item.id) : true
      })
    const modifiedResponse = {
      ...response.data.data,
      dealTypeList: modifiedData,
    }
    return modifiedResponse
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const getCampaginTypeFilter = async ({
  queryKey,
  signal,
}: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
  const { segmentIds } = queryKey[0]!
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled')
  })
  try {
    const response: AxiosResponse<CampaignTypeFilterType> =
      await getInstance().post(
        `/v3/cmp/campaign-type/count`,
        {
          countRequired: 'false',
        },
        {
          cancelToken: source.token,
        }
      )
    const modifiedData = response.data.data.campaignTypeDetails.filter(item => {
      return segmentIds ? segmentIds?.includes(`${item.id}`) : true
    })
    const modifiedResponse = {
      ...response.data.data,
      campaignTypeDetails: modifiedData,
    }
    return modifiedResponse
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const getDealIDFilter = async ({
  pageParam = -1,
  queryKey,
  signal,
}: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
  const { searchField, pageSize, segmentIds } = queryKey[0]!
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled')
  })
  try {
    const response: AxiosResponse<DealListFilterType> =
      await getInstance().post(
        `/v3/inv/deals/list`,
        {
          noOfEntries: pageSize,
          pageNo: pageParam,
          searchField,
          sortBy: '-id',
          ...(segmentIds && typeof segmentIds[0] === 'object'
            ? {
                deal: {
                  //@ts-ignore
                  ...(segmentIds[0]?.PG?.length && { PG: segmentIds[0]?.PG }),
                  //@ts-ignore
                  ...(segmentIds[0]?.PMP?.length && {
                    //@ts-ignore
                    PMP: segmentIds[0]?.PMP,
                  }),
                },
              }
            : {}),
        },
        {
          cancelToken: source.token,
        }
      )
    const modifiedData = response?.data?.data?.dealList.map(item => {
      return {
        ...item,
        id: `${item.dealType}_${item.id}`,
      }
    })
    const modifiedResponse = {
      ...response?.data?.data,
      dealList: modifiedData,
    }
    return modifiedResponse
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}
export const getDealFilter = async ({
  pageParam = -1,
  queryKey,
  signal,
}: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
  const { searchField, pageSize, segmentIds } = queryKey[0]!
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled')
  })
  try {
    const response: AxiosResponse<DealListFilterType> =
      await getInstance().post(
        `/v3/inv/deals/list`,
        {
          noOfEntries: pageSize,
          pageNo: pageParam,
          searchField,
          searchBy: ['dealId', 'dealName', 'exchangeName'],
          sortBy: '-id',
          ...(segmentIds
            ? {
                deal: {
                  //@ts-ignore
                  ...(segmentIds[0]?.PG.length && { PG: segmentIds[0].PG }),
                  //@ts-ignore
                  ...(segmentIds[0]?.PMP.length && { PMP: segmentIds[0].PMP }),
                },
              }
            : {}),
        },
        {
          cancelToken: source.token,
        }
      )
    const modifiedData = response?.data?.data?.dealList.map(item => {
      return {
        ...item,
        id: `${item.dealType}_${item.id}`,
      }
    })
    const modifiedResponse = {
      ...response?.data?.data,
      dealList: modifiedData,
    }
    return modifiedResponse
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const getCreativeFilter = async ({
  pageParam = 1,
  queryKey,
  signal,
}: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
  const { searchField, pageSize, isAllOwIds, owIds } = queryKey[0]!
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled')
  })
  try {
    const response: AxiosResponse<CreativeListFilterType> =
      await getInstance().post(
        `/v3/crt/creatives/list`,
        {
          noOfEntries: pageSize,
          pageNo: pageParam,
          searchField,
          platformCreativeTypeIds: [11, 13, 14, 15, 17],
          creativeStatusIds: [1, 2, 3, 4],
          includeOrganizationDetails: false,
          includeUserDetails: false,
          ...(isAllOwIds ? { allOwIds: isAllOwIds } : { owIds: owIds }),
        },
        {
          cancelToken: source.token,
        }
      )

    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}
