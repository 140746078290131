import { SortingState } from '@applift/datagrid'
import { enqueueSnackbar } from '@applift/factor'
import { useAppContext } from '@applift/platform'
import {
  GetNextPageParamFunction,
  InfiniteData,
  QueryFunctionContext,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query'
import * as React from 'react'
import { getFilterQueryKey } from '../../api/queryKey'
import { FilterKeys } from '../../models/filterMetaData'
import { WithResponse } from '../../models/Response'
import { useStore } from '../../store'
import { getCustomerIds } from '../../transformers/customer'
import { AccountTypes } from '../../utils'
import { Filter, FilterProps } from './Filter'

const DEFAULT_PAGESIZE = 50

export interface CreateFilterWrapperProps<Tdata>
  extends Pick<
    FilterProps,
    'rowIdKey' | 'mode' | 'columnDef' | 'filterTitle' | 'onClose' | 'rowHeight'
  > {
  pageSize?: number
  initialSortingState?: SortingState
  filterKey: FilterKeys
  filterFn: ({
    pageParam,
    queryKey,
    signal,
  }: QueryFunctionContext<
    ReturnType<(typeof getFilterQueryKey)['keys']>
  >) => Promise<Tdata>
  getNextPageParma: GetNextPageParamFunction<Tdata>
  transformData: (data: InfiniteData<Tdata>) => any
  getTotalRecords: (data?: InfiniteData<Tdata>) => number
  fetchAllPages: boolean
  isCustomerDependent?: boolean
  disableSearch?: boolean
  emptyfilterMessage?: { title: string; subText: string }
  dateRangeSearch?: boolean
}

export const CreateFilterWrapper = <T,>(
  props: CreateFilterWrapperProps<T>
): JSX.Element => {
  const {
    initialSortingState = [],
    pageSize = DEFAULT_PAGESIZE,
    filterKey,
    filterFn,
    getNextPageParma,
    columnDef: columnDefProps,
    transformData,
    getTotalRecords,
    fetchAllPages,
    rowIdKey,
    filterTitle,
    isCustomerDependent,
    dateRangeSearch,
    ...others
  } = props
  const [search, setSearch] = React.useState<string>('')
  const [dateRange, setDateRange] = React.useState<any>(null)
  const [sorting, setSorting] =
    React.useState<SortingState>(initialSortingState)

  const [filterData, setFilter, selectedCustomersData, isAllCustomerSelected] =
    useStore(state => [
      state.filters.filters[filterKey],
      state.filters.setFilters,
      state.customerFilter.selectedCustomersData,
      state.customerFilter.isAllCustomerSelected,
    ])

  const appContext = useAppContext()
  const organizationType = appContext.appMeta.organizationType
  const customerIds = React.useMemo(
    () => getCustomerIds(selectedCustomersData),
    [selectedCustomersData]
  )

  const {
    data,
    isLoading: loading,
    fetchNextPage,
  } = useInfiniteQuery(
    getFilterQueryKey.keys({
      scope: filterKey,
      searchField: search,
      dateRange: dateRange,
      pageSize,
      sortBy: sorting.length ? sorting[0]?.id : undefined,
      order: sorting.length ? (sorting[0]?.desc ? 'desc' : 'asc') : undefined,
      ...(organizationType !== AccountTypes.ADVERTISER && isCustomerDependent
        ? {
            ...(isAllCustomerSelected
              ? { isAllOwIds: true }
              : {
                  isAllOwIds: false,
                  owIds: customerIds,
                }),
          }
        : {}),
    }),
    filterFn,
    {
      getNextPageParam: fetchAllPages ? () => -1 : getNextPageParma,
      onError: (e: WithResponse) => {
        enqueueSnackbar(
          e.errorObjects
            ? (e.errorObjects[0]?.error as string)
            : 'Something went wrong!',
          {
            variant: 'error',
          }
        )
      },
    }
  )

  const columnDef = React.useMemo(() => columnDefProps, [columnDefProps])

  const filteredData = React.useMemo(
    () => transformData(data!),
    [data, transformData]
  )

  const onFilter: FilterProps['onFilter'] = ({ rowSelection }) => {
    setFilter(filterKey, rowSelection)
  }

  const { data: DateRangeData } = useQuery({
    queryKey: getFilterQueryKey.keys({
      scope: filterKey,
      pageSize,
      sortBy: sorting.length ? sorting[0]?.id : undefined,
      order: sorting.length ? (sorting[0]?.desc ? 'desc' : 'asc') : undefined,
      ...(organizationType !== AccountTypes.ADVERTISER && isCustomerDependent
        ? {
            ...(isAllCustomerSelected
              ? { isAllOwIds: true }
              : {
                  isAllOwIds: false,
                  owIds: customerIds,
                }),
          }
        : {}),
    }),
    queryFn: filterFn,
    enabled: !!dateRangeSearch,
  })

  const totalDateRangeDataAvailable = React.useMemo(() => {
    //@ts-ignored
    return !!DateRangeData?.totalRecords
  }, [DateRangeData])

  const filterRecordsWrapper = React.useMemo(() => {
    if (filterKey === 'IoNameFilter' || filterKey === 'IoIdFilter') {
      return getFilteredRecordsNested(data)
    }
    return getFilteredRecords(data)
  }, [data, filterKey])

  return (
    <Filter
      filterKey={filterKey}
      data={filteredData || []}
      search={search}
      setSearch={setSearch}
      onFetchRows={fetchNextPage}
      filteredRecords={filterRecordsWrapper}
      totalRecords={getTotalRecords(data)}
      loading={loading}
      pageSize={pageSize}
      columnDef={columnDef}
      sorting={sorting}
      setSorting={setSorting}
      rowIdKey={rowIdKey}
      filterTitle={filterTitle}
      intialRowSelection={filterData}
      onFilter={onFilter}
      dateRange={dateRange}
      setDateRange={setDateRange}
      isCustomerDependent={isCustomerDependent}
      totalDateRangeDataAvailable={totalDateRangeDataAvailable}
      dateRangeSearch={dateRangeSearch}
      {...others}
    />
  )
}

export function getNextPageParma(lastPage: any, pages: any[]) {
  const totalRecordsFetched = pages.reduce(
    (prev, one) => prev + one.data.length,
    0
  )
  if (totalRecordsFetched < lastPage?.filteredRecords) {
    return pages.length + 1
  }
  return false
}

export function getNextPageParmaWithKey(key: string) {
  return (lastPage: any, pages: any[]) => {
    const totalRecordsFetched = pages.reduce(
      (prev, one) => prev + one?.[key]?.length,
      0
    )
    if (totalRecordsFetched < lastPage?.filteredRecords) {
      return pages.length + 1
    }
    return false
  }
}

export function transformData(data: any) {
  return data?.pages.map((val: any) => val.data).flat(1) ?? []
}
export function transformDataWithKey(key: any) {
  return (data: any) => {
    return data?.pages.map((val: any) => val[key]).flat(1)
  }
}

export function transformDataWithKeyName(keyName: string) {
  return (data: any) => {
    return data?.pages.map((val: any) => val.data[keyName]).flat(1) ?? []
  }
}

export function getFilteredRecords(data: any) {
  return data?.pages?.[0]?.filteredRecords ?? 0
}

export function getFilteredRecordsNested(data: any) {
  return data?.pages?.[0]?.data.filteredRecords ?? 0
}

export function getTotalRecords(data: any) {
  return data?.pages?.[0]?.totalRecords ?? 0
}

export function getTotalRecordsTemp(data: any) {
  return data?.pages?.[0]?.data.totalRecords ?? 0
}
