import { Dialog } from '@applift/factor'
import {
  CreateFilterWrapper,
  CreateFilterWrapperProps,
} from './CreateFilterWrapper'
import { getFilterMetaData } from './FilterMetaData'
import { ReverseFilterMapping } from '../../models/filterMetaData'
import { useRef } from 'react'
import { useRouteContext } from '@tanstack/react-router'

type CreateFilterProps = Pick<
  CreateFilterWrapperProps<any>,
  'onClose' | 'filterKey' | 'rowHeight'
>

export const CreateFilter = (props: CreateFilterProps) => {
  const { filterKey, ...others } = props

  const contextData = useRouteContext({ from: '__root__' })
  const organizationType = contextData.appContext.appMeta.organizationType
  const FilterData = contextData._root.dimensionAndMetricsListData
  const filterMetaDataRef = useRef(getFilterMetaData(organizationType))

  const filterObj = filterMetaDataRef.current[filterKey]

  return (
    <Dialog
      id="create_filter_dialog"
      open={Boolean(filterKey)}
      maxWidth={filterObj?.dialogWidth || 'md'}
      fullWidth={true}
      PaperProps={{ sx: { boxShadow: 500, width: 100, height: 100 } }}
    >
      {filterObj ? (
        <CreateFilterWrapper
          filterKey={filterKey}
          filterTitle={filterObj.title}
          filterFn={filterObj.filterFn}
          getNextPageParma={filterObj.getNextPageParma}
          transformData={filterObj.transformData}
          getTotalRecords={filterObj.getTotalRecords}
          fetchAllPages={filterObj.fetchAllPages}
          columnDef={filterObj.columnDef}
          pageSize={filterObj.pageSize ? filterObj.pageSize : undefined}
          rowIdKey={filterObj.rowIdKey ? filterObj.rowIdKey : 'id'}
          rowHeight={filterObj.rowHeight}
          isCustomerDependent={
            FilterData?.keyWiseDimensions[ReverseFilterMapping[filterKey]]
              ?.isCustomerDependent
          }
          initialSortingState={
            filterObj?.initialSortingState
              ? filterObj.initialSortingState
              : undefined
          }
          disableSearch={filterObj?.disableSearch}
          emptyfilterMessage={filterObj?.emptyfilterMessage}
          dateRangeSearch={filterObj.dateRangeSearch}
          {...others}
        />
      ) : (
        ''
      )}
    </Dialog>
  )
}
