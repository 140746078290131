import {
  DimensionMetricChip,
  DimensionMetricChipProps,
} from '../../../components/DimensionMetricChip'
import {
  Dimension,
  Metrics,
  DimensionOrMetrics,
} from '../../../models/DimensionMetrics'
import { useDraggable } from '@dnd-kit/core'
import { Tooltip, Box } from '@applift/factor'
import styles from './index.module.scss'

export interface DimensionMetricChipWrapperProps
  extends DimensionMetricChipProps {
  item: Dimension | Metrics
  type: DimensionOrMetrics
}

export const DimensionMetricChipWrapper = (
  props: DimensionMetricChipWrapperProps
) => {
  const { item, type, disabled, ...other } = props
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: item.key,
    data: { type },
  })

  return (
    <Tooltip
      title={
        disabled
          ? type === 'Dimensions'
            ? 'Can’t add this dimension as the maximum limit for the main dimensions has been reached.'
            : 'Can’t add this metric as the maximum limit for the main dimensions has been reached.'
          : ''
      }
      placement="top"
      arrow
    >
      <Box className={styles.dimensionMetricChipWrapper}>
        <DimensionMetricChip
          color="secondary"
          ref={setNodeRef}
          disabled={disabled}
          {...other}
          {...listeners}
          {...attributes}
        />
      </Box>
    </Tooltip>
  )
}
