import { enqueueSnackbar } from '@applift/factor'
import { NormalizedCustomerList } from '@applift/platform'
import {
  createFileRoute,
  redirect,
  useNavigate,
  useRouteContext,
} from '@tanstack/react-router'
import React from 'react'
import { useLocalStorageReportDraft } from '../../hooks/useLocalStorageReportDraft'
import { useStore } from '../../store'

export const Route = createFileRoute('/create-from-draft/')({
  beforeLoad: ({ context }) => {
    if (!context._root.hasCampaigns) {
      throw redirect({ to: '/' })
    }
  },
  component: () => <CreateFromDraftPage />,
  errorComponent: () => <h3>error loading data</h3>,
})

export const CreateFromDraftPage = () => {
  const navigate = useNavigate()
  const contextData = useRouteContext({ from: '__root__' })
  const dimensionAndMetricsListData =
    contextData._root.dimensionAndMetricsListData
  const customerList = contextData._root.customerList
  const timezoneList = contextData._root.timezoneList
  const setReportMode = useStore(state => state.report.setReportMode)
  const [storeInitialized, setStoreInitialized] = React.useState(false)

  const handleReportLoadError = () => {
    enqueueSnackbar('Failed to load report', { variant: 'error' })
    navigate({ to: '/list' })
  }

  const [
    initSelectedCustomers,
    initCreateDimsAndMetrics,
    initReport,
    initFilters,
  ] = useStore(state => [
    state.customerFilter.initSelectedCustomersData,
    state.selectedDimensionsAndMetricsSlice.initCreateDimsAndMetrics,
    state.report.initReport,
    state.filters.initFilters,
  ])

  useLocalStorageReportDraft({
    enabled: (timezoneList?.length ?? 0) > 0 && !!dimensionAndMetricsListData,
    onError: () => {
      handleReportLoadError()
    },
    onSuccess: data => {
      const { startDate, endDate, timezone, campaignIds, filters } = data
      const timezoneData = timezoneList?.find(tz => tz.id === timezone)
      const dimension = campaignIds
        ? dimensionAndMetricsListData?.keyWiseDimensions?.campaign
        : dimensionAndMetricsListData?.keyWiseDimensions?.ioName

      if (!timezoneData || !dimension) {
        handleReportLoadError()
        return
      }
      let filteredCustomerList: Record<string, NormalizedCustomerList> = {}
      if (customerList && !data.owIds?.length) {
        filteredCustomerList = customerList
      } else if (customerList) {
        data.owIds?.forEach(id => {
          if (customerList[id]) {
            filteredCustomerList[id] = customerList[id]!
          }
        })
      }
      initReport({
        name: '',
        timezone: {
          id: timezoneData.id,
          label: timezoneData.label,
          value: timezoneData.name,
        },
        dateRange: { startDate, endDate },
        view: 'Aggregated',
      })
      initCreateDimsAndMetrics(
        [dimension],
        dimensionAndMetricsListData?.defaultMetrics ?? [],
        false,
        true
      )
      initFilters(filters)
      initSelectedCustomers(filteredCustomerList)
      setReportMode('Create')
      setStoreInitialized(true)
    },
  })

  React.useEffect(() => {
    if (storeInitialized) {
      navigate({
        to: '/create',
      })
    }
  }, [navigate, storeInitialized])

  return <></>
}
