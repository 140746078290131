import * as React from 'react'
import { EventListener, DNDRefType } from '../models/events'
import { useStore } from '../store'
import { FilterKeys } from '../models/filterMetaData'
import {
  ExtendDimension,
  ExtendMetrics,
  SpecialRuleType,
} from '../models/DimensionMetrics'
import {
  ExecuteReportColumnType,
  ExecuteReportColumnTypeArray,
} from '../models/Report'
import { enqueueSnackbar } from '@applift/factor'
import { useRouteContext } from '@tanstack/react-router'

interface DependentDimensionType {
  [key: ExtendDimension['key']]: ExtendMetrics['key'][]
}

export const useDragAndDropEvents = (apiRef: DNDRefType, callback: any) => {
  const contextData = useRouteContext({ from: '__root__' })
  const list = contextData._root.dimensionAndMetricsListData
  const [dependentMetricArray, setDependentMetricArray] = React.useState<
    DependentDimensionType[]
  >([])

  const removeFilters = useStore(state => state.filters.removeFilter)
  const [
    removeDimension,
    removeMetrics,
    setDimensions,
    setMetrics,
    reorderDimension,
    reorderMetrics,
    dimensions,
    metrics,
    hasAggregation,
    toggleAggregation,
    sorting,
    resetSorting,
  ] = useStore(state => [
    state.selectedDimensionsAndMetricsSlice.removeDimension,
    state.selectedDimensionsAndMetricsSlice.removeMetrics,
    state.selectedDimensionsAndMetricsSlice.setDimensions,
    state.selectedDimensionsAndMetricsSlice.setMetrics,
    state.selectedDimensionsAndMetricsSlice.reorderDimension,
    state.selectedDimensionsAndMetricsSlice.reorderMetrics,
    state.selectedDimensionsAndMetricsSlice.dimensions,
    state.selectedDimensionsAndMetricsSlice.metrics,
    state.selectedDimensionsAndMetricsSlice.hasAggregation,
    state.selectedDimensionsAndMetricsSlice.toggleAggregation,
    state.executeTable.sorting,
    state.executeTable.resetSorting,
  ])

  const resetSortingWrapper = React.useCallback(
    (params: any) => {
      if (params?.key === sorting[0]?.id) {
        resetSorting()
      }
    },
    [resetSorting, sorting]
  )

  const removeAggregationRule = React.useCallback(
    (eventType: SpecialRuleType['dialogType'], param?: any) => {
      const params = {
        dialogType: eventType,
        info: {
          dimensions: {
            dimensionName: dimensions[1]?.label,
            dimensionKey: dimensions[1]?.key,
          },
        },
        commit: function () {
          toggleAggregation(false)
          if (eventType === 'reorderRemoveAggregation') {
            reorderDimension(param.fromIndex, param.toIndex)
          }
          if (eventType === 'removeAggregation') {
            resetSortingWrapper(param)
            removeDimension(dimensions[1]!.key)
          }
        },
      }
      callback(params)
    },
    [
      callback,
      dimensions,
      removeDimension,
      reorderDimension,
      resetSortingWrapper,
      toggleAggregation,
    ]
  )

  const addDependentDimensionRule = React.useCallback(
    (droppedMetric: ExtendMetrics) => {
      const params = {
        dialogType: 'addDependentDimension',
        info: {
          metrics: {
            metricName: droppedMetric.label,
            metricKey: droppedMetric.key,
          },
        },
        commit: function (addedDimension: ExtendDimension) {
          setDimensions(addedDimension)
          setMetrics(droppedMetric)
        },
      }
      callback(params)
    },
    [callback, setDimensions, setMetrics]
  )

  const removeDependentMetricRule = React.useCallback(
    (param: any, checkForMetricDependency: any) => {
      const dependentDimensionInfo = checkForMetricDependency[0]!
      const repetedStrings = dependentDimensionInfo[param.key]
        ?.map((str: string) => {
          let strCount = 0
          dependentMetricArray.forEach(val =>
            Object.values(val)[0]?.includes(str) && val !== param.key
              ? (strCount += 1)
              : null
          )
          return strCount > 1 ? str : false
        })
        .filter(Boolean)

      const renderDetails = {
        [param.key]: dependentDimensionInfo[param.key]?.filter(
          (item: any) => !repetedStrings?.includes(item) || false
        ),
      }

      const isAggregationAffected =
        dimensions.length === 2 &&
        dimensions[1]?.key === param.key &&
        hasAggregation

      if (renderDetails[param.key]?.length) {
        const params = {
          dialogType: 'removeDependentMetric',
          info: {
            dimensions: { dimensionKey: param.key },
            metrics: {
              metricKey: renderDetails[param.key].join(', '),
            },
          },
          shouldToggleAggregation: isAggregationAffected,
          commit: function () {
            if (isAggregationAffected) {
              toggleAggregation(false)
            }
            removeDimension(param.key)
            renderDetails[param.key].forEach((val: string) =>
              removeMetrics(val)
            )
            resetSortingWrapper(param)
          },
        }
        return callback(params)
      }
      removeDimension(param.key)
    },
    [
      callback,
      dependentMetricArray,
      dimensions,
      hasAggregation,
      removeDimension,
      removeMetrics,
      resetSortingWrapper,
      toggleAggregation,
    ]
  )

  const reorder = React.useCallback<EventListener<'reorder'>>(
    params => {
      const isFirstSecondReplaced =
        params.fromIndex === 0 || params.toIndex === 0
      if (params.type === 'Dimensions') {
        if (hasAggregation && isFirstSecondReplaced) {
          return removeAggregationRule('reorderRemoveAggregation', params)
        }
        reorderDimension(params.fromIndex, params.toIndex)
      } else if (params.type === 'Metrics') {
        reorderMetrics(params.fromIndex, params.toIndex)
      }
    },
    [hasAggregation, removeAggregationRule, reorderDimension, reorderMetrics]
  )
  const addDimensionOrMetric = React.useCallback<
    EventListener<'addDimensionOrMetric'>
  >(
    params => {
      if (
        ExecuteReportColumnTypeArray.includes(
          params.key as ExecuteReportColumnType
        )
      ) {
        if (params.type === 'Dimensions') {
          const droppedDimension = list?.keyWiseDimensions[params.key]
          if (droppedDimension) {
            setDimensions(droppedDimension)
          }
        } else if (params.type === 'Metrics') {
          const droppedMetric = list?.keyWiseMetrics[params.key]
          if (droppedMetric) {
            if (
              droppedMetric.dependentDimensions?.length &&
              !dimensions?.filter(dimension =>
                droppedMetric.dependentDimensions?.includes(dimension.id)
              ).length
            ) {
              return addDependentDimensionRule(droppedMetric)
            }
            setMetrics(droppedMetric)
          }
        }
      } else {
        enqueueSnackbar('Respective dimension/metrics is not supported yet', {
          variant: 'error',
        })
      }
    },
    [
      list?.keyWiseDimensions,
      list?.keyWiseMetrics,
      setDimensions,
      dimensions,
      setMetrics,
      addDependentDimensionRule,
    ]
  )

  // console.log((ExecuteReportColumnType))

  const removeDimensionOrMetric = React.useCallback<
    EventListener<'removeDimensionOrMetric'>
  >(
    params => {
      if (params.type === 'Dimensions') {
        const checkForMetricDependency = dependentMetricArray.filter(
          val => !!Object.keys(val).includes(params.key)
        )
        if (checkForMetricDependency.length > 0) {
          return removeDependentMetricRule(params, checkForMetricDependency)
        }
        if (
          dimensions.length === 2 &&
          dimensions[1]?.key === params.key &&
          hasAggregation
        ) {
          return removeAggregationRule('removeAggregation')
        }
        resetSortingWrapper(params)
        removeFilters(params.key as FilterKeys)
        removeDimension(params.key)
      } else if (params.type === 'Metrics') {
        resetSortingWrapper(params)
        removeMetrics(params.key)
      }
    },
    [
      dependentMetricArray,
      dimensions,
      hasAggregation,
      removeAggregationRule,
      removeDependentMetricRule,
      removeDimension,
      removeFilters,
      removeMetrics,
      resetSortingWrapper,
    ]
  )

  React.useEffect(() => {
    const emptyArr: DependentDimensionType[] = []
    const metricsWithDependentDimensions = metrics.filter(
      val => val.dependentDimensions
    )
    dimensions.forEach(val =>
      emptyArr.push({
        [val.key]: metricsWithDependentDimensions
          .filter(innerVal => innerVal.dependentDimensions?.includes(val.id))
          .map(innerVal => innerVal.key)
          .flat(1),
      })
    )
    setDependentMetricArray(
      emptyArr.filter(obj =>
        Object.values(obj).some(val => Array.isArray(val) && val.length > 0)
      )
    )
  }, [dimensions, metrics])

  React.useEffect(() => {
    apiRef.current.addDimensionOrMetric = addDimensionOrMetric
    apiRef.current.removeDimension = removeDimensionOrMetric
    apiRef.current.reorder = reorder
  }, [addDimensionOrMetric, reorder, removeDimensionOrMetric, apiRef])
}
