export const creativeTypeIdMapper = {
  11: 'Image',
  13: 'HTML',
  14: 'Video',
  15: 'Native',
  17: 'Audio',
  18: 'GOTV',
}

export const creativeStatusIdMapper = {
  0: 'all',
  1: 'pending',
  2: 'running',
  3: 'paused',
  4: 'deleted',
  5: 'rejected',
}
