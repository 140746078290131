import { Box, Row, Col, Typography } from '@applift/factor'
import { DragndropCell } from '@applift/illustrations'

export const EmptyTable = () => {
  return (
    <Row
      sx={{
        alignItems: 'center',
        width: 100,
        mx: 0,
        height: 100,
        overflow: 'auto',
        p: 16,
      }}
    >
      <Col xs={12} sm={8} md={6} sx={{ justifyContent: 'center', mx: 'auto' }}>
        <Box sx={{ textColor: 'primary-500', width: 75, mx: 'auto' }}>
          <DragndropCell sx={{ width: 100, height: 'auto' }} />
        </Box>
        <Typography
          component="h2"
          variant="bodyLarge"
          gutterBottom={false}
          weight="normal"
          align="center"
          lineHeight="multi-line"
          sx={{
            mt: 24,
            textColor: 'neutral-600',
          }}
        >
          Drag and drop dimensions and metrics to create your report
        </Typography>
      </Col>
    </Row>
  )
}
