import { CustomerDetailsType } from './Customer'
import { ReportSchedulingEventDetailsType } from './Schedule'
import { ReportSlice, SelectedDimensionsAndMetricsSlice } from './Store'
import { Filters } from './filterMetaData'

export type ReportType = 'Daily' | 'Aggregated'

export type ReportMode = 'Edit' | 'Create'

export type CreateReportType = {
  reportName?: string
  customerIds: CustomerDetailsType['owId'][]
  startDate: number
  endDate: number
  // JSON: [{filter: filterName, value:[ids]}]
  dimensions: string
  columns: string[]
  // 1- dailly , 2 - Aggregated
  requestType: 1 | 2
  // Aggregated : "0"-off , "1"- on
  reportAggregated: '0' | '1'
  timezoneName: string
  timezoneId: number
  reportSchedulingEventDetails?: ReportSchedulingEventDetailsType
  // we have added a counter here to update the query key to call data
  counter?: number
}

export interface EditReportType {
  reportName?: string
  customerIds?: CustomerDetailsType['owId'][]
  startDate?: number
  endDate?: number
  dimensions?: string
  columns?: string[]
  requestType?: 1 | 2
  reportAggregated?: '0' | '1'
  timezoneName?: string
  timezoneId?: number
  reportSchedulingEventDetails?: ReportSchedulingEventDetailsType
  reportId?: number
}

export interface ReportDetailsType {
  id: number
  reportSchedulingEventDetails?: {
    eventId: number
    deliveryFrequency: number
    fileType: number
    subscriberEmails: string[]
    eventEndDate: number
    deliveryDay: string
    runningTotalEnabled: boolean
    earliestCampaignStartDate: number
  }
  customerIds?: number[]
  reportAggregated: CreateReportType['reportAggregated']
  reportName: CreateReportType['reportName']
  startDate: CreateReportType['startDate']
  endDate: CreateReportType['endDate']
  dimensions: CreateReportType['dimensions']
  columns: CreateReportType['columns']
  timezoneId: CreateReportType['timezoneId']
  requestType: CreateReportType['requestType']
}

export const ExecuteReportColumnTypeArray = [
  'clickUrl',
  'ioId',
  'ioName',
  'ioStartDate',
  'ioEndDate',
  'campaign',
  'campaignId',
  'campaignStartDate',
  'campaignEndDate',
  'campaignGroup',
  'campaignGroupId',
  'hour',
  'day',
  'creative',
  'creativeId',
  'creativeType',
  'creativeGroup',
  'playbackMethod',
  'playerSize',
  'placementType',
  'rollPosition',
  'skippability',
  'ageGroup',
  'gender',
  'ethnicity',
  'language',
  'incomeRange',
  'deviceType',
  'manufacturer',
  'carrier',
  'connectionType',
  'os',
  'channel',
  'siteAndApp',
  'siteAndAppId',
  'exchange',
  'privateDeal',
  'publisherCategory',
  'country',
  'state',
  'city',
  'county',
  'zipCode',
  'congressionalDistrict',
  'stateSenate',
  'stateHouse',
  'dma',
  'ioBudget',
  'dailyBudget',
  'totalBudget',
  'maxBid',
  'baseBid',
  'budgetCompletion',
  'impressions',
  'clicks',
  'reach',
  'frequency',
  'CTR',
  'winRate',
  'VCR',
  'viewability',
  'spent',
  'dataCost',
  'mediaSpent',
  'eCPM',
  'eCPC',
  'eCPCV',
  'startCount',
  'firstCount',
  'midCount',
  'thirdCount',
  'completeCount',
  'hoursViewed',
  'conversionCount',
  'attributedViewThroughConversions',
  'attributedClickThroughConversions',
  'costPerAttributedConversions',
  'attributedConversionsRate',
  'CPA',
  'conversionValue',
  'conversionRate',
  'returnOnAdSpend',
  'customer',
  'customerId',
  'workspaceSpent',
  'preBidCost',
  'workspaceEarnings',
  'platformEarnings',
  'platformSpent',
  'organizationBidShadingSavings',
  'workspaceBidShadingEarnings',
  'workspaceTotalEarnings',
  'platformBidShadingEarnings',
  'platformTotalEarnings',
  'deal',
  'dealId',
  'dealType',
  'campaignType',
  'vldInsightCost',
  'workspaceVLDInsightsEarnings',
  'platformVLDInsightsEarnings',
  'pldInsightCost',
  'workspacePLDInsightsEarnings',
  'platformPLDInsightsEarnings',
  'externalCreativeId',
] as const

export type ExecuteReportColumnType =
  (typeof ExecuteReportColumnTypeArray)[number]

export interface ExecuteReportType {
  id?: number
  timezoneName?: string
  customerIds?: number[]
  reportAggregated?: '0' | '1'
  dimensions?: CreateReportType['dimensions']
  columns?: CreateReportType['columns']
  requestType?: CreateReportType['requestType']
  startDate?: CreateReportType['startDate']
  endDate?: CreateReportType['endDate']
  timezoneId?: CreateReportType['timezoneId']
}

export type ExecuteReportResponseColumnType = {
  [key in ExecuteReportColumnType]: number | string | boolean
}

export interface ExecuteReportResponseType {
  data: (ExecuteReportResponseColumnType & { id: number })[]
  total: ExecuteReportResponseColumnType
  filteredRecords: number
  totalRecords: number
}
export interface DownloadReport {
  id?: number
  fileType: 1 | 2
  dimensions?: string
  columns?: string[]
  customerIds?: number[]
  requestType?: 1 | 2
  reportAggregated?: '0' | '1'
  startDate?: number
  endDate?: number
  timezoneId?: number
  timezoneName?: string
  sortBy?: string
}

export interface LocalStorageReportDraftType {
  source?: string
  owIds?: CustomerDetailsType['owId'][]
  startDate: number
  endDate: number
  timezone: number
  campaignIds?: number[]
  ioIds?: number[]
  filters: Filters
}

export interface ReportExecutionPayloadType {
  report?: ReportSlice['report']
  customerIds?: number[]
  dimsAndMetrics?: SelectedDimensionsAndMetricsSlice['selectedDimensionsAndMetricsSlice']
  filters?: Filters
  counter?: number
}
