import { SortingState } from '@applift/datagrid'

export const ListPageSize = 30

export const defaultListSorting: SortingState = [
  { id: 'modifyDate', desc: true },
]

export const defaultExecuteReportSorting: SortingState = [
  { id: 'impressions', desc: true },
]

export const CampaignStatus = [
  'draft',
  'pending',
  'running',
  'expired',
  'deleted',
  'paused',
  'rejected',
]

export const customerStatus = {
  ACTIVE: 1,
  SUSPENDED: 4,
}

export const BUDGET_TYPE_ID = {
  DOLLAR_BASED: 1,
  IMPRESSIONS_BASED: 2,
}

export const CAMPAIGN_TYPE_BY_NAME = {
  advanced: 1,
  pg: 2,
}

export const CAMPAIGN_TYPE_BY_ID = {
  1: 'Advanced',
  2: 'PG',
}
export const EMPTY_SEARCH_STRING = ''

// 5 mins
export const DEFAULT_STALE_TIME = 5 * 60 * 1000

export const REQUEST_TYPE = {
  daily: 1,
  aggregated: 2,
} as const
