import { ColumnDef, ReactTableCellContext } from '@applift/datagrid'
import { IconTextCell } from '../cellTypes'

export const creativeTypeFilterColDef: ColumnDef<any>[] = [
  {
    accessorKey: 'name',
    header: 'Creative Type',
    enableSorting: false,
    size: 300,
    cell: (props: ReactTableCellContext) => {
      return (
        <IconTextCell
          iconName={props.renderValue()}
          iconSize={24}
          label={props.renderValue()}
        />
      )
    },
  },
]
