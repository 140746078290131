import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  sx,
} from '@applift/factor'
import styles from './index.module.scss'
import { Dimension, Metrics } from '../../../../models/DimensionMetrics'
import { DimensionMetricChipWrapper } from '../DimensionMetricChipWrapper'
import { DimensionOrMetrics } from '../../../../models/DimensionMetrics'
import { useRouteContext } from '@tanstack/react-router'

interface Props {
  panelName: string
  isExpanded: boolean
  panelTitle?: string
  type: DimensionOrMetrics
  handleChange: (panel: string) => (event: any, isExpanded: boolean) => void
  list: Dimension[] | Metrics[]
  hideExpandIcon?: boolean
  groupId: Record<number, number>
  dimsId: number[]
  isDimsFull: boolean
}

export const DimensionMetricAccordion = ({
  panelName,
  panelTitle,
  isExpanded,
  type = 'Dimensions',
  handleChange,
  list = [],
  hideExpandIcon = false,
  groupId,
  dimsId,
  isDimsFull,
}: Props) => {
  const contextData = useRouteContext({ from: '__root__' })
  const dimsAndMetrics = contextData._root.dimensionAndMetricsListData
  const disableDimensions = (id: number) => !groupId[id]
  const disableMetrics = (dependent: number[]) => {
    if (Array.isArray(dependent)) {
      let found = false
      for (const one of dependent) {
        const groupIdOfDependentDimension =
          dimsAndMetrics?.idWiseDimensions[one]?.groupId

        const isSameGroupIdMetricPresent = Object.values(
          dimsAndMetrics?.keyWiseDimensions || {}
        )
          .filter(val => val.groupId === groupIdOfDependentDimension)
          .map(val => val.id)

        const isDependentDimsEnabled =
          !!isSameGroupIdMetricPresent.length &&
          isSameGroupIdMetricPresent.some(val => dimsId.includes(val))

        if (dimsId.indexOf(one) >= 0 || isDependentDimsEnabled) {
          found = true
        }
      }
      if (!found) {
        return true
      }
    }
    return false
  }

  const items = isExpanded
    ? list.map(one => {
        return (
          <DimensionMetricChipWrapper
            key={one.key}
            label={one.label}
            color="secondary"
            type={type}
            item={one}
            disabled={
              isDimsFull
                ? type === 'Dimensions'
                  ? disableDimensions((one as Dimension).groupId)
                  : disableMetrics((one as Metrics).dependentDimensions!)
                : false
            }
          />
        )
      })
    : []
  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleChange(panelName)}
      classes={{ root: styles.accordionRoot, rounded: styles.accordionRounded }}
      className={
        type === 'Dimensions'
          ? styles.accordionDimension
          : styles.accordionMetric
      }
    >
      <AccordionSummary
        {...(hideExpandIcon ? { expandIcon: null } : {})}
        classes={{ content: sx({ my: 0 }) }}
      >
        <Typography
          component="h6"
          variant="bodySmall"
          weight="demi"
          gutterBottom={false}
        >
          {panelTitle ?? panelName}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{items}</AccordionDetails>
    </Accordion>
  )
}
