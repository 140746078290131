import { StateCreator } from 'zustand'
import { defaultExecuteReportSorting } from '../../constants'
import { ExecuteTableSlice, StoreFilter } from '../../models/Store'
import { getCustomerIds } from '../../transformers/customer'

const initialState = {
  sorting: defaultExecuteReportSorting,
  isSortChanged: false,
  payload: {
    counter: 0,
  },
}

export const createExecutionTableStore: StateCreator<
  StoreFilter,
  [['zustand/devtools', never]],
  [],
  ExecuteTableSlice
> = set => ({
  executeTable: {
    ...initialState,
    resetSorting: () => {
      set(
        prev => ({
          ...prev,
          executeTable: {
            ...prev.executeTable,
            sorting: defaultExecuteReportSorting,
            isSortChanged: false,
          },
        }),
        false,
        'resetSorting'
      )
    },
    setSorting: sorting => {
      set(
        prev => ({
          ...prev,
          executeTable: {
            ...prev.executeTable,
            sorting: typeof sorting === 'function' ? sorting() : sorting,
            isSortChanged: true,
          },
        }),
        false,
        'setSorting'
      )
    },
    setExecutePayload: () => {
      set(
        prev => ({
          ...prev,
          executeTable: {
            ...prev.executeTable,
            payload: {
              ...prev.executeTable.payload,
              dimsAndMetrics: prev.selectedDimensionsAndMetricsSlice,
              filters: prev.filters.filters,
              report: prev.report,
              customerIds: prev.customerFilter.isAllCustomerSelected
                ? []
                : getCustomerIds(prev.customerFilter.selectedCustomersData),
            },
          },
        }),
        false,
        'setExecutePayload'
      )
    },
    initExecutePayload: () => {
      set(
        prev => ({
          ...prev,
          executeTable: {
            ...prev.executeTable,
            payload: {
              ...prev.executeTable.payload,
              dimsAndMetrics: prev.selectedDimensionsAndMetricsSlice,
              filters: prev.filters.filters,
              report: prev.report,
              customerIds: prev.customerFilter.isAllCustomerSelected
                ? []
                : getCustomerIds(prev.customerFilter.selectedCustomersData),
              counter: 1,
            },
          },
        }),
        false,
        'initExecutePayload'
      )
    },
    incrementExecutionCount: () => {
      set(
        prev => {
          let prevPayload
          if (
            prev.executeTable.payload &&
            Object.keys(prev.executeTable.payload).length > 0
          ) {
            prevPayload = prev.executeTable.payload
          } else {
            prevPayload = {
              counter: 0,
            }
          }
          return {
            ...prev,
            executeTable: {
              ...prev.executeTable,
              payload: {
                ...prevPayload,
                counter: prevPayload.counter! + 1,
              },
            },
          }
        },
        false,
        'incrementExecutionCount'
      )
    },
    resetExecutePayload: () => {
      set(
        prev => ({
          ...prev,
          executeTable: {
            ...prev.executeTable,
            payload: initialState.payload,
          },
        }),
        false,
        'resetExecutePayload'
      )
    },
    cancleExecution: () => {
      set(
        prev => ({
          ...prev,
          selectedDimensionsAndMetricsSlice: {
            ...prev.selectedDimensionsAndMetricsSlice,
            dirty: {
              ...prev.selectedDimensionsAndMetricsSlice.dirty,
              changed: true,
            },
          },
        }),
        false,
        'cancleExecution'
      )
    },
    commit: () => {
      set(
        prev => ({
          ...prev,
          executeTable: {
            ...prev.executeTable,
            isSortChanged: false,
          },
        }),
        false,
        'commitSorting'
      )
    },
  },
})
