import { ColumnDef, ReactTableCellContext } from '@applift/datagrid'
import { IconTextCell } from '../cellTypes'

export const campaignTypeFilterColDef: ColumnDef<any>[] = [
  {
    accessorKey: 'label',
    header: 'Campaign Type',
    enableSorting: false,
    cell: (props: ReactTableCellContext) => {
      return (
        <IconTextCell
          iconName={props.renderValue()}
          iconSize={24}
          label={props.renderValue()}
        />
      )
    },
  },
]
