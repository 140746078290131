import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  Link,
  sx,
  Typography,
} from '@applift/factor'
import * as React from 'react'
import { OrganizationAvatar } from '../../../components/OrganizationAvatar'
import { SelectCustomerDialog } from '../../../components/SelectCustomerDialog'
import { useStore } from '../../../store'
import { ResetAlertDialog } from './ResetAlertDialog'

export const SelectedCustomersFilter = () => {
  const [isCustomerAccordionExpanded, setCustomerAccordionExpanded] =
    React.useState<boolean>(false)
  const [isResetWarningOpen, setIsResetWarningOpen] =
    React.useState<boolean>(false)

  const [resetFilters, toggleAggregation, filters] = useStore(state => [
    state.filters.reset,
    state.selectedDimensionsAndMetricsSlice.toggleAggregation,
    state.filters.filters,
  ])

  const [
    isCustomersDialogOpen,
    setIsCustomerDialogOpen,
    selectedCustomers,
    setIsCustomerEdit,
    isCustomerEdit,
  ] = useStore(state => [
    state.customerFilter.isCustomersDialogOpen,
    state.customerFilter.toggleCustomerDialog,
    state.customerFilter.selectedCustomersData,
    state.customerFilter.setIsCustomerEdit,
    state.customerFilter.isCustomerEdit,
  ])

  const hasFilter = () => {
    for (const filter in filters) {
      if (Object.values(filters[filter as keyof typeof filters]).length) {
        return true
      }
    }
    return false
  }

  const handleResetClose = () => {
    setIsResetWarningOpen(false)
  }

  const handleReset = () => {
    resetFilters()
    toggleAggregation(false)
    setIsCustomerDialogOpen(true)
    setIsCustomerEdit(true)
    setIsResetWarningOpen(false)
  }

  const filteredCustomers = React.useMemo(() => {
    if (selectedCustomers) {
      return Object.values(selectedCustomers) || []
    } else {
      return []
    }
  }, [selectedCustomers])

  const handleCloseCustomerDialog = React.useCallback(() => {
    setIsCustomerDialogOpen(false)
  }, [setIsCustomerDialogOpen])

  return (
    <>
      <Accordion
        expanded={filteredCustomers.length ? isCustomerAccordionExpanded : true}
        onChange={() => setCustomerAccordionExpanded(currVal => !currVal)}
      >
        <AccordionSummary
          classes={{
            content: sx({
              alignItems: 'center',
              justifyContent: 'between',
              my: 0,
            }),
          }}
          expandIcon={filteredCustomers.length ? undefined : null}
        >
          <Typography variant="bodySmall" weight="demi" gutterBottom={false}>
            Customers ({filteredCustomers.length})
          </Typography>
          <Link
            sx={{ zIndex: 'dropdown', textWeight: 'demi', mr: 8 }}
            Component="button"
            color="primary"
            onClick={e => {
              e.stopPropagation()
              if (hasFilter()) {
                setIsResetWarningOpen(true)
              } else {
                setIsCustomerEdit(true)
                setIsCustomerDialogOpen(true)
              }
            }}
          >
            {filteredCustomers.length ? 'Edit' : 'Select'}
          </Link>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            {filteredCustomers.length ? (
              filteredCustomers.slice(0, 5).map(data => (
                <Box
                  key={data.value}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8,
                  }}
                >
                  <OrganizationAvatar
                    isAdvertiser={data.type === 'advertiser'}
                    size={24}
                    src={data.logoUrl}
                    alt={data.label}
                  />
                  <Typography sx={{ my: 0 }} noWrap>
                    {data.label}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="label">
                Select customers for this report
              </Typography>
            )}
            {filteredCustomers.length - 5 > 0 ? (
              <Typography sx={{ textColor: 'neutral-600' }}>
                +{filteredCustomers.length - 5} more
              </Typography>
            ) : null}
          </Box>
        </AccordionDetails>
      </Accordion>
      <ResetAlertDialog
        open={isResetWarningOpen}
        onClose={handleResetClose}
        onReset={handleReset}
      />
      <Dialog
        maxWidth={'xxl'}
        open={isCustomersDialogOpen}
        PaperProps={{ sx: { width: 100, height: 100 } }}
      >
        <SelectCustomerDialog
          handleClose={handleCloseCustomerDialog}
          isEdit={isCustomerEdit}
        />
      </Dialog>
    </>
  )
}
