import {
  NestedWorkspaceCustomerList,
  NormalizedCustomerList,
  normalizeWorkspaceMultilevelData,
} from '@applift/platform'

export const transformCustomerDataIntoCustomerInitialData = (
  customerData: NestedWorkspaceCustomerList[],
  organizationType?: 'SUPER' | 'WORKSPACE' | 'ADVERTISER',
  owId?: number
) => {
  const normalizedCustomerData = normalizeWorkspaceMultilevelData(customerData)
  const record: { [key: string]: NormalizedCustomerList } = {}

  normalizedCustomerData.forEach(data => {
    if (Number(data.value) === owId) {
      if (organizationType === 'WORKSPACE') {
        data.subRows?.forEach(subRow => {
          record[subRow.value] = subRow
        })
      }
    } else {
      if (data.subRows?.length !== 0) {
        data.subRows?.forEach(subRow => {
          record[subRow.value] = subRow
        })
      }
      record[data.value] = data
    }
  })

  return record as Record<string, NormalizedCustomerList>
}

export const getCustomerIds = (
  selectedCustomersData: Record<string, NormalizedCustomerList>
) => {
  return Object.keys(selectedCustomersData).map(item => {
    return Number(item)
  })
}
