import { ColumnDef } from '@applift/datagrid'
import { Box, Skeleton } from '@applift/factor'
import { CreativeFilterType } from '../../../models/Filters'
import { IconTextCell, ImageCell, TextCell } from '../cellTypes'

import {
  creativeStatusIdMapper,
  creativeTypeIdMapper,
} from '../../../models/Creative'

export const creativeFilterColDef: ColumnDef<CreativeFilterType>[] = [
  {
    accessorKey: 'creativeId',
    header: 'ID',
    size: 100,
    enableSorting: false,
    cell: props => {
      return <TextCell label={props.renderValue() as string} />
    },
  },
  {
    accessorKey: 'creativeThumbnailSource',
    header: 'Preview',
    size: 150,
    enableSorting: false,
    cell: props => {
      return (
        <ImageCell
          src={props.renderValue() as string}
          alt={props.row.original.name as string}
        />
      )
    },
    meta: {
      align: 'center',
      headerAlign: 'left',
      skeleton: () => (
        <Box sx={{ p: 8, width: 100, height: 100, mx: 'auto' }}>
          <Skeleton width="100%" height="100%" animation={false} />
        </Box>
      ),
    },
  },
  {
    accessorKey: 'creativeName',
    header: 'Creative Name',
    size: 250,
    enableSorting: false,
    cell: props => {
      return <TextCell label={props.renderValue() as string} />
    },
  },
  {
    accessorKey: 'creativeStatusId',
    header: 'Current Status',
    size: 150,
    enableSorting: false,
    cell: props => {
      return (
        <IconTextCell
          iconName={
            creativeStatusIdMapper[
              props.renderValue() as keyof typeof creativeStatusIdMapper
            ]
          }
          iconSize={16}
          label={
            creativeStatusIdMapper[
              props.renderValue() as keyof typeof creativeStatusIdMapper
            ]
          }
        />
      )
    },
  },
  {
    accessorKey: 'platformCreativeTypeId',
    header: 'Type',
    size: 100,
    enableSorting: false,
    cell: props => {
      return (
        <IconTextCell
          iconName={
            creativeTypeIdMapper[
              props.renderValue() as keyof typeof creativeTypeIdMapper
            ]
          }
          iconSize={24}
          label={
            creativeTypeIdMapper[
              props.renderValue() as keyof typeof creativeTypeIdMapper
            ]
          }
        />
      )
    },
  },
]
