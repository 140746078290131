import { CellContext } from '@applift/datagrid'
import { Box, TypoTooltip } from '@applift/factor'

import { NormalizedCustomerList } from '@applift/platform'
import { OrganizationAvatar } from '../OrganizationAvatar/OrganizationAvatar'

type Props = {
  data: CellContext<NormalizedCustomerList, unknown>['row']['original']
}

const CustomerCell = (props: Props) => {
  const { label, logoUrl, type } = props.data!
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 100,
      }}
    >
      <OrganizationAvatar
        isAdvertiser={type === 'advertiser'}
        size={24}
        src={logoUrl}
        alt={label}
      />
      <TypoTooltip
        arrow
        placement="top"
        TypgraphyProps={{ component: 'p', gutterBottom: false, sx: { ml: 8 } }}
      >
        {label}
      </TypoTooltip>
    </Box>
  )
}

export default CustomerCell
