import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ButtonProps,
  InputBaseProps,
  Typography,
  TextField,
} from '@applift/factor'

interface SaveReportProps {
  onSave: ButtonProps['onClick']
  onCancel: ButtonProps['onClick']
  reportName: string
  setReportName: InputBaseProps['onChange']
  isLoading: boolean
}

export const SaveReportDialog = (props: SaveReportProps) => {
  const { onSave, reportName, setReportName, onCancel, isLoading } = props

  return (
    <>
      <DialogTitle>Save Report</DialogTitle>
      <DialogContent dividers>
        <Typography component="h2" variant="bodySmall">
          Enter Report name to save the report
        </Typography>
        <TextField
          variant="outlinedDash"
          placeholder="Report Name"
          value={reportName}
          onChange={setReportName}
          fullWidth
          // eslint-disable-next-line
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancel} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={onSave}
          disabled={!reportName || isLoading}
        >
          Save Report
        </Button>
      </DialogActions>
    </>
  )
}
