import { DateRange } from '../models/DateTime'
import { CampaignFilterType, CustomerFilterType } from '../models/Filters'
import { ExecuteReportType } from '../models/Report'

export const getFilterQueryKey = {
  keys: (args: {
    scope: string
    searchField?: string
    dateRange?: DateRange
    pageSize?: number
    sortBy?: string
    order?: 'asc' | 'desc'
    segmentIds?: string[]
    ids?: string[]
    owIds?: CampaignFilterType['owId'][]
    isAllOwIds?: boolean
    status?: string[]
  }) => [args],
}

export const getCustomerListKey = {
  keys: (args: {
    scope: string
    status: string
    owId: number
    organizationType: 'SUPER' | 'WORKSPACE' | 'ADVERTISER'
  }) => [args],
}

export const getCustomersDetailsFilterKey = {
  keys: (
    scope: string,
    owIds: CustomerFilterType['owId'][],
    owStatusIds?: number[],
    pageNo?: number,
    noOfEntries?: number,
    searchField?: string,
    provideRunningCampaigns?: boolean
  ) => [
    {
      scope,
      owStatusIds,
      searchField,
      pageNo,
      noOfEntries,
      owIds,
      provideRunningCampaigns,
    } as const,
  ],
}

export const getDimensionAndMetricsKey = {
  keys: (args: { scope: string }) => [args],
}

export const getReportDetailsQueryKey = {
  keys: (scope: string, reportId?: number) => [{ scope, reportId } as const],
}

export const getExecuteReportQueryKey = {
  keys: (
    scope: string,
    data?: ExecuteReportType,
    sortBy?: string,
    pageNo?: number,
    noOfEntries?: number
  ) => [{ scope, data, sortBy, pageNo, noOfEntries } as const],
}
