import { StateCreator } from 'zustand'
import {
  StoreFilter,
  SelectedDimensionsAndMetricsSlice,
} from '../../models/Store'
import { arrayMove } from '@dnd-kit/sortable'

const initialState = {
  dimensions: [],
  metrics: [],
  hasAggregation: false,
  dirty: {
    dimensions: {},
    metrics: {},
    changed: false,
  },
  isDimensionListFull: false,
  isCreatingFromDraft: false,
}

export const createSelectedDimensionsAndMetricsStore: StateCreator<
  StoreFilter,
  [['zustand/devtools', never]],
  [],
  SelectedDimensionsAndMetricsSlice
> = set => ({
  selectedDimensionsAndMetricsSlice: {
    ...initialState,
    initDimsAndMetrics: (dims = [], metrics = [], hasAggregation = false) => {
      set(
        prev => ({
          ...prev,
          selectedDimensionsAndMetricsSlice: {
            ...prev.selectedDimensionsAndMetricsSlice,
            dimensions: dims,
            metrics: metrics,
            hasAggregation: hasAggregation,
            isDimensionListFull:
              new Set(dims.map(val => val.groupId)).size >= 5,
          },
        }),
        false,
        'initDimsAndMetrics'
      )
    },
    initCreateDimsAndMetrics: (
      dims = [],
      metrics = [],
      hasAggregation = false,
      isCreatingFromDraft = false
    ) => {
      set(
        prev => ({
          ...prev,
          selectedDimensionsAndMetricsSlice: {
            ...prev.selectedDimensionsAndMetricsSlice,
            dimensions: dims,
            metrics: metrics,
            hasAggregation,
            dirty: {
              dimensions: dims.reduce(
                (prev, one) => {
                  prev[one.key] = true
                  return prev
                },
                {} as Record<string, boolean>
              ),
              metrics: metrics.reduce(
                (prev, one) => {
                  prev[one.key] = true
                  return prev
                },
                {} as Record<string, boolean>
              ),
              changed: false,
            },
            isCreatingFromDraft,
          },
        }),
        false,
        'initCreateDimsAndMetrics'
      )
    },
    setDimensions: value => {
      set(
        prev => ({
          ...prev,
          selectedDimensionsAndMetricsSlice: {
            ...prev.selectedDimensionsAndMetricsSlice,
            dimensions: [
              ...prev.selectedDimensionsAndMetricsSlice.dimensions,
              value,
            ],
            dirty: {
              ...prev.selectedDimensionsAndMetricsSlice.dirty,
              dimensions: {
                ...prev.selectedDimensionsAndMetricsSlice.dirty.dimensions,
                [value.key]: true,
              },
            },
            isDimensionListFull:
              new Set(
                [
                  ...prev.selectedDimensionsAndMetricsSlice.dimensions,
                  value,
                ].map(val => val.groupId)
              ).size >= 5,
          },
        }),
        false,
        'setDimensions'
      )
    },
    setMetrics: value => {
      set(
        prev => ({
          ...prev,
          selectedDimensionsAndMetricsSlice: {
            ...prev.selectedDimensionsAndMetricsSlice,
            metrics: [...prev.selectedDimensionsAndMetricsSlice.metrics, value],
            dirty: {
              ...prev.selectedDimensionsAndMetricsSlice.dirty,
              metrics: {
                ...prev.selectedDimensionsAndMetricsSlice.dirty.metrics,
                [value.key]: true,
              },
            },
          },
        }),
        false,
        'setMetrics'
      )
    },
    removeDimension: key => {
      set(
        prev => {
          const totalDimensions =
            prev.selectedDimensionsAndMetricsSlice.dimensions.filter(
              one => one.key !== key
            )
          const newDirty =
            prev.selectedDimensionsAndMetricsSlice.dirty.dimensions
          delete newDirty[key]

          const removeAgregation =
            prev.selectedDimensionsAndMetricsSlice.dimensions[0]?.key === key &&
            prev.selectedDimensionsAndMetricsSlice.hasAggregation

          return {
            ...prev,
            selectedDimensionsAndMetricsSlice: {
              ...prev.selectedDimensionsAndMetricsSlice,
              dimensions: totalDimensions,
              isDimensionListFull:
                new Set(totalDimensions.map(val => val.groupId)).size >= 5,
              ...(removeAgregation ? { hasAggregation: false } : {}),
              dirty: {
                ...prev.selectedDimensionsAndMetricsSlice.dirty,
                dimensions: newDirty,
                changed: true,
              },
            },
          }
        },
        false,
        'removeDimension'
      )
    },
    removeMetrics: key => {
      set(
        prev => {
          const totalMetrics =
            prev.selectedDimensionsAndMetricsSlice.metrics.filter(
              one => one.key !== key
            )
          const newDirty = prev.selectedDimensionsAndMetricsSlice.dirty.metrics
          delete newDirty[key]

          return {
            ...prev,
            selectedDimensionsAndMetricsSlice: {
              ...prev.selectedDimensionsAndMetricsSlice,
              metrics: totalMetrics,
              dirty: {
                ...prev.selectedDimensionsAndMetricsSlice.dirty,
                metrics: newDirty,
                changed: true,
              },
            },
          }
        },
        false,
        'removeMetrics'
      )
    },
    reorderDimension: (fromIndex, toIndex) => {
      set(
        prev => {
          const reorderedList = arrayMove(
            prev.selectedDimensionsAndMetricsSlice.dimensions,
            fromIndex,
            toIndex
          )
          return {
            ...prev,
            selectedDimensionsAndMetricsSlice: {
              ...prev.selectedDimensionsAndMetricsSlice,
              dimensions: reorderedList,
              dirty: {
                ...prev.selectedDimensionsAndMetricsSlice.dirty,
                changed: true,
              },
            },
          }
        },
        false,
        'reorderDimension'
      )
    },
    reorderMetrics: (fromIndex, toIndex) => {
      set(
        prev => {
          const reorderedList = arrayMove(
            prev.selectedDimensionsAndMetricsSlice.metrics,
            fromIndex,
            toIndex
          )
          return {
            ...prev,
            selectedDimensionsAndMetricsSlice: {
              ...prev.selectedDimensionsAndMetricsSlice,
              metrics: reorderedList,
              dirty: {
                ...prev.selectedDimensionsAndMetricsSlice.dirty,
                changed: true,
              },
            },
          }
        },
        false,
        'reorderMetrics'
      )
    },
    toggleAggregation: (value: boolean) => {
      set(
        prev => ({
          ...prev,
          selectedDimensionsAndMetricsSlice: {
            ...prev.selectedDimensionsAndMetricsSlice,
            hasAggregation: value,
            dirty: {
              ...prev.selectedDimensionsAndMetricsSlice.dirty,
              dimensions: {
                [prev.selectedDimensionsAndMetricsSlice.dimensions[0]
                  ?.key as string]: true,
              },
              changed: true,
            },
          },
        }),
        false,
        'toggleAggregation'
      )
    },
    commit: () => {
      set(
        prev => ({
          ...prev,
          selectedDimensionsAndMetricsSlice: {
            ...prev.selectedDimensionsAndMetricsSlice,
            dirty: {
              dimensions: {},
              metrics: {},
              changed: false,
            },
          },
        }),
        false,
        'commitDimensionsAndMetrics'
      )
    },
    reset: () => {
      set(
        prev => ({
          ...prev,
          selectedDimensionsAndMetricsSlice: {
            ...prev.selectedDimensionsAndMetricsSlice,
            ...initialState,
          },
        }),
        false,
        'resetDimensionsAndMetrics'
      )
    },
  },
})
