import { NestedWorkspaceCustomerList } from '@applift/platform'
import { QueryFunctionContext } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { WithRecordResponse } from '../models/Response'
import { transformCustomerDataIntoCustomerInitialData } from '../transformers/customer'
import { getInstance } from './instance'
import { getCustomerListKey } from './queryKey'

export const getCustomerList = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof getCustomerListKey)['keys']>>) => {
  const { status, owId, organizationType } = queryKey[0] || {}
  try {
    const response: AxiosResponse<
      WithRecordResponse<NestedWorkspaceCustomerList[]>
    > = await getInstance().get(
      `v3/ua/customers/list/multi-level?status=${status}&pageNo=-1`
    )
    const customerList = response.data.data.data

    const transformedCustomerList =
      transformCustomerDataIntoCustomerInitialData(
        customerList,
        organizationType,
        owId
      )

    return transformedCustomerList
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}
