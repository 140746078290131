import { ColumnDef } from '@applift/datagrid'
import { NormalizedCustomerList } from '@applift/platform'
import { IconTextCell, TextCell } from '../../modules/Filters/cellTypes'
import CustomerCell from './customerCell'

export const viewCustomerColDef: ColumnDef<NormalizedCustomerList>[] = [
  {
    accessorKey: 'value',
    header: 'ID',
    enableSorting: false,
    size: 100,
    cell: props => <TextCell label={props.renderValue() as string} />,
  },
  {
    header: 'Customer Name',
    enableSorting: false,
    size: 380,
    cell: props => <CustomerCell data={props.row.original} />,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    enableSorting: false,
    size: 150,
    cell: props => {
      return (
        <IconTextCell
          iconName={(props.renderValue() as string) || 'Error'}
          iconSize={16}
          label={props.renderValue() as string}
        />
      )
    },
  },
  {
    accessorKey: 'activeCampaignsCount',
    header: 'Running Campaigns',
    enableSorting: false,
    size: 160,
    cell: props => <TextCell label={props.renderValue() as string} />,
  },
  {
    accessorKey: 'ownerUserEmail',
    header: 'Email',
    enableSorting: false,
    size: 320,
    cell: props => <TextCell label={props.renderValue() as string} />,
  },
]
