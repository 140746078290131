import { GridActionCellParams } from '@applift/datagrid'
import { Box, Link, Tooltip, Typography, TypoTooltip } from '@applift/factor'
import { OrganizationAvatar } from '../../../components/OrganizationAvatar/OrganizationAvatar'
import { ListType } from '../../../models/List'
import { ACTION } from '../action'

interface CustomerCellType {
  action?: (args: GridActionCellParams) => any
  rowData: ListType
  cellData: Pick<ListType, 'customerDetails' | 'customerIds'>
}

export const CustomerCell = ({
  action,
  rowData,
  cellData,
}: CustomerCellType) => {
  return cellData?.customerIds?.length > 1 ? (
    <Link
      Component="button"
      color="primary"
      sx={{ textWeight: 'demi', width: 100 }}
      onClick={() =>
        action?.({ actionName: ACTION.VIEW_CUSTOMER, metaData: rowData })
      }
    >
      <Tooltip title="View all customers" placement="top" arrow>
        <Typography component="span" gutterBottom={false} noWrap>
          {cellData.customerIds.length} Customers
        </Typography>
      </Tooltip>
    </Link>
  ) : cellData?.customerIds?.length === 1 ? (
    <Box sx={{ display: 'flex', alignItems: 'center', width: 100 }}>
      <OrganizationAvatar
        isAdvertiser={
          cellData?.customerDetails?.[0]?.organizationType === 'ADVERTISER'
        }
        size={24}
        src={cellData?.customerDetails?.[0]?.organizationLogo}
        alt={cellData?.customerDetails?.[0]?.organizationName}
      />
      <TypoTooltip
        placement="top"
        arrow
        TypgraphyProps={{ gutterBottom: false, sx: { ml: 4 } }}
      >
        {cellData.customerDetails?.[0]?.organizationName}
      </TypoTooltip>
    </Box>
  ) : null
}
