import { Box, Button, Typography } from '@applift/factor'
import { Add } from '@applift/icons'
import {
  createFileRoute,
  redirect,
  useNavigate,
  useRouteContext,
} from '@tanstack/react-router'
import React from 'react'
import { queryClient } from '../../cache'
import {
  EMPTY_SEARCH_STRING,
  ListPageSize,
  defaultListSorting,
} from '../../constants'
import { useClearCache } from '../../hooks/useClearCache'
import { useReportList } from '../../hooks/useReportList'
import { ListGridWrapper } from '../../modules/listGrid'

export const Route = createFileRoute('/list/')({
  beforeLoad: ({ context }) => {
    if (!context._root.hasCampaigns || !context._root.hasReports) {
      throw redirect({ to: '/' })
    }
  },

  component: () => <ListPage />,
  errorComponent: () => <h3>error loading data</h3>,
})

export const ListPage = () => {
  const contextData = useRouteContext({ from: '__root__' })
  const timezoneList = contextData._root.timezoneList

  const navigate = useNavigate()
  const { resetStore } = useClearCache()

  React.useEffect(() => {
    queryClient.removeQueries({
      predicate: query => {
        const queryKey = query.queryKey[0] as { scope?: string }
        return queryKey?.scope === 'ReportList'
      },
    })
  }, [])

  const { data } = useReportList(
    EMPTY_SEARCH_STRING,
    [],
    ListPageSize,
    defaultListSorting,
    {
      enabled: Array.isArray(timezoneList) && timezoneList.length > 0,
    },
    { timezoneList }
  )

  const totalRecords = React.useMemo(
    () => data?.pages[0]?.totalRecords,
    [data?.pages]
  )

  React.useEffect(() => {
    if (data && !data.pages[0]?.data.length) {
      contextData._root.hasReports = false
      navigate({ to: '/' })
    }
  }, [contextData._root, data, navigate])

  React.useEffect(() => {
    return () => {
      resetStore()
    }
  }, [resetStore])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 100,
        width: 100,
        px: 12,
        py: 24,
        gap: 16,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'between',
          alignItems: 'center',
          flexShrink: 0,
          gap: 12,
        }}
      >
        <Typography
          component="h4"
          variant="bodyLarge"
          weight="demi"
          gutterBottom={false}
        >
          Report {totalRecords ? `(${totalRecords})` : ''}
        </Typography>
        <Button
          color="primary"
          disableRipple
          startIcon={<Add />}
          onClick={() => {
            navigate({ to: '/create' })
          }}
        >
          Create Report
        </Button>
      </Box>
      <ListGridWrapper />
    </Box>
  )
}
