import { isJSON } from './isJSON'

export const deepDiffObj = (
  obj1: unknown,
  obj2: unknown | undefined
): any | undefined => {
  if (obj2) {
    const result: any = {}

    Object.keys(obj2).forEach(key => {
      const k = key as keyof typeof obj2
      // @ts-ignore
      const obj1Val = obj1[k]
      const obj2Val = obj2[k]

      if (Array.isArray(obj1Val) && Array.isArray(obj2Val)) {
        if (JSON.stringify(obj1Val) !== JSON.stringify(obj2Val)) {
          result[k] = obj1Val
        }
      } else if (typeof obj1Val === 'string' && typeof obj2Val === 'string') {
        if (!isJSON(obj1Val) && !isJSON(obj2Val)) {
          if (obj1Val !== obj2Val) {
            result[k] = obj1Val
          }
        } else {
          if (
            JSON.stringify(JSON.parse(obj1Val)) !==
            JSON.stringify(JSON.parse(obj2Val))
          ) {
            result[k] = obj1Val
          }
        }
      } else if (typeof obj1Val === 'number' && typeof obj2Val === 'number') {
        if (obj1Val !== obj2Val) {
          result[k] = obj1Val
        }
      } else if (obj1Val !== obj2Val) {
        result[k] = obj1Val
      }
    })

    return result
  }
}
