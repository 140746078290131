import {
  Box,
  debounce,
  Dialog,
  Divider,
  IconButton,
  TextField,
  Typography,
  TypoTooltip,
} from '@applift/factor'
import { ArrowLeft, ClipboardRemove, Close, Search } from '@applift/icons'
import * as React from 'react'
import { DiscardUnsavedChanges } from '../DiscardUnsavedChangesDialog'
import { SelectedCustomersFilter } from './SelectedCustomersFilter'
import { useHasChanged } from '../../../hooks/useHasChange'
import {
  DimensionAndMetricsType,
  DimensionOrMetrics,
  ExtendDimension,
  ExtendMetrics,
} from '../../../models/DimensionMetrics'
import { FilterMapping } from '../../../models/filterMetaData'
import { useStore } from '../../../store'
import { DimensionMetricAccordion } from './DimensionMerticAccordion'

import { useAppContext } from '@applift/platform'
import { useNavigate } from '@tanstack/react-router'
import styles from './index.module.scss'
interface DimensionMetricListProps {
  dimensions?: DimensionAndMetricsType['dimensions']
  metrics?: DimensionAndMetricsType['metrics']
}

export const DimensionMetricList = (props: DimensionMetricListProps) => {
  const { dimensions = {}, metrics = {} } = props

  const [expanded, setExpanded] = React.useState<string | false>(false)
  const [showDiscardChange, setShowDiscardChange] =
    React.useState<boolean>(false)
  const navigate = useNavigate()

  const handleExpanded =
    (panel: string) => (event: any, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }
  const [search, setSearch] = React.useState('')
  const searchInputRef = React.useRef<HTMLInputElement>(null)

  const appContext = useAppContext()
  const organizationType = appContext.appMeta.organizationType

  const [
    selectedDimensions,
    selectedMetrics,
    mode,
    name,
    isReportDirty,
    isDimensionListFull,
  ] = useStore(state => [
    state.selectedDimensionsAndMetricsSlice.dimensions,
    state.selectedDimensionsAndMetricsSlice.metrics,
    state.report.mode,
    state.report.name,
    state.report.dirty,
    state.selectedDimensionsAndMetricsSlice.isDimensionListFull,
  ])
  const { isSaveDisabled } = useHasChanged()

  const debounceSetSearch = debounce(setSearch, 200)

  const [groupIds, dimIds] = React.useMemo(() => {
    const groupIds = selectedDimensions.reduce<Record<number, number>>(
      (prev, one) => {
        prev[one.groupId] = one.groupId
        return prev
      },
      {}
    )
    const ids = selectedDimensions.map(one => one.id)
    return [groupIds, ids]
  }, [selectedDimensions])

  const filterAvailableDims = (
    dimensions: DimensionAndMetricsType['dimensions']
  ) => {
    const result: DimensionAndMetricsType['dimensions'] = {}
    for (const groupName in dimensions) {
      const dimOrMetric = dimensions[groupName]!
      const filteredData = dimOrMetric.filter(_one => {
        return !!FilterMapping[_one.key as keyof typeof FilterMapping]
      })
      result[groupName] = filteredData
    }
    return result
  }

  const dimensionsToRender = renderDimensionMetrics(
    filter(
      filterSelected(filterAvailableDims(dimensions), selectedDimensions),
      search
    ),
    'Dimensions',
    search,
    expanded,
    handleExpanded,
    groupIds,
    dimIds,
    isDimensionListFull
  )
  const metricsToRender = renderDimensionMetrics(
    filter(filterSelected(metrics, selectedMetrics), search),
    'Metrics',
    search,
    expanded,
    handleExpanded,
    groupIds,
    dimIds,
    isDimensionListFull
  )

  const noResultFound = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <ClipboardRemove color="secondary" fontSize={32} sx={{ mb: 16 }} />
      <Typography
        component="h5"
        variant="bodyMedium"
        align={'center'}
        sx={{ textColor: 'neutral-600' }}
      >
        {'No results found'}
      </Typography>
      <Typography
        component="p"
        align={'center'}
        sx={{ textColor: 'neutral-600' }}
      >
        {'We can’t find any items matching your search.'}
      </Typography>
    </Box>
  )

  const isResultFound =
    !!Object.keys(
      filter(filterSelected(dimensions, selectedDimensions), search)
    ).length ||
    !!Object.keys(filter(filterSelected(metrics, selectedMetrics), search))
      .length

  const goBack = () => {
    navigate({ to: '/list' })
  }

  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'center', gap: 4, pl: 12, pr: 24 }}
        className={styles.titleSidebar}
      >
        <IconButton
          onClick={() => {
            !isSaveDisabled || isReportDirty
              ? setShowDiscardChange(true)
              : goBack()
          }}
          sx={{ flexShrink: 0 }}
          color="secondary"
        >
          <ArrowLeft />
        </IconButton>
        <TypoTooltip
          title={mode === 'Edit' ? name : ''}
          placement="top"
          arrow
          TypgraphyProps={{
            component: 'h2',
            variant: 'bodyLarge',
            weight: 'demi',
            gutterBottom: false,
          }}
        >
          {mode === 'Edit' ? name : 'Create New Report'}
        </TypoTooltip>
      </Box>
      {organizationType != 'ADVERTISER' && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            pl: 12,
            pr: 24,
          }}
        >
          <SelectedCustomersFilter />
          <Divider variant="fullWidth" color="secondary" />
        </Box>
      )}
      <TextField
        type="text"
        onChange={e => {
          debounceSetSearch(e.target.value)
        }}
        placeholder="Search by dimensions or metrics"
        variant="outlinedDash"
        inputRef={searchInputRef}
        InputProps={{
          startAdornment: <Search />,
          endAdornment:
            search.length > 0 ? (
              <IconButton
                size="small"
                color="secondary"
                onClick={() => {
                  debounceSetSearch('')
                  ;(searchInputRef.current as HTMLInputElement).value = ''
                }}
              >
                <Close />
              </IconButton>
            ) : null,
        }}
        sx={{ pl: 12, pr: 24 }}
      />

      <Box sx={{ height: 100, pl: 12, pr: 24, pb: 8, py: 4, overflow: 'auto' }}>
        {isResultFound ? (
          <>
            {dimensionsToRender}
            {metricsToRender}
          </>
        ) : (
          <Box
            sx={{
              height: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: 32,
            }}
          >
            {noResultFound}
          </Box>
        )}
      </Box>
      {showDiscardChange && (
        <Dialog maxWidth={'sm'} fullWidth open={showDiscardChange}>
          <DiscardUnsavedChanges
            onCancel={() => setShowDiscardChange(false)}
            onDiscard={() => {
              goBack()
              setShowDiscardChange(false)
            }}
          />
        </Dialog>
      )}
    </>
  )
}

const filterSelected = (
  dimsOrMetrics:
    | DimensionAndMetricsType['dimensions']
    | DimensionAndMetricsType['metrics'],
  selectedDimensionsOrMetrics: ExtendDimension[] | ExtendMetrics[]
) => {
  // @ts-ignore
  const obj = selectedDimensionsOrMetrics.reduce((prev, one) => {
    prev[one.key] = one
    return prev
  }, {} as any)

  const result:
    | DimensionAndMetricsType['dimensions']
    | DimensionAndMetricsType['metrics'] = {}

  for (const groupName in dimsOrMetrics) {
    const dimOrMetric = dimsOrMetrics[groupName]!
    // @ts-ignore
    const filteredData = dimOrMetric.filter(
      // @ts-ignore
      _one => !obj[_one.key]
    )
    // @ts-ignore
    result[groupName] = filteredData
  }
  return result
}

const filter = (
  dimsOrMetrics:
    | DimensionAndMetricsType['dimensions']
    | DimensionAndMetricsType['metrics'],
  searchTerm: string
) => {
  if (searchTerm && searchTerm.length >= 3) {
    const lowerCaseSearch = searchTerm.toLocaleLowerCase()
    const result:
      | DimensionAndMetricsType['dimensions']
      | DimensionAndMetricsType['metrics'] = {}
    for (const groupName in dimsOrMetrics) {
      const dimOrMetric = dimsOrMetrics[groupName]!
      // @ts-ignore
      const filteredData = dimOrMetric.filter(
        // @ts-ignore
        _one => _one.label.toLocaleLowerCase().indexOf(lowerCaseSearch) >= 0
      )
      if (filteredData.length) {
        result[groupName] = filteredData
      }
    }
    return result
  }
  // remove emptied seaction
  const filterEmptiedList:
    | DimensionAndMetricsType['dimensions']
    | DimensionAndMetricsType['metrics'] = {}
  for (const groupName in dimsOrMetrics) {
    const dimOrMetric = dimsOrMetrics[groupName]!
    if (dimOrMetric.length) {
      filterEmptiedList[groupName] = dimOrMetric
    }
  }
  return filterEmptiedList
}

const renderDimensionMetrics = (
  dimsOrMetrics:
    | DimensionAndMetricsType['dimensions']
    | DimensionAndMetricsType['metrics'],
  type: DimensionOrMetrics,
  search: string,
  expanded: string | false,
  handleExpanded: (panel: string) => (event: any, isExpanded: boolean) => void,
  groupId: Record<number, number>,
  dimsId: number[],
  isDimsFull: boolean
) => {
  const list: JSX.Element[] = []
  const searchApplicable = search.length >= 3

  for (const key in dimsOrMetrics) {
    const groupName = key
    const one = dimsOrMetrics[key]

    const component = (
      <DimensionMetricAccordion
        key={groupName}
        panelName={groupName}
        panelTitle={groupName}
        isExpanded={searchApplicable ? true : expanded === groupName}
        handleChange={
          searchApplicable ? (_: any) => (_: any) => null : handleExpanded
        }
        type={type}
        list={one!}
        hideExpandIcon={searchApplicable}
        groupId={groupId}
        dimsId={dimsId}
        isDimsFull={isDimsFull}
      />
    )
    list.push(component)
  }
  return list
}
