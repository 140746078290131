import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@applift/factor'
import {
  CustomerDropdown,
  CustomerListApiRefType,
  useAppContext,
} from '@applift/platform'
import { NormalizedCustomerList } from '@applift/platform'
import { useRouteContext } from '@tanstack/react-router'
import * as React from 'react'
import { customerStatus } from '../../constants'
import { useStore } from '../../store'
import { getCustomerIds } from '../../transformers/customer'
import { SelectedCustomerGrid } from './SelectedCustomerGrid'

interface SelectCustomerDialogProps {
  handleClose: (params?: any) => void
  isEdit: boolean
}

export const SelectCustomerDialog = ({
  handleClose,
  isEdit,
}: SelectCustomerDialogProps) => {
  const [
    selectedCustomers,
    setSelectedCustomersData,
    setIsAllCustomerSelected,
  ] = useStore(state => [
    state.customerFilter.selectedCustomersData,
    state.customerFilter.setSelectedCustomersData,
    state.customerFilter.setIsAllCustomerSelected,
  ])

  const apiRef = React.useRef<CustomerListApiRefType>()
  const { appMeta } = useAppContext()
  const contextData = useRouteContext({ from: '__root__' })
  const allCustomerList = getCustomerIds(contextData._root.customerList ?? {})

  const [localSelectedCustomersData, setLocalSelectedCustomersData] =
    React.useState(selectedCustomers)

  const [isDirty, setIsDirty] = React.useState(isEdit === false)

  const onDeleteRow = React.useCallback((id: string) => {
    apiRef.current?.removeSelection(id)
  }, [])

  const setDataToStore = React.useCallback(() => {
    setSelectedCustomersData(localSelectedCustomersData)
    if (
      allCustomerList.length ===
      getCustomerIds(localSelectedCustomersData).length
    ) {
      setIsAllCustomerSelected(true)
    } else {
      setIsAllCustomerSelected(false)
    }
    handleClose()
  }, [
    setSelectedCustomersData,
    localSelectedCustomersData,
    allCustomerList.length,
    handleClose,
    setIsAllCustomerSelected,
  ])

  const handleOnChange = (data: Record<string, NormalizedCustomerList>) => {
    setIsDirty(true)

    setLocalSelectedCustomersData(data)
  }

  const gridData = Object.values(localSelectedCustomersData)
  const organizationType = appMeta.organizationType

  return (
    <>
      <DialogTitle onClose={handleClose}>Select Customers</DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            width: 100,
            height: 100,
            gap: 16,
          }}
        >
          <Typography>
            Select atleast one customer for which you want to create the report
          </Typography>

          {organizationType !== 'ADVERTISER' ? (
            // @ts-ignore
            <CustomerDropdown
              ref={apiRef}
              initialData={selectedCustomers}
              allowParentNodeSelection
              slotProps={{
                PaperProps: {
                  style: {
                    maxWidth: 420,
                    minHeight: 262,
                  },
                },
              }}
              userType={
                organizationType === 'WORKSPACE' ? 'WORKSPACE' : 'SUPER'
              }
              apiType="multiLevel"
              filters={{
                status: [customerStatus.ACTIVE, customerStatus.SUSPENDED].join(
                  ','
                ),
              }}
              multiple
              loggedInOwId={appMeta.loggedInOwId}
              placeholder="Customers (0)"
              onChange={handleOnChange}
            />
          ) : null}

          {gridData && gridData.length > 0 ? (
            <SelectedCustomerGrid
              data={gridData.length > 0 ? gridData : []}
              onDeleteRow={onDeleteRow}
              pageSize={0}
            />
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={setDataToStore}
          disabled={
            isDirty === false ||
            getCustomerIds(localSelectedCustomersData).length <= 0
          }
        >
          Select
        </Button>
      </DialogActions>
    </>
  )
}
