import { Box, TypographyProps, Typography, Badge } from '@applift/factor'

interface StatusCellProps {
  active: boolean
  position?: 'after' | 'before'
  campaignCount: number
  textTypographyProps?: Omit<TypographyProps, 'children'>
}

export const StatusCell = ({ active, campaignCount }: StatusCellProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 100,
      }}
    >
      {active ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Badge color="success" variant="dot" sx={{ m: 4 }} size="small" />
          <Typography variant="bodySmall" gutterBottom={false} sx={{ mr: 4 }}>
            {`Active  ${campaignCount ? `(${campaignCount})` : ''}`}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Badge color="error" variant="dot" sx={{ m: 4 }} size="small" />
          <Typography variant="bodySmall" gutterBottom={false}>
            Inactive
          </Typography>
        </Box>
      )}
    </Box>
  )
}
