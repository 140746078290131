import * as React from 'react'
import { Col } from '@applift/factor'
import styles from '../index.module.scss'
import { RowCol } from './RowCols'
import { CreateFilter, FilterKeys } from '../../../../modules/Filters'
import { useStore } from '../../../../store'
import { DimensionOrMetrics } from '../../../../models/DimensionMetrics'

interface RowColsWrapperProps {
  activeArea?: DimensionOrMetrics
}

export const RowColsWrapper = (props: RowColsWrapperProps) => {
  const { activeArea } = props
  const [filterKey, setFilterKey] = React.useState<FilterKeys | ''>('')
  const handleFilterClose = () => {
    setFilterKey('')
  }
  const filters = useStore(state => state.filters.filters)

  return (
    <Col
      className={styles.dragndropContainer}
      sx={{
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        flexGrow: 1,
        flexShrink: 0,
        pb: 16,
        height: 100,
        overflow: 'hidden',
        gap: 16,
      }}
    >
      <RowCol
        type="Dimensions"
        title="Rows"
        isActive={activeArea === 'Dimensions'}
        filters={filters}
        setFilter={setFilterKey}
      />
      <RowCol
        type="Metrics"
        title="Columns"
        isActive={activeArea === 'Metrics'}
      />
      <CreateFilter
        filterKey={filterKey as FilterKeys}
        onClose={handleFilterClose}
      />
    </Col>
  )
}
