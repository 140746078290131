import { Link } from '@applift/factor'
import { GridActionCellParams } from '@applift/datagrid'
import { ACTION } from '../action'
import { ListType, DimensionFilterType } from '../../../models/List'

interface DimensionCellType {
  action?: (args: GridActionCellParams) => any
  rowData: ListType
  cellData: ListType['dimensions']
}

export const DimensionCell = ({
  action,
  rowData,
  cellData,
}: DimensionCellType) => {
  let dimension: DimensionFilterType[] = []
  try {
    dimension = JSON.parse(cellData)
  } catch (e) {
    console.log(e)
  }
  return dimension.length ? (
    <Link
      Component="button"
      color="primary"
      sx={{ textWeight: 'demi' }}
      onClick={() =>
        action?.({ actionName: ACTION.VIEW_DIMENSION, metaData: rowData })
      }
    >
      {dimension.length} Dimensions
    </Link>
  ) : null
}
