import { Box, TypoTooltip, SvgIconProps } from '@applift/factor'
import { getIcon, IconNamesTypes } from '../../../registry'
import { toInitCap } from '../../../utils/utils'

interface IconTextCellType {
  iconName: IconNamesTypes | string
  iconSize: SvgIconProps['fontSize']
  label: string
  position?: 'after' | 'before'
}

export const IconTextCell = ({
  iconName,
  iconSize,
  label,
  position = 'before',
}: IconTextCellType) => {
  const icon = getIcon(iconName as any, { fontSize: iconSize })
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 100,
        ...(position === 'after' ? { flexDirection: 'row-reverse' } : {}),
      }}
    >
      {icon}
      <TypoTooltip
        arrow
        placement="top"
        TypgraphyProps={{
          gutterBottom: false,
          sx: { ...(position === 'after' ? { mr: 4 } : { ml: 4 }) },
        }}
      >
        {toInitCap(label)}
      </TypoTooltip>
    </Box>
  )
}
