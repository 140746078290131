import * as React from 'react'
import { Box } from '@applift/factor'
import { ViewFilterWrapper, ViewFilterWrapperProps } from './ViewFilterWrapper'
import { getFilterMetaData } from './FilterMetaData'
import { ReverseFilterMapping } from '../../models/filterMetaData'
import { useRouteContext } from '@tanstack/react-router'

interface ViewFilterProps
  extends Pick<
    ViewFilterWrapperProps<any>,
    'onClose' | 'filterKey' | 'initialFilterIds' | 'rowHeight'
  > {
  customerIds?: number[]
}

export const ViewFilter = (props: ViewFilterProps) => {
  const { filterKey, customerIds, ...others } = props

  const contextData = useRouteContext({ from: '__root__' })
  const FilterData = contextData._root.dimensionAndMetricsListData
  const organizationType = contextData.appContext.appMeta.organizationType
  const filterMetaDataRef = React.useRef(getFilterMetaData(organizationType))

  const filterObj = {
    ...filterMetaDataRef.current[filterKey],
    ...FilterData?.keyWiseDimensions[ReverseFilterMapping[filterKey]],
  }
  return (
    <Box sx={{ flexGrow: 1, height: 100 }}>
      {filterObj ? (
        <ViewFilterWrapper
          filterKey={filterKey}
          filterTitle={filterObj.title}
          filterFn={filterObj.filterFn}
          getNextPageParma={filterObj.getNextPageParma}
          transformData={filterObj.transformData}
          getTotalRecords={filterObj.getTotalRecords}
          fetchAllPages={filterObj.fetchAllPages}
          columnDef={filterObj.columnDef}
          pageSize={filterObj.pageSize}
          rowIdKey={filterObj.rowIdKey}
          initialSortingState={filterObj.initialSortingState}
          rowHeight={filterObj.rowHeight}
          isCustomerDependent={filterObj?.isCustomerDependent}
          selectedCustomers={customerIds}
          {...others}
        />
      ) : (
        ''
      )}
    </Box>
  )
}
