import {
  Box,
  Row,
  Col,
  Typography,
  Button,
  LinearProgress,
} from '@applift/factor'
import { Dashboard } from '@applift/illustrations'

export interface ExecuteLoaderProps {
  cancelExecution: () => void
}

export const ExecuteLoader = (props: ExecuteLoaderProps) => {
  const { cancelExecution } = props
  return (
    <Row
      sx={{
        alignItems: 'center',
        width: 100,
        mx: 0,
        height: 100,
        overflow: 'auto',
        p: 16,
      }}
    >
      <Col xs={12} sm={8} md={6} sx={{ justifyContent: 'center', mx: 'auto' }}>
        <Box sx={{ textColor: 'primary-500', width: 75, mx: 'auto' }}>
          <Dashboard sx={{ width: 100, height: 'auto' }} />
        </Box>
        <LinearProgress
          variant="indeterminate"
          value={40}
          valueBuffer={50}
          color={'primary'}
          sx={{ mt: 16 }}
        />
        <Typography
          component="h2"
          variant="bodyLarge"
          gutterBottom={false}
          weight="normal"
          align="center"
          lineHeight="multi-line"
          sx={{
            mt: 8,
            textColor: 'neutral-600',
          }}
        >
          Executing the report. Please wait..
        </Typography>
        <Button
          variant="text"
          sx={{ display: 'block', mt: 16, mx: 'auto' }}
          onClick={cancelExecution}
        >
          Cancel Report Execution
        </Button>
      </Col>
    </Row>
  )
}
