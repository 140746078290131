import { ColumnDef } from '@applift/datagrid'
import { siteAndAppFilterType } from '../../../models/Filters'
import { IconTextCell } from '../cellTypes'

const siteAndAppIconMapper = {
  app: 'App',
  web: 'Web',
  UN: 'Unknown',
}

export const SiteAndAppFilterColDef: ColumnDef<siteAndAppFilterType>[] = [
  {
    accessorKey: 'name',
    header: 'Site and App',
    enableSorting: false,
    size: 600,
    cell: props => {
      return (
        <IconTextCell
          iconName={
            //@ts-ignore
            siteAndAppIconMapper[props.row.original.trafficType] || 'error'
          }
          iconSize={24}
          label={props.renderValue() as string}
        />
      )
    },
  },
]
