import * as React from 'react'
import {
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  LoadingButton,
  Dialog,
  enqueueSnackbar,
} from '@applift/factor'
import { useStore } from '../../store'
import { daysDifference } from '../../utils/dateDifference'

interface DateRangeDialogProps {
  open: boolean
  dateRange: any
  dialogType: 'io' | 'campaign'
  onClose: () => void
}

export const DateRangeChangeDialog = (props: DateRangeDialogProps) => {
  const { open, onClose, dateRange, dialogType } = props
  const [setDateRange] = useStore(state => [state.report.setDateRange])

  const dialogCampaignOrIO = React.useMemo(
    () =>
      (dialogType === 'io' && 'insertion order') ||
      (dialogType === 'campaign' && 'campaign') ||
      '',
    [dialogType]
  )

  const changeDateRange = () => {
    if (daysDifference(dateRange.startDate, dateRange.endDate) > 365) {
      enqueueSnackbar({
        variant: 'error',
        message: 'Date range cannot exceeds more than one year',
      })
    } else {
      setDateRange(dateRange)
    }
    onClose()
  }
  return (
    <Dialog open={open} maxWidth={'sm'}>
      <DialogTitle>Change Date Range?</DialogTitle>
      <DialogContent dividers>
        <Typography>
          You changed a {dialogCampaignOrIO} filter, do you want to adjust the
          date range to include the entire {dialogCampaignOrIO} span?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton onClick={changeDateRange} variant="contained">
          Change
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
