import * as React from 'react'
import {
  Chip,
  ChipProps,
  Box,
  Badge,
  FormControlLabel,
  FormControlLabelProps,
  Switch,
  IconButton,
  Tooltip,
  TypoTooltip,
  Typography,
} from '@applift/factor'
import { GripVertical, Filter, InfoCircle, Close } from '@applift/icons'
import clsx from 'clsx'
import styles from './index.module.scss'

export interface DimensionMetricChipProps extends ChipProps {
  hasFilter?: boolean
  fullWidth?: boolean
  hasAggregate?: boolean
  onFilter?: () => any
  onAggregate?: FormControlLabelProps['onChange']
  aggregationValue?: boolean
  filterCount?: number
  labelTooltip?: boolean
}

export const DimensionMetricChip = React.forwardRef<
  HTMLDivElement,
  DimensionMetricChipProps
>(
  (
    {
      label,
      hasFilter = false,
      classes,
      className,
      sx,
      fullWidth = false,
      hasAggregate = false,
      onFilter,
      onAggregate,
      filterCount = 0,
      style,
      aggregationValue = false,
      labelTooltip,
      ...other
    },
    ref
  ) => {
    const labelAggregate = () => {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControlLabel
            onChange={
              typeof onAggregate === 'function' ? onAggregate : undefined
            }
            checked={aggregationValue}
            control={<Switch />}
            label="Aggregate"
            labelPlacement="end"
            sx={{ mr: 4 }}
          />
          <Tooltip
            title="Aggregate report refers to one standard report which adds all values (metric data).
         For eg. for campaign aggregation, the report will show total impressions from all the campaigns."
            placement="top"
            arrow
          >
            <InfoCircle />
          </Tooltip>
        </Box>
      )
    }

    return hasAggregate ? (
      <Box
        className={clsx(hasAggregate && styles.aggregateChip)}
        style={style}
        ref={ref}
      >
        <Chip
          label={
            labelTooltip ? (
              <TypoTooltip
                placement="top"
                arrow
                TypgraphyProps={{
                  gutterBottom: false,
                  sx: { display: 'block' },
                }}
              >
                {label}
              </TypoTooltip>
            ) : (
              <span>{label}</span>
            )
          }
          color="secondary"
          className={clsx(styles.chipRoot, className)}
          icon={<GripVertical />}
          deleteIcon={
            <DeleteComponent
              hasFilter={hasFilter}
              onFilter={onFilter}
              filterCount={filterCount}
            />
          }
          sx={{
            ...(fullWidth ? { width: 100 } : {}),
            justifyContent: 'start',
            ...sx,
          }}
          classes={{
            ...(fullWidth ? { label: styles.chipLabel } : {}),
            ...classes,
          }}
          {...other}
        />
        <Chip
          label={labelAggregate()}
          color="secondary"
          className={clsx(styles.chipRoot)}
          sx={{ width: 100, justifyContent: 'start' }}
          classes={{ label: styles.chipLabel }}
        />
      </Box>
    ) : (
      <Chip
        ref={ref}
        label={
          labelTooltip ? (
            <>
              <TypoTooltip
                placement="top"
                arrow
                TypgraphyProps={{
                  gutterBottom: false,
                  sx: { display: 'block' },
                }}
              >
                {label}
              </TypoTooltip>
            </>
          ) : (
            <Typography component={'span'}>{label}</Typography>
          )
        }
        color="secondary"
        className={clsx(styles.chipRoot, className)}
        icon={<GripVertical />}
        deleteIcon={
          <DeleteComponent
            hasFilter={hasFilter}
            onFilter={onFilter}
            filterCount={filterCount}
          />
        }
        sx={{
          ...(fullWidth ? { width: 100 } : {}),
          justifyContent: 'start',
          ...sx,
        }}
        classes={{
          ...(fullWidth ? { label: styles.chipLabel } : {}),
          ...classes,
        }}
        {...other}
        style={style}
      />
    )
  }
)

DimensionMetricChip.displayName = 'DimensionMetricChip'

interface DeleteComponentProps
  extends Pick<
    DimensionMetricChipProps,
    'onFilter' | 'hasFilter' | 'filterCount'
  > {
  onClick?: ChipProps['onDelete']
}

const DeleteComponent = (props: DeleteComponentProps) => {
  const { onClick, onFilter, hasFilter, filterCount } = props
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
      }}
    >
      {hasFilter && (
        <Badge badgeContent={filterCount} overlap="rectangular" color="warning">
          <IconButton
            onClick={typeof onFilter === 'function' ? onFilter : undefined}
            color="secondary"
          >
            <Filter />
          </IconButton>
        </Badge>
      )}
      <Close onClick={onClick} />
    </Box>
  )
}
