import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { StoreFilter } from '../models/Store'
import { createSelectedDimensionsAndMetricsStore } from './dimensionsAndMetrics'
import { createExecutionTableStore } from './executionTable'
import { createCustomerFilterStore, createFilterStore } from './filter'
import { createReportStore } from './report'

export const useStore = create<StoreFilter>()(
  devtools((...a) => ({
    ...createReportStore(...a),
    ...createFilterStore(...a),
    ...createCustomerFilterStore(...a),
    ...createSelectedDimensionsAndMetricsStore(...a),
    ...createExecutionTableStore(...a),
  }))
)
