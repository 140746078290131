import { DragOverlay, UniqueIdentifier } from '@dnd-kit/core'
import { DimensionMetricChip } from '../../components/DimensionMetricChip'
import { DimensionOrMetrics } from '../../models/DimensionMetrics'
import { useRouteContext } from '@tanstack/react-router'

interface DragOverlayWrapperProps {
  itemKey?: UniqueIdentifier | boolean
  type?: DimensionOrMetrics | ''
}

export const DragOverlayWrapper = (props: DragOverlayWrapperProps) => {
  const { itemKey, type } = props
  const contextData = useRouteContext({ from: '__root__' })
  const listData = contextData._root.dimensionAndMetricsListData

  const currentList =
    // @ts-ignore
    listData?.[`keyWise${type}`] ?? undefined
  if (currentList) {
    const currentItem = currentList[(itemKey ?? '') as UniqueIdentifier]
    if (currentItem) {
      return (
        <DragOverlay>
          <DimensionMetricChip
            key={currentItem.key}
            label={currentItem.label}
            style={{ cursor: 'grabbing' }}
            fullWidth
          />
        </DragOverlay>
      )
    }
  }
  return null
}
