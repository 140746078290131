import { Container, Box, Row, Col, TextField } from '@applift/factor'
import { DateRangePicker } from './DateRangePicker'
import styles from '../index.module.scss'
import { useStore } from '../../../../store'
import { Actions } from './Actions'

export const ReportHeader = () => {
  const [reportName, setReportName] = useStore(state => [
    state.report.name,
    state.report.setReportName,
  ])

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        bgColor: 'neutral-0',
        boxShadow: 200,
        pr: 0,
      }}
    >
      <Row
        sx={{
          width: 100,
          gap: 8,
          py: 16,
          overflow: 'auto',
        }}
      >
        <Col
          className={styles.dragndropContainer}
          sx={{ display: 'flex', alignItems: 'stretch', flexShrink: 0 }}
        >
          <TextField
            value={reportName}
            onChange={e =>
              setReportName(e.target.value.trim() ? e.target.value : '')
            }
            label="Report Name"
            placeholder="Report Name"
            variant="outlinedDash"
          />
        </Col>
        <Col
          xs={'auto'}
          sx={{
            display: 'flex',
            justifyContent: 'between',
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
            }}
          >
            <DateRangePicker />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Actions />
          </Box>
        </Col>
      </Row>
    </Container>
  )
}
