import { Box, TruncateMiddle } from '@applift/factor'
import { IoCurrency, IoImpression } from '@applift/icons'
import { BUDGET_TYPE_ID } from '../../../../../constants/constants'

interface IoNameCellType {
  rowData: any
}

export const IoNameCell = ({ rowData }: IoNameCellType) => {
  const { ioName, ioBudgetTypeId } = rowData
  return ioName != null ? (
    <Box sx={{ display: 'flex', alignItems: 'center', width: 100 }}>
      {ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED && (
        <IoCurrency color="primary" fontSize={24} sx={{ marginRight: 8 }} />
      )}
      {ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED && (
        <IoImpression color="primary" fontSize={24} sx={{ marginRight: 8 }} />
      )}
      <TruncateMiddle title={ioName} placement="top" arrow>
        {ioName}
      </TruncateMiddle>
    </Box>
  ) : (
    <>—</>
  )
}
