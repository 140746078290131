import { CircularProgress, Container } from '@applift/factor'

export const GlobalLoader = () => {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        my: 'auto',
        height: 100,
        width: 100,
      }}
    >
      <CircularProgress size={72} thickness={3} />
    </Container>
  )
}

export default GlobalLoader
