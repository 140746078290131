import * as React from 'react'
import { useMutation } from '@tanstack/react-query'
import { CreateReportType } from '../../models/Report'
import { ScheduleReportDialog } from './ScheduleReportDialog'
import { ReportSchedulingEventDetailsType } from '../../models/Schedule'
import * as ScheduleAPI from '../../api/schedule'
import { enqueueSnackbar } from '@applift/factor'

interface ScheduleReportProps {
  onClose: (params?: any) => void
  rowData: CreateReportType
  reportId: number
}

export const CreateReportScheduleReportWrapper = (
  props: ScheduleReportProps
) => {
  const { onClose, rowData, reportId } = props

  const showRunningTotal = React.useMemo(() => {
    const dimensions = JSON.parse(rowData.dimensions)
    const haveCampaignDimension =
      dimensions.filter(
        (dimension: { filter: string }) => dimension.filter === 'campaign'
      ).length > 0
    return dimensions.length === 1 && haveCampaignDimension
  }, [rowData])

  const addEmailScheduleMutation = useMutation(ScheduleAPI.addEmailSchedule, {
    mutationKey: ['AddEmailSchedule'],
    onSuccess: () => {
      enqueueSnackbar('Report scheduled successfully', { variant: 'success' })
    },
    onError: () => {
      enqueueSnackbar('Something went wrong. Please try after some time.', {
        variant: 'error',
      })
    },
    onSettled: () => {
      onClose()
    },
  })

  const onSchedule = (data: ReportSchedulingEventDetailsType) => {
    const _data = {
      ...data,
      reportId: reportId,
      ...(showRunningTotal
        ? { earliestCampaignStartDate: rowData.startDate }
        : null),
    }
    addEmailScheduleMutation.mutate(_data)
  }

  return (
    <ScheduleReportDialog
      onClose={onClose}
      showRunningTotal={showRunningTotal}
      onSchedule={onSchedule}
      mode="new"
      onStopSchedule={() => null}
      cancelTitle="Skip"
      isLoading={addEmailScheduleMutation.isLoading}
      reportName={rowData.reportName || ''}
      timezoneId={rowData.timezoneId}
    />
  )
}
