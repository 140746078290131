import { TypoTooltip } from '@applift/factor'

interface PercentCellType {
  value: number
  isFooterCell?: boolean
}

export const PercentCell = ({
  value,
  isFooterCell = false,
}: PercentCellType) => {
  return value != null ? (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        gutterBottom: false,
        weight: isFooterCell ? 'demi' : 'normal',
      }}
    >
      {value.toFixed(2)}%
    </TypoTooltip>
  ) : (
    <>—</>
  )
}
