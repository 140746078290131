import { RowSelectionState } from '@applift/datagrid'
import {
  SelectAdvanceComponents,
  TextField,
  Typography,
  TypoTooltip,
} from '@applift/factor'
import React from 'react'
import { Option } from '../../../models/Option'
interface DeliveryDaySelectProps {
  data: Option<string, string>[]
  isLoading: boolean
  onChange: (value: Option<string>) => void
  value: Option<string, string> | undefined
}

export const DeliveryMonthDaySelect = (props: DeliveryDaySelectProps) => {
  const { data, isLoading, onChange, value } = props
  const apiRef =
    SelectAdvanceComponents.useSelectAdvanceApiRef<Option<string, string>>()
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>(
    value?.value ? { [value.value]: true } : {}
  )

  const listItemRenderer = SelectAdvanceComponents.DefaultListOptionItemWrapper<
    Option<string, string>
  >({
    disableCheckbox: true,
    disableRowExpansion: true,
    selectionStyle: 'all',
    arrowPosition: 'right',
  })

  const rowSelectionData = React.useRef(
    new Map<string, Option<string, string>>(Object.entries({}))
  )

  const setRowSelectionWrapper = (updater: any) => {
    const selectedRows =
      typeof updater === 'function' ? updater(rowSelection) : updater

    const selectedRow = Object.keys(selectedRows)[0]!
    const allRowsById = apiRef.current.tableInstance.getRowModel().rowsById
    const data = allRowsById[selectedRow]?.original
    rowSelectionData.current.clear()
    rowSelectionData.current.set(selectedRow, data!)
    setRowSelection(updater)

    if (typeof onChange === 'function') {
      const rowSelectionEntries = rowSelectionData.current.entries()
      const rowSelectionObject = Object.fromEntries(rowSelectionEntries)
      const rowSelectionValues = Object.values(rowSelectionObject)
      const selectedValue = rowSelectionValues[0]
      if (selectedValue) onChange(selectedValue)
    }
  }

  return (
    <TextField
      label="Delivery Frequency"
      select="advanceSelect"
      placeholder="Delivery Frequency"
      variant="outlinedDash"
      sx={{ width: 100 }}
      SelectProps={{
        data: data ?? [],
        apiRef: apiRef,
        loading: isLoading,
        enableGlobalFilter: true,
        defaultExpanded: true,
        rowCount: data.length,
        renderValue: () => {
          return (
            <TypoTooltip
              TypgraphyProps={{
                component: 'span',
              }}
            >
              {value && value?.label}
            </TypoTooltip>
          )
        },
        renderListItem: listItemRenderer,

        renderOption: ({ row }) => {
          return <Typography>{row.original.label}</Typography>
        },
      }}
      value={rowSelection}
      onChange={setRowSelectionWrapper}
    />
  )
}
