import { ColumnDef, ReactTableCellContext } from '@applift/datagrid'
import { TextCell } from '../cellTypes'
import { StatusCell } from '../cellTypes/dealStatusCell'

export const dealFilterColDef: ColumnDef<any>[] = [
  {
    accessorKey: 'dealId',
    header: 'Deal ID',
    enableSorting: false,
    cell: (props: ReactTableCellContext) => {
      return <TextCell label={props.renderValue()} />
    },
  },
  {
    accessorKey: 'dealName',
    header: 'Deal Name',
    enableSorting: false,
    cell: (props: ReactTableCellContext) => {
      return <TextCell label={props.renderValue()} />
    },
  },
  {
    accessorKey: 'dealStatus',
    header: 'Deal Status',
    enableSorting: false,
    cell: (props: ReactTableCellContext) => {
      return (
        <StatusCell
          active={props.renderValue() === 'Active'}
          campaignCount={props.row.original?.campaignCount}
        />
      )
    },
  },
  {
    accessorKey: 'exchangeName',
    header: 'Exchange',
    enableSorting: false,
    cell: (props: ReactTableCellContext) => {
      return <TextCell label={props.renderValue()} />
    },
  },
  {
    accessorKey: 'dealType',
    header: 'Deal Type',
    enableSorting: false,
    cell: (props: ReactTableCellContext) => {
      return <TextCell label={props.renderValue()} />
    },
  },
]
