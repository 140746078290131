import { Box, Typography } from '@applift/factor'
import { SearchData } from '@applift/illustrations'

export const EmptyCampaign = ({
  title,
  subtitle,
}: {
  title?: string
  subtitle?: string
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 100,
        justifyContent: 'center',
      }}
    >
      <Box sx={{ textColor: 'primary-500', width: 25, mx: 'auto' }}>
        <SearchData sx={{ width: 100, height: 'auto' }} />
      </Box>
      <Typography
        component="h2"
        variant="bodyLarge"
        gutterBottom={false}
        weight="bold"
        align="center"
        lineHeight="multi-line"
        sx={{
          mt: 24,
          textColor: 'neutral-1000',
        }}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          component="h2"
          variant="bodyMedium"
          gutterBottom={false}
          weight="normal"
          align="center"
          lineHeight="multi-line"
          sx={{
            mt: 2,
            textColor: 'neutral-1000',
          }}
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  )
}
