import * as React from 'react'
import { enqueueSnackbar } from '@applift/factor'
import { SortingState } from '@applift/datagrid'
import { Filter, FilterProps } from './Filter'
import {
  useInfiniteQuery,
  QueryFunctionContext,
  GetNextPageParamFunction,
  InfiniteData,
} from '@tanstack/react-query'
import { FilterKeys } from '../../models/filterMetaData'
import { getFilterQueryKey } from '../../api/queryKey'
import { AccountTypes } from '../../utils'
import { WithResponse } from '../../models/Response'
import { useAppContext } from '@applift/platform'

const DEFAULT_PAGESIZE = 50

export interface ViewFilterWrapperProps<Tdata>
  extends Pick<
    FilterProps,
    'rowIdKey' | 'mode' | 'columnDef' | 'filterTitle' | 'onClose' | 'rowHeight'
  > {
  pageSize?: number
  initialSortingState?: SortingState
  filterKey: FilterKeys
  filterFn: ({
    pageParam,
    queryKey,
    signal,
  }: QueryFunctionContext<
    ReturnType<(typeof getFilterQueryKey)['keys']>
  >) => Promise<Tdata>
  getNextPageParma: GetNextPageParamFunction<Tdata>
  transformData: (data: InfiniteData<Tdata>) => any
  getTotalRecords: (data?: InfiniteData<Tdata>) => number
  fetchAllPages: boolean
  initialFilterIds: any[]
  isCustomerDependent?: boolean
  selectedCustomers?: number[]
}

export const ViewFilterWrapper = <T,>(
  props: ViewFilterWrapperProps<T>
): JSX.Element => {
  const {
    initialSortingState = [],
    pageSize = DEFAULT_PAGESIZE,
    filterKey,
    filterFn,
    getNextPageParma,
    columnDef: columnDefProps,
    transformData,
    getTotalRecords,
    fetchAllPages,
    rowIdKey,
    filterTitle,
    initialFilterIds,
    isCustomerDependent,
    selectedCustomers,
    ...others
  } = props

  const [sorting, setSorting] =
    React.useState<SortingState>(initialSortingState)

  const appContext = useAppContext()
  const organizationType = appContext.appMeta.organizationType

  // To change the sorting value when the Key changes
  React.useEffect(() => {
    setSorting(initialSortingState)
  }, [initialSortingState])

  const {
    data,
    isLoading: loading,
    fetchNextPage,
  } = useInfiniteQuery(
    getFilterQueryKey.keys({
      scope: filterKey,
      pageSize,
      sortBy: sorting.length ? sorting[0]?.id : undefined,
      order: sorting.length ? (sorting[0]?.desc ? 'desc' : 'asc') : undefined,
      segmentIds: initialFilterIds,
      ...(organizationType !== AccountTypes.ADVERTISER && isCustomerDependent
        ? { isAllOwIds: false, owIds: selectedCustomers }
        : {}),
    }),
    filterFn,
    {
      onError: (e: WithResponse) => {
        enqueueSnackbar(
          e.errorObjects
            ? (e.errorObjects[0]?.error as string)
            : 'Something went wrong!',
          {
            variant: 'error',
          }
        )
      },
      getNextPageParam: fetchAllPages ? () => -1 : getNextPageParma,
    }
  )
  const columnDef = React.useMemo(() => columnDefProps, [columnDefProps])
  const filteredData = React.useMemo(
    () => transformData(data!),
    [data, transformData]
  )
  return (
    <Filter
      filterKey={filterKey}
      data={filteredData || []}
      onFetchRows={fetchNextPage}
      totalRecords={getTotalRecords(data)}
      loading={loading}
      pageSize={pageSize}
      columnDef={columnDef}
      sorting={sorting}
      setSorting={setSorting}
      rowIdKey={rowIdKey}
      filterTitle={filterTitle}
      mode="view"
      {...others}
    />
  )
}

export function getNextPageParma(lastPage: any, pages: any[]) {
  const totalRecordsFetched = pages.reduce(
    (prev, one) => prev + one.data.length,
    0
  )
  if (totalRecordsFetched < lastPage?.filteredRecords) {
    return pages.length + 1
  }
  return false
}

export function transformData(data: any) {
  return data?.pages.map((val: any) => val.data).flat(1) ?? []
}

export function getTotalRecords(data: any) {
  return data?.pages[0]?.filteredRecords ?? 0
}
