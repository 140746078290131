import { SortingState } from '@applift/datagrid'
import {
  Box,
  enqueueSnackbar,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
} from '@applift/factor'
import {
  ArrowsMaximize,
  Duplicate,
  EmailAdd,
  FileCsv,
  FileXlsx,
} from '@applift/icons'
import { useAppContext } from '@applift/platform'
import { useMutation } from '@tanstack/react-query'
import * as React from 'react'
import { downloadReport } from '../../../../api'
import { REQUEST_TYPE } from '../../../../constants'
import { useHasChanged } from '../../../../hooks/useHasChange'
import { FilterMapping } from '../../../../models/filterMetaData'
import { ReportType } from '../../../../models/Report'
import { useStore } from '../../../../store'
import { getCustomerIds } from '../../../../transformers/customer'
import { downloadFile, sortingString } from '../../../../utils'
import { EditHeaderActionNameType } from './HeaderAction'
import { HeaderActionBar } from './HeaderActionBar'

export interface TableControlProps {
  isUsedInDialog?: boolean
  isExecuteDataAvailable?: boolean
  sorting: SortingState
  onAggregatedDailyToggle: () => void
  setShowMaximizeExecutionTable?: (arg: boolean) => void
}

export const TableControl = (props: TableControlProps) => {
  const {
    isUsedInDialog,
    sorting,
    onAggregatedDailyToggle,
    setShowMaximizeExecutionTable,
  } = props
  const [
    report,
    selectedDimensionsAndMetricsSlice,
    selectedCustomersData,
    isAllCustomerSelected,
    filters,
  ] = useStore(state => [
    state.report,
    state.selectedDimensionsAndMetricsSlice,
    state.customerFilter.selectedCustomersData,
    state.customerFilter.isAllCustomerSelected,
    state.filters.filters,
  ])
  const { isSaveDisabled, isExecuteDisabled } = useHasChanged()

  const [headerAction, setHeaderAction] =
    React.useState<EditHeaderActionNameType | null>(null)
  const [currentDownloadFileType, setCurrentDownloadFileType] = React.useState<
    1 | 2 | null
  >(null)

  const contextData = useAppContext()
  const organizationName = contextData.appMeta.organizationName

  const downloadMutation = useMutation(downloadReport, {
    mutationKey: ['DownloadReport'],
    onSuccess: (res, payload) => {
      const currentTime = Date.now()
      const fileName = `${organizationName.replace(/\s+/g, '')}${
        report.name ? `_${report?.name?.replace(/\s+/g, '')}` : ''
      }_${currentTime}.${payload.fileType === 1 ? 'csv' : 'xlsx'}`
      downloadFile(res.data?.url, fileName)
    },
    onError: () => {
      enqueueSnackbar('Something went wrong. Please try after some time.', {
        variant: 'error',
      })
    },
    onSettled: () => {
      setCurrentDownloadFileType(null)
    },
  })

  // @ts-ignore
  const sendDimensions = selectedDimensionsAndMetricsSlice.dimensions.reduce(
    (acc, val) => {
      const filterName = val.key
      let result
      // @ts-ignore
      const originalfilter = filters[FilterMapping[val.key]]
      if (
        (filterName === 'dealId' || filterName === 'deal') &&
        originalfilter
      ) {
        // @ts-ignore
        const originalfilter = filters[FilterMapping[val.key]]
        const transfromedFilter: { PG: string[]; PMP: string[] } = {
          PG: [],
          PMP: [],
        }

        const keys = Object?.keys(originalfilter)

        keys.forEach(key => {
          if (key.startsWith('PG_')) {
            transfromedFilter?.PG?.push(key.substring(3))
          } else if (key.startsWith('PMP_')) {
            transfromedFilter?.PMP?.push(key.substring(4))
          }
        })
        result = [transfromedFilter]
      } else {
        // @ts-ignore
        result = filters[FilterMapping[val.key]]
      }
      // @ts-ignore
      acc = [
        // @ts-ignore
        ...acc,
        // @ts-ignore
        filterName === 'dealId' || filterName === 'deal'
          ? { filter: filterName, value: result }
          : { filter: filterName, value: result && Object.keys(result) },
      ]
      return acc
    },
    []
  )

  const downloadfile = (fileType: 1 | 2) => {
    downloadMutation.mutate({
      fileType: fileType,
      dimensions: JSON.stringify(sendDimensions),
      columns: [...selectedDimensionsAndMetricsSlice.metrics].map(
        one => one.key
      ),
      customerIds: isAllCustomerSelected
        ? []
        : getCustomerIds(selectedCustomersData),
      requestType:
        report.view === 'Aggregated'
          ? REQUEST_TYPE.aggregated
          : REQUEST_TYPE.daily,
      reportAggregated: selectedDimensionsAndMetricsSlice.hasAggregation
        ? '1'
        : '0',
      startDate: report.dateRange?.startDate,
      endDate: report.dateRange?.endDate,
      timezoneId: report.timezone?.id,
      timezoneName: report.timezone?.value,
      sortBy: sortingString(sorting).sortBy,
    })
  }

  const disableBtn = !isSaveDisabled

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'between',
            px: 16,
          }}
        >
          {isExecuteDisabled ? (
            <Tabs
              value={report.view}
              onChange={(e, value) => {
                report.setReportView(value as ReportType)
                onAggregatedDailyToggle()
              }}
              aria-label="Platform"
            >
              <Tab value="Aggregated" label="Aggregated" />
              <Tab value="Daily" label="Daily" />
            </Tabs>
          ) : null}
          <Box>
            {report.mode === 'Edit' ? (
              <>
                {!isUsedInDialog ? (
                  <>
                    <Tooltip
                      title={
                        disableBtn
                          ? 'Update changes to expand the report table'
                          : 'Expand Table'
                      }
                      placement="top"
                      arrow
                    >
                      <Box sx={{ display: 'inline' }}>
                        <IconButton
                          onClick={() =>
                            setShowMaximizeExecutionTable &&
                            setShowMaximizeExecutionTable(true)
                          }
                          color="secondary"
                        >
                          <ArrowsMaximize />
                        </IconButton>
                      </Box>
                    </Tooltip>
                    <Tooltip
                      title={
                        disableBtn
                          ? 'Update changes to schedule the report'
                          : 'Schedule one-time or recurring report delivery over the email'
                      }
                      placement="top"
                      arrow
                    >
                      <Box sx={{ display: 'inline' }}>
                        <IconButton
                          onClick={() => setHeaderAction('EMAIL_SCHEDULE')}
                          disabled={disableBtn}
                          color="secondary"
                        >
                          <EmailAdd />
                        </IconButton>
                      </Box>
                    </Tooltip>
                  </>
                ) : null}
                <Tooltip
                  title={
                    disableBtn
                      ? 'Update changes to duplicate the report'
                      : 'Create the report with the same parameters without copying the scheduling'
                  }
                  placement="top"
                  arrow
                >
                  <Box sx={{ display: 'inline' }}>
                    <IconButton
                      disabled={disableBtn}
                      onClick={() => {
                        setHeaderAction('DUPLICATE')
                      }}
                      color="secondary"
                    >
                      <Duplicate />
                    </IconButton>
                  </Box>
                </Tooltip>
              </>
            ) : null}
            <>
              <Tooltip title={'Download report in CSV'} placement="top" arrow>
                <Box sx={{ display: 'inline' }}>
                  <IconButton
                    onClick={() => {
                      setCurrentDownloadFileType(1)
                      downloadfile(1)
                    }}
                    disabled={
                      currentDownloadFileType === 1 &&
                      downloadMutation.isLoading
                    }
                    color="secondary"
                  >
                    <FileCsv />
                  </IconButton>
                </Box>
              </Tooltip>
              <Tooltip title={'Download report in XLSX'} placement="top" arrow>
                <Box sx={{ display: 'inline' }}>
                  <IconButton
                    onClick={() => {
                      setCurrentDownloadFileType(2)
                      downloadfile(2)
                    }}
                    disabled={
                      currentDownloadFileType === 2 &&
                      downloadMutation.isLoading
                    }
                    color="secondary"
                  >
                    <FileXlsx />
                  </IconButton>
                </Box>
              </Tooltip>
            </>
          </Box>
          <HeaderActionBar
            action={headerAction}
            onClose={() => setHeaderAction(null)}
          />
        </Box>
      </Box>
    </>
  )
}
