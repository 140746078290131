import ReactDOM from 'react-dom/client'

// Do not move the style imports of factor and datagrid below app, that will mess with the import order and app styles will be in a mess
import '@applift/factor/dist/style'
import '@applift/datagrid/dist/style'
import '@applift/platform/dist/style'
import '@applift/app-utils/dist/style'
import { App } from './App'

const rootElement = document.getElementById('root')!

if (!rootElement?.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(<App />)
}
