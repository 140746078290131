type IObject = {
  [key: string | number]: any
}

export const toInitCap = (value: string) =>
  !!value && `${value[0]?.toLocaleUpperCase()}${value.slice(1)}`

export function getOrdinal(number: number) {
  switch (number) {
    case 1:
    case 21:
    case 31:
      return number + 'st'
      break
    case 2:
    case 22:
      return number + 'nd'
      break
    case 3:
    case 23:
      return number + 'rd'
      break
    default:
      return number + 'th'
  }
}

export const downloadBlob = async (blob: any, fileName: any) => {
  const link = window.document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const downloadFile = async (fileUrl: any, fileName: any) => {
  try {
    const file = await fetch(fileUrl)
    const blob = await file.blob()
    downloadBlob(blob, fileName)
  } catch (e) {
    console.error('error :', e)
  }
}

export const isDeepEqual = (object1: IObject, object2: IObject) => {
  const objKeys1 = Object.keys(object1)
  const objKeys2 = Object.keys(object2)

  if (objKeys1.length !== objKeys2.length) return false

  for (const key of objKeys1) {
    const value1 = object1[key]
    const value2 = object2[key]

    const isObjects = isObject(value1) && isObject(value2)

    if (
      (isObjects && !isDeepEqual(value1, value2)) ||
      (!isObjects && value1 !== value2)
    ) {
      return false
    }
  }
  return true
}

export const isObject = (object: any) => {
  return object != null && typeof object === 'object'
}

export const PATH = {
  HOME: '/',
  LISTPAGE: '/list',
  CREATEPAGE: '/create',
  EDITPAGE: '/edit/:id',
  CREATEFROMDRAFT: '/create-from-draft',
  NOTFOUND: '*',
}
