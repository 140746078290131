import { TypoTooltip } from '@applift/factor'
import { formatCurrency } from '../../../../../utils'
import { BUDGET_TYPE_ID } from '../../../../../constants/constants'

interface CurrencyCellType {
  value?: number | null
  isFooterCell?: boolean
  budgetType?: number
}

export const CurrencyCell = ({
  value,
  isFooterCell = false,
  budgetType = BUDGET_TYPE_ID.DOLLAR_BASED,
}: CurrencyCellType) => {
  return typeof value === 'number' ? (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        gutterBottom: false,
        weight: isFooterCell ? 'demi' : 'normal',
      }}
    >
      {budgetType === BUDGET_TYPE_ID.IMPRESSIONS_BASED ? 'Imps ' + value : null}
      {budgetType === BUDGET_TYPE_ID.DOLLAR_BASED
        ? formatCurrency(value)
        : null}
    </TypoTooltip>
  ) : (
    <>—</>
  )
}
