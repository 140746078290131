import { DialogProps } from '@applift/factor'
import { ViewCustomersDialog } from '../../components/ViewCustomersDialog'
import { ListScheduleReportDialogWrapper } from '../../components/ScheduleReportDialog'
import { DimensionsSummeryDialog } from '../../components/DimensionsSummeryDialog'
import { ListDeleteReportDialogWrapper } from '../../components/DeleteReportDialog'

export const ACTION = {
  RUN_NOW: 'RUN_NOW',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  VIEW_CUSTOMER: 'VIEW_CUSTOMER',
  VIEW_DIMENSION: 'VIEW_DIMENSION',
  VIEW_REPORT: 'VIEW_REPORT',
  ADD_SCHEDULE: 'ADD_SCHEDULE',
  EDIT_SCHEDULE: 'EDIT_SCHEDULE',
}

export type ActionNameType = keyof typeof ACTION

type ExtractProps<TComponentOrTProps> =
  TComponentOrTProps extends React.ComponentType<infer TProps>
    ? TProps
    : TComponentOrTProps

export interface ActionDetails {
  dialogProps: Partial<DialogProps>
  component: (props: ExtractProps<any>) => JSX.Element
}

export type Action = {
  [key in ActionNameType]: ActionDetails
}

// Todo remove ts-ignore once all are done
// @ts-ignore
export const ActionMapper: Action = {
  DELETE: {
    dialogProps: {
      maxWidth: 'sm',
      fullWidth: true,
      PaperProps: { sx: { boxShadow: 500 } },
    },
    component: ListDeleteReportDialogWrapper,
  },
  VIEW_DIMENSION: {
    dialogProps: {
      maxWidth: 'lg',
      fullWidth: true,
      PaperProps: { sx: { boxShadow: 500, height: 100 } },
    },
    component: DimensionsSummeryDialog,
  },
  ADD_SCHEDULE: {
    dialogProps: {
      maxWidth: 'md',
      fullWidth: true,
      PaperProps: { sx: { boxShadow: 500 } },
    },
    component: ListScheduleReportDialogWrapper,
  },
  EDIT_SCHEDULE: {
    dialogProps: {
      maxWidth: 'md',
      fullWidth: true,
      PaperProps: { sx: { boxShadow: 500 } },
    },
    component: ListScheduleReportDialogWrapper,
  },
  VIEW_CUSTOMER: {
    dialogProps: {
      maxWidth: 'xl',
      fullWidth: true,
      PaperProps: { sx: { boxShadow: 500, height: 100 } },
    },
    component: ViewCustomersDialog,
  },
}
