import { ColumnDef, ReactTableCellContext } from '@applift/datagrid'
import { TextCell } from '../cellTypes'

export const dealTypeFilterColDef: ColumnDef<any>[] = [
  {
    accessorKey: 'displayName',
    header: 'Deal Type',
    enableSorting: false,
    cell: (props: ReactTableCellContext) => {
      return <TextCell label={props.renderValue()} />
    },
  },
]
