import { StateCreator } from 'zustand'
import {
  CustomerFilterSlice,
  FilterStoreSlice,
  StoreFilter,
} from '../../models/Store'
import { FilterMapping, Filters } from '../../models/filterMetaData'

const initialFilterState = {
  filters: {} as Filters,
  dirty: false,
}

export const createFilterStore: StateCreator<
  StoreFilter,
  [['zustand/devtools', never]],
  [],
  FilterStoreSlice
> = set => ({
  filters: {
    ...initialFilterState,
    initFilters: (filters: Filters) => {
      set(
        prev => ({
          ...prev,
          filters: {
            ...prev.filters,
            filters: filters,
          },
        }),
        false,
        `initFilters`
      )
    },
    setFilters: (filterKey, rowSelection) => {
      set(
        prev => ({
          ...prev,
          filters: {
            ...prev.filters,
            filters: {
              ...prev.filters.filters,
              [filterKey]: rowSelection,
            },
            dirty: true,
          },
        }),
        false,
        `setFilters`
      )
    },
    removeFilter: filterKey => {
      set(
        prev => {
          const finalFilters = prev.filters.filters
          delete finalFilters[
            FilterMapping[
              filterKey as keyof typeof FilterMapping
            ] as keyof Filters
          ]
          return {
            ...prev,
            filters: {
              ...prev.filters,
              filters: finalFilters,
              dirty: true,
            },
          }
        },
        false,
        `removeFilter`
      )
    },
    commit: () => {
      set(
        prev => ({
          ...prev,
          filters: {
            ...prev.filters,
            dirty: false,
          },
        }),
        false,
        'commitFilters'
      )
    },
    reset: () => {
      set(
        prev => ({
          ...prev,
          filters: {
            ...prev.filters,
            ...initialFilterState,
          },
        }),
        false,
        'resetFilters'
      )
    },
  },
})

const initialCustomerState = {
  dirty: false,
  selectedCustomersData: {},
  isCustomersDialogOpen: false,
  isAllCustomerSelected: true,
  isCustomerEdit: false,
}

export const createCustomerFilterStore: StateCreator<
  StoreFilter,
  [['zustand/devtools', never]],
  [],
  CustomerFilterSlice
> = set => ({
  customerFilter: {
    ...initialCustomerState,
    initSelectedCustomersData: (selectedCustomer = {}) =>
      set(
        prev => ({
          ...prev,
          customerFilter: {
            ...prev.customerFilter,
            selectedCustomersData: selectedCustomer,
          },
        }),
        false,
        'initSelectedCustomersData'
      ),
    setSelectedCustomersData: (selectedCustomer = {}) =>
      set(
        prev => ({
          ...prev,
          customerFilter: {
            ...prev.customerFilter,
            selectedCustomersData: selectedCustomer,

            dirty: true,
          },
        }),
        false,
        'setCustomerDataFilter'
      ),
    setIsAllCustomerSelected: (setIsAllCustomerSelected: boolean) =>
      set(
        prev => ({
          ...prev,
          customerFilter: {
            ...prev.customerFilter,
            isAllCustomerSelected: setIsAllCustomerSelected,
          },
        }),
        false,
        'setCustomerDataFilter'
      ),
    toggleCustomerDialog: value =>
      set(
        prev => ({
          ...prev,
          customerFilter: {
            ...prev.customerFilter,
            isCustomersDialogOpen: value,
          },
        }),
        false,
        'toggleCustomerDialog'
      ),
    setIsCustomerEdit: (value: boolean) =>
      set(
        prev => ({
          ...prev,
          customerFilter: {
            ...prev.customerFilter,
            isCustomerEdit: value,
          },
        }),
        false,
        'setCustomerDataFilter'
      ),
    commit: () => {
      set(
        prev => ({
          ...prev,
          customerFilter: {
            ...prev.customerFilter,
            dirty: false,
          },
        }),
        false,
        'commitSelectedCustomers'
      )
    },
    reset: () => {
      set(
        prev => ({
          ...prev,
          customerFilter: {
            ...prev.customerFilter,
            ...initialCustomerState,
          },
        }),
        false,
        'resetSelectedCustomers'
      )
    },
  },
})
