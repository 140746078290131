import { Button, Dialog, enqueueSnackbar, IconButton } from '@applift/factor'
import { Delete } from '@applift/icons'
import { useMutation } from '@tanstack/react-query'
import { useNavigate, useParams } from '@tanstack/react-router'
import { AxiosError } from 'axios'
import * as React from 'react'
import * as CreateEditReportAPI from '../../../../api/createEdit'
import { queryClient } from '../../../../cache'
import { EditDeleteReportDialogWrapper } from '../../../../components/DeleteReportDialog'
import { DiscardUnsavedChanges } from '../../DiscardUnsavedChangesDialog'
import { SaveReportDialog } from './SaveReportDialog'
import { CreateReportScheduleReportWrapper } from '../../../../components/ScheduleReportDialog'
import { useGetEditData } from '../../../../hooks/useGetEditData'
import { useHasChanged } from '../../../../hooks/useHasChange'
import { CreateReportType, ReportDetailsType } from '../../../../models/Report'
import { useStore } from '../../../../store'
import { createExecutionPayload } from '../../../../utils/createExecutePayload'
import { deepDiffObj } from '../../../../utils/deepDiffObj'

export const Actions = () => {
  const {
    dimsAndMetrics,
    filters,
    report,
    isSaveDisabled,
    customerFilterIds,
    resetStore,
    isExecuteDisabled,
    commitAllChanges,
  } = useHasChanged()

  const [currentDialog, setCurrentDialog] = React.useState<
    'save' | 'schedule' | 'cancel' | 'delete' | ''
  >('')

  const navigate = useNavigate()

  const [
    mode,
    toggleGlobalDirty,
    isReportDirty,
    reportName,
    selectedDimensionsAndMetricsSlice,
    setExecutePayload,
    incrementExecutionCount,
    isAllCustomerSelected,
  ] = useStore(state => [
    state.report.mode,
    state.report.toggleGlobalDirty,
    state.report.dirty,
    state.report.name,
    state.selectedDimensionsAndMetricsSlice,
    state.executeTable.setExecutePayload,
    state.executeTable.incrementExecutionCount,
    state.customerFilter.isAllCustomerSelected,
  ])

  // @ts-ignore
  const { id } = useParams({ strict: false })
  const reportId = id ? Number(id) : null

  const [savedReportId, setSavedReportId] = React.useState<number>()

  const isReportValid = React.useMemo(() => {
    if (
      (mode === 'Edit' ? reportName : true) &&
      selectedDimensionsAndMetricsSlice.dimensions.length &&
      selectedDimensionsAndMetricsSlice.metrics.length
    ) {
      return true
    }
    return false
  }, [mode, reportName, selectedDimensionsAndMetricsSlice])

  const getData = () =>
    createExecutionPayload(
      {
        report,
        customerIds: isAllCustomerSelected ? [] : customerFilterIds,
        dimsAndMetrics,
        filters,
      },
      false
    )

  const { data: reportDetails, refetch: fetchGetEditData } = useGetEditData(
    reportId!,
    {
      enabled: reportId !== null,
    }
  )

  const saveMutation = useMutation(CreateEditReportAPI.createReport, {
    mutationKey: ['createReport'],
    onSuccess: result => {
      setSavedReportId(result.data.data)
      setCurrentDialog('schedule')
    },
    onError: (e: AxiosError<any>) => {
      enqueueSnackbar(
        e.response?.data?.errorObjects?.[0]?.error ||
          'Something went wrong. Please try after sometime.',
        {
          variant: 'error',
        }
      )
      setCurrentDialog('')
    },
    onSettled: () => {
      saveMutation.reset()
    },
  })

  const updateMutationFn = async (params: {
    oldReportData: ReportDetailsType
    newReportData: CreateReportType
    reportId: number
  }) => {
    const { oldReportData, newReportData, reportId } = params
    const updatedFields = deepDiffObj(newReportData, oldReportData)
    return CreateEditReportAPI.updateReport({ ...updatedFields, reportId })
  }

  const updateMutation = useMutation(updateMutationFn, {
    mutationKey: ['updateReport'],
    onSuccess: (response, payload) => {
      fetchGetEditData()
      commitAllChanges()
      toggleGlobalDirty(false)
      if (!isExecuteDisabled) {
        incrementExecutionCount()
        setExecutePayload()
      }
      queryClient.setQueriesData<any>(
        {
          // @ts-ignore
          predicate: query => query.queryKey[0].scope === 'getReportDetails',
        },
        (pages: any) => {
          const modifiedPayload = Object.assign({}, payload)
          for (const key in modifiedPayload) {
            if (
              // @ts-ignore
              typeof modifiedPayload[key] === 'undefined'
            ) {
              // @ts-ignore
              delete modifiedPayload[key]
            }
          }

          return { ...pages, ...modifiedPayload }
        }
      )
      enqueueSnackbar('Report updated successfully', {
        variant: 'success',
      })
    },
    onError: (e: any) => {
      enqueueSnackbar(
        e.response?.data?.errorObjects?.[0]?.error ||
          'Something went wrong. Please try after sometime.',
        {
          variant: 'error',
        }
      )
    },
  })

  const currentMutation = mode === 'Edit' ? updateMutation : saveMutation

  const onSave = () => {
    const result = getData()
    // @ts-ignore
    currentMutation.mutate(result as CreateReportType)
  }

  const closeDialog = () => {
    setCurrentDialog('')
  }

  const determineSaveOrOpenSaveDialog = () => {
    if (!report.name) {
      setCurrentDialog('save')
    } else {
      onSave()
    }
  }

  const updateReport = () => {
    if (reportId) {
      updateMutation.mutate({
        oldReportData: reportDetails as ReportDetailsType,
        newReportData: getData(),
        reportId: reportId,
      })
    }
  }

  const gotoListPage = () => {
    navigate({ to: '/list' })
  }

  return (
    <>
      {mode === 'Edit' && (
        <IconButton
          sx={{ mr: 16 }}
          onClick={() => setCurrentDialog('delete')}
          color="error"
        >
          <Delete />
        </IconButton>
      )}
      <Button
        color="secondary"
        sx={{ mr: 16 }}
        disabled={saveMutation.isLoading}
        onClick={() =>
          !isSaveDisabled || isReportDirty
            ? setCurrentDialog('cancel')
            : gotoListPage()
        }
      >
        Cancel
      </Button>
      <Button
        onClick={mode === 'Edit' ? updateReport : determineSaveOrOpenSaveDialog}
        disabled={
          isSaveDisabled ||
          saveMutation.isLoading ||
          updateMutation.isLoading ||
          !isReportValid
        }
        sx={{ flexShrink: 0 }}
      >
        {mode === 'Edit' ? 'Update Changes' : 'Save Report'}
      </Button>

      <Dialog
        maxWidth={currentDialog === 'schedule' ? 'md' : 'sm'}
        fullWidth
        open={Boolean(currentDialog)}
      >
        {currentDialog === 'save' ? (
          <SaveReportDialog
            onSave={onSave}
            onCancel={closeDialog}
            reportName={report.name}
            setReportName={(e: { target: { value: string } }) =>
              report.setReportName(e.target.value.trim() ? e.target.value : '')
            }
            isLoading={saveMutation.isLoading}
          />
        ) : currentDialog === 'schedule' ? (
          <CreateReportScheduleReportWrapper
            onClose={() => {
              closeDialog()
              resetStore()
              queryClient.clear()
              navigate({ to: `/edit/${savedReportId}` })
            }}
            rowData={getData()}
            reportId={savedReportId!}
          />
        ) : currentDialog === 'cancel' ? (
          <DiscardUnsavedChanges
            onCancel={closeDialog}
            onDiscard={() => {
              closeDialog()
              navigate({ to: '/list' })
            }}
          />
        ) : currentDialog === 'delete' && reportId ? (
          <EditDeleteReportDialogWrapper id={reportId} onClose={closeDialog} />
        ) : null}
      </Dialog>
    </>
  )
}
