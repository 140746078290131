import { TypoTooltip, SxProps } from '@applift/factor'

interface TextCellType {
  label: number | string
  sx?: SxProps
}

export const TextCell = ({ label, sx }: TextCellType) => {
  return (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        gutterBottom: false,
        sx: sx,
      }}
    >
      {label}
    </TypoTooltip>
  )
}
