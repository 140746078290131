import * as React from 'react'
import { DNDRefType } from '../models/events'

export interface DNDContextProvider {
  children: React.ReactNode
  value: DNDRefType
}

export const DNDContext = React.createContext<unknown>(null)

export const DNDContextProvider = ({ children, value }: DNDContextProvider) => {
  return <DNDContext.Provider value={value}>{children}</DNDContext.Provider>
}
