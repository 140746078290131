import * as React from 'react'
import { TypoTooltip } from '@applift/factor'
import style from './index.module.scss'

export interface DateRangeCellProps {
  startDate: number
  endDate: number
}

export const DateRangeCellMaker = (
  local = 'en-US',
  options?: Intl.DateTimeFormatOptions
) => {
  const formatter = Intl.DateTimeFormat(local, options)
  const Component = (props: DateRangeCellProps) => {
    const formattedStartDate = formatter.format(props.startDate)
    const formattedEndDate = formatter.format(props.endDate)

    const [startMonth, startDay, startYear] = formattedStartDate.split('/')
    const paddedStartMonth = startMonth?.padStart(2, '0')
    const paddedStartDay = startDay?.padStart(2, '0')
    const formattedStartDateWithZeroes = `${paddedStartMonth}/${paddedStartDay}/${startYear}`

    const [endMonth, endDay, endYear] = formattedEndDate.split('/')
    const paddedEndMonth = endMonth?.padStart(2, '0')
    const paddedEndDay = endDay?.padStart(2, '0')
    const formattedEndDateWithZeroes = `${paddedEndMonth}/${paddedEndDay}/${endYear}`

    const dateRangeToRender = React.useMemo(() => {
      if (formattedStartDateWithZeroes && formattedEndDateWithZeroes) {
        return `${formattedStartDateWithZeroes} - ${formattedEndDateWithZeroes}`
      }
      if (formattedStartDateWithZeroes && !formattedEndDateWithZeroes) {
        return formattedStartDateWithZeroes
      }
      if (!formattedStartDateWithZeroes && formattedEndDateWithZeroes) {
        return formattedEndDateWithZeroes
      }
      return 'N/A'
    }, [formattedEndDateWithZeroes, formattedStartDateWithZeroes])

    return (
      <TypoTooltip
        placement="top"
        arrow
        TypgraphyProps={{
          className: style.fontVariantNumeric,
          gutterBottom: false,
        }}
      >
        {dateRangeToRender}
      </TypoTooltip>
    )
  }
  Component.displayName = 'DateRange'
  return Component
}
