import { ColumnDef, ReactTableCellContext } from '@applift/datagrid'
import { CampaignFilterType } from '../../../models/Filters'
import { IconTextCell, TextCell } from '../cellTypes'
import { BUDGET_TYPE_ID } from '../../../constants'

export const ioIdFilter: ColumnDef<CampaignFilterType>[] = [
  {
    accessorKey: 'ioId',
    header: 'ID',
    maxSize: 150,
    size: 100,
    minSize: 50,
    enableSorting: false,
    cell: (props: ReactTableCellContext) => {
      return <TextCell label={props.renderValue()} />
    },
  },
  {
    accessorKey: 'ioName',
    header: 'Insertion Order Name',
    size: 385,
    minSize: 150,
    enableSorting: false,
    cell: (props: ReactTableCellContext) => {
      const iconName =
        (props.row.original.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED &&
          'IoCurrency') ||
        (props.row.original.ioBudgetTypeId ===
          BUDGET_TYPE_ID.IMPRESSIONS_BASED &&
          'IoImpression')
      return (
        <IconTextCell
          iconName={iconName || 'Error'}
          iconSize={24}
          label={props.renderValue() as string}
        />
      )
    },
  },
]
