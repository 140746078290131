import { Dialog } from '@applift/factor'
import { SpecialRuleType } from '../../../models/DimensionMetrics'
import { DependentMetricDialog } from './DependentMetricDialog'
import { RemoveAggragationDialog } from './RemoveAggragationDialog'
import { RemoveDependentMetricDialog } from './RemoveDependentMetricDialog'
import { ReorderAggragationDialog } from './ReorderAggragationDialog'

interface ConditionsProps {
  closeDialog: () => void
  state?: SpecialRuleType
}

export const Conditions = ({
  closeDialog,
  state = {} as SpecialRuleType,
}: ConditionsProps) => {
  const getComponent = () => {
    switch (state?.dialogType) {
      case 'addDependentDimension': {
        return (
          <DependentMetricDialog
            dimensionMetricInfo={state?.info}
            onClose={closeDialog}
            onReset={dimension => {
              state?.commit(dimension)
              closeDialog()
            }}
          />
        )
      }
      case 'removeAggregation': {
        return (
          <RemoveAggragationDialog
            dimensionMetricInfo={state?.info}
            onReset={() => {
              state?.commit()
              closeDialog()
            }}
            onClose={closeDialog}
          />
        )
      }
      case 'removeDependentMetric': {
        return (
          <RemoveDependentMetricDialog
            onClose={closeDialog}
            dimensionMetricInfo={state?.info}
            isAggregationDependent={state?.shouldToggleAggregation}
            onReset={() => {
              state?.commit()
              closeDialog()
            }}
          />
        )
      }
      case 'reorderRemoveAggregation': {
        return (
          <ReorderAggragationDialog
            onClose={closeDialog}
            dimensionMetricInfo={state?.info}
            onReset={() => {
              state?.commit()
              closeDialog()
            }}
          />
        )
      }
      default: {
        return null
      }
    }
  }

  return (
    <Dialog open={Boolean(state?.dialogType)} maxWidth={'sm'} fullWidth>
      {getComponent()}
    </Dialog>
  )
}
