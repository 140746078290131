import { Box, Typography } from '@applift/factor'
import { ClipboardRemove } from '@applift/icons'

export const NoResultsOverlay = () => {
  return (
    <Box
      sx={{
        width: 100,
        height: 100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ClipboardRemove color="secondary" fontSize={32} sx={{ mb: 16 }} />
      <Typography
        component="h5"
        variant="bodyMedium"
        align={'center'}
        sx={{ textColor: 'neutral-600' }}
      >
        {'No results found'}
      </Typography>
      <Typography
        component="p"
        align={'center'}
        variant="bodySmall"
        sx={{ textColor: 'neutral-600' }}
      >
        {'We can’t find any items matching your search.'}
      </Typography>
    </Box>
  )
}
