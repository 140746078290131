import { Avatar, AvatarProps, Badge } from '@applift/factor'
import { Crown } from '@applift/icons'
import clsx from 'clsx'
import styles from './index.module.scss'

interface OrganizationAvatarProps {
  isAdvertiser: boolean
  src: AvatarProps['src']
  size: AvatarProps['size']
  alt?: string
}

export const OrganizationAvatar = (props: OrganizationAvatarProps) => {
  if (props.isAdvertiser === false) {
    return (
      <Badge
        color="primary"
        overlap="circular"
        variant="dot"
        size="small"
        badgeContent={<Crown />}
        classes={{ badge: styles.badge }}
      >
        <Avatar
          size={props.size}
          className={clsx(styles.avatar, styles.workspace)}
          src={props.src}
          alt={props.alt}
        />
      </Badge>
    )
  }
  return (
    <Avatar
      size={props.size}
      className={styles.avatar}
      src={props.src}
      alt={props.alt}
    />
  )
}
