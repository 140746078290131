import { SortingState } from '@applift/datagrid'
import { DialogProps } from '@applift/factor'
import {
  QueryFunctionContext,
  GetNextPageParamFunction,
  InfiniteData,
} from '@tanstack/react-query'
import { ColumnDef } from '@applift/datagrid'
import { getFilterQueryKey } from '../api/queryKey'
import { RowSelectionState } from '@applift/datagrid'

// TODO : remove filter mapping and align with backend names

export const FilterTypes = {
  AgeGroupFilter: 'AgeGroupFilter',
  IoNameFilter: 'IoNameFilter',
  IoIdFilter: 'IoIdFilter',
  IoStartDateFilter: 'IoStartDate',
  IoEndDateFilter: 'IoEndDate',
  CampaignFilter: 'CampaignFilter',
  CampaignIdFilter: 'CampaignIdFilter',
  CampaignGroupFilter: 'CampaignGroupFilter',
  CampaignGroupIdFilter: 'CampaignGroupIdFilter',
  CampaignStartDateFilter: 'CampaignStartDateFilter',
  CampaignEndDateFilter: 'CampaignEndDateFilter',
  CreativeGroupFilter: 'CreativeGroupFilter',
  CarrierFilter: 'CarrierFilter',
  CityFilter: 'CityFilter',
  CongressionalDistrictFilter: 'CongressionalDistrictFilter',
  ConnectionTypeFilter: 'ConnectionTypeFilter',
  CountiesFilter: 'CountiesFilter',
  CountryFilter: 'CountryFilter',
  CreativeFilter: 'CreativeFilter',
  CreativeIdFilter: 'CreativeIdFilter',
  CreativeTypeFilter: 'CreativeTypeFilter',
  CustomerFilter: 'CustomerFilter',
  CustomerIdFilter: 'CustomerIdFilter',
  ChannelFilter: 'ChannelFilter',
  DayFilter: 'DayFilter',
  DeviceTypesFilter: 'DeviceTypesFilter',
  DmaFilter: 'DmaFilter',
  EthnicityFilter: 'EthnicityFilter',
  ExchangeFilter: 'ExchangeFilter',
  GenderFilter: 'GenderFilter',
  HoursFilter: 'HoursFilter',
  IncomeRangeFilter: 'IncomeRangeFilter',
  LanguageFilter: 'LanguageFilter',
  ManufacturerFilter: 'ManufacturerFilter',
  OsFilter: 'OsFilter',
  PlaybackMethodFilter: 'PlaybackMethodFilter',
  PlayerSizeFilter: 'PlayerSizeFilter',
  PlacementTypeFilter: 'PlacementTypeFilter',
  RollPositionFilter: 'RollPositionFilter',
  SkippabilityFilter: 'SkippabilityFilter',
  SiteAndAppFilter: 'SiteAndAppFilter',
  SiteAndAppIdFilter: 'SiteAndAppIdFilter',
  StatesHouseFilter: 'StatesHouseFilter',
  StatesSenateFilter: 'StatesSenateFilter',
  StatesFilter: 'StatesFilter',
  ZipFilter: 'ZipFilter',
  DealFilter: 'DealFilter',
  DealIdFilter: 'DealIdFilter',
  DealTypeFilter: 'DealTypeFilter',
  CampaignTypeFilter: 'CampaignTypeFilter',
  ExternalCreativeIdFilter: 'ExternalCreativeIdFilter',
}

export const FilterMapping = {
  clickUrl: 'ClickUrl',
  ioName: 'IoNameFilter',
  ioId: 'IoIdFilter',
  ioStartDate: 'IoStartDateFilter',
  ioEndDate: 'IoEndDateFilter',
  ageGroup: 'AgeGroupFilter',
  campaign: 'CampaignFilter',
  campaignId: 'CampaignIdFilter',
  campaignGroup: 'CampaignGroupFilter',
  campaignGroupId: 'CampaignGroupIdFilter',
  campaignStartDate: 'CampaignStartDateFilter',
  campaignEndDate: 'CampaignEndDateFilter',
  creativeGroup: 'CreativeGroupFilter',
  carrier: 'CarrierFilter',
  city: 'CityFilter',
  congressionalDistrict: 'CongressionalDistrictFilter',
  connectionType: 'ConnectionTypeFilter',
  county: 'CountiesFilter',
  country: 'CountryFilter',
  customer: 'CustomerFilter',
  customerId: 'CustomerIdFilter',
  day: 'DayFilter',
  creative: 'CreativeFilter',
  creativeId: 'CreativeIdFilter',
  creativeType: 'CreativeTypeFilter',
  channel: 'ChannelFilter',
  deviceType: 'DeviceTypesFilter',
  dma: 'DmaFilter',
  ethnicity: 'EthnicityFilter',
  exchange: 'ExchangeFilter',
  gender: 'GenderFilter',
  hour: 'HoursFilter',
  incomeRange: 'IncomeRangeFilter',
  language: 'LanguageFilter',
  manufacturer: 'ManufacturerFilter',
  os: 'OsFilter',
  playbackMethod: 'PlaybackMethodFilter',
  playerSize: 'PlayerSizeFilter',
  placementType: 'PlacementTypeFilter',
  rollPosition: 'RollPositionFilter',
  skippability: 'SkippabilityFilter',
  siteAndApp: 'SiteAndAppFilter',
  siteAndAppId: 'SiteAndAppIdFilter',
  stateHouse: 'StatesHouseFilter',
  stateSenate: 'StatesSenateFilter',
  state: 'StatesFilter',
  zipCode: 'ZipFilter',
  deal: 'DealFilter',
  dealId: 'DealIdFilter',
  dealType: 'DealTypeFilter',
  campaignType: 'CampaignTypeFilter',
  externalCreativeId: 'ExternalCreativeIdFilter',
}

export const ReverseFilterMapping = {
  AgeGroupFilter: 'ageGroup',
  IoNameFilter: 'ioName',
  IoIdFilter: 'ioId',
  IoStartDateFilter: 'ioStartDate',
  IoEndDateFilter: 'ioEndDate',
  CampaignFilter: 'campaign',
  CampaignIdFilter: 'campaignId',
  CampaignGroupFilter: 'campaignGroup',
  CampaignGroupIdFilter: 'campaignGroupId',
  CampaignStartDateFilter: 'campaignStartDate',
  CampaignEndDateFilter: 'campaignEndDate',
  CreativeGroupFilter: 'creativeGroup',
  CarrierFilter: 'carrier',
  CityFilter: 'city',
  CongressionalDistrictFilter: 'congressionalDistrict',
  ConnectionTypeFilter: 'connectionType',
  CountiesFilter: 'county',
  CreativeFilter: 'creative',
  CreativeIdFilter: 'creativeId',
  CreativeTypeFilter: 'creativeType',
  ChannelFilter: 'channel',
  CountryFilter: 'country',
  CustomerFilter: 'customer',
  CustomerIdFilter: 'customerId',
  DayFilter: 'day',
  DeviceTypesFilter: 'deviceType',
  DmaFilter: 'dma',
  EthnicityFilter: 'ethnicity',
  ExchangeFilter: 'exchange',
  GenderFilter: 'gender',
  HoursFilter: 'hour',
  IncomeRangeFilter: 'incomeRange',
  LanguageFilter: 'language',
  ManufacturerFilter: 'manufacturer',
  OsFilter: 'os',
  PlaybackMethodFilter: 'playbackMethod',
  PlayerSizeFilter: 'playerSize',
  PlacementTypeFilter: 'placementType',
  RollPositionFilter: 'rollPosition',
  SkippabilityFilter: 'skippability',
  SiteAndAppFilter: 'siteAndApp',
  SiteAndAppIdFilter: 'siteAndAppId',
  StatesHouseFilter: 'stateHouse',
  StatesSenateFilter: 'stateSenate',
  StatesFilter: 'state',
  ZipFilter: 'zipCode',
  DealFilter: 'deal',
  DealIdFilter: 'dealId',
  DealTypeFilter: 'dealType',
  CampaignTypeFilter: 'campaignType',
  ExternalCreativeIdFilter: 'externalCreativeId',
}

export type FilterKeys = keyof typeof FilterTypes

export type FilterMappingKeys = keyof typeof FilterMapping

export interface FilterMetaDataObj<Tdata> {
  title: string
  filterFn: ({
    pageParam,
    queryKey,
    signal,
  }: QueryFunctionContext<
    ReturnType<(typeof getFilterQueryKey)['keys']>
  >) => Promise<Tdata>
  getNextPageParma: GetNextPageParamFunction<Tdata>
  transformData: (data: InfiniteData<unknown>) => any
  getTotalRecords: (data?: InfiniteData<unknown> | undefined) => number
  fetchAllPages: boolean
  columnDef: ColumnDef<any, unknown>[]
  pageSize?: number
  initialSortingState?: SortingState
  rowIdKey?: string
  rowHeight?: number
  disableSearch?: boolean
  dialogWidth?: DialogProps['maxWidth']
  emptyfilterMessage?: { title: string; subText: string }
  dateRangeSearch?: boolean
}

export type FilterMetaData = {
  [key in FilterKeys]: FilterMetaDataObj<any>
}

export type Filters = {
  [key in FilterKeys]: RowSelectionState
}
