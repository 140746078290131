import * as React from 'react'
import { Col, Paper, Box, Alert, enqueueSnackbar } from '@applift/factor'
import { TableControl } from './TableControl'
import { ExecuteReport } from './ExecuteReport'
import { EmptyTable } from './EmptyTable'
import { Table } from './Table'
import { ExecuteLoader } from './ExecuteLoader'
import { useStore } from '../../../../store'
import { queryClient } from '../../../../cache'
import { useExecuteReport } from '../../../../hooks/useExecuteReport'
import { getNewKey } from '../../../../utils/getNewKey'
import { useHasChanged } from '../../../../hooks/useHasChange'
import styles from '../index.module.scss'

export interface TableContainerProps {
  isUsedInDialog?: boolean
  setShowMaximizeExecutionTable?: (arg: boolean) => void
}

export const TableContainer = (props: TableContainerProps) => {
  const { isUsedInDialog, setShowMaximizeExecutionTable } = props
  const [
    dimensions,
    metrics,
    hasAggregation,
    view,
    cancleExecution,
    setExecutePayload,
    resetExecutePayload,
    sorting,
    setSorting,
    resetSorting,
    isSortChanged,
    commitSortChange,
    executeTablePayload,
  ] = useStore(state => [
    state.selectedDimensionsAndMetricsSlice.dimensions,
    state.selectedDimensionsAndMetricsSlice.metrics,
    state.selectedDimensionsAndMetricsSlice.hasAggregation,
    state.report.view,
    state.executeTable.cancleExecution,
    state.executeTable.setExecutePayload,
    state.executeTable.resetExecutePayload,
    state.executeTable.sorting,
    state.executeTable.setSorting,
    state.executeTable.resetSorting,
    state.executeTable.isSortChanged,
    state.executeTable.commit,
    state.executeTable.payload,
  ])

  const hasSortChangeRef = React.useRef(false)

  const { commitChangesLocally, isExecuteDisabled } = useHasChanged()
  const [showExecuteFailedMsg, setShowExecuteFailedMsg] =
    React.useState<boolean>(false)
  const [isExecuteDataAvailable, setExecuteDataAvailable] =
    React.useState<boolean>(true)

  const {
    data: executedReportData,
    fetchNextPage: fetchNextExecutePage,
    isFetching: executeReportFetching,
    isFetchingNextPage: isFetchingNextPage,
    isFetched,
    isError,
    isSuccess,
    isFetchedAfterMount,
  } = useExecuteReport(
    {
      onSuccess: data => {
        if (!data.pages[0]?.data) {
          setExecuteDataAvailable(false)
        } else {
          setExecuteDataAvailable(true)
        }
        if (hasSortChangeRef.current === false) {
          commitChangesLocally()
        }
        hasSortChangeRef.current = false
      },
      onError: (e: any) => {
        if (!isUsedInDialog) {
          enqueueSnackbar(
            e.errorObjects
              ? (e.errorObjects[0]?.error as string)
              : 'Something went wrong!',
            {
              variant: 'error',
            }
          )
        }
      },
    },
    sorting
  )

  const isReportLoadedFirstTime = React.useRef<boolean | null>(null)

  function getIsReportLoadedFirstTime() {
    if (isFetchedAfterMount) {
      isReportLoadedFirstTime.current = true
      return
    }
    if (isReportLoadedFirstTime.current) {
      return
    }
    return
  }
  getIsReportLoadedFirstTime()

  const cancelExecution = React.useCallback(() => {
    queryClient
      .cancelQueries({
        exact: false,
      })
      .then(() => cancleExecution())
      .catch(() => console.log('Unable to cancel report execution'))
  }, [cancleExecution])

  const executedFilteredData = React.useCallback(() => {
    const flatArr = executedReportData?.pages.length
      ? executedReportData?.pages.map(val => val.data).flat(1)
      : []

    const tableDataWithId = flatArr[0]
      ? flatArr.map(val => ({
          ...val,
          ...(val && { id: getNewKey() }),
        }))
      : []
    return tableDataWithId
  }, [executedReportData])

  React.useEffect(() => {
    if (isError) {
      setShowExecuteFailedMsg(true)
    }
    if (isSuccess) {
      setShowExecuteFailedMsg(false)
    }
  }, [isError, isSuccess])

  React.useEffect(() => {
    return () => {
      if (!isUsedInDialog) {
        resetSorting()
        resetExecutePayload()
      }
    }
  }, [isUsedInDialog, resetExecutePayload, resetSorting])

  const overlay = React.useMemo(() => {
    return !executedFilteredData()?.length &&
      !executeReportFetching &&
      isExecuteDisabled &&
      !isError &&
      isFetched
      ? 'noResult'
      : undefined
  }, [
    executedFilteredData,
    executeReportFetching,
    isExecuteDisabled,
    isError,
    isFetched,
  ])

  return (
    <Col
      xs={'auto'}
      sx={{ display: 'flex', height: 100, overflow: 'auto', pb: 16 }}
      // @ts-ignore
      className={isUsedInDialog ? '' : styles.tableContainer}
    >
      <Paper
        elevation={!isUsedInDialog ? 2 : 0}
        sx={{ display: 'flex', width: 100, height: 100, overflow: 'auto' }}
      >
        <Box
          className={styles.tableWrapper}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 100,
            height: 100,
          }}
        >
          {executeReportFetching && !isFetchingNextPage && !isSortChanged ? (
            <ExecuteLoader
              cancelExecution={() => {
                cancelExecution()
              }}
            />
          ) : dimensions.length >= 1 && metrics.length >= 1 ? (
            <>
              {!isUsedInDialog &&
                (showExecuteFailedMsg ||
                  !isExecuteDisabled ||
                  isError ||
                  !isReportLoadedFirstTime.current) && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: 100,
                      gap: 16,
                      px: 16,
                      pt: 16,
                    }}
                  >
                    {showExecuteFailedMsg && (
                      <Alert
                        severity="warning"
                        onClose={() => setShowExecuteFailedMsg(false)}
                      >
                        Report execution failed. Please execute the report
                        again.
                      </Alert>
                    )}
                    {!isUsedInDialog &&
                      (!isExecuteDisabled ||
                        isError ||
                        !isReportLoadedFirstTime.current) && (
                        <ExecuteReport
                          isExecuteReportFetching={executeReportFetching}
                        />
                      )}
                  </Box>
                )}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 100,
                  height: 100,
                  overflow: 'auto',
                }}
              >
                {!overlay &&
                  (!isUsedInDialog ? isReportLoadedFirstTime.current : true) &&
                  executeTablePayload?.counter !== 0 &&
                  !isError &&
                  isExecuteDisabled && (
                    <TableControl
                      sorting={sorting}
                      isExecuteDataAvailable={isExecuteDataAvailable}
                      isUsedInDialog={isUsedInDialog}
                      setShowMaximizeExecutionTable={
                        setShowMaximizeExecutionTable
                      }
                      onAggregatedDailyToggle={() => {
                        if (sorting[0]?.id === 'date') {
                          resetSorting()
                        }
                        commitSortChange()
                        setExecutePayload()
                      }}
                    />
                  )}
                <Table
                  // @ts-ignore
                  tableData={
                    !executeReportFetching ||
                    (executeReportFetching && isFetchingNextPage)
                      ? executedFilteredData()
                      : []
                  }
                  filteredRecord={executedReportData?.pages[0]?.filteredRecords}
                  dimensions={dimensions}
                  metrics={metrics}
                  hasAggregation={hasAggregation}
                  view={view}
                  loading={executeReportFetching}
                  fetchNextPage={() => {
                    if (executedFilteredData().length && isFetched) {
                      fetchNextExecutePage()
                    }
                  }}
                  footerData={executedReportData?.pages[0]?.total}
                  sorting={sorting}
                  isError={isError}
                  isFetched={isFetched}
                  overlay={overlay}
                  setSorting={e => {
                    setSorting(e)
                    hasSortChangeRef.current = true
                  }}
                />
              </Box>
            </>
          ) : (
            <EmptyTable />
          )}
        </Box>
      </Paper>
    </Col>
  )
}
