import {
  createFileRoute,
  redirect,
  useLoaderData,
  useRouteContext,
} from '@tanstack/react-router'

import { ExtendDimension, ExtendMetrics } from '../../models/DimensionMetrics'
import { CreateWrapper } from '../../modules/CreateWrapper'
import { useStore } from '../../store'

import { enqueueSnackbar } from '@applift/factor'
import { NormalizedCustomerList } from '@applift/platform'
import React from 'react'
import * as EditApi from '../../api/createEdit'
import { getReportDetailsQueryKey } from '../../api/queryKey'
import { queryClient } from '../../cache'
import { GlobalLoader } from '../../components/GlobalLoader'
import { REQUEST_TYPE } from '../../constants'
import { DimensionFilterType } from '../../models/List'
import { Filters } from '../../models/filterMetaData'
import { getCustomerIds } from '../../transformers/customer'
import { transformColumns, transformFilters } from '../../transformers/edit'

export const Route = createFileRoute('/edit/$id')({
  component: () => <EditPage />,
  validateSearch: search =>
    search as {
      executeReport: boolean
    },

  loaderDeps: ({ search: { executeReport } }) => ({ executeReport }),
  loader: async ({ context, params, deps }) => {
    const executeReport = deps.executeReport
    const id = params.id
    let reportDetails
    try {
      reportDetails = await context.queryClient.fetchQuery(
        getReportDetailsQueryKey.keys('getReportDetails', Number(id)),
        EditApi.getReportDetails,
        {
          cacheTime: 0,
        }
      )
    } catch (error) {
      // @ts-ignore
      if (error.status === 422) {
        enqueueSnackbar('Report does not exists', { variant: 'error' })
        throw redirect({ to: '/list' })
      }
    }

    return {
      editReportData: { reportDetails, executeReport },
    }
  },

  errorComponent: () => <h3>error loading data</h3>,
})

export const EditPage = () => {
  const setReportMode = useStore(state => state.report.setReportMode)
  const [storeInitialized, setStoreInitialized] = React.useState(false)
  const contextData = useRouteContext({ from: '__root__' })
  const dimensionAndMetricsListData =
    contextData._root.dimensionAndMetricsListData
  const timezoneList = contextData._root.timezoneList
  const customerList = contextData._root.customerList

  const { editReportData } = useLoaderData({
    from: '/edit/$id',
  })

  React.useEffect(() => {
    queryClient.removeQueries({
      predicate: query => {
        const queryKey = query.queryKey[0] as { scope?: string }
        return queryKey?.scope === 'executeReport'
      },
    })
  }, [editReportData])

  const { executeReport, reportDetails } = editReportData
  const [
    setIsAllCustomerSelected,
    initSelectedCustomersData,
    initDimsAndMetrics,
    initReport,
    initFilters,
    initExecutePayload,
  ] = useStore(state => [
    state.customerFilter.setIsAllCustomerSelected,
    state.customerFilter.initSelectedCustomersData,
    state.selectedDimensionsAndMetricsSlice.initDimsAndMetrics,
    state.report.initReport,
    state.filters.initFilters,
    state.executeTable.initExecutePayload,
  ])
  React.useEffect(() => {
    if (reportDetails) {
      const {
        reportName,
        customerIds,
        timezoneId,
        endDate,
        startDate,
        dimensions,
        reportAggregated,
        requestType,
        columns,
      } = reportDetails

      let filteredCustomerList: Record<string, NormalizedCustomerList> = {}
      if (customerList && !customerIds?.length) {
        filteredCustomerList = customerList
      } else if (customerList) {
        customerIds?.forEach(id => {
          if (customerList[id]) {
            filteredCustomerList[id] = customerList[id]!
          }
        })
      }

      const metricsData: ExtendMetrics[] = []
      const dimensionsData: ExtendDimension[] = []
      const filters = {} as Filters
      const dimensionFilters = JSON.parse(dimensions) as DimensionFilterType[]
      const timezoneData = timezoneList?.filter(val => val.id === timezoneId)[0]
      const isAllCustomerSelected =
        getCustomerIds(customerList ?? {}).length ===
        getCustomerIds(filteredCustomerList).length

      if (
        !columns &&
        !dimensions &&
        !dimensionAndMetricsListData &&
        !timezoneData &&
        !reportName &&
        !timezoneId &&
        !startDate &&
        !endDate
      ) {
        throw new Error('Invalid data')
      }

      transformColumns(columns, metricsData, dimensionAndMetricsListData)

      transformFilters(
        dimensionFilters,
        dimensionsData,
        filters,
        dimensionAndMetricsListData
      )

      initReport({
        name: reportName || '',
        timezone: {
          id: timezoneData!.id,
          label: timezoneData!.label,
          value: timezoneData!.name,
        },
        dateRange: { startDate, endDate },
        view: requestType === REQUEST_TYPE.daily ? 'Daily' : 'Aggregated',
      })
      initDimsAndMetrics(dimensionsData, metricsData, reportAggregated === '1')
      initFilters(filters)
      initSelectedCustomersData(filteredCustomerList)
      setIsAllCustomerSelected(isAllCustomerSelected)
      setReportMode('Edit')
      setStoreInitialized(true)
      if (executeReport !== false) {
        initExecutePayload()
      }
    }
  }, [
    customerList,
    dimensionAndMetricsListData,
    executeReport,
    initDimsAndMetrics,
    initExecutePayload,
    initFilters,
    initReport,
    initSelectedCustomersData,
    reportDetails,
    setIsAllCustomerSelected,
    setReportMode,
    timezoneList,
  ])

  return storeInitialized ? (
    <CreateWrapper key={reportDetails?.id} />
  ) : (
    <GlobalLoader />
  )
}
