import {
  Box,
  Button,
  Chip,
  Col,
  DialogActions,
  DialogContent,
  DialogTitle,
  enqueueSnackbar,
  FormControlLabel,
  InputLabel,
  InputWithTags,
  LoadingButton,
  Row,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@applift/factor'
import { InfoCircle } from '@applift/icons'
import { useQuery } from '@tanstack/react-query'
import * as React from 'react'

import { DatePicker, MinMaxParamsType } from '@applift/app-utils'
import { fromAbsolute, now } from '@internationalized/date'
import { useRouteContext } from '@tanstack/react-router'
import * as ScheduleAPI from '../../api/schedule'
import { staticDays, staticWeeks } from '../../api/schedule'
import { Option } from '../../models/Option'
import { WithResponse } from '../../models/Response'
import { ReportSchedulingEventDetailsType } from '../../models/Schedule'
import { getOrdinal, isDeepEqual } from '../../utils/utils'
import styles from './index.module.scss'
import { DeliveryDaySelect } from './ScheduleSelects/DeliveryDaySelect'
import { DeliveryFrequencySelect } from './ScheduleSelects/DeliveryFrequencySelect'
import { DeliveryMonthDaySelect } from './ScheduleSelects/DeliveryMonthDaySelect'

interface ScheduleReportProps {
  onClose: (params?: any) => void
  initialWeekDay?: Option<string>
  initialMonthDay?: Option<string>
  initialDeliverySchedule?: Option<number>
  initialTimeZone?: Option<string, number>
  initialDocFormat?: number
  initialEndDate?: number
  initialReceptiantEmails?: string[]
  initialRunningTotal?: boolean
  onSchedule: (details: ReportSchedulingEventDetailsType) => void
  onStopSchedule: () => void
  showRunningTotal: boolean
  mode: 'edit' | 'new'
  cancelTitle?: string
  isLoading: boolean
  reportName: string
  timezoneId?: number
}

export const ScheduleReportDialog = (props: ScheduleReportProps) => {
  const {
    onClose,
    initialDeliverySchedule = {
      name: 'One Time Now',
      value: 1,
      label: 'One time now',
    },
    initialMonthDay,
    initialWeekDay,
    initialTimeZone = {
      id: 29,
      label: 'US/Eastern',
      value: 'US/Eastern',
    },
    initialDocFormat = 2,
    initialEndDate = now(initialTimeZone?.value)
      .add({ days: 1 })
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
      .toDate()
      .valueOf(),

    initialReceptiantEmails = [],
    initialRunningTotal = false,
    onSchedule,
    onStopSchedule,
    showRunningTotal,
    cancelTitle = 'Cancel',
    mode = 'edit',
    isLoading,
    reportName,
    timezoneId,
  } = props
  const contextData = useRouteContext({ from: '__root__' })
  const timezoneList = contextData._root.timezoneList
  const [receptianEmails, setReceptiantEmails] = React.useState(
    initialReceptiantEmails.map(one => ({ label: one, error: false }))
  )
  const [selectedDocFormat, setSelectedDocFormat] =
    React.useState(initialDocFormat)
  const [timezone] = React.useState<Option<string, number>>(initialTimeZone)
  const [endDate, setEndDate] = React.useState<number>(initialEndDate)
  const [endDateError] = React.useState('')
  const [deliverySchedule, setDeliverySchedule] =
    React.useState<Option<number> | null>(initialDeliverySchedule)
  const [weekDay, setWeekDay] = React.useState<Option<string> | undefined>(
    initialWeekDay
  )
  const [monthDay, setMonthDay] = React.useState<Option<string> | undefined>(
    initialMonthDay
  )
  const [runningTotal, setRunningTotal] = React.useState(initialRunningTotal)

  const handleChangeDocFormat = (
    event: React.MouseEvent<HTMLElement>,
    newSelectedDocFormat: number
  ) => {
    if (newSelectedDocFormat === null) {
      return
    }
    setSelectedDocFormat(newSelectedDocFormat)
  }

  const setDefaultValue = React.useCallback(
    (schedule: Option<number>) => {
      if (schedule.name === 'WEEK') {
        const currentDate = now(timezone?.value)
        const jsDate = currentDate.toDate()
        const dayOfWeek = jsDate.getDay()

        setWeekDay(staticWeeks[dayOfWeek])
      }
      if (schedule.name === 'MONTH') {
        const currentDate = now(timezone?.value)
        const jsDate = currentDate.toDate()
        const currentDay = jsDate.getDate()
        let DefaultDeliveryDay
        if (currentDay > 1 && currentDay < 29) {
          const asStringCurrentDay = currentDay.toString()
          DefaultDeliveryDay = {
            value: asStringCurrentDay,
            label: asStringCurrentDay,
            name: asStringCurrentDay,
          }
        } else {
          DefaultDeliveryDay = currentDay === 1 ? staticDays[0] : staticDays[1]
        }
        setMonthDay(DefaultDeliveryDay)
      }
    },
    [timezone?.value]
  )

  const initialData = {
    subscriberEmails: initialReceptiantEmails,
    fileType: initialDocFormat,
    deliveryFrequency: initialDeliverySchedule.value,
    eventEndDate: initialEndDate,
    deliveryDay:
      initialDeliverySchedule?.name === 'WEEK'
        ? initialWeekDay?.value
        : initialMonthDay?.value,
    runningTotalEnabled: initialRunningTotal,
  }

  const {
    data: deliveryScheduleOptions,
    isLoading: isLoadingDeliveryScheduleOptions,
  } = useQuery(
    ScheduleAPI.getMiscQueryKey.keys('getDeliverySchedule'),
    ScheduleAPI.getDeliverySchedule,
    {
      onError: (e: WithResponse) => {
        enqueueSnackbar(
          e.errorObjects
            ? (e.errorObjects[0]?.error as string)
            : 'Something went wrong!',
          {
            variant: 'error',
          }
        )
      },
    }
  )
  const { data: monthDaysOptions, isLoading: isLoadingMonthDayOptions } =
    useQuery(
      ScheduleAPI.getMiscQueryKey.keys('getMonthDay'),
      ScheduleAPI.getMonthDay,
      {
        onError: (e: WithResponse) => {
          enqueueSnackbar(
            e.errorObjects
              ? (e.errorObjects[0]?.error as string)
              : 'Something went wrong!',
            {
              variant: 'error',
            }
          )
        },
      }
    )

  const { data: weekDaysOptions, isLoading: isLoadingWeekdayOptions } =
    useQuery(
      ScheduleAPI.getMiscQueryKey.keys('getWeekDay'),
      ScheduleAPI.getWeekDay,
      {
        onError: (e: WithResponse) => {
          enqueueSnackbar(
            e.errorObjects
              ? (e.errorObjects[0]?.error as string)
              : 'Something went wrong!',
            {
              variant: 'error',
            }
          )
        },
      }
    )

  const currentData = React.useMemo<
    ReportSchedulingEventDetailsType | {}
  >(() => {
    if (deliverySchedule) {
      return {
        subscriberEmails: receptianEmails.map(one => one.label),
        fileType: selectedDocFormat,
        deliveryFrequency: deliverySchedule.value,
        eventEndDate: endDate,
        // @ts-ignore
        deliveryDay:
          deliverySchedule.name === 'WEEK' ? weekDay?.value : monthDay?.value,
        runningTotalEnabled: runningTotal,
      }
    }
    return {}
  }, [
    deliverySchedule,
    endDate,
    weekDay?.value,
    monthDay?.value,
    receptianEmails,
    runningTotal,
    selectedDocFormat,
  ])

  const _onSchedule = () => {
    if (deliverySchedule && deliverySchedule.name && currentData) {
      onSchedule(currentData as ReportSchedulingEventDetailsType)
    }
  }

  const timezoneData = timezoneList?.filter(val => val.id === timezoneId)[0]

  const getMessage = (
    schedule?: number,
    endDate?: number,
    monthNumber?: string,
    weekDay?: string
  ) => {
    const endDateInstance = fromAbsolute(endDate ?? 0, timezone?.value)
    const formatedMonth = endDateInstance
      .toDate()
      .toLocaleString('en-GB', { month: 'short' })

    const endDateStr = `${getOrdinal(endDateInstance.day)} ${formatedMonth} ${endDateInstance.year}`

    if (schedule === 2) {
      return `Daily report at 8:00am ${timezoneData?.label}, until ${endDateStr}`
    } else if (schedule === 3) {
      return `Weekly report every ${weekDay} at 8:00am ${timezoneData?.label}, until ${endDateStr}`
    } else if (schedule === 4) {
      const month = Number(monthNumber)
      return `Monthly report on every ${
        !isNaN(month)
          ? getOrdinal(Number(monthNumber))
          : `${monthNumber} day of month`
      } at 8:00am ${timezoneData?.label}, until ${endDateStr} `
    }
    return ''
  }

  const deliveryDayRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (deliverySchedule?.value === 4 || deliverySchedule?.value === 3) {
      const resizeObserver = new ResizeObserver(() => {
        deliveryDayRef.current!.querySelector(
          '.opener__content'
          //@ts-ignore
        )!.style.width = `${
          deliveryDayRef.current!.querySelector('.select-simple')!.clientWidth
        }px`
      })
      resizeObserver.observe(deliveryDayRef.current!)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [deliverySchedule])

  const isEmailValid = React.useMemo(
    () => receptianEmails.every(one => one.error === false),
    [receptianEmails]
  )

  const minValueParams: MinMaxParamsType = {
    param: 'days',
    subtractor: -1,
  }

  return (
    <>
      <DialogTitle>Schedule Email Delivery</DialogTitle>
      <DialogContent
        dividers
        className={styles.scheduleEmailContainer}
        sx={{ display: 'flex', flexDirection: 'column', gap: 16 }}
      >
        <Typography component="p">
          Schedule the report for <strong>{reportName}</strong>
        </Typography>
        <InputWithTags
          label="Recipient Email Addresses"
          placeholder="Enter recipient emails"
          value={receptianEmails}
          onChange={(e, value, option, reason) => {
            if (
              reason === 'createOption' ||
              reason === 'createMultipleOption'
            ) {
              value = value.reduce<{ label: string; error: boolean }[]>(
                (prev, curr) => {
                  if (prev.findIndex(one => one.label === curr.label) >= 0) {
                    return prev
                  }
                  return [...prev, curr]
                },
                []
              )
              setReceptiantEmails(value)
            } else {
              setReceptiantEmails(value)
            }
          }}
          createOption={value => ({
            label: value.trim(),
            error:
              // eslint-disable-next-line
              !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*))@((([a-zA-Z0-9]+)(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,})$/.test(
                value.trim()
              ),
          })}
          renderTags={(value, getTagProps) =>
            value.map((one, index) => (
              <Chip
                {...getTagProps({ index })}
                color={one.error ? 'error' : 'primary'}
                key={index}
                label={one.label}
                clickable
                size="small"
              />
            ))
          }
          getOptionLabel={value => value.label}
          createOptionOnBlur={true}
          splitCharacter={','}
        />
        <Row>
          <Col xs={6} sm={3}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'between',
                height: 100,
                pb: 8,
              }}
            >
              <InputLabel>Document Format</InputLabel>
              <ToggleButtonGroup
                color="primary"
                variant="outlined"
                value={selectedDocFormat}
                exclusive
                onChange={handleChangeDocFormat}
                aria-label="Platform"
              >
                {/* as https://docs.stage.iqm.services/swagger/internal/?path=/Report%20API/getFileType */}
                <ToggleButton value={2}>XLSX</ToggleButton>
                <ToggleButton value={1}>CSV</ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Col>
          <Col xs={6} sm={3}>
            <DeliveryFrequencySelect
              data={deliveryScheduleOptions ?? []}
              isLoading={isLoadingDeliveryScheduleOptions}
              onChange={(value: Option<number>) => {
                setDeliverySchedule(value)
                setDefaultValue(value)
              }}
              value={deliverySchedule}
            />
          </Col>
          <Col xs={6} sm={3}>
            {deliverySchedule?.value !== 1 ? (
              <>
                <div className={styles.daterangePicker}>
                  <DatePicker
                    selectedDate={endDate}
                    minValueParams={minValueParams}
                    onChange={setEndDate}
                    hideDateSegment={false}
                    disabled={false}
                    label="End date"
                    variant="outlinedDash"
                    timezoneName={timezone?.value}
                    hideCaret
                  />

                  <span className={styles.error}>{endDateError ?? ' '}</span>
                </div>
              </>
            ) : null}
          </Col>

          <Col ref={deliveryDayRef} xs={6} sm={3}>
            {deliverySchedule?.value === 3 ? (
              <DeliveryDaySelect
                data={weekDaysOptions ?? []}
                onChange={(value: Option<string>) => {
                  setWeekDay(value)
                }}
                value={weekDay}
                isLoading={isLoadingWeekdayOptions}
              />
            ) : deliverySchedule?.value === 4 ? (
              <DeliveryMonthDaySelect
                data={monthDaysOptions ?? []}
                onChange={(value: Option<string>) => {
                  setMonthDay(value)
                }}
                value={monthDay}
                isLoading={isLoadingMonthDayOptions}
              />
            ) : null}
          </Col>
        </Row>
        <Box
          sx={{
            display: deliverySchedule?.value === 1 ? 'none' : 'flex',
            alignItems: 'center',
          }}
        >
          <InfoCircle fontSize={24} sx={{ textColor: 'neutral-400', mr: 8 }} />
          <Typography component="p" gutterBottom={false}>
            {getMessage(
              deliverySchedule?.value,
              endDate,
              monthDay?.value,
              weekDay?.value
            )}
          </Typography>
        </Box>
        {showRunningTotal ? (
          <Box
            sx={{
              display: deliverySchedule?.value === 1 ? 'none' : 'flex',
              alignItems: 'center',
              borderTop: 1,
              paddingTop: 16,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexShrink: 0,
                mr: 48,
              }}
            >
              <FormControlLabel
                value={runningTotal}
                checked={runningTotal}
                onChange={_event => {
                  //@ts-ignored
                  setRunningTotal(_event.target.checked)
                }}
                control={<Switch />}
                label="Running Total"
                labelPlacement="end"
              />
            </Box>
            <Typography
              component="p"
              gutterBottom={false}
              sx={{ textColor: 'neutral-600' }}
            >
              When Running Total is OFF, a report will be generated for the last
              day/week/month but when Running Total is ON, a report will be
              generated from the report start date to the last day/week/month
              report delivery date.
            </Typography>
          </Box>
        ) : null}
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'between' }}>
        {mode === 'edit' ? (
          <Button
            color="error"
            variant="text"
            onClick={onStopSchedule}
            disabled={isLoading}
          >
            Stop Schedule
          </Button>
        ) : null}
        <Box sx={{ ml: 'auto' }}>
          <Button
            color="secondary"
            onClick={onClose}
            sx={{ mr: 16 }}
            disabled={isLoading}
          >
            {cancelTitle}
          </Button>
          <LoadingButton
            disabled={
              (receptianEmails.length > 0 ? !isEmailValid : true) ||
              isLoading ||
              isDeepEqual(currentData, initialData)
            }
            onClick={_onSchedule}
            loading={isLoading}
            variant="contained"
          >
            {mode === 'edit'
              ? 'Update Changes'
              : deliverySchedule?.value === 1
                ? 'Send'
                : 'Schedule'}
          </LoadingButton>
        </Box>
      </DialogActions>
    </>
  )
}
