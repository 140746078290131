const PluralWord = new Map()

export const pluralize = (value: string, count?: number): string => {
  if (count && count <= 1) {
    return value
  }
  return PluralWord.get(value.toLowerCase()) || `${value}s`
}

export const registerPlural = (value: string, pural: string) => {
  PluralWord.set(value, pural)
}

export const initPluralize = () => {
  registerPlural('country', 'countries')
  registerPlural('county', 'counties')
  registerPlural('ethnicity', 'ethnicities')
  registerPlural('city', 'cities')
}
