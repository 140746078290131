import { Box, TypoTooltip } from '@applift/factor'
import { NormalizedCustomerList } from '@applift/platform'
import { OrganizationAvatar } from '../../../OrganizationAvatar/OrganizationAvatar'

interface CustomerCellType {
  rowData: NormalizedCustomerList
}

export const CustomerCell = ({ rowData }: CustomerCellType) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: 100 }}>
      <OrganizationAvatar
        isAdvertiser={rowData.type === 'advertiser'}
        size={24}
        src={rowData.logoUrl}
        alt={rowData.label}
      />
      <TypoTooltip
        arrow
        placement="top"
        TypgraphyProps={{ component: 'p', gutterBottom: false }}
        sx={{ ml: 8 }}
      >
        {rowData.label}
      </TypoTooltip>
    </Box>
  )
}
