import styles from './index.module.scss'
import { Box, Typography } from '@applift/factor'
import {
  CalendarSettings,
  InsightsDatabase,
  Omnichannel,
  DatabaseCheck,
} from '@applift/icons'
const componentMapping = {
  CustomizeReport: CalendarSettings,
  DataReport: InsightsDatabase,
  OmniChannel: Omnichannel,
  SupplyPath: DatabaseCheck,
} as const
type ComponentKeys = keyof typeof componentMapping
type ComponentValues = (typeof componentMapping)[ComponentKeys]
export interface EmptyFeturesReportProps {
  icon: ComponentValues
  title: string
  desciption: string
  className?: string
}
/**
 * EmptyFeturesReport - this are the fetures of report.
 *
 */
export const EmptyFeturesReport = ({
  icon,
  title,
  desciption,
  className,
}: EmptyFeturesReportProps) => {
  const IconComponent = icon
  return (
    <Box className={className}>
      <IconComponent
        className={styles['svgIcon']}
        sx={{ textColor: 'primary-600', mb: 16 }}
      />
      <Typography
        component="h5"
        variant="bodyMedium"
        weight="demi"
        sx={{ mb: 8 }}
      >
        {title}
      </Typography>
      <Typography variant="bodySmall" gutterBottom={false}>
        {desciption}
      </Typography>
    </Box>
  )
}
