import * as React from 'react'
import { useStore } from '../store'
import { getCustomerIds } from '../transformers/customer'

/**
 * This hooks will facilitate enabling of save Report and execute report button
 */
export const useHasChanged = () => {
  const [customerFilter, filters, report, dimsAndMetrics] = useStore(state => [
    state.customerFilter.selectedCustomersData,
    state.filters.filters,
    state.report,
    state.selectedDimensionsAndMetricsSlice,
  ])

  const [
    isCustomerDirty,
    isFiltersDirty,
    isDimsAndMetricsDirty,
    isReportDirty,
    isReportViewDirty,
    isReportNameDirty,
  ] = useStore(state => [
    state.customerFilter.dirty,
    state.filters.dirty,
    state.selectedDimensionsAndMetricsSlice.dirty,
    state.report.dirty,
    state.report.dirtyView,
    state.report.dirtyName,
  ])

  const [
    commitCustomerFilters,
    commitFilters,
    commitDimsAndMetrics,
    commitReport,
    commitReportView,
    commitReportName,
  ] = useStore(state => [
    state.customerFilter.commit,
    state.filters.commit,
    state.selectedDimensionsAndMetricsSlice.commit,
    state.report.commit,
    state.report.commitView,
    state.report.commitName,
  ])

  const [
    resetCustomerFilter,
    resetFilter,
    resetDimsandMetrics,
    resetReport,
    isCreatingFromDraft,
  ] = useStore(state => [
    state.customerFilter.reset,
    state.filters.reset,
    state.selectedDimensionsAndMetricsSlice.reset,
    state.report.reset,
    state.selectedDimensionsAndMetricsSlice.isCreatingFromDraft,
  ])

  const commitChangesLocally = () => {
    commitReport()
    commitFilters()
    commitDimsAndMetrics()
    commitCustomerFilters()
  }

  const commitAllChanges = () => {
    commitChangesLocally()
    commitReportView()
    commitReportName()
  }

  const resetStore = () => {
    resetCustomerFilter()
    resetFilter()
    resetDimsandMetrics()
    resetReport()
  }

  const isExecuteDisabled = React.useMemo(() => {
    if (dimsAndMetrics.dimensions.length <= 0) {
      return true
    }
    if (
      !(
        isCustomerDirty ||
        isDimsAndMetricsDirty.changed ||
        Object.keys(isDimsAndMetricsDirty.dimensions).length ||
        Object.keys(isDimsAndMetricsDirty.metrics).length ||
        isFiltersDirty ||
        isReportDirty
      )
    ) {
      return true
    }
    return false
  }, [
    dimsAndMetrics,
    isCustomerDirty,
    isFiltersDirty,
    isDimsAndMetricsDirty,
    isReportDirty,
  ])

  const isOnlyReportViewDirty = React.useMemo(() => {
    if (
      !isCustomerDirty &&
      !isDimsAndMetricsDirty.changed &&
      !Object.keys(isDimsAndMetricsDirty.dimensions).length &&
      !Object.keys(isDimsAndMetricsDirty.metrics).length &&
      !isFiltersDirty &&
      !isReportDirty &&
      (isReportViewDirty || isReportNameDirty)
    ) {
      return true
    }
    return false
  }, [
    isCustomerDirty,
    isFiltersDirty,
    isDimsAndMetricsDirty,
    isReportDirty,
    isReportViewDirty,
    isReportNameDirty,
  ])

  React.useEffect(() => {
    if (!isExecuteDisabled && !report.globalDirty) {
      report.toggleGlobalDirty(true)
    } else if ((report.dirtyView || report.dirtyName) && !report.globalDirty) {
      report.toggleGlobalDirty(true)
    } else if (isCustomerDirty && !report.globalDirty) {
      report.toggleGlobalDirty(true)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [
    isExecuteDisabled,
    report.globalDirty,
    report.dirtyName,
    report.toggleGlobalDirty,
    isCustomerDirty,
  ])

  const isSaveDisabled = !report.globalDirty && !isCreatingFromDraft
  const customerFilterIds = getCustomerIds(customerFilter)
  return {
    isSaveDisabled,
    isExecuteDisabled,
    commitChangesLocally,
    resetStore,
    customerFilterIds,
    filters,
    report,
    dimsAndMetrics,
    isOnlyReportViewDirty,
    commitAllChanges,
  }
}
