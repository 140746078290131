import { Layout } from '@applift/factor'
import { useAppContext } from '@applift/platform'
import {
  createFileRoute,
  redirect,
  useLoaderData,
  useNavigate,
} from '@tanstack/react-router'
import { EmptyCreateReport } from '../components/EmptyCreateReport'
import { EmptyReportAnalytics } from '../components/EmptyReportAnalytics'
import { GlobalLoader } from '../components/GlobalLoader'

export const Route = createFileRoute('/')({
  component: () => <EmptyPageComponent />,
  loader: ({ context }) => {
    if (context._root.hasReports) {
      throw redirect({ to: '/list' })
    }
    return {
      timezoneList: context._root.timezoneList,
      view: context._root.emptyView,
    }
  },
  pendingComponent: () => <GlobalLoader />,
  errorComponent: () => <h1>error loading data</h1>,
})

const EmptyPageComponent = () => {
  const navigate = useNavigate()
  const { view } = useLoaderData({ from: '/' })

  const appContext = useAppContext()
  const organizationType = appContext.appMeta.organizationType
  const campaignsAppData = appContext.appMeta.getAppData('Campaigns')
  let redirectToCampaignsURL = ''
  if (campaignsAppData) {
    redirectToCampaignsURL =
      appContext.appMeta.prepareAppSwitchingURL(campaignsAppData)
  }

  return (
    <Layout
      sidebar={
        <EmptyCreateReport
          view={view}
          handleCreate={() => {
            navigate({ to: '/create' })
          }}
          redirectToCampaignsURL={redirectToCampaignsURL}
          organizationType={organizationType}
        />
      }
      sidebarWidth={320}
      maxWidth={320}
      minWidth={320}
      hideResizerHandle
    >
      <EmptyReportAnalytics />
    </Layout>
  )
}
