import { Box, Button, Typography } from '@applift/factor'
import { Add } from '@applift/icons'

interface EmptyViewProps {
  illustrations: JSX.Element
  description: string
  buttonCta: string
  handleCreate: () => void
  hideCTA?: boolean
}

export const EmptyView = (props: EmptyViewProps) => {
  const { buttonCta, description, handleCreate, illustrations, hideCTA } = props

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        gap: 16,
      }}
    >
      <Box sx={{ textColor: 'primary-500' }}>{illustrations}</Box>
      <Typography
        component="h1"
        variant="bodySmall"
        weight="normal"
        sx={{ px: 24 }}
        gutterBottom={false}
      >
        {description}
      </Typography>
      {hideCTA !== true ? (
        <Button
          color="primary"
          disableRipple
          startIcon={<Add />}
          sx={{ mx: 'auto' }}
          onClick={handleCreate}
        >
          {buttonCta}
        </Button>
      ) : null}
    </Box>
  )
}
