import { ColumnDef, ReactTableCellContext } from '@applift/datagrid'
import { TextCell } from '../cellTypes'

export const dealIdFilterColDef: ColumnDef<any>[] = [
  {
    accessorKey: 'dealId',
    header: 'Deal ID',
    enableSorting: false,
    cell: (props: ReactTableCellContext) => {
      return <TextCell label={props.renderValue()} />
    },
  },
  {
    accessorKey: 'dealName',
    header: 'Deal Name',
    enableSorting: false,
    cell: (props: ReactTableCellContext) => {
      return <TextCell label={props.renderValue()} />
    },
  },
]
