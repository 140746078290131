import {
  DateRangePicker as DateRangePickerComponent,
  DateRangeType,
  MinMaxParamsType,
  Timezone,
  TimezonePicker,
  handleTimezoneChange,
} from '@applift/app-utils'
import { Box, enqueueSnackbar } from '@applift/factor'
import * as React from 'react'
import { DEFAULT_TIMEZONE } from '../../../../constants'
import { DateRange } from '../../../../models/DateTime'
import { useStore } from '../../../../store'
import { daysDifference } from '../../../../utils/dateDifference'
import styles from '../index.module.scss'

export const DateRangePicker = () => {
  const [dateRange, setDateRange] = useStore(state => [
    state.report.dateRange,
    state.report.setDateRange,
  ])

  const [timezone, setTimezone] = useStore(state => [
    state.report.timezone,
    state.report.setTimezone,
    state.report.mode,
  ])

  const [prevDateRange, setPrevDateRange] = React.useState<DateRange | null>()

  const handleDateChange = React.useCallback(
    ({ startDate, endDate }: { startDate: number; endDate: number }) => {
      setPrevDateRange(dateRange)
      setDateRange({
        startDate: startDate,
        endDate: endDate,
      })
    },
    [dateRange, setDateRange]
  )

  React.useEffect(() => {
    if (
      dateRange &&
      prevDateRange &&
      daysDifference(dateRange.startDate, dateRange.endDate) > 365
    ) {
      enqueueSnackbar({
        variant: 'error',
        message: 'Date range cannot exceeds more than one year',
      })
      setDateRange(prevDateRange)
    }
  }, [dateRange, prevDateRange, setDateRange])

  const onTimezoneChangeSetDate = (value: DateRangeType) => {
    setDateRange(value)
  }
  const onTimeZoneChangeSetTimeZone = (value: Timezone[]) => {
    setTimezone({
      label: value[0]?.label ?? DEFAULT_TIMEZONE?.label,
      value: value[0]?.name ?? DEFAULT_TIMEZONE?.value,
      id: value[0]?.id ?? DEFAULT_TIMEZONE?.id,
    })
  }

  const maxValueParams: MinMaxParamsType = {
    param: 'days',
    subtractor: 0,
  }
  const minValueParams: MinMaxParamsType = {
    param: 'months',
    subtractor: 36,
  }

  const timezoneArray = [
    {
      id: timezone?.id || DEFAULT_TIMEZONE?.id,
      label: timezone?.label || DEFAULT_TIMEZONE?.value,
      name: timezone?.value || timezone?.name || DEFAULT_TIMEZONE?.value,
    },
  ]

  return (
    <Box sx={{ display: 'flex', gap: 12 }}>
      <Box className={styles.timezonePicker}>
        <TimezonePicker
          variant="outlinedDash"
          value={timezoneArray}
          onChange={value =>
            handleTimezoneChange(
              value,
              maxValueParams,
              minValueParams,
              timezoneArray,
              dateRange,
              onTimezoneChangeSetDate,
              onTimeZoneChangeSetTimeZone
            )
          }
        />
      </Box>

      <DateRangePickerComponent
        dateRange={dateRange}
        onChange={handleDateChange}
        maxValueParams={maxValueParams}
        minValueParams={minValueParams}
        timezoneName={timezone?.value ?? 'UTC'}
        hideDateSegment={false}
        disabled={false}
        label="Date Range"
        variant="outlinedDash"
      />
    </Box>
  )
}
