import { DimensionMetricList } from './DimensionMetricList'
import { useRouteContext } from '@tanstack/react-router'

export const DimensionMetricsSidebar = () => {
  const contextData = useRouteContext({ from: '__root__' })

  const dimensionAndMetricsListData =
    contextData._root.dimensionAndMetricsListData

  return (
    <DimensionMetricList
      metrics={dimensionAndMetricsListData?.metrics}
      dimensions={dimensionAndMetricsListData?.dimensions}
    />
  )
}
