import * as React from 'react'
import { sx } from '@applift/factor'
import {
  SortingState,
  DataGrid,
  ColumnPinningState,
  GridActionCellParams,
} from '@applift/datagrid'
import { AccountTypes } from '../../utils'
import { NoResultsOverlay } from '../../components/NoResultOverlay'
import { useAppContext } from '@applift/platform'

export interface ListGridProps {
  data: any[]
  columnDef: any[]
  totalRecords?: number
  loading: boolean
  rowIdKey: string
  sorting: SortingState
  onSortingChange: React.Dispatch<React.SetStateAction<SortingState>>
  onFetchRows?: () => void
  pageSize: number
  columnPinning: ColumnPinningState
  search: string
  onAction: (params: GridActionCellParams<any, any>) => void
}

export const ListGrid = (props: ListGridProps) => {
  const {
    data,
    columnDef,
    totalRecords,
    loading,
    rowIdKey,
    onFetchRows,
    onSortingChange,
    pageSize,
    sorting,
    onAction,
    columnPinning,
    search,
  } = props

  const overlay = React.useMemo(() => {
    return !data?.length && !loading && search ? 'noResult' : undefined
  }, [data?.length, loading, search])

  const appContext = useAppContext()
  const organizationType = appContext.appMeta.organizationType

  return (
    <DataGrid
      data={data}
      columns={columnDef}
      getRowId={(row: any) => {
        if (row && typeof row[rowIdKey] === 'number') {
          return `${row[rowIdKey]}`
        }
        return row[rowIdKey]
      }}
      state={{ sorting, columnPinning }}
      pageSize={pageSize}
      hideHeader
      hideFooter
      density="compact"
      checkboxSelection={false}
      loading={loading}
      overlay={overlay}
      components={{
        NoResultsOverlay: NoResultsOverlay,
      }}
      showColumnRightBorder
      showCellRightBorder
      disableRowSelectionOnClick
      rowCount={totalRecords}
      onFetchRows={onFetchRows}
      onSortingChange={onSortingChange}
      classes={{ root: sx({ borderRadius: 0, border: 0, borderTop: 1 }) }}
      onAction={onAction}
      initialState={{
        columnVisibility: {
          Customer: organizationType !== AccountTypes.ADVERTISER,
        },
      }}
    />
  )
}
