import * as React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  DimensionMetricChip,
  DimensionMetricChipProps,
} from '../../../../components/DimensionMetricChip'
import {
  ExtendDimension,
  ExtendMetrics,
} from '../../../../models/DimensionMetrics'
import { Filters, FilterKeys } from '../../../../models/filterMetaData'
import { FilterMapping } from '../../../../models/filterMetaData'
import { DimensionOrMetrics } from '../../../../models/DimensionMetrics'
import { useDNDRef } from '../../../../hooks/useDNDRef'

export interface RolColsItemProps
  extends Omit<DimensionMetricChipProps, 'filterCount'> {
  item: ExtendDimension | ExtendMetrics
  type: DimensionOrMetrics
  filters?: Filters
  setFilter?: React.Dispatch<React.SetStateAction<FilterKeys | ''>>
  insertIndex: number
  totalItems: number
}

export const RolColsItem = (props: RolColsItemProps) => {
  const dndRef = useDNDRef()

  const {
    item,
    type,
    filters,
    setFilter,
    insertIndex,
    totalItems,
    onAggregate,
    aggregationValue,
    ...other
  } = props
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: item.key,
      data: {
        type: type === 'Dimensions' ? 'Rows' : 'Columns',
        insertIndex,
        accepts: [type === 'Dimensions' ? 'Rows' : 'Columns'],
      },
    })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  }
  const filterCount = Object.keys(
    // @ts-ignore
    filters?.[FilterMapping?.[item.key as any] ?? ''] ?? {}
  ).length

  const handleFilterClick = React.useCallback(() => {
    // @ts-ignore
    setFilter(FilterMapping[item.key])
  }, [setFilter, item.key])

  const removeColumn = React.useCallback(() => {
    dndRef.current.removeDimension({
      key: item.key,
      type: type,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dndRef, item.key, type])

  return (
    <>
      <DimensionMetricChip
        labelTooltip={true}
        color="secondary"
        ref={setNodeRef}
        label={item?.label}
        onAggregate={onAggregate}
        onDelete={removeColumn}
        style={style}
        onFilter={handleFilterClick}
        filterCount={filterCount}
        hasFilter={
          type === 'Dimensions' && (item as ExtendDimension).isFilterSupported
        }
        hasAggregate={
          type === 'Dimensions' &&
          filterCount > 0 &&
          insertIndex === 0 &&
          totalItems > 1
        }
        aggregationValue={aggregationValue}
        // @ts-ignore
        {...other}
        {...listeners}
        {...attributes}
      />
    </>
  )
}
