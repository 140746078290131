import { ColumnDef, ReactTableCellContext } from '@applift/datagrid'
import { TextCell } from '../cellTypes'

export const customerIdFilterColDef: ColumnDef<any>[] = [
  {
    accessorKey: 'owId',
    header: 'Customer ID',
    enableSorting: false,
    size: 300,
    cell: (props: ReactTableCellContext) => {
      return <TextCell label={props.renderValue()} />
    },
  },
  {
    accessorKey: 'organizationName',
    header: 'Customer',
    enableSorting: false,
    size: 300,
    cell: (props: ReactTableCellContext) => {
      return <TextCell label={props.renderValue()} />
    },
  },
]
