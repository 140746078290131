import { QueryFunctionContext } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { ExecuteReportResponseType } from '../models/Report'
import { WithResponse } from '../models/Response'
import { getInstance } from './instance'
import { getExecuteReportQueryKey } from './queryKey'

export const executeReport = async ({
  pageParam = 1,
  queryKey,
  signal,
}: QueryFunctionContext<
  ReturnType<(typeof getExecuteReportQueryKey)['keys']>
>) => {
  const { data, noOfEntries = 20, sortBy } = queryKey[0]!
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled')
  })
  try {
    const response: AxiosResponse<WithResponse<ExecuteReportResponseType>> =
      await getInstance().post(
        `/v3/ra/report/execute`,
        { ...data, pageNo: pageParam, noOfEntries, sortBy },
        {
          cancelToken: source.token,
        }
      )
    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}
