import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { enqueueSnackbar } from '@applift/factor'
import { AxiosError } from 'axios'
import * as EditApi from '../api/createEdit'
import { getReportDetailsQueryKey } from '../api/queryKey'
import { ReportDetailsType } from '../models/Report'
import { PATH } from '../utils'
import { useNavigate } from '@tanstack/react-router'

export const useGetEditData = (
  id: number,
  options?: {
    onSuccess?: UseQueryOptions<ReportDetailsType>['onSuccess']
    onError?: UseQueryOptions<ReportDetailsType>['onError']
    enabled?: UseQueryOptions<ReportDetailsType>['enabled']
  }
) => {
  const navigate = useNavigate()

  const queryData = useQuery(
    getReportDetailsQueryKey.keys('getReportDetails', id),
    EditApi.getReportDetails,
    {
      cacheTime: 0,
      enabled: options?.enabled,
      onSuccess: options?.onSuccess,
      onError: (e: AxiosError<any>['response']) => {
        enqueueSnackbar(
          e?.data.errorObjects
            ? (e.data.errorObjects[0]?.error as string)
            : 'Something went wrong!',
          {
            variant: 'error',
          }
        )
        if (e?.status === 422) {
          navigate({ from: PATH.LISTPAGE })
        }
      },
    }
  )
  return queryData
}
