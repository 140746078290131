import * as React from 'react'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Radio,
  FormControlLabel,
  Row,
  Box,
  RadioGroup,
  Tooltip,
} from '@applift/factor'
import { SemanticWarning } from '@applift/icons'
import { SpecialRuleType } from '../../../models/DimensionMetrics'
import { useStore } from '../../../store'
import { ExtendDimension } from '../../../models/DimensionMetrics'
import { useRouteContext } from '@tanstack/react-router'

export interface DependentMetricDialogProps {
  dimensionMetricInfo?: SpecialRuleType['info']
  onClose: (params?: any) => any
  onReset: (params?: any) => any
}

export const DependentMetricDialog = (props: DependentMetricDialogProps) => {
  const { onClose, dimensionMetricInfo, onReset } = props
  const contextData = useRouteContext({ from: '__root__' })
  const dimensionMetricsList = contextData._root.dimensionAndMetricsListData

  const [selectedDimension, setSelectedDimension] = React.useState<string>('')

  const [isDimensionListFull, dimensions] = useStore(state => [
    state.selectedDimensionsAndMetricsSlice.isDimensionListFull,
    state.selectedDimensionsAndMetricsSlice.dimensions,
  ])

  const shouldDimensionAdded = (dimension: ExtendDimension) => {
    if (dimension) {
      return (
        isDimensionListFull &&
        !dimensions.filter(val => val.groupId === dimension.groupId).length
      )
    }
    return false
  }

  const metricFromKey =
    dimensionMetricsList?.keyWiseMetrics[
      dimensionMetricInfo?.metrics?.metricKey || ''
    ]

  const dependentDimensions = React.useCallback(() => {
    const dimensions = metricFromKey?.dependentDimensions?.map(
      id => dimensionMetricsList!.idWiseDimensions[id]
    )
    return dimensions || []
  }, [dimensionMetricsList, metricFromKey?.dependentDimensions])

  React.useEffect(() => {
    setSelectedDimension(dependentDimensions()[0]!.id.toString())
  }, [dependentDimensions])

  return (
    <>
      <DialogTitle>Add Dependent Metric ?</DialogTitle>
      <DialogContent dividers>
        <Row>
          <Typography>
            To add <b>{dimensionMetricInfo?.metrics?.metricName}</b>, one of the
            following is required:
          </Typography>
        </Row>
        <Row sx={{ mt: 4 }}>
          <RadioGroup
            value={selectedDimension}
            onChange={e => {
              setSelectedDimension(e.target.value)
            }}
          >
            {dependentDimensions().map(id =>
              id ? (
                <Box
                  key={id?.id}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label={id?.label}
                    value={id?.id}
                    sx={{ py: 0 }}
                    disabled={shouldDimensionAdded(id!)}
                  />
                  {shouldDimensionAdded(id!) ? (
                    <Tooltip
                      title="Can’t add this dimension as  the maximum limit for the main dimensions has been reached."
                      placement="top"
                      arrow
                    >
                      <SemanticWarning fontSize={24} color="warning" />
                    </Tooltip>
                  ) : null}
                </Box>
              ) : null
            )}
          </RadioGroup>
        </Row>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!selectedDimension}
          onClick={() =>
            onReset(dimensionMetricsList!.idWiseDimensions[+selectedDimension!])
          }
        >
          Add
        </Button>
      </DialogActions>
    </>
  )
}
