import { ColumnDef, ReactTableCellContext } from '@applift/datagrid'
import { IoFilterType } from '../../../models/Filters'
import { IconTextCell, TextCell } from '../cellTypes'
import { BUDGET_TYPE_ID } from '../../../constants'

const StatusMapper = {
  1: {
    label: 'Active',
    key: 'active',
    iconName: 'Ready',
  },
  2: {
    label: 'Expired',
    key: 'expired',
    iconName: 'Expired',
  },
  3: {
    label: 'Deleted',
    key: 'deleted',
    iconName: 'Deleted',
  },
}

export const ioNameFilterColDef: ColumnDef<IoFilterType>[] = [
  {
    accessorKey: 'ioId',
    header: 'ID',
    maxSize: 150,
    size: 100,
    minSize: 50,
    enableSorting: false,
    cell: (props: ReactTableCellContext) => {
      return <TextCell label={props.renderValue()} />
    },
  },
  {
    accessorKey: 'ioName',
    header: 'Insertion Order Name',
    size: 385,
    minSize: 150,
    enableSorting: false,
    cell: (props: ReactTableCellContext) => {
      const iconName =
        (props.row.original.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED &&
          'IoCurrency') ||
        (props.row.original.ioBudgetTypeId ===
          BUDGET_TYPE_ID.IMPRESSIONS_BASED &&
          'IoImpression')
      return (
        <IconTextCell
          iconName={iconName || 'Error'}
          iconSize={24}
          label={props.renderValue() as string}
        />
      )
    },
  },
  {
    accessorKey: 'status',
    header: 'Status',
    enableSorting: false,
    cell: props => {
      return (
        <IconTextCell
          iconName={
            StatusMapper[
              props.row.original.ioStatusId as keyof typeof StatusMapper
            ].iconName
          }
          iconSize={16}
          label={
            StatusMapper[
              props.row.original.ioStatusId as keyof typeof StatusMapper
            ].label
          }
        />
      )
    },
  },
]
