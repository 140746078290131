import { AxiosError, AxiosResponse } from 'axios'
import { WithResponse } from '../models/Response'
import { DownloadReport } from '../models/Report'
import { getInstance } from './instance'

export const downloadReport = async (data: DownloadReport) => {
  try {
    const response: AxiosResponse<WithResponse<{ url: string }>> =
      await getInstance().post(`/v3/ra/report/download`, data)
    return response.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}
