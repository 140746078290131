import { Box, Layout } from '@applift/factor'
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import * as React from 'react'
import { useDNDRef } from '../../hooks/useDNDRef'
import { useDragAndDropEvents } from '../../hooks/useDragAndDropEvents'
import {
  DimensionOrMetrics,
  SpecialRuleType,
} from '../../models/DimensionMetrics'
import { Conditions } from './Conditions'
import { Container } from './Container'
import { DimensionMetricsSidebar } from './DimensionMetricSidebar'
import { DragOverlayWrapper } from './DragOverlay'
import style from './index.module.scss'

export const Create = () => {
  const dndRef = useDNDRef()
  const [dialogState, setDialogState] = React.useState<SpecialRuleType>()
  const handleCallback = (
    param: React.SetStateAction<SpecialRuleType | undefined>
  ) => setDialogState(param)
  useDragAndDropEvents(dndRef, handleCallback)

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  )
  const [activeDraggableKey, setActiveDraggableKey] = React.useState<{
    key: UniqueIdentifier | boolean
    type: DimensionOrMetrics | 'Rows' | 'Columns' | ''
  } | null>(null)

  const handleDragStart = (event: DragStartEvent) => {
    setActiveDraggableKey({
      key: event?.active?.id ?? false,
      type: event.active?.data?.current?.type ?? '',
    })
  }
  const handleDragEnd = (event: DragEndEvent) => {
    if (
      (event.over?.id === 'Rows' ||
        event.over?.data.current?.type === 'Rows') &&
      activeDraggableKey?.type === 'Dimensions'
    ) {
      dndRef.current.addDimensionOrMetric({
        type: 'Dimensions',
        key: activeDraggableKey?.key as string,
      })
    } else if (
      (event.over?.id === 'Columns' ||
        event.over?.data.current?.type === 'Columns') &&
      activeDraggableKey?.type === 'Metrics'
    ) {
      dndRef.current.addDimensionOrMetric({
        type: 'Metrics',
        key: activeDraggableKey?.key as string,
      })
    } else if (
      event.over?.data.current?.type === 'Rows' &&
      activeDraggableKey?.type === 'Rows'
    ) {
      dndRef.current.reorder({
        type: 'Dimensions',
        toIndex: event.over?.data.current?.insertIndex as number,
        fromIndex: event.active?.data.current?.insertIndex as number,
      })
    } else if (
      event.over?.data.current?.type === 'Columns' &&
      activeDraggableKey?.type === 'Columns'
    ) {
      dndRef.current.reorder({
        type: 'Metrics',
        toIndex: event.over?.data.current?.insertIndex as number,
        fromIndex: event.active?.data.current?.insertIndex as number,
      })
    } else {
      console.log(event, activeDraggableKey)
    }
    setActiveDraggableKey(null)
  }

  const normalizeActiveArea = React.useMemo(() => {
    let result: DimensionOrMetrics | '' = ''
    if (
      activeDraggableKey?.type === 'Dimensions' ||
      activeDraggableKey?.type === 'Rows'
    ) {
      result = 'Dimensions'
    } else if (
      activeDraggableKey?.type === 'Metrics' ||
      activeDraggableKey?.type === 'Columns'
    ) {
      return 'Metrics'
    }
    return result
  }, [activeDraggableKey?.type])

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <Layout
        className={style.layoutStyle}
        sidebar={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: 100,
              overflow: 'hidden',
              gap: 16,
              py: 16,
            }}
          >
            <DimensionMetricsSidebar />
          </Box>
        }
        sidebarWidth={320}
        maxWidth={320}
        minWidth={320}
        hideResizerHandle
      >
        <Container activeArea={normalizeActiveArea} />
      </Layout>
      <DragOverlayWrapper
        itemKey={activeDraggableKey?.key}
        type={normalizeActiveArea}
      />
      <Conditions
        state={dialogState}
        closeDialog={() => setDialogState(undefined)}
      />
    </DndContext>
  )
}
