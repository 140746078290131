import * as React from 'react'
import { Container as FactorContainer, Box, Row } from '@applift/factor'
import { RowColsWrapper } from './RowCols/RowColsWrapper'
import { ReportHeader } from './ReportHeader/ReportHeader'
import { TableContainer } from './Table/TableContainer'
import { DimensionOrMetrics } from '../../../models/DimensionMetrics'
import { ExpandExecutionTableDialog } from './Table/ExpandExecutionTableDialog'

export interface ContainerProps {
  activeArea?: DimensionOrMetrics | ''
}

export const Container = ({ activeArea }: ContainerProps) => {
  // This fix is to tackle css issues we had with flexbox, which was not growing.
  const [showMaximizeExecutionTable, setShowMaximizeExecutionTable] =
    React.useState<boolean>(false)

  return (
    <Box
      sx={{
        bgColor: 'neutral-0',
        display: 'flex',
        flexDirection: 'column',
        width: 100,
        height: 100,
        gap: 24,
        overflow: 'auto',
      }}
    >
      <ReportHeader />
      <FactorContainer
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          width: 100,
          height: 100,
          overflow: 'auto',
          pr: 0,
        }}
      >
        <Row sx={{ width: 100, height: 100, overflow: 'auto' }}>
          <RowColsWrapper activeArea={activeArea as DimensionOrMetrics} />
          <TableContainer
            setShowMaximizeExecutionTable={setShowMaximizeExecutionTable}
          />
          <ExpandExecutionTableDialog
            open={showMaximizeExecutionTable}
            onClose={() => setShowMaximizeExecutionTable(false)}
          />
        </Row>
      </FactorContainer>
    </Box>
  )
}
