import {
  Dialog,
  DialogContent,
  DialogTitle,
  TypoTooltip,
} from '@applift/factor'
import { useStore } from '../../../../store'
import { TableContainer } from './TableContainer'

export interface ResetAlertDialogProps {
  open: boolean
  onClose: (params?: any) => any
}

export const ExpandExecutionTableDialog = (props: ResetAlertDialogProps) => {
  const { open, onClose } = props

  const [reportName] = useStore(state => [state.report.name])

  return (
    <Dialog
      open={open}
      fullScreen="bleed"
      PaperProps={{ sx: { boxShadow: 500, width: 100, height: 100 } }}
    >
      <DialogTitle onClose={onClose} noWrap={true}>
        <TypoTooltip placement="bottom" arrow sx={{ display: 'block' }}>
          {reportName}
        </TypoTooltip>
      </DialogTitle>
      <DialogContent dividers sx={{ p: 0 }}>
        <TableContainer isUsedInDialog />
      </DialogContent>
    </Dialog>
  )
}
