import * as React from 'react'
import { useMutation } from '@tanstack/react-query'
import { enqueueSnackbar } from '@applift/factor'
import { useParams } from '@tanstack/react-router'
import { ScheduleReportDialog } from './ScheduleReportDialog'
import {
  staticDeliverySchedule,
  staticWeeks,
  staticDays,
} from '../../api/schedule'
import {
  ReportSchedulingEventDetailsType,
  ScheduleResponseData,
} from '../../models/Schedule'
import * as ScheduleAPI from '../../api/schedule'
import { queryClient } from '../../cache'
import { useGetEditData } from '../../hooks/useGetEditData'
import { WithResponse } from '../../models/Response'

interface ScheduleReportProps {
  onClose: (params?: any) => void
}

export const EditScheduleReportDialogWrapper = (props: ScheduleReportProps) => {
  const { onClose } = props

  const { id } = useParams({ from: '/edit/$id' })
  const { data: rowData } = useGetEditData(+id, {
    enabled: !!id,
  })

  const mode = rowData?.reportSchedulingEventDetails ? 'edit' : 'new'

  const initialDeliverySchedule =
    rowData?.reportSchedulingEventDetails?.deliveryFrequency

  let initialWeekDay: string | undefined = undefined
  let initialMonthDay: string | undefined = undefined

  if (initialDeliverySchedule === 3) {
    initialWeekDay = rowData?.reportSchedulingEventDetails?.deliveryDay
  } else if (initialDeliverySchedule === 4) {
    initialMonthDay = rowData?.reportSchedulingEventDetails?.deliveryDay
  }

  const showRunningTotal = React.useMemo(() => {
    const dimensions = rowData?.dimensions && JSON.parse(rowData.dimensions)
    const haveCampaignDimension =
      dimensions.filter(
        (dimension: { filter: string }) => dimension.filter === 'campaign'
      ).length > 0
    return dimensions.length === 1 && haveCampaignDimension
  }, [rowData])

  const addEmailScheduleMutation = useMutation(ScheduleAPI.addEmailSchedule, {
    mutationKey: ['AddEmailSchedule'],
    onSuccess: (response, payload) => {
      queryClient.setQueriesData<WithResponse<ScheduleResponseData>>(
        {
          // @ts-ignore
          predicate: query => query.queryKey[0].scope === 'getReportDetails',
        },
        (queriedReportData: any) => {
          return {
            ...queriedReportData,
            ...(payload.deliveryFrequency !== 1
              ? { reportSchedulingEventDetails: response?.data?.scheduledData }
              : {}),
          }
        }
      )

      enqueueSnackbar('Report schedule has been successfully updated.', {
        variant: 'success',
      })
      onClose()
    },
    onError: () => {
      enqueueSnackbar('Oops! Something went wrong. Please try again.', {
        variant: 'error',
      })
    },
  })

  const updateEmailScheduleMutation = useMutation(
    ScheduleAPI.updateEmailSchedule,
    {
      mutationKey: ['UpdateEmailSchedule'],
      onSuccess: (response, payload) => {
        queryClient.setQueriesData<WithResponse<ScheduleResponseData>>(
          {
            // @ts-ignore
            predicate: query => query.queryKey[0].scope === 'getReportDetails',
          },
          (queriedReportData: any) => {
            return {
              ...queriedReportData,
              ...(payload.deliveryFrequency !== 1
                ? {
                    reportSchedulingEventDetails: response?.data?.scheduledData,
                  }
                : {}),
            }
          }
        )
        enqueueSnackbar('Report schedule has been successfully updated', {
          variant: 'success',
        })
        onClose()
      },
      onError: () => {
        enqueueSnackbar('Oops! Something went wrong. Please try again.', {
          variant: 'error',
        })
      },
    }
  )

  const onSchedule = (data: ReportSchedulingEventDetailsType) => {
    const _data = {
      ...data,
      reportId: rowData?.id || parseInt(id),
      ...(showRunningTotal
        ? { earliestCampaignStartDate: rowData?.startDate }
        : null),
    }

    if (mode === 'new' && _data) {
      addEmailScheduleMutation.mutate(_data)
    } else if (mode === 'edit' && _data) {
      updateEmailScheduleMutation.mutate(_data)
    }
  }

  const stopSchedulingMutation = useMutation(ScheduleAPI.stopScheduling, {
    mutationKey: ['DeleteReport'],
    onSuccess: () => {
      enqueueSnackbar('Report schedule has been successfully stopped.', {
        variant: 'success',
      })
      queryClient.setQueriesData<WithResponse<ScheduleResponseData>>(
        {
          // @ts-ignore
          predicate: query => query.queryKey[0].scope === 'getReportDetails',
        },
        (queriedReportData: any) => {
          delete queriedReportData.reportSchedulingEventDetails
          return queriedReportData
        }
      )
    },
    onError: () => {
      enqueueSnackbar('Oops! Something went wrong. Please try again.', {
        variant: 'error',
      })
    },
    onSettled: () => {
      onClose()
    },
  })

  const onStopScheduling = () => {
    if (rowData?.id) {
      stopSchedulingMutation.mutate(rowData.id)
    }
  }

  return (
    <ScheduleReportDialog
      reportName={rowData?.reportName || ''}
      timezoneId={rowData?.timezoneId}
      onClose={onClose}
      initialRunningTotal={
        rowData?.reportSchedulingEventDetails?.runningTotalEnabled
      }
      initialReceptiantEmails={
        rowData?.reportSchedulingEventDetails?.subscriberEmails
      }
      initialDeliverySchedule={
        staticDeliverySchedule.filter(
          deliverySchedule => deliverySchedule.value === initialDeliverySchedule
        )[0]
      }
      initialEndDate={rowData?.reportSchedulingEventDetails?.eventEndDate}
      initialDocFormat={rowData?.reportSchedulingEventDetails?.fileType}
      initialWeekDay={
        staticWeeks.filter(day => day.value === initialWeekDay)[0]
      }
      initialMonthDay={
        staticDays.filter(day => day.value === initialMonthDay)[0]
      }
      showRunningTotal={showRunningTotal}
      onSchedule={onSchedule}
      onStopSchedule={onStopScheduling}
      mode={mode}
      isLoading={
        addEmailScheduleMutation.isLoading ||
        updateEmailScheduleMutation.isLoading ||
        stopSchedulingMutation.isLoading
      }
    />
  )
}
