import {
  Alert,
  Box,
  Button,
  Col,
  DialogActions,
  DialogContent,
  DialogTitle,
  Layout,
  List,
  ListItem,
  Paper,
  Row,
  Typography,
  TypoTooltip,
} from '@applift/factor'
import { Report } from '@applift/illustrations'
import * as React from 'react'

import {
  FilterMapping,
  FilterMappingKeys,
  ReverseFilterMapping,
} from '../../models/filterMetaData'
import { DimensionFilterType, ListType } from '../../models/List'
import { FilterKeys } from '../../modules/Filters'
import { getFilterMetaData } from '../../modules/Filters/FilterMetaData'
import { ViewFilter } from '../../modules/Filters/ViewFilter'

import { useNavigate, useRouteContext } from '@tanstack/react-router'
import styles from './index.module.scss'

interface DimensionsSummaryProps {
  onClose: (params: any) => void
  data: ListType
}

interface SelectedDimension {
  title: string
  filterKey: FilterKeys
  ids?: number[]
}

export const DimensionsSummeryDialog = (props: DimensionsSummaryProps) => {
  const { onClose, data: rowData } = props
  const navigate = useNavigate()
  const [selectedDimension, setSelectedDimension] =
    React.useState<SelectedDimension | null>(null)

  const contextData = useRouteContext({ from: '__root__' })
  const FilterRecords = contextData._root.dimensionAndMetricsListData

  const organizationType = contextData.appContext.appMeta.organizationType

  const filterMetaDataRef = React.useRef(getFilterMetaData(organizationType))
  const dimensionsObj = React.useMemo((): SelectedDimension[] | null => {
    try {
      const dimensions: DimensionFilterType[] = JSON.parse(rowData.dimensions)
      //@ts-ignored
      return dimensions.map((dimension: DimensionFilterType) => {
        return {
          filterKey:
            FilterMapping[dimension?.filter as FilterMappingKeys] ||
            FilterRecords?.keyWiseDimensions[dimension.filter]?.key,
          title:
            filterMetaDataRef.current[
              FilterMapping[
                dimension?.filter as FilterMappingKeys
              ] as FilterKeys
            ]?.title ||
            FilterRecords?.keyWiseDimensions[dimension.filter]?.label,
          ids: dimension.value,
        }
      })
    } catch (e) {
      return []
    }
  }, [rowData, FilterRecords])

  React.useEffect(() => {
    if (selectedDimension === null && dimensionsObj && dimensionsObj?.length) {
      setSelectedDimension(dimensionsObj[0] as SelectedDimension)
    }
  }, [selectedDimension, dimensionsObj])

  const DimensionSummarySideBar = () => {
    return (
      <List>
        {dimensionsObj?.map(dimensions => (
          <ListItem
            key={dimensions.filterKey}
            selected={selectedDimension?.filterKey === dimensions.filterKey}
            onClick={() => {
              setSelectedDimension(dimensions)
            }}
          >
            <Typography gutterBottom={false}>{dimensions.title}</Typography>
          </ListItem>
        ))}
      </List>
    )
  }

  const TabHeader = (props: { title: string }) => {
    const { title } = props
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'between',
          px: 16,
        }}
      >
        <Typography
          component="h2"
          variant="bodySmall"
          className={styles.tabsButton}
        >
          {title}
        </Typography>
      </Box>
    )
  }

  const EmptyBlockDimensionSummary = (EmptyBlockrops: { title: string }) => {
    const { title } = EmptyBlockrops
    return (
      <>
        <Box sx={{ textColor: 'primary-500', width: 75, mx: 'auto' }}>
          <Report sx={{ width: 100, height: 'auto' }} />
        </Box>
        <Typography
          component="h2"
          variant="bodyMedium"
          gutterBottom={false}
          weight="normal"
          align="center"
          lineHeight="multi-line"
          sx={{
            textColor: 'neutral-1000',
          }}
        >
          <b>{title}</b> is not filtered. All the eligible{' '}
          {title?.toLowerCase()} will be seen in the report after you click on
          Run Now.
        </Typography>
      </>
    )
  }

  const filterSupported = React.useMemo(() => {
    const key =
      ReverseFilterMapping[selectedDimension?.filterKey as FilterKeys] ||
      FilterRecords?.keyWiseDimensions[
        selectedDimension?.filterKey as FilterKeys
      ]?.key
    return key
      ? FilterRecords?.keyWiseDimensions[key]?.isFilterSupported
      : false
  }, [FilterRecords, selectedDimension])

  let selectedDimensionIdsLength

  if (
    (selectedDimension?.filterKey === 'DealFilter' ||
      selectedDimension?.filterKey === 'DealIdFilter') &&
    selectedDimension?.ids
  ) {
    selectedDimensionIdsLength =
      // @ts-ignore
      selectedDimension?.ids[0]?.PMP?.length +
      // @ts-ignore
      selectedDimension?.ids[0]?.PG?.length
  } else {
    selectedDimensionIdsLength = selectedDimension?.ids?.length
  }

  return (
    <>
      <DialogTitle onClose={onClose} noWrap={true}>
        <TypoTooltip
          placement="bottom"
          arrow
          TypgraphyProps={{ sx: { display: 'block' } }}
        >
          {rowData.reportName}
        </TypoTooltip>
      </DialogTitle>
      <DialogContent dividers sx={{ display: 'flex', p: 0 }}>
        <Box sx={{ display: 'flex', width: 100 }}>
          <Layout
            sidebar={<DimensionSummarySideBar />}
            sidebarWidth={200}
            maxWidth={200}
            minWidth={200}
            hideResizerHandle
            sidebarVariant="permanent"
          >
            <Box sx={{ display: 'flex', width: 100, height: 100, p: 16 }}>
              <Paper
                elevation={2}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 100,
                  height: 100,
                }}
              >
                <Box
                  sx={{
                    pt: 12,
                    borderBottom: 1,
                  }}
                >
                  <TabHeader title={selectedDimension?.title as string} />
                </Box>
                {dimensionsObj &&
                  !!rowData.reportAggregated &&
                  !!parseInt(rowData.reportAggregated) &&
                  selectedDimension?.filterKey ===
                    dimensionsObj[0]?.filterKey && (
                    <Alert sx={{ m: 16 }} severity="info">
                      {`The report has been aggregated by ${dimensionsObj[0]?.title}. This Column will not be seen in the report.`}
                    </Alert>
                  )}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 100,
                    height: 100,
                  }}
                >
                  {selectedDimensionIdsLength &&
                  filterSupported &&
                  selectedDimension?.ids ? (
                    <Box
                      sx={{
                        width: 100,
                        height: 100,
                        borderTop:
                          dimensionsObj &&
                          !!rowData.reportAggregated &&
                          !!parseInt(rowData.reportAggregated) &&
                          selectedDimension?.filterKey ===
                            dimensionsObj[0]?.filterKey
                            ? 1
                            : 0,
                      }}
                    >
                      <ViewFilter
                        key={selectedDimension?.filterKey as FilterKeys}
                        filterKey={selectedDimension?.filterKey as FilterKeys}
                        initialFilterIds={selectedDimension.ids}
                        customerIds={rowData?.customerIds}
                      />
                    </Box>
                  ) : (
                    <Row
                      sx={{
                        width: 100,
                        height: 100,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Col
                        xs={12}
                        sm={8}
                        md={7}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 24,
                        }}
                      >
                        <EmptyBlockDimensionSummary
                          title={selectedDimension?.title as string}
                        />
                      </Col>
                    </Row>
                  )}
                </Box>
              </Paper>
            </Box>
          </Layout>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            navigate({ to: `/edit/${rowData.id}` })
          }}
        >
          Run Now
        </Button>
      </DialogActions>
    </>
  )
}
