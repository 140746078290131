export const getNewKey = (): number => {
  const existingKeys = new Set<number>()
  let newKey: number

  do {
    newKey = Math.floor(Math.random() * 1000000) + 1
  } while (existingKeys.has(newKey))

  existingKeys.add(newKey)
  return newKey
}
