import * as React from 'react'
import { queryClient } from '../cache/query'
import { useStore } from '../store'

export const useClearCache = () => {
  const [
    resetReporNameDateTimezone,
    resetDimensionsAndMetrics,
    resetCustomers,
    resetFilters,
  ] = useStore(state => [
    state.report.reset,
    state.selectedDimensionsAndMetricsSlice.reset,
    state.customerFilter.reset,
    state.filters.reset,
  ])

  const resetStore = React.useCallback(() => {
    resetReporNameDateTimezone()
    resetDimensionsAndMetrics()
    resetFilters()
    resetCustomers()
  }, [
    resetCustomers,
    resetDimensionsAndMetrics,
    resetFilters,
    resetReporNameDateTimezone,
  ])

  const clearQueryCache = React.useCallback(() => {
    queryClient.clear()
  }, [])

  return { clearQueryCache, resetStore }
}
