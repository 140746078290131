import * as React from 'react'
import { DataGrid, SortingState } from '@applift/datagrid'
import { sx } from '@applift/factor'
import { generateMetaData } from './metadata'
import {
  ExtendDimension,
  ExtendMetrics,
} from '../../../../models/DimensionMetrics'
import {
  ExecuteReportResponseColumnType,
  ExecuteReportResponseType,
  ReportType,
} from '../../../../models/Report'
import { useStore } from '../../../../store'
import { useHasChanged } from '../../../../hooks/useHasChange'
import { EmptyExecuteReport } from './EmptyExecuteReport'

interface TableProps {
  dimensions: ExtendDimension[]
  metrics: ExtendMetrics[]
  hasAggregation: boolean
  view: ReportType
  sorting: SortingState
  tableData?: (ExecuteReportResponseColumnType & { id: number })[]
  filteredRecord?: ExecuteReportResponseType['filteredRecords']
  footerData?: ExecuteReportResponseType['total']
  loading?: boolean
  isError?: boolean
  isFetched?: boolean
  overlay?: 'noResult' | 'error' | 'noRows'
  fetchNextPage: () => void
  setSorting: (param: any) => void
}

export const Table = (props: TableProps) => {
  const {
    dimensions,
    metrics,
    view,
    tableData,
    fetchNextPage,
    filteredRecord,
    footerData,
    sorting,
    setSorting,
    loading,
    isFetched,
    overlay,
  } = props

  const [dirtyDimensionOrMetric] = useStore(state => [
    state.selectedDimensionsAndMetricsSlice.dirty,
  ])

  const { dimsAndMetrics, isExecuteDisabled } = useHasChanged()

  const columnDef = React.useMemo(() => {
    const temp = generateMetaData(
      dimensions,
      metrics,
      dimsAndMetrics.hasAggregation,
      view,
      dirtyDimensionOrMetric
    )
    return temp
  }, [
    dimensions,
    metrics,
    view,
    dirtyDimensionOrMetric,
    dimsAndMetrics.hasAggregation,
  ])

  const rowIdKey = 'id'

  const columnOrder = React.useMemo(() => {
    const allCols = [...dimensions, ...metrics].map(one => one.key)
    if (view === 'Daily') {
      return ['date', ...allCols]
    }
    return allCols
  }, [dimensions, metrics, view])

  const setSortingWrapper = (fn: any) => {
    if (overlay) {
      return
    }
    if (!isFetched) {
      return
    }
    setSorting(fn(sorting))
  }

  return (
    <DataGrid
      footerPlacement={tableData?.length ? 'top' : undefined}
      footerData={footerData}
      data={tableData || []}
      columns={columnDef}
      getRowId={(row: any) => {
        if (row && typeof row[rowIdKey] === 'number') {
          return `${row[rowIdKey]}`
        }
        return row[rowIdKey]
      }}
      hideHeader
      hideFooter
      density="compact"
      checkboxSelection={false}
      state={{
        columnOrder: columnOrder,
        columnVisibility: { _id: false },
        ...(isFetched && !overlay ? { sorting } : {}),
      }}
      loading={loading || (filteredRecord === 0 && !isExecuteDisabled)}
      showColumnRightBorder
      showCellRightBorder
      disableRowSelectionOnClick
      onFetchRows={fetchNextPage}
      onSortingChange={setSortingWrapper}
      pageSize={20}
      rowCount={overlay ? undefined : filteredRecord ?? 19}
      classes={{ root: sx({ borderRadius: 0, border: 0, borderTop: 1 }) }}
      components={{
        NoResultsOverlay: EmptyExecuteReport,
      }}
      overlay={overlay}
    />
  )
}
