import {
  StatusRunning,
  StatusPaused,
  StatusPending,
  StatusRejected,
  StatusDeleted,
  StatusDraft,
  StatusExpired,
  StatusError,
  CreativeImage,
  CreativeHTML,
  CreativeAudio,
  CreativeVideo,
  CreativeNative,
  CreativeGoTV,
  DeviceDesktop,
  DeviceMobile,
  DeviceTablet,
  DeviceTV,
  ChannelApp,
  ChannelWeb,
  ChannelCTV,
  ChannelOTT,
  ChannelUnknown,
  StatusApproved,
  IoImpression,
  IoCurrency,
  StatusReady,
  CampaignAdvanced,
  CampaignPG,
} from '@applift/icons'
import { SvgIconProps } from '@applift/factor'
import { toInitCap } from '../utils/utils'

interface IconType {
  element: any
  props?: Partial<SvgIconProps>
}

const _IconNames = {
  Active: 'StatusActive',
  Ready: 'StatusReady',
  Running: 'StatusRunning',
  Paused: 'StatusPaused',
  Suspended: 'StatusPaused',
  Pending: 'StatusPending',
  Rejected: 'StatusRejected',
  Deleted: 'StatusDeleted',
  Draft: 'StatusDraft',
  Expired: 'StatusExpired',
  Image: 'CreativeImage',
  HTML: 'CreativeHTML',
  Audio: 'CreativeAudio',
  Video: 'CreativeVideo',
  Native: 'CreativeNative',
  GOTV: 'CreativeGoTV',
  Error: 'StatusError',
  Desktop: 'DeviceDesktop',
  Mobile: 'DeviceMobile',
  Tablet: 'DeviceTablet',
  ConnectedTV: 'DeviceTV',
  Web: 'ChannelWeb',
  App: 'ChannelApp',
  OTT: 'ChannelOTT',
  CTV: 'ChannelCTV',
  Unknown: 'ChannelUnknown',
  IoImpression: 'IoImpression',
  IoCurrency: 'IoCurrency',
  Advanced: 'campaignAdvance',
  PG: 'CampaignPG',
} as const

export type IconNamesTypes = keyof typeof _IconNames

export type IconRegistry = {
  [key in IconNamesTypes]: IconType
}

export const IconNames: IconRegistry = {
  Active: {
    element: StatusApproved,
    props: { sx: { textColor: 'success-400' } },
  },
  Ready: {
    element: StatusReady,
    props: { sx: { textColor: 'info-400' } },
  },
  Running: {
    element: StatusRunning,
    props: { sx: { textColor: 'success-400' } },
  },
  Paused: {
    element: StatusPaused,
    props: { sx: { textColor: 'warning-400' } },
  },
  Suspended: {
    element: StatusPaused,
    props: { sx: { textColor: 'warning-400' } },
  },
  Pending: {
    element: StatusPending,
    props: { sx: { textColor: 'warning-400' } },
  },
  Rejected: {
    element: StatusRejected,
    props: { sx: { textColor: 'danger-400' } },
  },
  Deleted: {
    element: StatusDeleted,
    props: { sx: { textColor: 'danger-400' } },
  },
  Draft: {
    element: StatusDraft,
    props: { sx: { textColor: 'info-400' } },
  },
  Expired: {
    element: StatusExpired,
    props: { sx: { textColor: 'neutral-600' } },
  },
  Image: {
    element: CreativeImage,
    props: { sx: { textColor: 'primary-600' } },
  },
  HTML: {
    element: CreativeHTML,
    props: { sx: { textColor: 'primary-600' } },
  },
  Audio: {
    element: CreativeAudio,
    props: { sx: { textColor: 'primary-600' } },
  },
  Video: {
    element: CreativeVideo,
    props: { sx: { textColor: 'primary-600' } },
  },
  Native: {
    element: CreativeNative,
    props: { sx: { textColor: 'primary-600' } },
  },
  GOTV: {
    element: CreativeGoTV,
    props: { sx: { textColor: 'primary-600' } },
  },
  Desktop: {
    element: DeviceDesktop,
    props: { sx: { textColor: 'primary-600' } },
  },
  Mobile: {
    element: DeviceMobile,
    props: { sx: { textColor: 'primary-600' } },
  },
  Tablet: {
    element: DeviceTablet,
    props: { sx: { textColor: 'primary-600' } },
  },
  ConnectedTV: {
    element: DeviceTV,
    props: { sx: { textColor: 'primary-600' } },
  },
  App: {
    element: ChannelApp,
    props: { sx: { textColor: 'primary-600' } },
  },
  Web: {
    element: ChannelWeb,
    props: { sx: { textColor: 'primary-600' } },
  },
  OTT: {
    element: ChannelOTT,
    props: { sx: { textColor: 'primary-600' } },
  },
  CTV: {
    element: ChannelCTV,
    props: { sx: { textColor: 'primary-600' } },
  },
  Unknown: {
    element: ChannelUnknown,
    props: { sx: { textColor: 'primary-600' } },
  },
  Error: {
    element: StatusError,
    props: { sx: { textColor: 'danger-400' } },
  },
  IoImpression: {
    element: IoImpression,
    props: { sx: { textColor: 'primary-600' } },
  },
  IoCurrency: {
    element: IoCurrency,
    props: { sx: { textColor: 'primary-600' } },
  },
  Advanced: {
    element: CampaignAdvanced,
    props: { sx: { textColor: 'primary-600' } },
  },
  PG: {
    element: CampaignPG,
    props: { sx: { textColor: 'primary-600' } },
  },
}

export const getIcon = (
  iconName: IconNamesTypes,
  props?: Partial<SvgIconProps>
) => {
  const result =
    IconNames[
      iconName
        .trim()
        .replace(/\s\s+/g, '')
        .split(' ')
        .map(toInitCap)
        .join('') as IconNamesTypes
    ]
  const Component = (result?.element || StatusError) as (
    props: any
  ) => JSX.Element
  const finalProps = result?.props || {}
  return <Component {...finalProps} {...props} />
}
