import axios, { AxiosInstance } from 'axios'

export type Params = { [key: string]: string | number }
let IQMInstance: AxiosInstance

export function attachIqmInstance(instance: AxiosInstance) {
  instance.interceptors.response.use(
    // @ts-ignore
    (response: unknown) => {
      return response
    },
    (response: any) => {
      if (axios.isCancel(response)) {
        const res = {
          response: {
            data: response,
          },
        }

        throw res
      }
      return Promise.reject(response)
    }
  )

  IQMInstance = instance
}

export function getInstance(): AxiosInstance {
  return IQMInstance || axios
}

export function getInstanceWithTimestamps(): AxiosInstance {
  return IQMInstance || axios
}
