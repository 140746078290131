import { ColumnDef, ReactTableCellContext } from '@applift/datagrid'
import { IoFilterType } from '../../../models/Filters'
import { TextCell } from '../cellTypes'

export const ioEndDateFilterColDef: ColumnDef<IoFilterType>[] = [
  {
    accessorKey: 'ioDate',
    header: 'Insertion Order End Date',
    size: 500,
    minSize: 150,
    enableSorting: false,
    cell: (props: ReactTableCellContext) => {
      return <TextCell label={props.renderValue()} />
    },
  },
]
