import { GridActionCellParams } from '@applift/datagrid'
import { Box, Button, IconButton, Tooltip } from '@applift/factor'
import { Delete, Edit } from '@applift/icons'
import { ListType } from '../../../models/List'
import { ACTION } from '../action'

interface ListActionCellType {
  action?: (args: GridActionCellParams) => any
  rowData: ListType
}

export const ListActionCell = ({ action, rowData }: ListActionCellType) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip
        title="Run report to view most up to date data "
        placement="top"
        arrow
      >
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => {
            action?.({ actionName: ACTION.RUN_NOW, metaData: rowData })
          }}
        >
          Run Now
        </Button>
      </Tooltip>
      <Tooltip title="Edit the report " placement="top" arrow>
        <IconButton
          color="secondary"
          sx={{ ml: 4 }}
          onClick={() => {
            action?.({ actionName: ACTION.EDIT, metaData: rowData })
          }}
        >
          <Edit />
        </IconButton>
      </Tooltip>
      <IconButton
        color="secondary"
        sx={{ ml: 0 }}
        onClick={() => {
          action?.({ actionName: ACTION.DELETE, metaData: rowData })
        }}
      >
        <Delete />
      </IconButton>
    </Box>
  )
}
