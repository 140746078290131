import { RowSelectionState } from '@applift/datagrid'
import { FilterMapping, Filters } from '../models/filterMetaData'

const createRowSelectionState = (
  ids: string[] | undefined
): RowSelectionState => {
  return (
    ids?.reduce((acc, id) => {
      acc[id] = true
      return acc
    }, {} as RowSelectionState) ?? {}
  )
}

export const createFiltersFromDraft = (
  campaignIds: string[] | undefined,
  ioIds: string[] | undefined
): Filters => {
  return {
    [FilterMapping.campaign]: createRowSelectionState(campaignIds),
    [FilterMapping.ioName]: createRowSelectionState(ioIds),
  } as Filters
}
