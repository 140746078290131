export const timeFormat = (secs: string | number) => {
  const secNum = parseInt(secs as string, 10)
  const hours = Math.floor(secNum / 3600)
  const minutes = Math.floor(secNum / 60) % 60
  const seconds = secNum % 60

  return [hours, minutes, seconds].map(v => (v < 10 ? '0' + v : v)).join(':')
}

export const displayTime = (data: number) => {
  return data !== null ? timeFormat(+data) : '—'
}
