import { QueryFunctionContext } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import {
  WithResponse,
  ResponseV2,
  WithRecordResponse,
} from '../models/Response'
import {
  EditReportType,
  ReportDetailsType,
  CreateReportType,
} from '../models/Report'
import { getInstance, getInstanceWithTimestamps } from './instance'
import { ITimezone } from '../models/DateTime'
import { getFilterQueryKey, getReportDetailsQueryKey } from './queryKey'
import { CampaignFilterType } from '../models/Filters'

export const createReport = async (data: CreateReportType) => {
  try {
    const response: AxiosResponse<WithResponse<number>> =
      await getInstanceWithTimestamps().post(`/v3/ra/report/add`, data)
    return response
  } catch (e: any) {
    return Promise.reject(e as AxiosError)
  }
}

export const updateReport = async (data: EditReportType) => {
  const { reportId } = data
  delete data.reportId

  try {
    const response: AxiosResponse<WithResponse<number>> =
      await getInstanceWithTimestamps().patch(`/v3/ra/report/${reportId}`, data)
    return response
  } catch (e: any) {
    return Promise.reject(e as AxiosError)
  }
}

export const getReportDetails = async ({
  queryKey,
  signal,
}: QueryFunctionContext<
  ReturnType<(typeof getReportDetailsQueryKey)['keys']>
>) => {
  const { reportId } = queryKey[0]!
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled')
  })
  try {
    const response: AxiosResponse<WithResponse<ReportDetailsType>> =
      await getInstance().get(`/v3/ra/report/${reportId}`, {
        cancelToken: source.token,
      })
    return response.data.data
  } catch (e: any) {
    return Promise.reject((e as AxiosError).response)
  }
}

export const getTimezones = async () => {
  try {
    const response: AxiosResponse<ResponseV2<ITimezone[]>> =
      await getInstance().get(`/v2/adv/static/timezones`)
    return response?.data?.responseObject ?? []
  } catch (e: any) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const getCheckCampaignExist = async ({
  signal,
}: QueryFunctionContext<ReturnType<(typeof getFilterQueryKey)['keys']>>) => {
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled')
  })
  try {
    const response: AxiosResponse<WithRecordResponse<CampaignFilterType[]>> =
      await getInstance().post(
        `/v3/cmp/basic/list`,
        {
          noOfEntries: 1,
          pageNo: 1,
          isAllOwIds: true,
        },
        { cancelToken: source.token }
      )
    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}
