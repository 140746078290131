import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  enqueueSnackbar,
  LoadingButton,
  TextField,
  Typography,
} from '@applift/factor'
import * as React from 'react'
import { useStore } from '../../../../store'

import { useMutation } from '@tanstack/react-query'
import { useNavigate, useParams } from '@tanstack/react-router'
import { duplicateReport } from '../../../../api/duplicate'
import { useGetEditData } from '../../../../hooks/useGetEditData'

interface DuplicateReportProps {
  onClose: () => void
}

export const DuplicateReportDialog = (props: DuplicateReportProps) => {
  const { onClose } = props

  const { id } = useParams({ from: '/edit/$id' })
  const { data: reportData } = useGetEditData(+id, {
    enabled: !!id,
  })

  const [name] = useStore(state => [state.report.name])

  const [reportName, setReportName] = React.useState(
    reportData?.reportName ? `Copy of ${name}` : ''
  )

  const navigate = useNavigate()

  const duplicateReportMutation = useMutation(duplicateReport, {
    mutationKey: ['DuplicateReport'],
    onSuccess: response => {
      //@ts-ignored
      const duplicateId = response.data.split('id')[1].trim()
      enqueueSnackbar('Report Duplicated Successfully', {
        variant: 'success',
        actionHandler: () => {
          navigate({
            to: `/edit/${duplicateId}`,
          })
        },
        persist: true,
        actionName: 'View Duplicated Report',
      })
    },
    onError: (e: any) => {
      enqueueSnackbar(
        e.errorObjects
          ? (e.errorObjects[0]?.error as string)
          : 'Something went wrong. Please try after sometime.',
        {
          variant: 'error',
        }
      )
    },
    onSettled: () => {
      onClose()
    },
  })

  const duplicateAction = () => {
    duplicateReportMutation.mutate({
      id: reportData?.id || parseInt(id),
      reportName: reportName,
    })
  }
  return (
    <>
      <DialogTitle>Duplicate Report</DialogTitle>
      <DialogContent dividers>
        <Typography>
          Create the report with the same parameters without copying the
          scheduling
        </Typography>
        <TextField
          value={reportName}
          onChange={e =>
            setReportName(e.target.value.trim() ? e.target.value : '')
          }
          label="Report Name"
          placeholder="Report Name"
          variant="outlinedDash"
          fullWidth
          sx={{ mb: 16, mt: 16 }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          disabled={duplicateReportMutation.isLoading || !reportName.length}
          onClick={duplicateAction}
          loading={duplicateReportMutation.isLoading}
          variant="contained"
        >
          Duplicate Report
        </LoadingButton>
      </DialogActions>
    </>
  )
}
