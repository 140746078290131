import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@applift/factor'
import { SpecialRuleType } from '../../../models/DimensionMetrics'

export interface ReorderAggragationDialogProps {
  dimensionMetricInfo?: SpecialRuleType['info']
  onClose?: (params?: any) => any
  onReset?: () => void
}

export const ReorderAggragationDialog = (
  props: ReorderAggragationDialogProps
) => {
  const { onClose, onReset } = props

  return (
    <>
      <DialogTitle>Reorder Dimension List</DialogTitle>
      <DialogContent>
        <Typography>
          Reordering the dimensions will reset the report aggregation. Are you
          sure you want to reorder the dimensions in the list ?
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="error" onClick={onReset}>
          Reorder
        </Button>
      </DialogActions>
    </>
  )
}
