import { Box } from '@applift/factor'
import { Dashboard, Launch } from '@applift/illustrations'
import { ORGANIZATION_TYPES } from '@applift/platform'
import { EmptyView } from './EmptyView'
export interface EmptyCreateReportProps {
  view: 'report' | 'campaign'
  className?: string
  handleCreate: () => void
  organizationType: ORGANIZATION_TYPES
  redirectToCampaignsURL: string
}
/**
 * EmptyCreateReport - this add the create report's empty card while user has not created report yet.
 *
 */
export const EmptyCreateReport = ({
  view,
  className,
  handleCreate,
  organizationType,
  redirectToCampaignsURL,
}: EmptyCreateReportProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: 100,
        width: 100,
        p: 12,
        overflow: 'hidden',
      }}
      className={className}
    >
      {view === 'campaign' ? (
        <EmptyView
          buttonCta="Create Campaign"
          description={
            organizationType !== 'ADVERTISER'
              ? 'No campaigns created by any customer yet. '
              : 'Create your first campaign to use the power of Report App.'
          }
          handleCreate={() => window.open(redirectToCampaignsURL, '_blank')}
          illustrations={<Launch sx={{ width: 100, height: 'auto' }} />}
          hideCTA={
            organizationType === 'SUPER' || organizationType === 'WORKSPACE'
          }
        />
      ) : (
        <EmptyView
          buttonCta="Create Report"
          description="Create a new report or schedule it as per your convenience"
          handleCreate={handleCreate}
          illustrations={<Dashboard sx={{ width: 100, height: 'auto' }} />}
        />
      )}
    </Box>
  )
}
