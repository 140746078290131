import {
  ExtendDimension,
  ExtendMetrics,
  TransformedDimensionAndMetricsType,
} from '../models/DimensionMetrics'
import { FilterMapping, Filters } from '../models/filterMetaData'
import { DimensionFilterType } from '../models/List'

export const transformColumns = (
  columns: string[],
  metricsData: ExtendMetrics[],
  dimensionAndMetricsListData?: TransformedDimensionAndMetricsType
) => {
  columns.forEach(column => {
    const value = dimensionAndMetricsListData?.keyWiseMetrics[column]
    if (value) {
      metricsData.push(value)
    }
  })
}

export const transformFilters = (
  dimensionFilters: DimensionFilterType[],
  dimensionsData: ExtendDimension[],
  filters: Filters,
  dimensionAndMetricsListData?: TransformedDimensionAndMetricsType
) => {
  dimensionFilters.forEach(dimension => {
    const extendedDimension =
      dimensionAndMetricsListData?.keyWiseDimensions[dimension.filter]
    if (extendedDimension) {
      dimensionsData.push(extendedDimension)
      if (dimension.filter === 'dealId' || dimension.filter === 'deal') {
        if (dimension.value) {
          const filterKey = FilterMapping[dimension.filter]
          const rowSelection: Record<string, boolean> = {}
          // @ts-ignore
          dimension?.value[0]?.PG?.forEach(id => {
            rowSelection[`PG_${id}`] = true
          })
          // @ts-ignore
          dimension?.value[0]?.PMP?.forEach(id => {
            rowSelection[`PMP_${id}`] = true
          })
          // @ts-ignore
          filters[filterKey] = rowSelection
        }
      } else {
        if (Array.isArray(dimension.value) && dimension.value.length) {
          // @ts-ignore
          const filterKey = FilterMapping[dimension.filter]
          const rowSelection: Record<number, boolean> = dimension.value.reduce(
            (acc, curr) => {
              // @ts-ignore
              acc[curr] = true
              return acc
            },
            {}
          )
          // @ts-ignore
          filters[filterKey] = rowSelection
        }
      }
    }
  })
}
