import * as React from 'react'
import { Box, Typography, Tooltip } from '@applift/factor'
import { DragDrop, SemanticWarning } from '@applift/icons'
import clsx from 'clsx'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useDroppable } from '@dnd-kit/core'
import { useStore } from '../../../../store'
import { RolColsItem, RolColsItemProps } from './RowColsItem'
import { ExtendDimension } from '../../../../models/DimensionMetrics'
import styles from '../index.module.scss'
import { useRouteContext } from '@tanstack/react-router'

interface RowColProps extends Pick<RolColsItemProps, 'filters' | 'setFilter'> {
  title: string
  type: 'Metrics' | 'Dimensions'
  isActive?: boolean
}

export const RowCol = (props: RowColProps) => {
  const { title, type, isActive = false, filters, setFilter } = props
  const [dimensions, metrics, sorting, resetSorting] = useStore(state => [
    state.selectedDimensionsAndMetricsSlice.dimensions,
    state.selectedDimensionsAndMetricsSlice.metrics,
    state.executeTable.sorting,
    state.executeTable.resetSorting,
  ])

  const [hasAggregation, toggleAggregation, isDimensionListFull] = useStore(
    state => [
      state.selectedDimensionsAndMetricsSlice.hasAggregation,
      state.selectedDimensionsAndMetricsSlice.toggleAggregation,
      state.selectedDimensionsAndMetricsSlice.isDimensionListFull,
    ]
  )

  const contextData = useRouteContext({ from: '__root__' })
  const dimsAndMetrics = contextData._root.dimensionAndMetricsListData

  const { setNodeRef } = useDroppable({
    id: type === 'Dimensions' ? 'Rows' : 'Columns',
    data: {
      accepts: [type, type === 'Dimensions' ? 'Rows' : 'Columns'],
    },
  })

  const active = type === 'Dimensions' ? dimensions : metrics
  const count = Object.keys(active).length

  const items: JSX.Element[] = []

  const activeKeys: string[] = []

  for (let itr = 0; itr < active.length; itr++) {
    const item = active[itr]!
    activeKeys.push(item.key)
    items.push(
      <RolColsItem
        key={item?.key}
        totalItems={active.length}
        insertIndex={itr}
        item={item!}
        type={type}
        fullWidth
        filters={filters}
        setFilter={setFilter}
        aggregationValue={hasAggregation}
        onAggregate={e => {
          // @ts-ignore
          toggleAggregation(e.target.checked)
          // @ts-ignore
          if (e.target.checked && dimensions[0]?.key === sorting[0]?.id) {
            resetSorting()
          }
        }}
      />
    )
  }

  const showStatusWarningIcon = React.useMemo(() => {
    const unusedGroupDimensions: ExtendDimension[] = []
    const allDimensions = Object.values(dimsAndMetrics?.keyWiseDimensions || {})
    allDimensions.forEach(val => {
      dimensions.forEach(obj => {
        if (
          unusedGroupDimensions.findIndex(nestObj => nestObj.id === val.id) > -1
        ) {
          return
        }
        if (
          val.groupId === obj.groupId &&
          dimensions.findIndex(nestObj => nestObj.id === val.id) === -1
        ) {
          return unusedGroupDimensions.push(val)
        }
      })
    })
    return !unusedGroupDimensions.length
  }, [dimensions, dimsAndMetrics?.keyWiseDimensions])

  return (
    <Box
      ref={setNodeRef}
      className={clsx(
        styles.dragndropContext,
        styles[`dragndropContext-${type}`],
        isActive && styles['dragndropContext-Active'],
        isDimensionListFull &&
          type === 'Dimensions' &&
          dimensions.length &&
          showStatusWarningIcon &&
          styles['dragndropContext-Full']
      )}
      sx={{
        width: 100,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 4,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'between',
          px: 16,
        }}
      >
        <Typography
          component="h2"
          variant="bodySmall"
          weight="demi"
          sx={{ mb: 8 }}
        >
          {title} {count ? `(${count})` : ''}
        </Typography>
        {isDimensionListFull &&
        type === 'Dimensions' &&
        dimensions.length &&
        showStatusWarningIcon ? (
          <Tooltip
            title="Can’t add more dimensions as the maximum dimension limit for report has been reached."
            placement="top"
            arrow
          >
            <SemanticWarning fontSize={24} color="warning" />
          </Tooltip>
        ) : null}
      </Box>
      {count === 0 ? (
        <EmptyList type={type} />
      ) : (
        <Box
          sx={{
            // display: 'flex',
            // height: 100,
            // flexDirection: 'column',
            overflow: 'auto',
            pt: 8,
            px: 16,
          }}
        >
          <SortableContext
            items={activeKeys}
            strategy={verticalListSortingStrategy}
          >
            {items}
          </SortableContext>
        </Box>
      )}
    </Box>
  )
}

interface EmptyListProps {
  type: 'Metrics' | 'Dimensions'
}
const EmptyList = (props: EmptyListProps) => {
  const { type } = props
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: 100,
        p: 16,
      }}
    >
      <DragDrop sx={{ textColor: 'neutral-400', mb: 8 }} fontSize={32} />
      <Typography
        variant="bodySmall"
        weight="normal"
        align="center"
        gutterBottom={false}
        sx={{
          textColor: 'neutral-600',
        }}
      >
        Drag & drop
        <br />
        {type.toLocaleLowerCase()} here
      </Typography>
    </Box>
  )
}
