import { enqueueSnackbar } from '@applift/factor'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { deleteReport } from '../../api/list'
import { queryClient } from '../../cache'
import { useGetEditData } from '../../hooks/useGetEditData'
import { ListType } from '../../models/List'
import { WithRecordResponse } from '../../models/Response'
import { DeleteReportDialog } from './DeleteReportDialog'

interface DeleteReportProps {
  onClose: (params?: any) => void
  id: number
}

export const EditDeleteReportDialogWrapper = (props: DeleteReportProps) => {
  const { onClose, id } = props

  const { data: reportData } = useGetEditData(id, {
    enabled: id !== null,
  })

  const navigate = useNavigate()

  const deletePostMutation = useMutation(deleteReport, {
    mutationKey: ['DeleteReport'],
    onSuccess: () => {
      enqueueSnackbar('Report deleted successfully', { variant: 'success' })
      queryClient.setQueriesData<WithRecordResponse<ListType[]>>(
        {
          // @ts-ignore
          predicate: query => query.queryKey[0].scope === 'ReportList',
        },
        (pages: any) => {
          const newPages = pages?.pages?.map((page: any) => {
            const newData = page.data?.filter((one: any) => one.id !== id)
            return {
              ...page,
              data: newData,
              totalRecords:
                page.totalRecords - 1 > 0 ? page.totalRecords - 1 : 0,
              filteredRecords:
                page.filteredRecords - 1 > 0 ? page.filteredRecords - 1 : 0,
            }
          })
          return { ...pages, pages: newPages }
        }
      )
      navigate({ to: '/list' })
    },
    onError: (_: any) => {
      enqueueSnackbar('failed', { variant: 'error' })
    },
    onSettled: () => {
      onClose()
    },
  })
  const deleteAction = () => {
    if (reportData?.id) {
      deletePostMutation.mutate(reportData.id)
    }
  }
  return (
    <DeleteReportDialog
      onClose={onClose}
      data={{
        reportName: reportData?.reportName,
        reportSchedulingEventDetails: reportData?.reportSchedulingEventDetails,
      }}
      deleteAction={deleteAction}
      isLoading={deletePostMutation.isLoading}
    />
  )
}
