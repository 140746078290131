export const daysDifference = (
  date1InMillis: number | string | Date,
  date2InMillis: number | string | Date
) => {
  // Convert inputs to Date objects
  const date1 = new Date(date1InMillis)
  const date2 = new Date(date2InMillis)

  // Ensure the inputs are valid dates
  if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
    throw new Error('Invalid date inputs')
  }

  // Calculate the absolute difference in days
  const diffInTime = Math.abs(date1.getTime() - date2.getTime())
  const diffInDays = Math.floor(diffInTime / (1000 * 60 * 60 * 24))

  return diffInDays
}
