import { Box, debounce, IconButton, TextField } from '@applift/factor'
import { Close, Search } from '@applift/icons'
import {
  CustomerDropdown,
  CustomerListApiRefType,
  useAppContext,
} from '@applift/platform'
import { NormalizedCustomerList } from '@applift/platform'
import { useRouteContext } from '@tanstack/react-router'
import * as React from 'react'
import { customerStatus } from '../../constants'

interface FilterProps {
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  customerIds: string[]
  setCustomerIds: React.Dispatch<React.SetStateAction<string[]>>
}

export const Filter = (props: FilterProps) => {
  const { search, setSearch, setCustomerIds } = props

  const searchInputRef = React.useRef<HTMLInputElement>(null)
  const apiRef = React.useRef<CustomerListApiRefType>()
  const { appMeta } = useAppContext()
  const organizationType = appMeta.organizationType

  const contextData = useRouteContext({ from: '__root__' })
  const customerList = contextData._root.customerList

  const handleOnChange = (data: Record<string, NormalizedCustomerList>) => {
    const customerIds = Object.keys(data).map(id => {
      return id
    })
    setCustomerIds(customerIds)
  }

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const debounceOnChange = React.useCallback(
    debounce((e: string) => setSearch && setSearch(e), 300),
    [setSearch]
  )
  return (
    <Box
      sx={{
        width: 100,
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        p: 16,
        gap: 16,
      }}
    >
      {organizationType !== 'ADVERTISER' ? (
        <Box>
          {/* @ts-ignore */}
          <CustomerDropdown
            ref={apiRef}
            initialData={customerList}
            allowParentNodeSelection
            slotProps={{
              PaperProps: {
                style: {
                  maxWidth: 420,
                  minHeight: 262,
                },
              },
              PopperProps: {
                anchorOrigin: {
                  horizontal: 'right',
                  vertical: 'bottom',
                },
                transformOrigin: {
                  horizontal: 'right',
                  vertical: 'top',
                },
                open: true,
              },
            }}
            userType={organizationType === 'WORKSPACE' ? 'WORKSPACE' : 'SUPER'}
            apiType="multiLevel"
            filters={{
              status: [customerStatus.ACTIVE, customerStatus.SUSPENDED].join(
                ','
              ),
            }}
            multiple
            loggedInOwId={appMeta.loggedInOwId}
            placeholder="Customers (0)"
            onChange={handleOnChange}
          />
        </Box>
      ) : null}
      <TextField
        type="text"
        onChange={e => debounceOnChange(e.target.value)}
        placeholder="Search by ID, Name"
        variant="outlinedDash"
        inputRef={searchInputRef}
        style={{ width: '180px' }}
        InputProps={{
          startAdornment: <Search />,
          endAdornment:
            search.length > 0 ? (
              <IconButton
                color="secondary"
                size="small"
                onClick={() => {
                  debounceOnChange('')
                  ;(searchInputRef.current as HTMLInputElement).value = ''
                }}
              >
                <Close />
              </IconButton>
            ) : (
              <></>
            ),
        }}
      />
    </Box>
  )
}
