import * as React from 'react'
import {
  Box,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  sx,
  Tooltip,
} from '@applift/factor'
import { GridActionCellParams } from '@applift/datagrid'
import { Download, FileCsv, FileXlsx } from '@applift/icons'
import { useMutation } from '@tanstack/react-query'
import { enqueueSnackbar } from '@applift/factor'

import { ACTION } from '../action'
import { ListType } from '../../../models/List'
import { downloadReport } from '../../../api'
import { downloadFile } from '../../../utils'
import styles from './index.module.scss'
import { useAppContext } from '@applift/platform'

interface IDCellType {
  action?: (args: GridActionCellParams) => any
  rowData: ListType
  cellData: ListType['id']
}

export const IdCell = ({ action, rowData, cellData }: IDCellType) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const contextData = useAppContext()
  const organizationName = contextData.appMeta.organizationName
  const downloadMutation = useMutation(downloadReport, {
    mutationKey: ['DownloadReport'],
    onSuccess: (res, payload) => {
      const currentTime = Date.now()
      const fileName = `${organizationName?.replace(/\s+/g, '')}${
        rowData.reportName ? `_${rowData.reportName.replace(/\s+/g, '')}` : ''
      }_${currentTime}.${payload.fileType === 1 ? 'csv' : 'xlsx'}`
      downloadFile(res.data?.url, fileName)
    },
    onError: () => {
      enqueueSnackbar('Something went wrong. Please try after some time.', {
        variant: 'error',
      })
    },
  })

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: 100 }}>
      <Typography
        gutterBottom={false}
        noWrap
        onClick={() =>
          action?.({ actionName: ACTION.VIEW_REPORT, metaData: rowData })
        }
      >
        {cellData}
      </Typography>
      <Tooltip title="Download report in CSV/XLSX" placement="top" arrow>
        <Box sx={{ ml: 'auto' }}>
          <IconButton
            color="secondary"
            className={styles.onHoverActive}
            aria-controls={open ? 'DownloadMenu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <Download />
          </IconButton>
        </Box>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'DownloadMenuButton',
        }}
        classes={{
          list: sx({ border: 1, borderColor: 'primary-600', borderRadius: 4 }),
        }}
      >
        <MenuItem
          onClick={() => {
            downloadMutation.mutate({ id: rowData.id, fileType: 1 })
            handleClose()
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FileCsv fontSize={24} sx={{ mr: 4, textColor: 'neutral-400' }} />
            Download as CSV
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            downloadMutation.mutate({ id: rowData.id, fileType: 2 })
            handleClose()
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FileXlsx fontSize={24} sx={{ mr: 4, textColor: 'neutral-400' }} />
            Download as XLSX
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}
