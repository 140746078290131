import { ColumnDef, DataGrid, SortingState } from '@applift/datagrid'
import {
  Box,
  Button,
  Chip,
  Col,
  debounce,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Row,
  sx,
  TextField,
} from '@applift/factor'
import { Close, Search } from '@applift/icons'
import { fromAbsolute, now } from '@internationalized/date'
import { RowSelectionState } from '@tanstack/table-core'
import * as React from 'react'
import { DateRange } from '../../models/DateTime'
import { FilterKeys } from '../../models/filterMetaData'
import { SelectedCampaignRow, SelectedIORow } from '../../models/Filters'
import { pluralize } from '../../registry'
import { useStore } from '../../store'
import { daysDifference, isDeepEqual } from '../../utils'
import { TextCell } from './cellTypes'
import { EmptyCampaign } from './component'
import { FilterDateRangePicker } from './component/FilterDateRangePicker'
import { DateRangeChangeDialog } from './DateRangeChangeDialog'

export type Mode = 'view' | 'edit'

export interface FilterProps {
  filterKey: FilterKeys
  columnDef: ColumnDef<any>[]
  data: any[]
  search?: string
  totalRecords?: number
  filteredRecords?: number
  loading: boolean
  rowIdKey?: string
  filterTitle: string
  sorting?: SortingState
  setSorting?: React.Dispatch<React.SetStateAction<SortingState>>
  pageSize?: number
  onClose?: (arg?: any) => any
  setSearch?: (arg: string) => void
  onFetchRows?: () => void
  intialRowSelection?: RowSelectionState
  onFilter?: (args: {
    search: string
    sorting: SortingState
    rowSelection: RowSelectionState
  }) => void
  rowHeight?: number
  dateRange?: DateRange
  setDateRange?: (prop: any) => void
  emptyfilterMessage?: { title: string; subText: string }
  isCustomerDependent?: boolean
  dateRangeSearch?: boolean
  totalDateRangeDataAvailable?: boolean
  /**
   * mode: edit mode with enable row selection, view mode will not have row selection option
   */
  mode?: Mode
  /**
   * disableSearch: will disable search functionality for the filter
   */
  disableSearch?: boolean
}

export const Filter = (props: FilterProps) => {
  const {
    columnDef,
    data,
    loading,
    search,
    setSearch,
    onFetchRows,
    filteredRecords,
    rowIdKey = 'id',
    filterTitle,
    setSorting,
    sorting,
    pageSize,
    intialRowSelection = {},
    onFilter,
    mode = 'edit',
    disableSearch = false,
    onClose,
    rowHeight = 56,
    dateRange,
    setDateRange,
    isCustomerDependent,
    emptyfilterMessage,
    dateRangeSearch,
    totalDateRangeDataAvailable,
    filterKey,
    totalRecords,
  } = props

  const [rowSelection, setRowSelection] =
    React.useState<RowSelectionState>(intialRowSelection) // initial value - zustand

  const [timezone] = useStore(state => [state.report.timezone])

  const [chipTotalCount, setChipTotalCount] = React.useState<
    number | undefined
  >()
  const [openDateRangeDialog, setOpenDateRangeDialog] =
    React.useState<boolean>(false)
  const searchInputRef = React.useRef<HTMLInputElement>(null)

  const [selectedRowData, setSelectedRowData] = React.useState<
    Map<string, SelectedCampaignRow | SelectedIORow>
  >(new Map())

  const overlay = React.useMemo(() => {
    if (search?.length && !loading && data.length === 0) {
      return 'noResult'
    }
    if (
      dateRange?.startDate &&
      dateRange?.endDate &&
      !loading &&
      data.length === 0
    ) {
      return 'noResult'
    }
    return undefined
  }, [data, search, loading, dateRange])
  /* eslint-disable-next-line */
  const debounceOnChange = React.useCallback(
    debounce((e: string) => setSearch && setSearch(e), 300),
    [setSearch]
  )

  const selectedItemsCount = React.useMemo(
    () => Object.keys(rowSelection).length,
    [rowSelection]
  )

  const chipLabel = React.useMemo(() => {
    return `${selectedItemsCount} of ${chipTotalCount} ${pluralize(
      filterTitle,
      selectedItemsCount
    ).toLowerCase()} selected`
  }, [selectedItemsCount, filterTitle, chipTotalCount])

  const [currentSelected, outsideSelected] = React.useMemo(() => {
    const map = new Map(Object.entries(rowSelection))

    const currentSelected = {} as RowSelectionState

    for (let itr = 0; itr < data.length; itr++) {
      const one = data[itr]
      if (map.has(`${one[rowIdKey]}`)) {
        currentSelected[one[rowIdKey]] = true
        map.delete(`${one[rowIdKey]}`)
      }
    }

    const outsideSelected = Object.fromEntries(map) as RowSelectionState

    return [currentSelected, outsideSelected]
  }, [rowSelection, data, rowIdKey])

  React.useEffect(() => {
    const selectedId = [
      ...Object.keys(currentSelected),
      ...Object.keys(outsideSelected),
    ]
    const clonedData = new Map(selectedRowData)
    // Adding the row data in the map
    data.forEach(row => {
      if (selectedId.includes(`${row[rowIdKey]}`)) {
        clonedData.set(`${row[rowIdKey]}`, row)
      }
    })

    // removing the unselected data from the map
    const mapDataIds = Array.from(selectedRowData, ([id, _]) => id)

    mapDataIds.forEach(id => {
      if (!selectedId.includes(id)) {
        clonedData.delete(id)
      }
    })

    setSelectedRowData(clonedData)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection, data, currentSelected, outsideSelected])

  const campaignDateRange = React.useMemo(() => {
    let start
    let end
    const current = now(timezone?.value ?? 'UTC')
      .toDate()
      .getTime()
    const oneYearAgoTimestamp = now(timezone?.value ?? 'UTC')
      .subtract({ days: 365 })
      .toDate()
      .valueOf()

    const timezoneName = timezone?.value ?? 'UTC'

    if (filterKey !== 'CampaignFilter' && filterKey !== 'CampaignIdFilter') {
      return {}
    }
    if (selectedRowData && selectedRowData.size === 1) {
      const firstValue = selectedRowData.values().next()
        .value as SelectedCampaignRow
      if (firstValue) {
        start = firstValue.startTime * 1000
        end = firstValue.endTime === 0 ? current : firstValue.endTime * 1000
      }
    }

    if (selectedRowData.size > 1) {
      // minimum of the start date
      const firstValue = selectedRowData.values().next()
        .value as SelectedCampaignRow
      const selectedArray = Array.from(
        selectedRowData.values()
      ) as SelectedCampaignRow[]
      if (firstValue && selectedArray.length > 0) {
        start =
          selectedArray.reduce(
            (acc, curr) => (curr.startTime < acc ? curr.startTime : acc),
            firstValue.startTime
          ) * 1000
        // maximum of the end date
        end =
          selectedArray.reduce(
            (acc, curr) => (curr.endTime > acc ? curr.endTime : acc),
            firstValue.endTime
          ) * 1000
      }

      if (end === 0) {
        end = current
      }
    }

    start = fromAbsolute(start ?? 0, timezoneName)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toDate()
      .valueOf()
    end = fromAbsolute(end ?? 0, timezoneName)
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
      .toDate()
      .valueOf()

    if (start && end) {
      const isWholeCampaignInFuture = start > current
      const isWholeCampaignInPast = end <= current && start < current
      const isCampaignLieInBoth = end > current && start < current

      if (isWholeCampaignInFuture) {
        return {
          startDate: oneYearAgoTimestamp,
          endDate: current,
        }
      }
      if (isWholeCampaignInPast) {
        if (daysDifference(start, end) > 365) {
          return {
            startDate: fromAbsolute(end ?? 0, timezoneName)
              .subtract({
                days: 365,
              })
              .toDate()
              .valueOf(),
            endDate: end,
          }
        }
        return {
          startDate: start,
          endDate: end,
        }
      }
      if (isCampaignLieInBoth) {
        if (daysDifference(start, current) > 365) {
          return {
            startDate: oneYearAgoTimestamp,
            endDate: fromAbsolute(current, timezoneName)
              .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
              .toDate()
              .valueOf(),
          }
        }
        return {
          startDate: start,
          endDate: fromAbsolute(current, timezoneName)
            .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
            .toDate()
            .valueOf(),
        }
      }
    }

    return { startDate: start, endDate: end }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterKey, selectedRowData])

  const ioDateRange = React.useMemo(() => {
    let start
    let end
    const current = now(timezone?.value ?? 'UTC')
      .toDate()
      .getTime()

    const oneYearAgoTimestamp = now(timezone?.value ?? 'UTC')
      .subtract({ days: 365 })
      .toDate()
      .valueOf()

    const timezoneName = timezone?.value ?? 'UTC'

    if (filterKey !== 'IoNameFilter' && filterKey !== 'IoIdFilter') {
      return {}
    }
    if (selectedRowData && selectedRowData.size === 1) {
      const firstValue = selectedRowData.values().next().value as SelectedIORow
      if (firstValue) {
        start = firstValue.ioStartTime
        end = firstValue.ioEndTime === 0 ? current : firstValue.ioEndTime
      }
    }

    if (selectedRowData.size > 1) {
      // minimum of the start date
      const firstValue = selectedRowData.values().next().value as SelectedIORow
      const selectedArray = Array.from(
        selectedRowData.values()
      ) as SelectedIORow[]
      if (firstValue && selectedArray.length > 0) {
        start = selectedArray.reduce(
          (acc, curr) => (curr.ioStartTime < acc ? curr.ioStartTime : acc),
          firstValue.ioStartTime
        )
        // maximum of the end date
        end = selectedArray.reduce(
          (acc, curr) => (curr.ioEndTime > acc ? curr.ioEndTime : acc),
          firstValue.ioEndTime
        )
      }

      if (end === 0) {
        end = current
      }
    }

    start = fromAbsolute(start ?? 0, timezoneName)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toDate()
      .valueOf()
    end = fromAbsolute(end ?? 0, timezoneName)
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
      .toDate()
      .valueOf()

    if (start && end) {
      const isWholeIOInFuture = start > current
      const isWholeIOInPast = end <= current && start < current
      const isIOLieInBoth = end > current && start < current

      if (isWholeIOInFuture) {
        return {
          startDate: oneYearAgoTimestamp,
          endDate: current,
        }
      }
      if (isWholeIOInPast) {
        if (daysDifference(start, end) > 365) {
          return {
            startDate: fromAbsolute(end ?? 0, timezoneName)
              .subtract({
                days: 365,
              })
              .toDate()
              .valueOf(),
            endDate: end,
          }
        }
        return {
          startDate: start,
          endDate: end,
        }
      }
      if (isIOLieInBoth) {
        if (daysDifference(start, current) > 365) {
          return {
            startDate: oneYearAgoTimestamp,
            endDate: fromAbsolute(current, timezoneName)
              .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
              .toDate()
              .valueOf(),
          }
        }
        return {
          startDate: start,
          endDate: fromAbsolute(current, timezoneName)
            .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
            .toDate()
            .valueOf(),
        }
      }
    }

    return { startDate: start, endDate: end }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterKey, selectedRowData])

  const setRowSelectionWrapper = React.useCallback(
    (_value: any) => {
      let value = _value
      if (typeof _value === 'function') {
        value = _value()
      }
      setRowSelection({ ...value, ...outsideSelected })
    },
    [setRowSelection, outsideSelected]
  )

  const modifiedColDef = React.useMemo(() => {
    if (mode === 'view' && columnDef.length === 1) {
      return columnDef.map(colDef => ({
        ...colDef,
        // @ts-ignore
        cell: props => {
          return <TextCell label={props.renderValue()} sx={{ ml: 8 }} />
        },
      }))
    }
    return columnDef
  }, [columnDef, mode])

  React.useEffect(() => {
    if (filterKey !== 'CustomerFilter' && filterKey !== 'CustomerIdFilter') {
      setChipTotalCount(totalRecords)
    }
    if (!chipTotalCount) {
      setChipTotalCount(filteredRecords)
    }
  }, [chipTotalCount, filteredRecords, totalRecords, filterKey])

  const dialogContent = () => (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 100,
        }}
      >
        {mode === 'edit' && (
          <Row>
            {!disableSearch ? (
              <Col xs={12} sx={{ mb: 16 }}>
                <TextField
                  type="text"
                  onChange={e => {
                    // @ts-ignore
                    debounceOnChange(e.target.value)
                  }}
                  placeholder={`Search by ${pluralize(filterTitle, 2)}`}
                  variant="outlinedDash"
                  inputRef={searchInputRef}
                  InputProps={{
                    startAdornment: <Search />,
                    endAdornment:
                      search && search.length > 0 ? (
                        <IconButton
                          size="small"
                          color="secondary"
                          onClick={() => {
                            debounceOnChange('')
                            ;(
                              searchInputRef.current as HTMLInputElement
                            ).value = ''
                          }}
                        >
                          <Close />
                        </IconButton>
                      ) : (
                        <></>
                      ),
                  }}
                  sx={{ mb: 2 }}
                  fullWidth
                />
              </Col>
            ) : null}
            {(filterTitle === 'Campaign Start Date' ||
              filterTitle === 'Campaign End Date') &&
            setDateRange ? (
              <Col xs={12} sx={{ mb: 16 }}>
                <FilterDateRangePicker
                  dateRange={dateRange}
                  setDateRange={value => {
                    setDateRange(value)
                    setRowSelection({})
                  }}
                  variant={filterTitle}
                />
              </Col>
            ) : null}
            {selectedItemsCount ? (
              <Col xs={12} sx={{ mb: 16 }}>
                <Chip
                  size="small"
                  style={{ visibility: chipTotalCount ? 'revert' : 'hidden' }}
                  label={chipLabel}
                  color="secondary"
                  onDelete={() => setRowSelection({})}
                />
              </Col>
            ) : null}
          </Row>
        )}

        <Row sx={{ flexGrow: 1 }}>
          <Col xs={12} sx={{ height: 100 }}>
            <DataGrid
              data={data || []}
              columns={modifiedColDef}
              getRowId={(row: any) => {
                if (row && typeof row[rowIdKey] === 'number') {
                  return `${row[rowIdKey]}`
                }
                return !!row && row[rowIdKey]
              }}
              state={{ rowSelection: currentSelected, sorting }}
              pageSize={pageSize || 20}
              hideHeader
              hideFooter
              density="compact"
              onRowSelectionChange={setRowSelectionWrapper}
              checkboxSelection={mode === 'edit'}
              loading={loading}
              showColumnRightBorder
              showCellRightBorder
              disableRowSelectionOnClick
              rowCount={filteredRecords}
              onFetchRows={onFetchRows}
              enableHiding={true}
              onSortingChange={setSorting}
              rowHeight={rowHeight}
              hideColumnHeader={mode === 'view' && columnDef.length === 1}
              components={{
                NoResultsOverlay: EmptyCampaign,
              }}
              componentsProps={{
                noResultsOverlay: {
                  title: 'No search results found',
                  subtitle:
                    filterTitle === 'Campaign Start Date' ||
                    filterTitle === 'Campaign End Date'
                      ? ''
                      : 'No keywords matched your search criteria. Try searching for another keyword',
                },
              }}
              overlay={overlay}
              classes={
                mode === 'view'
                  ? { root: sx({ borderRadius: 0, border: 0 }) }
                  : undefined
              }
            />
          </Col>
        </Row>
      </Box>
      {(filterKey === 'CampaignFilter' || filterKey === 'CampaignIdFilter') && (
        <DateRangeChangeDialog
          open={openDateRangeDialog}
          onClose={() => {
            setOpenDateRangeDialog(false)
            onClose && onClose()
          }}
          dateRange={campaignDateRange}
          dialogType="campaign"
        />
      )}
      {(filterKey === 'IoNameFilter' || filterKey === 'IoIdFilter') && (
        <DateRangeChangeDialog
          open={openDateRangeDialog}
          onClose={() => {
            setOpenDateRangeDialog(false)
            onClose && onClose()
          }}
          dateRange={ioDateRange}
          dialogType="io"
        />
      )}
    </>
  )

  const showEmptyScreen = React.useMemo(() => {
    if (dateRangeSearch) {
      return isCustomerDependent && !totalDateRangeDataAvailable && !loading
    }
    return isCustomerDependent && !search?.length && !data.length && !loading
  }, [
    dateRangeSearch,
    isCustomerDependent,
    totalDateRangeDataAvailable,
    loading,
    search,
    data,
  ])

  return (
    <>
      {mode === 'edit' ? (
        <>
          <DialogTitle
            onClose={onClose}
          >{`Filter ${filterTitle} For Report`}</DialogTitle>
          <DialogContent dividers>
            {showEmptyScreen
              ? EmptyCampaign({
                  title: emptyfilterMessage?.title,
                  subtitle: emptyfilterMessage?.subText,
                })
              : dialogContent()}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="secondary">
              Cancel
            </Button>
            <Button
              disabled={
                !data.length || isDeepEqual(intialRowSelection, rowSelection)
              }
              onClick={() => {
                typeof onFilter === 'function' &&
                  onFilter({
                    search: search!,
                    sorting: sorting!,
                    rowSelection: rowSelection,
                  })
                if (
                  (filterKey === 'CampaignFilter' ||
                    filterKey === 'CampaignIdFilter' ||
                    filterKey === 'IoNameFilter' ||
                    filterKey === 'IoIdFilter') &&
                  selectedItemsCount > 0
                ) {
                  const filterDialog = document.getElementById(
                    'create_filter_dialog'
                  )
                  if (filterDialog) {
                    filterDialog.style.visibility = 'hidden'
                  }
                  setOpenDateRangeDialog(true)
                } else {
                  onClose && onClose()
                }
              }}
            >
              Filter
            </Button>
          </DialogActions>
        </>
      ) : (
        dialogContent()
      )}
    </>
  )
}
