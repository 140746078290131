import { ColumnDef, ReactTableCellContext } from '@applift/datagrid'
import { CampaignFilterType } from '../../../models/Filters'
import { TextCell, TextCellWithTooltip } from '../cellTypes'

export const campaignIdFilterColDef: ColumnDef<CampaignFilterType>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    maxSize: 150,
    size: 100,
    minSize: 50,
    enableSorting: false,
    cell: (props: ReactTableCellContext) => {
      return <TextCell label={props.renderValue()} />
    },
  },
  {
    accessorKey: 'name',
    header: 'Campaign Name',
    size: 500,
    minSize: 150,
    enableSorting: false,
    cell: (props: ReactTableCellContext) => {
      return (
        <TextCellWithTooltip label={props.renderValue()} truncation="middle" />
      )
    },
  },
]
