import { ColumnDef, ReactTableCellContext } from '@applift/datagrid'
import { CampaignGroupFilterType } from 'packages/report-builder/src/models/Filters'
import { TextCell } from '../cellTypes'

export const campaignGroupIdFilterColDef: ColumnDef<CampaignGroupFilterType>[] =
  [
    {
      accessorKey: 'groupId',
      header: 'Campaign Group ID',
      enableSorting: false,
      size: 200,
      cell: (props: ReactTableCellContext) => {
        return <TextCell label={props.renderValue()} />
      },
    },
    {
      accessorKey: 'groupName',
      header: 'Campaign Group Name',
      enableSorting: false,
      size: 300,
      cell: (props: ReactTableCellContext) => {
        return <TextCell label={props.renderValue()} />
      },
    },
  ]
