import {
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  LoadingButton,
} from '@applift/factor'
import { ReportSchedulingEventDetailsType } from '../../models/Schedule'

interface DeleteDialogData {
  reportName?: string
  reportSchedulingEventDetails?: ReportSchedulingEventDetailsType
}

interface DeleteReportProps {
  onClose: (params?: any) => void
  deleteAction: (params?: any) => void
  isLoading: boolean
  data: DeleteDialogData
}

export const DeleteReportDialog = (props: DeleteReportProps) => {
  const { onClose, data: rowData, deleteAction, isLoading } = props

  return (
    <>
      <DialogTitle>Delete Report</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete the report{' '}
          <strong>{rowData?.reportName}?</strong>{' '}
          {rowData.reportSchedulingEventDetails ? (
            <span>
              Deleting this report will also stop the scheduled email delivery.
            </span>
          ) : null}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          disabled={isLoading}
          onClick={deleteAction}
          loading={isLoading}
          variant="contained"
          color="error"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </>
  )
}
