import { DataGrid } from '@applift/datagrid'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@applift/factor'
import { useRouteContext } from '@tanstack/react-router'
import { ListType } from '../../models/List'
import { viewCustomerColDef } from './colDef'
interface ViewCustomersProps {
  onClose: (params: any) => void
  data: ListType
}

export const ViewCustomersDialog = (props: ViewCustomersProps) => {
  const { onClose, data: rowData } = props
  const customerList =
    useRouteContext({ from: '__root__' })._root.customerList ?? {}

  const filterCustomerList = Object.values(customerList).filter(item =>
    rowData.customerIds.includes(Number(item.value))
  )

  return (
    <>
      <DialogTitle onClose={onClose}>View Customers</DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 100,
            gap: 16,
          }}
        >
          <Typography>
            The customers for which the report is being created
          </Typography>

          <DataGrid
            data={filterCustomerList || []}
            columns={viewCustomerColDef}
            getRowId={(row: any) => {
              return row.owId
            }}
            hideHeader
            hideFooter
            density="compact"
            showCellRightBorder
            showColumnRightBorder
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </>
  )
}
