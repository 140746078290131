import { SortingState } from '@applift/datagrid'
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import * as ExecuteApi from '../api/execute'
import { getExecuteReportQueryKey } from '../api/queryKey'
import { defaultExecuteReportSorting } from '../constants'
import { ExecuteReportResponseType } from '../models/Report'
import { useStore } from '../store'
import { createExecutionPayload } from '../utils/createExecutePayload'
import { sortingString } from '../utils/sorting'

export const useExecuteReport = (
  options?: {
    enabled?: UseInfiniteQueryOptions<ExecuteReportResponseType>['enabled']
    onSuccess?: UseInfiniteQueryOptions<ExecuteReportResponseType>['onSuccess']
    onSettled?: UseInfiniteQueryOptions<ExecuteReportResponseType>['onSettled']
    onError?: UseInfiniteQueryOptions<ExecuteReportResponseType>['onError']
  },
  sorting: SortingState = defaultExecuteReportSorting
) => {
  const executePayload = useStore(state => state.executeTable.payload)
  const payload = createExecutionPayload(executePayload, true)

  const queryData = useInfiniteQuery(
    getExecuteReportQueryKey.keys(
      'executeReport',
      payload,
      sortingString(sorting).sortBy
    ),
    ExecuteApi.executeReport,
    {
      keepPreviousData: true,
      enabled: options?.enabled || !!Object.keys(payload).length,
      onSuccess: options?.onSuccess,
      onError: options?.onError,
      getNextPageParam: (lastPage, pages) => {
        const totalRecordsFetched = pages.reduce(
          (prev, one) => prev + one!.data?.length,
          0
        )
        if (totalRecordsFetched < lastPage!.filteredRecords) {
          return pages.length + 1
        }
        return false
      },
      onSettled: options?.onSettled,
    }
  )
  return queryData
}
