import {
  DimensionAndMetricsApiType,
  DimensionAndMetricsType,
  ExtendDimension,
  ExtendMetrics,
  TransformedDimensionAndMetricsType,
} from '../models/DimensionMetrics'

export const transformDimensionAndMetrics = (
  data: DimensionAndMetricsApiType
): TransformedDimensionAndMetricsType => {
  const dimensions: DimensionAndMetricsType['dimensions'] =
    data.dimensions.reduce((prev, one) => {
      const result = Object.entries(one)
      // @ts-ignore
      prev[result[0][0]] = result[0][1]
      return prev
    }, {} as any)

  const metrics: DimensionAndMetricsType['metrics'] = data.metrics.reduce(
    (prev, one) => {
      const result = Object.entries(one)
      // @ts-ignore
      prev[result[0][0]] = result[0][1]
      return prev
    },
    {} as any
  )

  // const allDimensions: ExtendDimension[] = []
  // const allMetrics: ExtendMetrics[] = []

  const idWiseDimensions: { [key: number]: ExtendDimension } = {}
  const idWiseMertics: { [key: number]: ExtendMetrics } = {}
  const keyWiseDimensions: { [key: string]: ExtendDimension } = {}
  const keyWiseMetrics: { [key: string]: ExtendMetrics } = {}
  const defaultMetrics: ExtendMetrics[] = []

  for (const key in dimensions) {
    const one = dimensions[key]
    if (one && Array.isArray(one)) {
      for (let itr = 0; itr < one?.length; itr++) {
        const dim = one[itr]!
        const extended = { ...dim, groupName: key }
        idWiseDimensions[dim.id] = extended
        keyWiseDimensions[dim.key] = extended
      }
    }
  }
  for (const key in metrics) {
    const one = metrics[key]
    if (one && Array.isArray(one)) {
      for (let itr = 0; itr < one?.length; itr++) {
        const metric = one[itr]!
        const extended = { ...metric, groupName: key }
        idWiseMertics[metric.id] = extended
        keyWiseMetrics[metric.key] = extended
        if (metric.defaultEnabled) {
          defaultMetrics.push(extended)
        }
      }
    }
  }

  return {
    dimensions,
    metrics,
    idWiseDimensions,
    idWiseMertics,
    keyWiseDimensions,
    keyWiseMetrics,
    defaultMetrics,
  }
}
