import { ColumnDef } from '@applift/datagrid'
import { Delete } from '@applift/icons'
import { IconButton } from '@applift/factor'
import { CustomerCell } from './cellTypes/CustomerCell'
import { IconTextCell, TextCell } from '../../../modules/Filters/cellTypes'
import { NormalizedCustomerList } from '@applift/platform'

export const ACTION = {
  RUN_NOW: 'RUN_NOW',
  DELETE: 'DELETE',
  VIEW_CUSTOMER: 'VIEW_CUSTOMER',
  VIEW_DIMENSION: 'VIEW_DIMENSION',
  VIEW_REPORT: 'VIEW_REPORT',
  ADD_SCHEDULE: 'ADD_SCHEDULE',
  EDIT_SCHEDULE: 'EDIT_SCHEDULE',
}

export const customerFilterColDef: ColumnDef<NormalizedCustomerList>[] = [
  {
    accessorKey: 'value',
    header: 'ID',
    enableSorting: false,
    size: 100,
    cell: props => <TextCell label={props.renderValue() as string} />,
  },
  {
    accessorKey: 'label',
    header: 'Customer Name',
    enableSorting: false,
    size: 500,
    cell: props => {
      return <CustomerCell rowData={props.row.original} />
    },
  },
  {
    accessorKey: 'status',
    header: 'Status',
    enableSorting: false,
    size: 150,
    cell: props => {
      return (
        <IconTextCell
          iconName={(props.renderValue() as string) || 'Error'}
          iconSize={16}
          label={props.renderValue() as string}
        />
      )
    },
  },
  {
    accessorKey: 'activeCampaignsCount',
    header: 'Running Campaigns',
    enableSorting: false,
    size: 160,
    cell: props => <TextCell label={props.renderValue() as string} />,
  },
  {
    accessorKey: 'ownerUserEmail',
    header: 'Email',
    enableSorting: false,
    size: 350,
    cell: props => <TextCell label={props.renderValue() as string} />,
  },
  {
    header: 'Actions',
    enableSorting: false,
    size: 70,
    cell: ({ onAction, row }) => (
      <IconButton
        onClick={() =>
          onAction?.({ actionName: ACTION.DELETE, metaData: row.original })
        }
        color="secondary"
      >
        <Delete />
      </IconButton>
    ),
  },
]
