import { TypoTooltip } from '@applift/factor'

interface RawDataCellProps {
  value: string
  isFooterCell?: boolean
}

export const RawDataCell = ({ value, isFooterCell }: RawDataCellProps) => {
  return value != null ? (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        gutterBottom: false,
        weight: isFooterCell ? 'demi' : 'normal',
      }}
    >
      {value}
    </TypoTooltip>
  ) : (
    <>—</>
  )
}
