import { ColumnDef, ReactTableCellContext } from '@applift/datagrid'
import { CampaignFilterType } from '../../../models/Filters'
import { IconTextCell, TextCell, TextCellWithTooltip } from '../cellTypes'
import { creativeTypeIdMapper } from '../../../models/Creative'

export const campaignFilterColDef: ColumnDef<CampaignFilterType>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    maxSize: 150,
    size: 100,
    minSize: 50,
    enableSorting: false,
    cell: (props: ReactTableCellContext) => {
      return <TextCell label={props.renderValue()} />
    },
  },
  {
    accessorKey: 'name',
    header: 'Campaign Name',
    size: 385,
    minSize: 150,
    enableSorting: false,
    cell: (props: ReactTableCellContext) => {
      return (
        <TextCellWithTooltip label={props.renderValue()} truncation="middle" />
      )
    },
  },
  {
    accessorKey: 'status',
    header: 'Current Status',
    enableSorting: false,
    cell: props => {
      return (
        <IconTextCell
          iconName={(props.renderValue() as string) || 'Error'}
          iconSize={16}
          label={props.renderValue() as string}
        />
      )
    },
  },
  {
    accessorKey: 'creativeTypeId',
    header: 'Type',
    enableSorting: false,
    size: 100,
    cell: props => {
      return props.renderValue() !== 0 ? (
        <IconTextCell
          //@ts-ignored
          iconName={creativeTypeIdMapper[props.renderValue()] || 'Error'}
          iconSize={24}
          //@ts-ignored
          label={creativeTypeIdMapper[props.renderValue()] as string}
        />
      ) : (
        <>—</>
      )
    },
  },
]
