import { ColumnDef } from '@applift/datagrid'
import {
  ExtendDimension,
  ExtendMetrics,
} from '../../../../models/DimensionMetrics'
import { ReportType } from '../../../../models/Report'
import { SkeletonCell } from './cellTypes/SkeletonCell'
import { executeReportColumnMap, ExecuteReportColumnMapType } from './colDef'
import { SelectedDimensionsAndMetricsSlice } from '../../../../models/Store'

// TODO: scroll to column added (upcoming feature)
export const generateMetaData = (
  dimensions: ExtendDimension[],
  metrics: ExtendMetrics[],
  hasAggregation: boolean,
  view: ReportType,
  dirtyCols: SelectedDimensionsAndMetricsSlice['selectedDimensionsAndMetricsSlice']['dirty']
): ColumnDef<any>[] => {
  const dimsCols = dimensions
    .filter((_, index) => (hasAggregation ? index !== 0 : true))
    .map(one => {
      const isDirty = Object.keys(dirtyCols.dimensions).includes(one.key)
      const columnParams =
        executeReportColumnMap[one.key as keyof ExecuteReportColumnMapType]
      if (isDirty) {
        return {
          ...columnParams,
          cell: () => <SkeletonCell />,
          footer: () => <SkeletonCell />,
          enableSorting: false,
        }
      }

      return {
        ...columnParams,
      }
    }) as ColumnDef<any>[]

  const metricsCols = metrics.map(one => {
    const isDirty = Object.keys(dirtyCols.metrics).includes(one.key)
    const columnParams =
      executeReportColumnMap[one.key as keyof ExecuteReportColumnMapType]
    if (isDirty) {
      return {
        ...columnParams,
        cell: () => <SkeletonCell />,
        footer: () => <SkeletonCell />,
        enableSorting: false,
      }
    }
    return {
      ...columnParams,
    }
  }) as ColumnDef<any>[]

  const primaryKey: ColumnDef<any> = {
    accessorKey: '_id',
    header: 'Primary Key',
    enableSorting: false,
    size: 70,
  }

  const dailyKey: ColumnDef<any> = {
    accessorKey: 'date',
    header: 'Date',
    enableSorting: true,
    size: 100,
    cell: props => props.getValue() ?? <SkeletonCell />,
  }

  return [
    primaryKey,
    ...(view === 'Daily' ? [dailyKey] : []),
    ...dimsCols,
    ...metricsCols,
  ]
}
