import * as React from 'react'
import { useMutation } from '@tanstack/react-query'
import { enqueueSnackbar } from '@applift/factor'

import { ListType } from '../../models/List'
import { ScheduleReportDialog } from './ScheduleReportDialog'
import { ActionNameType } from '../../modules/listGrid/action'
import {
  staticDeliverySchedule,
  staticWeeks,
  staticDays,
} from '../../api/schedule'
import {
  ReportSchedulingEventDetailsType,
  ScheduleResponseData,
} from '../../models/Schedule'
import * as ScheduleAPI from '../../api/schedule'
import { queryClient } from '../../cache'
import { WithResponse } from '../../models/Response'

interface ScheduleReportProps {
  onClose: (params?: any) => void
  data: ListType
  action: ActionNameType
}

export const ListScheduleReportDialogWrapper = (props: ScheduleReportProps) => {
  const { onClose, data: rowData, action } = props

  const initialDeliverySchedule =
    rowData?.reportSchedulingEventDetails?.deliveryFrequency

  let initialWeekDay: string | undefined = undefined
  let initialMonthDay: string | undefined = undefined

  if (initialDeliverySchedule === 3) {
    initialWeekDay = rowData?.reportSchedulingEventDetails?.deliveryDay
  } else if (initialDeliverySchedule === 4) {
    initialMonthDay = rowData?.reportSchedulingEventDetails?.deliveryDay
  }

  const showRunningTotal = React.useMemo(() => {
    const dimensions = JSON.parse(rowData.dimensions)
    const haveCampaignDimension =
      dimensions.filter(
        (dimension: { filter: string }) => dimension.filter === 'campaign'
      ).length > 0
    return dimensions.length === 1 && haveCampaignDimension
  }, [rowData])

  const addEmailScheduleMutation = useMutation(ScheduleAPI.addEmailSchedule, {
    mutationKey: ['AddEmailSchedule'],
    onSuccess: (response, payload) => {
      queryClient.setQueriesData<WithResponse<ScheduleResponseData>>(
        {
          // @ts-ignore
          predicate: query => query.queryKey[0].scope === 'ReportList',
        },
        (pages: any) => {
          const newPages = pages?.pages?.map((page: any) => {
            const newData = page.data?.map((one: any) => {
              if (one.id === payload.reportId) {
                return {
                  ...one,
                  ...(payload.deliveryFrequency !== 1
                    ? {
                        reportSchedulingEventDetails:
                          response?.data?.scheduledData,
                      }
                    : {}),
                }
              }
              return one
            })
            return {
              ...page,
              data: newData,
            }
          })
          return {
            ...pages,
            pages: newPages,
          }
        }
      )
      enqueueSnackbar('Report schedule has been successfully updated.', {
        variant: 'success',
      })
      onClose()
    },
    onError: () => {
      enqueueSnackbar('Oops! Something went wrong. Please try again.', {
        variant: 'error',
      })
    },
  })

  const updateEmailScheduleMutation = useMutation(
    ScheduleAPI.updateEmailSchedule,
    {
      mutationKey: ['UpdateEmailSchedule'],
      onSuccess: (response, payload) => {
        queryClient.setQueriesData<WithResponse<ScheduleResponseData>>(
          {
            // @ts-ignore
            predicate: query => query.queryKey[0].scope === 'ReportList',
          },
          (pages: any) => {
            const newPages = pages?.pages?.map((page: any) => {
              const newData = page.data?.map((one: any) => {
                if (one.id === payload.reportId) {
                  if (payload.deliveryFrequency === 1) {
                    const newObj = Object.assign({}, one)
                    delete newObj.reportSchedulingEventDetails
                    return newObj
                  }
                  return {
                    ...one,
                    ...{
                      reportSchedulingEventDetails:
                        response?.data?.scheduledData,
                    },
                  }
                }
                return one
              })
              return {
                ...page,
                data: newData,
              }
            })
            return {
              ...pages,
              pages: newPages,
            }
          }
        )
        enqueueSnackbar('Report schedule has been successfully updated', {
          variant: 'success',
        })
        onClose()
      },
      onError: () => {
        enqueueSnackbar('Oops! Something went wrong. Please try again.', {
          variant: 'error',
        })
      },
    }
  )

  const onSchedule = (data: ReportSchedulingEventDetailsType) => {
    const _data = {
      ...data,
      reportId: rowData.id,
      ...(showRunningTotal
        ? { earliestCampaignStartDate: rowData.startDate }
        : null),
    }

    if (action === 'ADD_SCHEDULE') {
      addEmailScheduleMutation.mutate(_data)
    } else if (action === 'EDIT_SCHEDULE') {
      updateEmailScheduleMutation.mutate(_data)
    }
  }

  const stopSchedulingMutation = useMutation(ScheduleAPI.stopScheduling, {
    mutationKey: ['DeleteReport'],
    onSuccess: (response, id) => {
      enqueueSnackbar('Report schedule has been successfully stopped.', {
        variant: 'success',
      })
      queryClient.setQueriesData<WithResponse<ScheduleResponseData>>(
        {
          // @ts-ignore
          predicate: query => query.queryKey[0].scope === 'ReportList',
        },
        (pages: any) => {
          const newPages = pages?.pages?.map((page: any) => {
            const newData = page.data?.map((one: any) => {
              if (one.id === id) {
                const newObj = Object.assign({}, one)
                delete newObj.reportSchedulingEventDetails
                return newObj
              }
              return one
            })
            return {
              ...page,
              data: newData,
            }
          })
          return {
            ...pages,
            pages: newPages,
          }
        }
      )
    },
    onError: () => {
      enqueueSnackbar('Oops! Something went wrong. Please try again.', {
        variant: 'error',
      })
    },
    onSettled: () => {
      onClose()
    },
  })

  const onStopScheduling = () => {
    stopSchedulingMutation.mutate(rowData.id)
  }

  return (
    <ScheduleReportDialog
      reportName={rowData.reportName}
      timezoneId={rowData.timezoneId}
      onClose={onClose}
      initialRunningTotal={
        rowData?.reportSchedulingEventDetails?.runningTotalEnabled
      }
      initialReceptiantEmails={
        rowData?.reportSchedulingEventDetails?.subscriberEmails
      }
      initialDeliverySchedule={
        staticDeliverySchedule.filter(
          deliverySchedule => deliverySchedule.value === initialDeliverySchedule
        )[0]
      }
      initialEndDate={rowData?.reportSchedulingEventDetails?.eventEndDate}
      initialDocFormat={rowData?.reportSchedulingEventDetails?.fileType}
      initialWeekDay={
        staticWeeks.filter(day => day.value === initialWeekDay)[0]
      }
      initialMonthDay={
        staticDays.filter(day => day.value === initialMonthDay)[0]
      }
      showRunningTotal={showRunningTotal}
      onSchedule={onSchedule}
      onStopSchedule={onStopScheduling}
      mode={action === 'ADD_SCHEDULE' ? 'new' : 'edit'}
      isLoading={
        addEmailScheduleMutation.isLoading ||
        updateEmailScheduleMutation.isLoading ||
        stopSchedulingMutation.isLoading
      }
    />
  )
}
