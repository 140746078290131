import { TruncateMiddle } from '@applift/factor'

interface CampaignCellType {
  campaign: string
}

export const CampaignCell = ({ campaign }: CampaignCellType) => {
  return campaign != null ? (
    <TruncateMiddle title={campaign} placement="top" arrow>
      {campaign}
    </TruncateMiddle>
  ) : (
    <>—</>
  )
}
