import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@applift/factor'

export interface ResetAlertDialogProps {
  open: boolean
  onClose: (params?: any) => any
  onReset: (params?: any) => any
}

export const ResetAlertDialog = (props: ResetAlertDialogProps) => {
  const { open, onClose, onReset } = props

  return (
    <Dialog open={open} maxWidth={'xs'}>
      <DialogTitle>Reset Report Parameters</DialogTitle>
      <DialogContent>
        <Typography>
          Editing customers will reset the applied dimension filters. Are you
          sure you want to reset & edit the customer list?
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onReset}>Reset</Button>
      </DialogActions>
    </Dialog>
  )
}
