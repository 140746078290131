import {
  DataGrid,
  DataGridProps,
  GridActionCellParams,
} from '@applift/datagrid'
import { customerFilterColDef } from './colDef'
import { NormalizedCustomerList } from '@applift/platform'

export interface SelectedCustomerGridType
  extends Pick<DataGridProps, 'loading'> {
  data: NormalizedCustomerList[]
  pageSize: number
  onDeleteRow: (owId: string) => void
}

export const SelectedCustomerGrid = (props: SelectedCustomerGridType) => {
  const { data, loading, onDeleteRow, pageSize } = props

  const onAction = (meta: GridActionCellParams) => {
    if (meta.actionName === 'DELETE') {
      onDeleteRow(meta.metaData.value)
    }
  }

  return (
    <DataGrid
      data={data || []}
      columns={customerFilterColDef}
      getRowId={(row: NormalizedCustomerList) => `${row.value}`}
      hideHeader
      hideFooter
      density="compact"
      loading={loading}
      showColumnRightBorder
      showCellRightBorder
      disableRowSelectionOnClick
      onAction={onAction}
      pageSize={pageSize}
    />
  )
}
