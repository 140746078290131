import { ColumnDef, ReactTableCellContext } from '@applift/datagrid'
import { CreativeFilterType } from 'packages/report-builder/src/models/Filters'
import { TextCell } from '../cellTypes'

export const creativeIdFilterColDef: ColumnDef<CreativeFilterType>[] = [
  {
    accessorKey: 'creativeId',
    header: 'ID',
    enableSorting: false,
    size: 100,
    cell: (props: ReactTableCellContext) => {
      return <TextCell label={props.renderValue()} />
    },
  },
  {
    accessorKey: 'creativeName',
    header: 'Creative Name',
    size: 500,
    enableSorting: false,
    cell: (props: ReactTableCellContext) => {
      return <TextCell label={props.renderValue()} />
    },
  },
]
