import { useMemo } from 'react'
import {
  Typography,
  Box,
  IconButton,
  Tooltip,
  TypoTooltip,
} from '@applift/factor'
import { GridActionCellParams } from '@applift/datagrid'
import { ACTION } from '../action'
import { ListType } from '../../../models/List'
import { EmailAdd, Edit } from '@applift/icons'
import styles from './index.module.scss'

interface EmailCellType {
  action?: (args: GridActionCellParams) => any
  rowData: ListType
  cellData: ListType['reportSchedulingEventDetails']
}

export const EmailCell = ({ action, rowData, cellData }: EmailCellType) => {
  const emailText = useMemo(() => {
    const count = (cellData?.subscriberEmails?.length ?? 0) - 1
    return cellData?.subscriberEmails?.length ?? 0 ? (
      <>
        <TypoTooltip
          arrow
          placement="top"
          TypgraphyProps={{ gutterBottom: false }}
        >
          {cellData?.subscriberEmails[0]}
        </TypoTooltip>
        {count ? (
          <Typography gutterBottom={false} sx={{ ml: 4 }}>
            {addCount(count)}
          </Typography>
        ) : (
          ''
        )}
      </>
    ) : (
      ''
    )
  }, [cellData?.subscriberEmails])

  return (
    <>
      {emailText ? (
        <Box sx={{ display: 'flex', alignItems: 'center', width: 100 }}>
          {emailText}
          <Tooltip title="Edit Email Delivery Schedule" placement="top" arrow>
            <IconButton
              sx={{ ml: 4 }}
              color="secondary"
              className={styles.onHoverActive}
              onClick={() =>
                action?.({
                  actionName: ACTION.EDIT_SCHEDULE,
                  metaData: rowData,
                })
              }
            >
              <Edit />
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        <Tooltip
          title="Schedule one-time or recurring report delivery over the email"
          placement="top"
          arrow
        >
          <IconButton
            color="secondary"
            onClick={() =>
              action?.({ actionName: ACTION.ADD_SCHEDULE, metaData: rowData })
            }
          >
            <EmailAdd />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}

const addCount = (count: number) => {
  if (count > 0) {
    return `+ ${count}`
  }
  return ''
}
