import { AxiosError, AxiosResponse } from 'axios'
import { WithResponse } from '../models/Response'
import { getInstance } from './instance'

export const duplicateReport = async (data: {
  id: number
  reportName?: string
}) => {
  try {
    const response: AxiosResponse<WithResponse<string>> =
      await getInstance().post(`/v3/ra/report/duplicate`, data)
    return response.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}
