// we want to cache logged in user so that when page is not refreshed but another tab changes localstorage we still have our copy of logged in user type

let loggedInUser = ''

export const getAccountType = () => {
  if (loggedInUser) {
    return loggedInUser
  }
  const user = JSON.parse(localStorage.getItem('authorizedUser') ?? '{}')
  if (user.isPlatformOwnerOrg) {
    loggedInUser = 'PlatformOwnerOrg'
    return loggedInUser // for super account
  }
  if (user.isWorkspaceOwnerOrg && !user.isPlatformOwnerOrg) {
    loggedInUser = 'WorkspaceOwnerOrg'
    return loggedInUser
  }
  loggedInUser = 'Advertiser'
  return loggedInUser
}

export const AccountTypes = {
  SUPER: 'SUPER',
  WORKSPACE: 'WORKSPACE',
  ADVERTISER: 'ADVERTISER',
}
const AccountTypeMapping = {
  PlatformOwnerOrg: 'SUPER',
  WorkspaceOwnerOrg: 'WORKSPACE',
  Advertiser: 'ADVERTISER',
} as const

type AccountTypeMapping = keyof typeof AccountTypeMapping

export type AccountType = keyof typeof AccountTypes

export const getLoggedInUserType = (): AccountType => {
  const accountType = getAccountType() as AccountTypeMapping
  return AccountTypeMapping[accountType]
}
