import axios, { AxiosError, AxiosResponse } from 'axios'
import { QueryFunctionContext } from '@tanstack/react-query'
import { WithRecordResponse, WithResponse } from '../models/Response'
import { getInstance } from './instance'
import { ListType } from '../models/List'
import { ITimezone } from '../models/DateTime'

export const getReportQueryKey = {
  keys: (
    scope: string,
    searchField: string,
    customerIds: string,
    pageSize: number,
    sortBy?: string
  ) => [{ scope, searchField, customerIds, pageSize, sortBy } as const],
}

export const getReportList = async ({
  pageParam = 1,
  queryKey,
  signal,
  meta,
}: QueryFunctionContext<ReturnType<(typeof getReportQueryKey)['keys']>>) => {
  const { searchField, customerIds, pageSize, sortBy } = queryKey[0]!
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled')
  })
  try {
    const response: AxiosResponse<WithRecordResponse<ListType[]>> =
      await getInstance().post(
        `/v3/ra/reports/list`,
        {
          noOfEntries: pageSize,
          pageNo: pageParam,
          searchField,
          sortBy,
          customerIds: customerIds,
        },
        {
          cancelToken: source.token,
        }
      )
    if (
      meta?.timezoneList &&
      Array.isArray(meta.timezoneList) &&
      meta.timezoneList.length > 0
    ) {
      const modifiedData = response.data.data.data.map(list => ({
        ...list,
        //@ts-ignore
        timezoneName: meta?.timezoneList?.filter(
          (val: ITimezone) => val.id === list.timezoneId
        )[0].name,
      }))
      return { ...response.data.data, data: modifiedData }
    }
    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response)
  }
}

export const deleteReport = async (id: number) => {
  try {
    const response: AxiosResponse<WithResponse<string>> =
      await getInstance().delete(`/v3/ra/report/${id}`)
    return response.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}
