import { DuplicateReportDialog } from './DuplicateReportDialog'
import { ActionDetails } from '../../../listGrid/action'
import { EditScheduleReportDialogWrapper } from '../../../../components/ScheduleReportDialog'

export const EDIT_HEADER_ACTION = {
  DUPLICATE: 'DUPLICATE',
  EMAIL_SCHEDULE: 'EMAIL_SCHEDULE',
}

export type EditHeaderActionNameType = keyof typeof EDIT_HEADER_ACTION

export type HeaderAction = {
  [key in EditHeaderActionNameType]: ActionDetails
}

export const HeaderActionMapper: HeaderAction = {
  DUPLICATE: {
    dialogProps: { maxWidth: 'sm', PaperProps: { sx: { boxShadow: 500 } } },
    component: DuplicateReportDialog,
  },
  EMAIL_SCHEDULE: {
    dialogProps: {
      maxWidth: 'md',
      PaperProps: { sx: { boxShadow: 500, width: 100 } },
    },
    component: EditScheduleReportDialogWrapper,
  },
}
