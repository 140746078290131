import { ColumnDef } from '@applift/datagrid'
import { ListType } from '../../models/List'
import { CustomerCell } from './cellTypes/CustomerCell'
import { DateRangeCellMaker } from './cellTypes/DateRangeCell'
import { DimensionCell } from './cellTypes/DimensionCell'
import { EmailCell } from './cellTypes/EmailCell'
import { IdCell } from './cellTypes/IdCell'
import { ListActionCell } from './cellTypes/ListActionCell'
import { ReportNameCell } from './cellTypes/ReportNameCell'

export const colDef: ColumnDef<ListType>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    cell: props => {
      return (
        <IdCell
          action={props.onAction}
          rowData={props.row.original}
          cellData={props.renderValue() as any}
        />
      )
    },
    size: 100,
  },
  {
    accessorKey: 'reportName',
    header: 'Report Name',
    id: 'name',
    cell: props => {
      return <ReportNameCell cellData={props.renderValue() as any} />
    },
    size: 300,
  },
  {
    id: 'startDate',
    header: 'Date Range',
    accessorFn: row => ({
      startDate: row.startDate,
      endDate: row.endDate,
    }),
    cell: props => {
      const value = props.renderValue()
      const DateRange = DateRangeCellMaker('en-US', {
        timeZone: props.row.original.timezoneName,
      })
      return DateRange ? (
        // @ts-ignore
        <DateRange startDate={value.startDate} endDate={value.endDate} />
      ) : null
    },
    size: 200,
  },
  {
    id: 'Customer',
    accessorFn: row => ({
      // customerDetails not returned by api
      customerDetails: row.customerDetails,
      customerIds: row.customerIds,
    }),
    cell: props => {
      return (
        <CustomerCell
          action={props.onAction}
          rowData={props.row.original}
          cellData={props.renderValue() as any}
        />
      )
    },
    enableSorting: false,
    size: 250,
  },
  {
    accessorKey: 'reportSchedulingEventDetails',
    header: 'Email Scheduled',
    cell: props => {
      return (
        <EmailCell
          action={props.onAction}
          rowData={props.row.original}
          cellData={props.renderValue() as any}
        />
      )
    },
    size: 250,
    enableSorting: false,
  },
  {
    accessorKey: 'dimensions',
    header: 'Dimensions',
    cell: props => {
      return (
        <DimensionCell
          action={props.onAction}
          rowData={props.row?.original}
          cellData={props.renderValue() as any}
        />
      )
    },
    size: 150,
    enableSorting: false,
  },
  {
    accessorKey: 'Actions',
    cell: props => {
      return (
        <ListActionCell action={props.onAction} rowData={props.row?.original} />
      )
    },
    size: 180,
    enableSorting: false,
  },
]
