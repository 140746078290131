import { ColumnDef, ReactTableCellContext } from '@applift/datagrid'
import { IDValueFilterType } from '../../../models/Filters'
import { TextCell } from '../cellTypes'

export const osFilterColDef: ColumnDef<IDValueFilterType>[] = [
  {
    accessorKey: 'name',
    header: 'OS',
    enableSorting: false,
    size: 300,
    cell: (props: ReactTableCellContext) => {
      return <TextCell label={props.renderValue()} />
    },
  },
]
