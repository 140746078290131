import { Container, Box, Row, Col, Typography } from '@applift/factor'
import {
  CalendarSettings,
  InsightsDatabase,
  Omnichannel,
  DatabaseCheck,
} from '@applift/icons'
import { Analysis } from '@applift/illustrations'
import { EmptyFeturesReport } from './EmptyFeturesReport'

/**
 * EmptyReportAnalytics - this add the create report's empty card while user has not created report yet.
 *
 */
export const EmptyReportAnalytics = () => {
  const fetures = [
    {
      icon: InsightsDatabase,
      title: 'Data Driven Reporting',
      desciption:
        'Unlock the power of data. Evolve future strategies with unaggregated data that demystifies your marketing campaign results.',
    },
    {
      icon: Omnichannel,
      title: 'Omni Channel Reporting',
      desciption:
        'Analyze your marketing campaign with all possible dimensions and metrics.',
    },
    {
      icon: DatabaseCheck,
      title: 'Supply Path Transparency',
      desciption:
        'Take full control of your campaign reporting with fully transparent and understandable data',
    },
    {
      icon: CalendarSettings,
      title: 'Customizable and Flexible',
      desciption:
        'Advertising campaign reporting options that you can completely customize and schedule',
    },
  ]
  return (
    <Container
      sx={{
        height: 100,
        py: 16,
        bgColor: 'neutral-0',
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          height: 100,
        }}
      >
        <Row sx={{ alignItems: 'center', px: 64, pb: 16, mb: 64 }}>
          <Col xs={12} sm={4}>
            <Box sx={{ textColor: 'primary-500' }}>
              <Analysis sx={{ width: 100, height: 'auto' }} />
            </Box>
          </Col>
          <Col
            xs={12}
            sm={8}
            sx={{ display: 'flex', flexDirection: 'column', gap: 16 }}
          >
            <Typography
              component="h1"
              variant="h5"
              weight="demi"
              gutterBottom={false}
            >
              Reporting & Analytics
            </Typography>
            <Typography
              component="p"
              variant="bodyLarge"
              lineHeight="multi-line"
              weight="normal"
              gutterBottom={false}
            >
              Find, track and share the most important metrics of your campaign.
              Deep dive into reach, site transparency, geo performance,
              audiences, contextual insights and much more.
            </Typography>
            <Typography
              component="p"
              variant="bodyLarge"
              lineHeight="multi-line"
              weight="normal"
              gutterBottom={false}
            >
              No more guesswork on performance. See what's working, what's not,
              and why not.
            </Typography>
          </Col>
        </Row>
        <Row sm={4} sx={{ width: 100 }}>
          {fetures.map(feture => (
            <Col key={feture.title}>
              <EmptyFeturesReport
                icon={feture.icon}
                title={feture.title}
                desciption={feture.desciption}
              />
            </Col>
          ))}
        </Row>
      </Box>
    </Container>
  )
}
