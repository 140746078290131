import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@applift/factor'
import { SpecialRuleType } from '../../../models/DimensionMetrics'

export interface RemoveAggragationDialogProps {
  dimensionMetricInfo?: SpecialRuleType['info']
  onClose?: (params?: any) => any
  onReset?: () => void
}

export const RemoveAggragationDialog = (
  props: RemoveAggragationDialogProps
) => {
  const { onClose, dimensionMetricInfo, onReset } = props

  return (
    <>
      <DialogTitle>Remove Dimension?</DialogTitle>
      <DialogContent>
        <Typography>
          Removing <b>{dimensionMetricInfo?.dimensions?.dimensionName}</b> will
          remove the report aggregation. Are you sure you want to remove the
          dimension?
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="error" onClick={onReset}>
          Remove
        </Button>
      </DialogActions>
    </>
  )
}
