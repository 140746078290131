import { Dialog } from '@applift/factor'

import { HeaderActionMapper, EditHeaderActionNameType } from './HeaderAction'

interface HeaderActionBar {
  action: EditHeaderActionNameType | null
  onClose: () => void
}

const EmptyComponent = () => {
  return null
}

// eslint-disable-next-line
export const HeaderActionBar = (props: HeaderActionBar) => {
  const { action, onClose } = props
  const currentAction = action ? HeaderActionMapper[action!] : null
  const Component = currentAction?.component || EmptyComponent

  return (
    <Dialog open={Boolean(currentAction)} {...currentAction?.dialogProps}>
      <Component onClose={onClose} />
    </Dialog>
  )
}
