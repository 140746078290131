import { FilterMetaData } from '../../models/filterMetaData'
import {
  getV2FilterFn,
  getV3FilterFn,
  getV3FilterGetFn,
  getCampaignFilters,
  getCampaignGroupFilters,
  getCreativeTypeFilter,
  getExchangeFilter,
  getSiteAndAppFilter,
  getDayFilter,
  getHoursFilter,
  getCustomerFilters,
  getCampaignDateRangeFilters,
  getCreativeGroupFilters,
  getCountyFilter,
  getChannelFilter,
  getIoFilters,
  getIODateRangeFilters,
  getDealTypeFilter,
  getCampaginTypeFilter,
  getDealFilter,
  getDealIDFilter,
  getCreativeFilter,
} from '../../api/filters'
import {
  getNextPageParma,
  transformData,
  getTotalRecords,
  transformDataWithKeyName,
  getTotalRecordsTemp,
  transformDataWithKey,
  getNextPageParmaWithKey,
} from './CreateFilterWrapper'
import { AccountTypes, AccountType } from '../../utils'
import * as Schema from './schema'

export const getFilterMetaData = (organizationType: AccountType) => {
  const filterMetaData: FilterMetaData = {
    AgeGroupFilter: {
      title: 'Age Group',
      filterFn: getV2FilterFn('/v2/master/segment/age'),
      getNextPageParma: getNextPageParma,
      transformData: transformData,
      getTotalRecords: getTotalRecords,
      fetchAllPages: true,
      columnDef: Schema.ageGroupFilterColDef,
    },
    IoNameFilter: {
      title: 'Insertion Order',
      filterFn: getIoFilters(),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecordsTemp,
      transformData: transformDataWithKeyName('ioBasicDetailsList'),
      fetchAllPages: true,
      columnDef: Schema.ioNameFilterColDef,
      pageSize: 30,
      initialSortingState: [{ id: 'ioId', desc: true }],
      rowIdKey: 'ioId',
      dialogWidth: 'lg',
      emptyfilterMessage: {
        title: 'No insertion orders found',
        subText: `${
          organizationType !== AccountTypes.ADVERTISER
            ? 'The selected customers'
            : 'You'
        } haven’t created any insertion orders yet. The insertion orders will appear here once they are created.`,
      },
    },
    IoIdFilter: {
      title: 'Insertion Order ID',
      filterFn: getIoFilters(),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecordsTemp,
      transformData: transformDataWithKeyName('ioBasicDetailsList'),
      fetchAllPages: true,
      columnDef: Schema.ioIdFilter,
      pageSize: 30,
      initialSortingState: [{ id: 'ioId', desc: true }],
      rowIdKey: 'ioId',
      dialogWidth: 'lg',
      emptyfilterMessage: {
        title: 'No insertion order ids found',
        subText: `${
          organizationType !== AccountTypes.ADVERTISER
            ? 'The selected customers'
            : 'You'
        } haven’t created any insertion orders yet. The insertion orders will appear here once they are created.`,
      },
    },
    IoStartDateFilter: {
      title: 'Insertion Order Start Date',
      filterFn: getIODateRangeFilters(`/v3/cmp/io/startDate/list`),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.ioStartDateFilterColDef,
      pageSize: 30,
      initialSortingState: [{ id: 'ioDate', desc: true }],
      rowIdKey: 'ioDate',
      disableSearch: true,
      dateRangeSearch: true,
      emptyfilterMessage: {
        title: 'No insertion order start dates found',
        subText: `${
          organizationType !== AccountTypes.ADVERTISER
            ? 'The selected customers'
            : 'You'
        } haven’t created any insertion orders yet. The insertion orders start dates will appear here once they are created.`,
      },
    },
    IoEndDateFilter: {
      title: 'Insertion Order End Date',
      filterFn: getIODateRangeFilters(`/v3/cmp/io/endDate/list`),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.ioEndDateFilterColDef,
      pageSize: 30,
      initialSortingState: [{ id: 'ioDate', desc: true }],
      rowIdKey: 'ioDate',
      disableSearch: true,
      dateRangeSearch: true,
      emptyfilterMessage: {
        title: 'No insertion order end dates found',
        subText: `${
          organizationType !== AccountTypes.ADVERTISER
            ? 'The selected customers'
            : 'You'
        } haven’t created any insertion orders yet. The insertion orders start dates will appear here once they are created.`,
      },
    },
    CampaignFilter: {
      title: 'Campaign',
      filterFn: getCampaignFilters([
        // @ts-ignored
        ...Schema.campaignFilterColDef.map(obj => obj.accessorKey),
        ...['startTime', 'endTime'],
      ]),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.campaignFilterColDef,
      pageSize: 30,
      initialSortingState: [{ id: 'id', desc: true }],
      rowIdKey: 'id',
      dialogWidth: 'lg',
      emptyfilterMessage: {
        title: 'No campaigns found',
        subText: `${
          organizationType !== AccountTypes.ADVERTISER
            ? 'The selected customers'
            : 'You'
        } haven’t created any campaigns yet. The campaigns will appear here once they are created.`,
      },
    },
    CampaignIdFilter: {
      title: 'Campaign ID',
      filterFn: getCampaignFilters([
        // @ts-ignored
        ...Schema.campaignIdFilterColDef.map(obj => obj.accessorKey),
        ...['startTime', 'endTime'],
      ]),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.campaignIdFilterColDef,
      pageSize: 30,
      initialSortingState: [{ id: 'id', desc: true }],
      rowIdKey: 'id',
      emptyfilterMessage: {
        title: 'No campaign ids found',
        subText: `${
          organizationType !== AccountTypes.ADVERTISER
            ? 'The selected customers'
            : 'You'
        } haven’t created any campaigns yet. The campaign ids will appear here once they are created.`,
      },
    },
    CampaignGroupFilter: {
      title: 'Campaign Group',
      filterFn: getCampaignGroupFilters,
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.campaignGroupFilterColDef,
      pageSize: 10,
      initialSortingState: [{ id: 'id', desc: true }],
      rowIdKey: 'groupId',
      emptyfilterMessage: {
        title: 'No campaign groups found',
        subText: `${
          organizationType !== AccountTypes.ADVERTISER
            ? 'The selected customers'
            : 'You'
        } haven’t created any campaign groups yet. The campaign groups will appear here once they are created.`,
      },
    },
    CampaignGroupIdFilter: {
      title: 'Campaign Group ID',
      filterFn: getCampaignGroupFilters,
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.campaignGroupIdFilterColDef,
      pageSize: 10,
      initialSortingState: [{ id: 'id', desc: true }],
      rowIdKey: 'groupId',
      emptyfilterMessage: {
        title: 'No campaign group ids found',
        subText: `${
          organizationType !== AccountTypes.ADVERTISER
            ? 'The selected customers'
            : 'You'
        } haven’t created any campaign groups yet. The campaign group ids will appear here once they are created.`,
      },
    },
    CampaignStartDateFilter: {
      title: 'Campaign Start Date',
      filterFn: getCampaignDateRangeFilters(`/v3/cmp/startDate/list`),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.campaignStartDateFilterColDef,
      pageSize: 30,
      initialSortingState: [{ id: 'campaignDate', desc: true }],
      rowIdKey: 'campaignDate',
      disableSearch: true,
      dateRangeSearch: true,
      emptyfilterMessage: {
        title: 'No campaign start dates found',
        subText: `${
          organizationType !== AccountTypes.ADVERTISER
            ? 'The selected customers'
            : 'You'
        } haven’t created any campaigns yet. The campaign start dates will appear here once they are created.`,
      },
    },
    CampaignEndDateFilter: {
      title: 'Campaign End Date',
      filterFn: getCampaignDateRangeFilters(`/v3/cmp/endDate/list`),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.campaignEndDateFilterColDef,
      pageSize: 30,
      initialSortingState: [{ id: 'campaignDate', desc: true }],
      rowIdKey: 'campaignDate',
      disableSearch: true,
      dateRangeSearch: true,
      emptyfilterMessage: {
        title: 'No campaign end dates found',
        subText: `${
          organizationType !== AccountTypes.ADVERTISER
            ? 'The selected customers'
            : 'You'
        } haven’t created any campaigns yet. The campaign end dates will appear here once they are created.`,
      },
    },
    CampaignTypeFilter: {
      title: 'Campaign Type',
      filterFn: getCampaginTypeFilter,
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformDataWithKey('campaignTypeDetails'),
      fetchAllPages: true,
      columnDef: Schema.campaignTypeFilterColDef,
      pageSize: 30,
      initialSortingState: [{ id: 'id', desc: true }],
      rowIdKey: 'id',
      disableSearch: true,
      dateRangeSearch: true,
      emptyfilterMessage: {
        title: 'No campaign end dates found',
        subText: `${
          organizationType !== AccountTypes.ADVERTISER
            ? 'The selected customers'
            : 'You'
        } haven’t created any campaigns yet. The campaign end dates will appear here once they are created.`,
      },
    },
    DealTypeFilter: {
      title: 'Deal Type',
      filterFn: getDealTypeFilter,
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformDataWithKey('dealTypeList'),
      fetchAllPages: true,
      columnDef: Schema.dealTypeFilterColDef,
      pageSize: 30,
      initialSortingState: [{ id: 'id', desc: true }],
      rowIdKey: 'id',
      disableSearch: true,
      dateRangeSearch: true,
      emptyfilterMessage: {
        title: 'No campaign end dates found',
        subText: `${
          organizationType !== AccountTypes.ADVERTISER
            ? 'The selected customers'
            : 'You'
        } haven’t created any campaigns yet. The campaign end dates will appear here once they are created.`,
      },
    },
    DealIdFilter: {
      title: 'Deal ID',
      filterFn: getDealIDFilter,
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformDataWithKey('dealList'),
      fetchAllPages: true,
      columnDef: Schema.dealIdFilterColDef,
      pageSize: 30,
      initialSortingState: [{ id: 'id', desc: true }],
      rowIdKey: 'id',
      disableSearch: false,
      dateRangeSearch: false,
      emptyfilterMessage: {
        title: 'No Deal ID dates found',
        subText: `${
          organizationType !== AccountTypes.ADVERTISER
            ? 'The selected customers'
            : 'You'
        } haven’t created any Deals yet. The Deal ID will appear here once they are created.`,
      },
    },
    DealFilter: {
      title: 'Deal',
      filterFn: getDealFilter,
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformDataWithKey('dealList'),
      fetchAllPages: true,
      columnDef: Schema.dealFilterColDef,
      pageSize: 30,
      dialogWidth: 'lg',
      initialSortingState: [{ id: 'id', desc: true }],
      rowIdKey: 'id',
      disableSearch: false,
      dateRangeSearch: false,
      emptyfilterMessage: {
        title: 'No Deal found',
        subText: `${
          organizationType !== AccountTypes.ADVERTISER
            ? 'The selected customers'
            : 'You'
        } haven’t created any Deal yet. The Deals will appear here once they are created.`,
      },
    },
    CreativeGroupFilter: {
      title: 'Creative Group',
      filterFn: getCreativeGroupFilters,
      getNextPageParma: getNextPageParmaWithKey('creativeGroupList'),
      getTotalRecords: getTotalRecords,
      transformData: transformDataWithKey('creativeGroupList'),
      fetchAllPages: false,
      columnDef: Schema.creativeGroupFilterColDef,
      pageSize: 200,
      initialSortingState: [{ id: 'id', desc: true }],
      rowIdKey: 'creativeGroupId',
      emptyfilterMessage: {
        title: 'No creative groups found',
        subText: `${
          organizationType !== AccountTypes.ADVERTISER
            ? 'The selected customers'
            : 'You'
        } haven’t created any creative groups yet. The creative groups will appear here once they are created.`,
      },
    },
    CarrierFilter: {
      title: 'Carrier',
      filterFn: getV2FilterFn('/v2/master/segment/device-carrier'),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.carrierFilterColDef,
      pageSize: 50,
      rowIdKey: 'id',
    },
    CityFilter: {
      title: 'City',
      filterFn: getV3FilterFn('/v3/master/segment/city'),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.cityFilterColDef,
      pageSize: 50,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
    CongressionalDistrictFilter: {
      title: 'Congressional District',
      filterFn: getV3FilterFn('/v3/master/segment/congressional-district'),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.congressionalDistrictFilterColDef,
      pageSize: 30,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
    ConnectionTypeFilter: {
      title: 'Connection Type',
      filterFn: getV2FilterFn('/v2/master/segment/connection-type'),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.connectionTypeFilterColDef,
      pageSize: 50,
      rowIdKey: 'id',
    },
    CountiesFilter: {
      title: 'County',
      filterFn: getV3FilterFn('/v3/master/segment/county'),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.countiesFilterColDef,
      pageSize: 30,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
    CountryFilter: {
      title: 'Country',
      filterFn: getCountyFilter,
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.countryFilterColDef,
      pageSize: 30,
      // this dimension support multisort
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
    CreativeFilter: {
      title: 'Creative',
      filterFn: getCreativeFilter,
      getNextPageParma: getNextPageParmaWithKey('filteredList'),
      getTotalRecords: getTotalRecords,
      transformData: transformDataWithKey('filteredList'),
      fetchAllPages: false,
      columnDef: Schema.creativeFilterColDef,
      pageSize: 200,
      initialSortingState: [{ id: 'id', desc: true }],
      rowIdKey: 'creativeId',
      rowHeight: 200,
      dialogWidth: 'lg',
      emptyfilterMessage: {
        title: 'No creatives found',
        subText: `${
          organizationType !== AccountTypes.ADVERTISER
            ? 'The selected customers'
            : 'You'
        } haven’t created any creatives yet. The creatives will appear here once they are created.`,
      },
    },
    CreativeIdFilter: {
      title: 'Creative ID',
      filterFn: getCreativeFilter,
      getNextPageParma: getNextPageParmaWithKey('filteredList'),
      getTotalRecords: getTotalRecords,
      transformData: transformDataWithKey('filteredList'),
      fetchAllPages: false,
      columnDef: Schema.creativeIdFilterColDef,
      pageSize: 200,
      initialSortingState: [{ id: 'id', desc: true }],
      rowIdKey: 'creativeId',
      emptyfilterMessage: {
        title: 'No creative ids found',
        subText: `${
          organizationType !== AccountTypes.ADVERTISER
            ? 'The selected customers'
            : 'You'
        } haven’t created any creative yet. The creative ids will appear here once they are created.`,
      },
    },
    CreativeTypeFilter: {
      title: 'Creative Type',
      filterFn: getCreativeTypeFilter,
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformDataWithKey('creativeTypeList'),
      fetchAllPages: true,
      columnDef: Schema.creativeTypeFilterColDef,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
    ChannelFilter: {
      title: 'Channel',
      filterFn: getChannelFilter,
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.channelFilterColDef,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
    CustomerFilter: {
      title: 'Customer',
      filterFn: getCustomerFilters,
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.customerFilterColDef,
      pageSize: 30,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'owId',
    },
    CustomerIdFilter: {
      title: 'Customer ID',
      filterFn: getCustomerFilters,
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.customerIdFilterColDef,
      pageSize: 30,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'owId',
    },
    DeviceTypesFilter: {
      title: 'Device Type',
      filterFn: getV2FilterFn('/v2/master/segment/device-type'),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.deviceTypeFilterColDef,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
    DmaFilter: {
      title: 'DMA',
      filterFn: getV3FilterFn('/v3/master/segment/dma'),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.dmaFilterColDef,
      pageSize: 30,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
    EthnicityFilter: {
      title: 'Ethnicity',
      filterFn: getV2FilterFn('/v2/master/segment/ethnicity-group'),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.ethnicityFilterColDef,
      pageSize: 50,
      rowIdKey: 'id',
    },
    ExchangeFilter: {
      title: 'Exchange',
      filterFn: getExchangeFilter,
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.exchangeFilterColumnDef,
      initialSortingState: [{ id: 'name', desc: false }],
      pageSize: 50,
      rowIdKey: 'id',
    },
    GenderFilter: {
      title: 'Gender',
      filterFn: getV2FilterFn('/v2/master/segment/gender'),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.genderFilterColDef,
      pageSize: 50,
      rowIdKey: 'id',
    },
    IncomeRangeFilter: {
      title: 'Income Range',
      filterFn: getV2FilterFn('/v2/master/segment/income'),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.incomeRangeFilterColDef,
      pageSize: 50,
      rowIdKey: 'id',
    },
    LanguageFilter: {
      title: 'Language',
      filterFn: getV2FilterFn('/v2/master/segment/language'),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.languageFilterColDef,
      pageSize: 50,
      rowIdKey: 'id',
    },
    ManufacturerFilter: {
      title: 'Manufacturer',
      filterFn: getV2FilterFn('/v2/master/segment/device-manufacturer'),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.manufacturerFilterColDef,
      pageSize: 50,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
    OsFilter: {
      title: 'OS',
      filterFn: getV2FilterFn('/v2/master/segment/device-os'),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.osFilterColDef,
      pageSize: 50,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
    PlaybackMethodFilter: {
      title: 'Playback Method',
      filterFn: getV3FilterGetFn('/v3/master/creative/video/playback-method'),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.playbackMethodFilterColDef,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
    PlayerSizeFilter: {
      title: 'Player Size',
      filterFn: getV3FilterGetFn('/v3/master/creative/video/player-size'),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.playerSizeFilterColDef,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
    PlacementTypeFilter: {
      title: 'Placement Type',
      filterFn: getV3FilterGetFn('/v3/master/creative/video/placement-type'),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.placementTypeFilterColDef,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
    SkippabilityFilter: {
      title: 'Skippability',
      filterFn: getV3FilterGetFn('/v3/master/creative/video/skippability'),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.skippabilityFilterColDef,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
    RollPositionFilter: {
      title: 'Roll Position',
      filterFn: getV3FilterGetFn('/v3/master/creative/video/roll-position'),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.rollPositionFilterColDef,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
    SiteAndAppFilter: {
      title: 'Site and App',
      filterFn: getSiteAndAppFilter,
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: false,
      columnDef: Schema.SiteAndAppFilterColDef,
      pageSize: 50,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
    SiteAndAppIdFilter: {
      title: 'Site and App ID',
      filterFn: getSiteAndAppFilter,
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: false,
      columnDef: Schema.siteAndAppIdFilterColDef,
      pageSize: 50,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
    StatesHouseFilter: {
      title: 'State House',
      filterFn: getV3FilterFn('/v3/master/segment/house-district'),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.stateHouseFilterColDef,
      pageSize: 30,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
    StatesSenateFilter: {
      title: 'State Senate',
      filterFn: getV3FilterFn('/v3/master/segment/senate-district'),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.stateSenateFilterColDef,
      pageSize: 30,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
    StatesFilter: {
      title: 'State',
      filterFn: getV3FilterFn('/v3/master/segment/state'),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.stateFilterColDef,
      pageSize: 30,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
    ZipFilter: {
      title: 'Zip Code',
      filterFn: getV3FilterFn('/v3/master/segment/zip'),
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.zipFilterColDef,
      pageSize: 50,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
    DayFilter: {
      title: 'Day',
      filterFn: getDayFilter,
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.dayFilterColDef,
      pageSize: 50,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
    HoursFilter: {
      title: 'Hour',
      filterFn: getHoursFilter,
      getNextPageParma: getNextPageParma,
      getTotalRecords: getTotalRecords,
      transformData: transformData,
      fetchAllPages: true,
      columnDef: Schema.hoursFilterColDef,
      pageSize: 50,
      initialSortingState: [{ id: 'id', desc: false }],
      rowIdKey: 'id',
    },
  }
  return filterMetaData
}
