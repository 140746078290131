import { Dialog } from '@applift/factor'

import { useNavigate } from '@tanstack/react-router'
import { useEffect } from 'react'
import { ListType } from '../../models/List'
import { ActionMapper, ActionNameType } from './action'

interface ActionBarProps {
  action: ActionNameType
  rowData?: ListType
  onClose: (props?: any) => any
}

const EmptyComponent = () => {
  return null
}

export const ActionBar = (props: ActionBarProps) => {
  const { action, rowData, onClose } = props
  const navigate = useNavigate({ from: '/list' })

  useEffect(() => {
    if (action === 'RUN_NOW' && rowData) {
      navigate({
        to: `/edit/${rowData.id}`,
        search: { executeReport: true },
      })
    } else if (action === 'EDIT' && rowData) {
      navigate({
        to: `/edit/${rowData.id}`,
        search: { executeReport: false },
      })
    }
  }, [action, navigate, rowData])
  const currentAction = ActionMapper[action]

  const Component = currentAction?.component || EmptyComponent
  return (
    <Dialog open={Boolean(currentAction)} {...currentAction?.dialogProps}>
      <Component onClose={onClose} data={rowData!} action={action} />
    </Dialog>
  )
}
